/* eslint-disable no-unused-expressions */
import React from 'react';
import { Table } from 'semantic-ui-react';
import { LANGUAGES } from '../../../constants';

export function Summary({ state }) {
  return <Table structured celled size='small'>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell rowSpan='2'/>
        <Table.HeaderCell>Entity One</Table.HeaderCell>
        <Table.HeaderCell>Entity Two</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      { Object.keys(state.requestPayload).map(lang => {
        return <Table.Row key={ lang }>
          <Table.Cell>{ LANGUAGES[lang] }</Table.Cell>
          <Table.Cell>{ (state.requestPayload[lang] || {}).entityName || 'None' }</Table.Cell>
          <Table.Cell>{ (state.requestPayload[lang] || {}).secondEntityName || 'None' }</Table.Cell>
        </Table.Row>
      })}
    </Table.Body>
  </Table>
}
