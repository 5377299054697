import React, { Fragment } from 'react';
import { Form, Button, Icon } from 'semantic-ui-react';
import { FINANCIAL_STATEMENT_TYPES, ENTITY_TYPE } from '../constants';
import { updateKeystoneEntity } from '../../../../utils/api';

const FINANCIAL_CATEGORIES = FINANCIAL_STATEMENT_TYPES.reduce((acc, item) => {
  acc[item.key] = item.text;
  return acc;
}, {});


export function Statements({ state, setState, componentState, setComponentState, values, setValues }) {

  const translateFinancials = (lineItems) => {
    let container = [];
    for (let cat of FINANCIAL_STATEMENT_TYPES.map(item => item.key)) {
      if (lineItems[cat]) {
        container.push(lineItems[cat]);
      }
    }
    return container;
  }

  const handleOnRemoveLineItem = (item) => {
    setState(prevState => (
      { ...prevState, financialLineItems: {
        ...prevState.financialLineItems, [item.type]: prevState.financialLineItems[item.type].filter(itm => itm.label !== item.label)
      }}
    ))
    for (let year of componentState.years) {
      setState(prevState => {
        const modified = (((prevState.rawFinancials|| {})[year]|| {})[item.type]||{});
        delete modified[item.label];
        return { ...prevState, rawFinancials: {
          ...prevState.rawFinancials, [year]: {
            ...(prevState.rawFinancials[year] || {}), [item.type]: modified
          }
        }}
      })
    }
  }

  const handleOnChange = async (value, year, type, label, index) => {
    await setState(prevState => (
      { ...prevState, rawFinancials: {
        ...prevState.rawFinancials, [year]: {
          ...(prevState.rawFinancials[year] || {}), [type]: {
            ...((prevState.rawFinancials|| {})[year]|| {})[type],
            [label]: parseInt(value)
          }
        }
      }}
    ))

    setValues(prevState => {
      const stored = (prevState[label] || {}).values || [0, 0];
      stored[index] = parseInt(value);
      return {...prevState, [label]: {
        key: label,
        values: stored,
        magnitude: 1,
        indent: 0,
        bold: false,
        blank: false
      },
      
    }
    })    
  }

  const handleOnSubmit = async evt => {
    evt.preventDefault();
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))
    const layout = [
      {
        label: 'Income Statement',
        dates: componentState.years,
        data: state.financialLineItems.incomeStatement.map(item => item.label),
        currency: componentState.currency,
        magnitude: componentState.magnitude
      },
      {
        label: 'Balance Sheet',
        dates: componentState.years,
        data: state.financialLineItems.currentAssets.map(item => item.label).concat(
          state.financialLineItems.nonCurrentAssets.map(item => item.label),
          state.financialLineItems.currentLiabilities.map(item => item.label),
          state.financialLineItems.nonCurrentLiabilities.map(item => item.label),
          state.financialLineItems.equity.map(item => item.label)
        ),
        currency: componentState.currency,
        magnitude: componentState.magnitude
      },
    ]
    const clone = JSON.parse(JSON.stringify(values));
    const mapping = {
      'Total Comprehensive Income': 'incomeStatement',
      'Total current assets': 'currentAssets',
      'Total non-current assets': 'nonCurrentAssets',
      'Total current liabilities': 'currentLiabilities',
      'Total non-current liabilities': 'nonCurrentLiabilities',
      'Total equity': 'equity'
    }
    for (let lineItem of Object.keys(mapping)) {
      clone[lineItem] = {
        key: lineItem,
        values: parseValues(componentState.years, mapping[lineItem]),
        magnitude: componentState.magnitude,
        indent: 0,
        bold: true,
        blank: false
      }
    }
    const financials = {
      layout,
      values: Object.keys(clone).reduce((acc, lineItem) => {
        const object = JSON.parse(JSON.stringify(clone[lineItem]));
        object.currency = componentState.currency;
        object.dates = componentState.years;
        object.magnitude = componentState.magnitude;
        acc[lineItem] = object;
        return acc;
      }, {})
    }
    const payload = {
      entityType: ENTITY_TYPE,
      dataramaId: state.dataramaEntityId,
      estimated: state.estimated,
      financials: JSON.stringify(financials),
      rawFinancials: state.rawFinancials,
      financialLineItems: state.financialLineItems
    };
    await updateKeystoneEntity(JSON.stringify(payload));
    setState(prevState => (
      { ...prevState, isProcessing: false }
    ))
  }

  const parseValues = (years, itemType) => {
    let array = [];
    for (let year of years) {
      const value = Object.values((state.rawFinancials[year]||{})[itemType]|| {}).reduce((a, b) => (a + b), 0);
      array.push(value);
    }
    return array;
  }



  return <><Form size='small' style={{paddingLeft: '20px'}} onSubmit={ handleOnSubmit }>
    <div className='justify-space-between'>
      <Button onClick={() => setComponentState(prevState => ({ ...prevState, active: 'config' }))}
        type='button'
        content='Back'
        size='mini'
        disabled={state.isProcessing}
        loading={state.isProcessing} />
      <Button type='submit'
        content='Save'
        size='mini'
        disabled={state.isProcessing}
        loading={state.isProcessing} />
    </div>
  { translateFinancials(state.financialLineItems).map(category => (
    category.map((item, idx) => {
      return <Fragment key={ idx }>
        { idx === 0 && ['currentAssets', 'incomeStatement'].indexOf(item.type) !== -1 &&
          <>
            <br /><br /><Form.Group>
            <label style={{width: '300px', fontWeight: 'bold'}}>
              { item.type === 'currentAssets' ? 'Balance Sheet': 'Income Statement' }
            </label>
            <div style={{display: 'flex', justifyContent: 'flex-start'}}>
            { componentState.years.map(year => (
              <b key={year} style={{minWidth: '150.94px', padding: '0 6.5px'}}>{year}</b>
            ))}
          </div>
          </Form.Group>
          <br />
          </> }
        { idx === 0 &&
          <Form.Group><u>{ FINANCIAL_CATEGORIES[item.type] }</u><br /></Form.Group> }
        <Form.Group>
          <label style={{width: '300px'}}>{item.label}</label>
          { componentState.years.map((year, idx) => {
            let value = (((state.rawFinancials || {})[year] || {})[item.type] || {})[item.label] || 0;
            if (item.disabled) {
              value = Object.values((state.rawFinancials[year]||{})[item.type]|| {}).reduce((a, b) => (a + b), 0)
            }
            return <Form.Input
              key={year+item.label}
              id={year+item.label}
              className={item.type}
              disabled={item.disabled}
              size='mini'
              type='Number'
              value={value}
              onChange={ (evt, data) => handleOnChange(data.value, year, item.type, item.label, idx) } />
          })}
          { !item.disabled && <Icon color='grey' name='remove' onClick={() => handleOnRemoveLineItem(item)} /> }
        </Form.Group>
      </Fragment>
    })
  ))}
  <div>
    <div style={{paddingLeft: '300px',display: 'flex', justifyContent: 'flex-start'}}>
      { componentState.years.map(year => {
        const currentAssets = Object.values((state.rawFinancials[year]||{})['currentAssets']|| {}).reduce((a, b) => (a + b), 0);
        const nonCurrentAssets = Object.values((state.rawFinancials[year]||{})['nonCurrentAssets']|| {}).reduce((a, b) => (a + b), 0);
        const currentLiabilities = Object.values((state.rawFinancials[year]||{})['currentLiabilities']|| {}).reduce((a, b) => (a + b), 0);
        const nonCurrentLiabilities = Object.values((state.rawFinancials[year]||{})['nonCurrentLiabilities']|| {}).reduce((a, b) => (a + b), 0);
        const equity = Object.values((state.rawFinancials[year]||{})['equity']|| {}).reduce((a, b) => (a + b), 0);
        const difference = currentAssets + nonCurrentAssets - currentLiabilities - nonCurrentLiabilities - equity;
        return <b key={year} style={{minWidth: '150.94px', padding: '0 6.5px'}}>
          { difference === 0 ? '-' : difference }
        </b>
      })}
    </div>
  </div>

</Form><br /><br /></>
}