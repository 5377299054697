import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { addSentence } from '../../utils/api';

const IconsWrapper = styled.div`
  text-align: right;
`;

const Icon = styled.span`
	font-family: 'Material Icons';
  vertical-align: middle;
  color: ${({ color }) => color ? color : 'black'};
  :hover {
    cursor: pointer;
    font-size: 2rem;
  }
`;
const Paragraph = styled.div`
  display: inline;
`;

const Extract = styled(Paragraph)`
  display: inline;
  b {
    border-bottom: 2px solid red;
  }
`;

const CancelledExtract = styled(Paragraph)`
  display: inline;
  b {
    border-bottom: 2px solid red;
  }
  text-decoration: line-through;
`;


export function FeedbackWidget({article, sentence, mlSentences=null, loggedInUser, category}) {

  const [disabled, setDisabled] = useState(false);
  const [cancelled, setCancelled] = useState(false);

  useEffect(() => {
    async function fetching() {
      if (mlSentences !== null && (article.id in mlSentences)) {
        for (let line of mlSentences[article.id]) {
          if (line.extractContent === sentence.originalExtract) {
            setDisabled(true);
            if (!line.isRelevant) {
              setCancelled(true);
            }  
          }
        }
      }
      return () => {
        new AbortController().abort();
      };
    }
    fetching();
  }, mlSentences);


  const handleClick = (event, isRelevant) => {
    event.preventDefault();
    const {
      lang,
      title,
      dataType,
      datetime,
      url,
      articleId,
      searchTerms,
      concept,
      extractEntities,
      extractAdverseTerm,
      extractContent
    } = event.target.dataset;
    if (disabled) {
      return;
    }
    addSentence({
      lang,
      title,
      dataType,
      datetime,
      url,
      articleId,
      searchTerms,
      concept,
      extractEntities,
      extractAdverseTerm,
      extractContent,
      isRelevant
    });
    setDisabled(true);
    if (!isRelevant) {
      setCancelled(true);
    }
  }

  return (
    <>
    { cancelled 
      ? <CancelledExtract dangerouslySetInnerHTML={{ __html: sentence.extract }} />
      : <Extract dangerouslySetInnerHTML={{ __html: sentence.extract }} /> }
    { !disabled &&
      <IconsWrapper>
      <Icon
        data-lang={article.lang}
        data-title={article.title}
        data-data-type={article.dataType}
        data-datetime={article.dateTime}
        data-url={article.url}
        data-article-id={article.id}
        data-search-terms={article.searchTerms}
        data-concept={article.category}
        data-extract-entities={ sentence.entities ? sentence.entities.join(', ') : '' }
        data-extract-adverse-term={ Array.isArray(sentence.term) ? sentence.term.join(', ') : sentence.term }
        data-extract-content={sentence.originalExtract}
        onClick={evt => handleClick(evt, true)}
        color={disabled ? 'grey' : 'green'}>thumb_up</Icon>
      <Icon
        data-lang={article.lang}
        data-title={article.title}
        data-data-type={article.dataType}
        data-datetime={article.dateTime}
        data-url={article.url}
        data-article-id={article.id}
        data-search-terms={article.searchTerms}
        data-concept={article.category}
        data-extract-entities={ sentence.entities ? sentence.entities.join(', ') : '' }
        data-extract-adverse-term={ Array.isArray(sentence.term) ? sentence.term.join(', ') : sentence.term }
        data-extract-content={sentence.originalExtract}
        onClick={evt => handleClick(evt, false)}
        color={disabled ? 'grey' : 'red'}>thumb_down</Icon>
    </IconsWrapper>
    }
    <br />
    </>
  )
}