import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { withRouter } from 'react-router';

const BreadcrumbComponent = (props) => {
  const { entityId, entityType } = props.match.params;
  const { pathname } = props.location;
  return (
    <Breadcrumb>
      <Breadcrumb.Section
        link
        onClick={() => props.history.push('/research/pepblacklist')}
        >Top</Breadcrumb.Section>
      <Breadcrumb.Divider />
      <Breadcrumb.Section
        link
        onClick={() => props.history.push(`/research/pepblacklist/byId/${entityType}`)}
        active={pathname === `/research/pepblacklist/byId/${entityType}`}
        >{entityType}</Breadcrumb.Section>
      <Breadcrumb.Divider />
      <Breadcrumb.Section active>{entityId}</Breadcrumb.Section>
    </Breadcrumb>
  )
}

export default withRouter(BreadcrumbComponent);
