import React from 'react';
import DtrmID from '../../components/DtrmID';

class Client {
  constructor() {
    this._fieldDetails = {
      'clientId': {
        Header: 'Client ID',
        id: 'clientId',
        accessor: (value) => {
          return (<DtrmID id={value.entityId} type={value.entityType} />)
        },
        type: 'integer',
      },
      name: {
        Header: 'Name',
        id: 'name',
        accessor: 'name',
        type: 'string',
        required: true,
        input: {
          type: 'text',
        }
      },
      clientType: {
        Header: 'Client Type',
        id: 'clientType',
        accessor: 'clientType',
        type: 'string',
        required: true,
        input: {
          type: 'dropdown',
          source: 'clientType',
        }
      },
      subscriptionType: {
        Header: 'Subscription Type',
        id: 'subscriptionType',
        accessor: 'subscriptionType',
        type: 'string',
        input: {
          type: 'dropdown',
          source: 'clientSubscriptionType'
        }
      },
      accountManager: {
        Header: 'Account manager',
        id: 'accountManager',
        accessor: 'accountManager',
        type: 'array',
        fieldTransform: (data) => data.map(d => JSON.parse(d).id),
        displayTransform: (data) => data.map(d => {
          return JSON.parse(d).display
        }).join(', '),
        input: {
          type: 'dropdown',
          multiple: true,
        }
      },
      startDate: {
        Header: 'Subscription Start Date',
        id: 'startDate',
        accessor: 'startDate',
        type: 'date',
        input: {
          type: 'date',
        }
      },
      endDate: {
        Header: 'Subscription End Date',
        id: 'endDate',
        accessor: 'endDate',
        type: 'date',
        input: {
          type: 'date',
          noMax: true,
        }
      },
      quota: {
        Header: 'Total Requests Size',
        id: 'quota',
        accessor: 'quota',
        type: 'integer',
        input: {
          type: 'text',
          meta: {
            type: 'number',
            min: 1,
            max: 500
          }
        }
      },
      usedQuota: {
        Header: 'Used Quota',
        id: 'usedQuota',
        accessor: 'usedQuota',
        type: 'integer',
        input: {
          type: 'text',
          dataType: 'number'
        }
      },
      lastUpdate: {
        Header: 'Last Update',
        id: 'lastUpdate',
        accessor: 'lastUpdate',
        type: 'date',
        input: {
          type: 'date',
        }
      }
    };

    this.newGroupFields = [
      ['name', 'clientType'],
      ['accountManager'],
      ['startDate', 'endDate'],
      ['subscriptionType', 'quota']
    ]

  }

  getColumns() {
    return this.columns && this.columns.map(col => {
      return this._fieldDetails[col];
    });

  }

  getNewGroupedFields() {
    return this.newGroupFields && this.newGroupFields.map(group => group.map(field => this._fieldDetails[field]));
  }

  getFields() {
    return this._fieldDetails;
  }

  // transformData(data) {
  //   const removeNull = (value) => value === 'null' || value === 'NULL' ? null : value;
  //
  //   const output = Object.keys(data).reduce((a, c) => {
  //     if (c === 'aliases' && data[c]) {
  //       const aliases = data[c].split('||||');
  //       let count = 1;
  //       aliases.forEach(alias => {
  //         if (alias) {
  //           const aliasDetails = alias.split(';');
  //           a[`entityAlias${count}`] = aliasDetails[0];
  //           a[`langId${count}`] = aliasDetails[1];
  //           count++;
  //         }
  //       })
  //     } else if (this._fieldDetails[c] &&
  //       this._fieldDetails[c].input &&
  //       this._fieldDetails[c].input.source === 'yesNo') {
  //         a[c] = data[c] ? 'yes' : 'no'
  //       } else {
  //         a[c] = removeNull(data[c]);
  //       }
  //     return a;
  //   }, {});
  //   return output;
  // }

}

export default Client;
