import * as firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAorfh_Z34dFOiwscPC8AY6JjXXPLOsQnE",
  authDomain: "dtrm-portal.firebaseapp.com",
  databaseURL: "https://dtrm-portal.firebaseio.com",
  projectId: "dtrm-portal",
  storageBucket: "dtrm-portal.appspot.com",
  messagingSenderId: "886820097036"
};
firebase.initializeApp(firebaseConfig);

export const fbdb = firebase.database();
