import React, { useState } from 'react';
import { Button, Grid, Header, Form, Message, Segment,  } from 'semantic-ui-react';
import { checkEntityIdsForCapiqId } from '../../utils/api';

export function EntitiesDeduplication() {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [message, setMessage] = useState(null);
  const SEARCH_FIELDS = [
    {
      label: 'Dtrm ID of entity to be kept',
      key: 'entityId_keep'
    },
    {
      label: 'Dtrm ID of entity to be removed',
      key: 'entityId_remove'
    },
  ];

  const handleOnChange = (event, { id, value }) => {
    event.preventDefault();
    setForm(prevState => {
      if (id === 'entityId_keep') {
        return { ...prevState, entityId_keep: value }
      }
      if (id === 'entityId_remove') {
        return { ...prevState, entityId_remove: value }
      }
    })    
  }

  const handleCheck = async (event) => {
    setLoading(true);
    const body = JSON.stringify({
      entityId_keep: form.entityId_keep,
      entityId_remove: form.entityId_remove
    })
    const result = await checkEntityIdsForCapiqId(body);
    if (result && result.errorMessage) {
      setMessage(result);
    }
    if (result && result.entityId) {
      setMessage(result.entityId); // removed entity
    }
    setLoading(false);
  }

  return (
    <Segment placeholder>
      <Grid columns={1}>
        <Grid.Row stretched>
          <Grid.Column>
            <Header icon>
              Deduplication of entities
            </Header>
            <Form onSubmit={handleCheck}>
              { SEARCH_FIELDS.map(field => (
                <Form.Input
                  size='small'
                  type='number'
                  disabled={loading}
                  required
                  id={field.key}
                  key={field.key}
                  value={form[field.key] || ''}
                  onChange={handleOnChange}
                  fluid label={ field.label }
                  placeholder={field.label}  /> )) }
              <Button
                content='Check and deduplicate'
                size='small'
                type="submit"
                loading={loading}
                disabled={loading} />
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column />
          <Grid.Column>
            { message && message.errorMessage &&
              <Message negative>
                <b>{ message.errorMessage }</b>
              </Message> }
            { message && !message.errorMessage &&
            <Message positive>
              <b>{`Entity ${message} has been removed successfully.`}</b>
            </Message> }
          </Grid.Column>
          <Grid.Column />
        </Grid.Row>
      </Grid>
    </Segment>
  )  
}