import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dimmer, Segment, Button, Accordion, Form, Icon, Table, Label, Message } from 'semantic-ui-react';
import { validateEntity, pushNewCapiqEntity } from '../utils/api';
import FIELDS from '../model/entity/entityName';
import { getAuth, getSettings } from '../reducers/selectors';
import Loading from './Loading';
import ES from './validate/ES';
import DtrmID from './DtrmID';
import ValueFromState from './ValueFromState';
import capiqColumns from '../utils/ref/capiqColumns';
import * as tableUtils from '../utils/tableUtils';

const Wrapper = styled.div`
  overflow: auto;
`;
const Scrollable = styled.div`
  overflow-x: scroll;
`;

const ActionWrapper = styled.div`
  padding: 10px 0 20px 0;
`;

const content = {
  capiq: {
    color: 'red',
    header: 'Capital IQ Matches'
  },
  dtrm: {
    color: 'blue',
    header: 'Datarama (private_04) Matches'
  },
  es: {
    color: 'orange',
    header: 'Platform search (Top 10)'
  }
};

class ValidateEntity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputVal: FIELDS[this.props.type] || {},
      importList: {},
      message: null,
      activeIndex: [],
      isLoading: false,
      loading: {},
    }

    this.setLoadingStatus = (field, status) => {
      this.setState(() => ({
        loading: status ? {
          [field]: status
        } : {},
      }))
    }

    this.updateSearchResults = this.updateSearchResults.bind(this);
    this.searchAgain = this.searchAgain.bind(this);

    this.handleAccordionChange = (e, { index }) => {
      this.setState(prevState => ({
        activeIndex: prevState.activeIndex.indexOf(index) !== -1 ? prevState.activeIndex.filter(p => p !== index) : prevState.activeIndex.concat(index),
      }))
    }

    this.selectRow = (e, { checked, label, data }) => {
      if (!data.imported) {
        this.setState(() => ({
          selectedCapIqEntity: checked ? data : null,
        }))
      }
    }

    this.pushEntity = async () => {
      const { selectedCapIqEntity } = this.state;
      if (selectedCapIqEntity) {
        this.setLoadingStatus('pushEntity', true);
        const newDataramId = await pushNewCapiqEntity(this.props.type, selectedCapIqEntity, (status) => {
        });
        this.setState(prevState => {
          const nextState = {
            selectedCapIqEntity: null,
            loading: {}
          }
          if (newDataramId) {
            nextState.importList = Object.assign({}, prevState.importList, {
              [selectedCapIqEntity.capiqId]: newDataramId,
            });
            nextState.message = {
              type: 'success',
              entityId: newDataramId,
            }

          }
          return nextState;
        })

      }
    }

    this.searchByName = async () => {
      const { inputVal } = this.state;
      const { dtrmOnly } = this.props;
      const body = Object.keys(FIELDS[this.props.type]).reduce((a, field) => {
        if (inputVal[field].required && !inputVal[field].value) return false;
        if (inputVal[field].value) {
          a[field] = inputVal[field].value
        }
        return a;
      }, {});
      if (body) {
        this.setState(() => ({
          isLoading: true,
        }))
        if (dtrmOnly) {
          body.scope = ['dtrm']
        }
        // send to check
        const result = await validateEntity(this.props.type, body);

        if (result) {
          this.setState(prevState => ({
            matches: {
              result,
              search: body
            },
            inputVal: {},
            isLoading: false,
            activeIndex: [0, 1, 2]
          }))
          if (this.props.onDataFetch) {
            this.props.onDataFetch(true);
          }
        }
        else {
          this.setState(() => ({
            isLoading: false,
          }))
        }
      }
    }

    this.handleValueChange = (e, { name, value }) => {
      this.setState(prevState => ({
        inputVal: Object.assign({}, prevState.inputVal, {
          [name]: Object.assign({}, prevState.inputVal[name], {
            value,
          }),
        })
      }))
    }
  }

  updateSearchResults(type, newHits) {
    if (type !== 'es') return;
    this.setState(prevState => ({
      matches: {
        ...prevState.matches,
        result: {
          ...prevState.matches.result,
          es: {
            ...prevState.matches.result.es,
            hits: {
              ...prevState.matches.result.es.hits,
              ...newHits
            }
          }
        }
      }
    }))

  }

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type && this.props.type) {
      this.searchAgain();
    }
  }

  searchAgain() {
    if (this.props.onDataFetch) {
      this.props.onDataFetch(false);
    }
    this.setState(() => ({
      matches: null,
      inputVal: FIELDS[this.props.type] || {},
      importList: {},
      message: null,
      activeIndex: [],
      isLoading: false,
    }))
  }

  render() {
    const { type, scope, canPushEntity, selectionAction } = this.props;

    const {
      inputVal,
      activeIndex,
      isLoading,
      matches,
      selectedCapIqEntity,
      importList,
      message,
      loading
    } = this.state;

    let displayScope = scope || {
      capiq: true,
      dtrm: true,
      es: true,
    };
    const columns = capiqColumns(canPushEntity)[type];

    const accordionHeader = (type, count = 0) => {
      const displayContent = content[type];
      if (!displayContent) return;
      return (
        <React.Fragment>
          <Label size='large' color={displayContent.color}>{displayContent.header}</Label>{' '}<Label circular >{count}</Label> matches
        </React.Fragment>
      )
    }

    const cellContent = (col, value, dataramaId, disabled) => {
      if (col.isBoolean) {
        return value ? 'Yes' : 'No'
      }
      if (col.isStateValue) {
        return <ValueFromState field={col.stateKey} value={value} displayField={col.stateValue} />
      }
      if (col.isLink && !disabled) {
        return (
          <DtrmID id={value} type={type} link action={selectionAction} />
        )
      }
      return value;
    }

    return (
      <Wrapper>
        {message && message.type === 'success' && (
          <Message
            success
            header='Successfully added entity as a new Datarama Entity'
            content={`New Datarama ID: ${message.entityId}`}
          />
        )}
        {!matches ? (
          <Segment>
            <Dimmer.Dimmable as={Form} dimmed={isLoading} blurring onSubmit={this.searchByName}>
              <Form.Group widths='equal'>
              {FIELDS[type] && Object.keys(FIELDS[type]).map(field => (
                <Form.Input
                  key={field}
                  fluid
                  name={field}
                  label={FIELDS[type][field].label}
                  placeholder={FIELDS[type][field].placeholder}
                  onChange={this.handleValueChange}
                  value={(inputVal[field] || {}).value || ''}
                  required={FIELDS[type][field].required}
                />
              ))}
              </Form.Group>
              <Button type="submit">Search</Button>
            </Dimmer.Dimmable>
            <Loading active={isLoading}/>
          </Segment>
        ) : (
          <React.Fragment>
            <Button onClick={this.searchAgain} basic compact size='small'><Icon name='arrow left' /> Search Again</Button>
            <Segment>
              Current Search: {' '}
              {matches.search && Object.keys(FIELDS[type]).map(field => matches.search[field] ? (
                <Label key={field} size='large'>{matches.search[field]}</Label>
              ): null)}
              {!canPushEntity && (
                <Message
                  size='mini'
                  negative
                  header='You do not have the access right to add new datarama entity from CapIQ.'
                  content='You can only check if entity exists. If you wish to have the right, please contact tech team or analyst team'
                />
              )}
            </Segment>
            <Accordion fluid exclusive={false}>
              {displayScope.capiq && (
                <React.Fragment>
                  <Accordion.Title active={activeIndex.indexOf(0) !== -1} index={0} onClick={this.handleAccordionChange}>
                    <Icon name='dropdown' />
                    {accordionHeader('capiq', matches.result && matches.result.capiq && matches.result.capiq.length)}
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex.indexOf(0) !== -1}>
                  <Scrollable>
                  {matches.result && matches.result.capiq && (
                    <Table compact size='small' celled>
                      <Table.Header>
                        <Table.Row>
                          {columns.map(col => (
                            <Table.HeaderCell key={col.value}>{col.label}</Table.HeaderCell>
                          ))}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {matches.result.capiq.map(match => (
                          <Table.Row key={match.capiqId}>
                            {columns.map(col => (
                              <Table.Cell key={col.value}>{col.key ? col.value(match, col.key, (selectedCapIqEntity || {}).capiqId === match[col.key], importList[match.capiqId] || match.imported, this.selectRow) : match[col.value]}</Table.Cell>
                            ))}
                          </Table.Row>
                        ))}
                      </Table.Body>
                      {selectedCapIqEntity && (
                        <Table.Footer fullWidth>
                          <Table.Row>
                            <Table.HeaderCell colSpan={columns.length}>
                                <React.Fragment>
                                  <Button size='small' onClick={this.pushEntity} loading={loading.pushEntity} disabled={loading.pushEntity}>Add selected entity to Datarama Database</Button> <span>(you will get a new Datarama ID for the entity)</span>
                                </React.Fragment>
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Footer>
                      )}

                    </Table>
                  )}
                  </Scrollable>
                </Accordion.Content>
              </React.Fragment>
            )}
            {displayScope.dtrm && (
              <React.Fragment>
                <Accordion.Title active={activeIndex.indexOf(1) !== -1} index={1} onClick={this.handleAccordionChange}>
                  <Icon name='dropdown' />
                  {accordionHeader('dtrm', matches.result && matches.result.dtrm && matches.result.dtrm.length)}
                </Accordion.Title>
                <Accordion.Content active={activeIndex.indexOf(1) !== -1}>
                {matches.result && matches.result.dtrm && (
                  <Table compact size='small' celled>
                    <Table.Header>
                      <Table.Row>
                        {tableUtils.dtrmColumns[type].map(col => (
                          <Table.HeaderCell
                            key={col.label}
                            rowSpan={col.items ? 1 : 2}
                            colSpan={col.items ? col.items.length : 1}
                            width={col.width || 1}
                          >{col.label}</Table.HeaderCell>
                        ))}
                      </Table.Row>
                      <Table.Row>
                        {tableUtils.dtrmColumns[type].map(col => col.items ? col.items.map(item => (
                          <Table.HeaderCell key={item.value} width={item.width || 1}>{item.label}</Table.HeaderCell>
                        )) : null)}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {matches.result.dtrm.map(match => (
                        <Table.Row key={match.entityId}>
                          {tableUtils.dtrmColumns[type].map(col => col.items ? col.items.map(item => (
                            <Table.Cell key={item.value}>
                              {item.isBoolean ? (match[item.value] ? 'Yes' : 'No') : match[item.value]}
                            </Table.Cell>
                          )) : (
                            <Table.Cell key={col.value}>
                              {cellContent(col, match[col.value], match.entityId, match.doNotUseFlag)}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
                </Accordion.Content>
              </React.Fragment>
            )}
            {displayScope.es && (
              <React.Fragment>
                <Accordion.Title active={activeIndex.indexOf(2) !== -1} index={2} onClick={this.handleAccordionChange}>
                  <Icon name='dropdown' />
                  {accordionHeader('es', matches.result && matches.result.es && matches.result.es.total)}
                </Accordion.Title>
                <Accordion.Content active={activeIndex.indexOf(2) !== -1}>
                  <Scrollable>
                    {matches.result && matches.result.es && (
                      <ES
                        results={matches.result.es}
                        currentSearch={matches.search}
                        type={type}
                        action={selectionAction}
                        updateSearchResults={this.updateSearchResults}
                      />
                    )}
                  </Scrollable>
                </Accordion.Content>
                </React.Fragment>
              )}
            </Accordion>
            <ActionWrapper>
              <Button onClick={this.searchAgain} fluid basic size='large'>Search Again</Button>
            </ActionWrapper>
          </React.Fragment>
        )}
      </Wrapper>
    )
  }
}

const mapStateToProps = state => {
  const permissions = (getAuth(state).permissions || {}).general;
  const capiqSettings = getSettings(state).capiq;
  return {
    canPushEntity: permissions && permissions['capiqEntity.create'],
    capiqSettings,
  }
};

export default withRouter(connect(mapStateToProps)(ValidateEntity));
