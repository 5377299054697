import React from 'react';
import { withRouter } from 'react-router';
import { Button } from 'semantic-ui-react';

const GotoPath = ({ history, path, display }) => {
  return (
    <Button
      onClick={() => {
        history.push(path)
      }}
    >
    {display}
    </Button>
  )
}

export default withRouter(GotoPath);
