import React from 'react';
import DtrmID from '../DtrmID';

// Component to display after successful Ingestion Record placed.
export default ({ entityId, type }) => {
  return (
    <React.Fragment>
      Datarama ID updated: <DtrmID id={entityId} type={type} link/> (Note: Platform page will NOT be ready until importer is run)
    </React.Fragment>
  )
}
