import React from 'react';
import { Button } from 'semantic-ui-react';
import { updateKeystoneEntity } from '../../../../utils/api';
import { CustomTextArea } from '../CustomTextArea';
import { ENTITY_TYPE } from '../constants';

export function PublicProfile({ setState, state }) {

  const handleOnSubmit = async evt => {
    evt.preventDefault();
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))
    await updateKeystoneEntity(JSON.stringify({
      dataramaId: state.dataramaEntityId,
      entityType: ENTITY_TYPE,
      publicProfile: state.publicProfile
    }))
    setState(prevState => (
      { ...prevState,
        completed: { ...prevState.completed, publicProfile: true },
        isProcessing: false }
    ))
  }
  
  return <div className='segment publicProfile'>
  <div className='justify-space-between'>
    <Button size='mini' content='Save' type='button' onClick={ handleOnSubmit } loading={ state.isProcessing } disabled={ state.isProcessing } />
  </div>
  <CustomTextArea setState={ setState } state={ state } />
</div>

}