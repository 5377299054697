import React from 'react';

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.onClick(this.props.value);
  }

  render() {
    const { component: Comp , disabled, selected, highlight, type, meta } = this.props;
    if (Comp) {
      return (
        <Comp onClick={this.onClick} type={type} selected={selected} highlight={highlight} disabled={disabled} {...meta}>{this.props.label}</Comp>
      )
    }
    return (
      <button onClick={this.onClick} disabled={disabled}>{this.props.label}</button>
    )
  }
}

Button.defaultProps = {
  disabled: false
}

export { Button };
