import React, { useEffect, useState, useCallback } from 'react';
import { ContentTable } from './ContentTable';
import { Disclaimer } from './Disclaimer';
import { BasicInformation } from './BasicInformation';
import { AdverseMediaAnalysis } from './AdverseMediaAnalysis';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Blacklist } from './Blacklist';
import './LiteEDDTemplate.scss';
import { getPreviewReport } from '../../../utils/api';
import moment from 'moment';

export function LiteEDDTemplate() {
  const [report, setReport] = useState({});
  const [lastPage, setLastPage] = useState(4);
  useEffect(() => {
    async function fetching() {
      const reportId = window.location.pathname.split('/')[4];
      const {
        entityName,
        basicInformation,
        totalArticlesCount,
        adverseArticlesCount,
        blacklist,
        politicalExposure,
        articles,
        contentPages,
        isCompany
      } = await getPreviewReport(reportId);
      setReport({
        entityName,
        basicInformation,
        totalArticlesCount,
        adverseArticlesCount,
        blacklist,
        politicalExposure,
        articles,
        contentPages,
        isCompany
      });
      
      return () => {
        new AbortController().abort();
      };
    }
    fetching();
  }, []);

  const computeLastPage = (node) => {
    let page = 4;
    const FIRST_PAGE = 1122.519685 - 240;
    const NORMAL_PAGE = 1122.519685 - 240;
    if (node && !(node.getBoundingClientRect().height <= FIRST_PAGE)) {
      const additionalPages = Math.ceil((node.getBoundingClientRect().height - FIRST_PAGE)/NORMAL_PAGE);
      page = page + additionalPages;
    }
    return page;
  }
  
  const detailsRef = useCallback(node => {
    // TODO: setTimeout to use DOM method instead
    if (node !== null) {
      setTimeout(() => {
        const page = computeLastPage(node);
        setLastPage(page);
      }, 1000)
    }
  }, [(report.articles || {}).length]);

  if (Object.keys(report).length === 0 && !report.articles) {
    return <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>;
  }

  return <div className='liteEDDTemplate'>
      <div className="pageHeader">
        <img className='dtrmHeader' src={`/datarama_header.png`} alt='datarama_header' />
        <br/>
        <button type="button" onClick={() => window.print()} style={{background: 'pink'}}>
          PRINT AS PDF
        </button>
      </div>
      <div className="pageFooter">
        <img className='dtrmFooter' src={`/datarama_footer.png`} alt='datarama_footer' />
      </div>
      <table>
        <thead>
          <tr>
            <td>
              <div className="pageHeaderSpace"></div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="page pageOne">
                <div className='buffer placeholder' />
                <div className='title'>
                  <div>LiteEDD</div>
                  <div className='buffer' />
                  <div>Report</div>
                </div>
                <div className='buffer placeholder' />
                <div className='body'>
                  {moment(Date.now()).format('DD MMMM YYYY')}
                  <br />
                  ___________________________________________
                  <div className='imageWrapper'>
                  <img className='dtrmEntityLogo' src={ report.isCompany ? '/company_logo.png' : '/person_logo.png' } alt='datarama_header' />
                  <div>{ report.entityName }</div>
                  </div>
                  ___________________________________________
                </div>
              </div>
              <div className="page pageTwo">
                <div className='contentPageTitle'>Table of Contents</div>
                <div className='contentPageWrapper'>
                  <ContentTable report={ report } lastPage={ lastPage } />
                </div>
                <div className='contentPageWrapper'>
                  <Disclaimer />
                </div>
              </div>
              <div className="page pageThree" ref={detailsRef}>
                <BasicInformation report={ report } />
                <AdverseMediaAnalysis report={ report } />
              </div>
              <div className="page pageFour">
                <Blacklist report={ report } />
                <div className='fineprint'>Full list of blacklists/sanctions lists is available upon request</div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="pageFooterSpace"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
}