import React from 'react';
import { Icon, Checkbox, Label } from 'semantic-ui-react';
import DtrmID from '../../components/DtrmID';
import { ENTITY_TYPE } from './entityType';

export default (editable) => ({
  [ENTITY_TYPE.person]: [{
    label: 'Capiq ID',
    key: 'capiqId',
    value: (value, field, checked, imported, cb) => {
      if (imported || !editable || value.doNotUseFlag) {
        // imported is boolean if already imported before validation
        return value[field]
      }
      return ((<Checkbox label={value[field]} data={value} checked={checked} onClick={cb} />))
    }
  }, {
    label: 'Marked as do-not-use',
    key: 'doNotUseFlag',
    value: (value, field) => {
      return value[field] ? <Label basic color='red' size='mini'>Not in Use</Label> : ''
    }
  }, {
    label: 'Imported',
    key: 'imported',
    value: (value, field, checked, imported) => {
      if (value.doNotUseFlag) return <Label>Imported</Label>
      return imported ? (<span><Icon name='check circle' color='green' /><br/><Label><DtrmID id={value.entityId || imported} /></Label></span>) : 'Not yet imported'
    }
  }, {
    label: 'Prefix',
    value: 'prefix'
  }, {
    label: 'First Name',
    value: 'firstName'
  }, {
    label: 'Middle Name',
    value: 'middleName'
  }, {
    label: 'Last Name',
    value: 'lastName'
  }, {
    label: 'Suffix',
    value: 'suffix'
  }, {
    label: 'Phone',
    value: 'phoneValue'
  }, {
    label: 'Email address',
    value: 'emailAddress'
  }, {
    label: 'Salutation',
    value: 'salutation'
  }, {
    label: 'Year born',
    value: 'yearBorn',
  }],
  [ENTITY_TYPE.company]: [{
    label: 'Capiq ID',
    key: 'capiqId',
    value: (value, field, checked, imported, cb) => {
      if (imported || !editable || value.doNotUseFlag) {
        // imported is boolean if already imported before validation
        return value[field]
      }
      return ((<Checkbox label={value[field]} data={value} checked={checked} onClick={cb} />))
    }
  }, {
    label: 'Marked as do-not-use',
    key: 'doNotUseFlag',
    value: (value, field) => {
      return value[field] ? <Label basic color='red' size='mini'>Not in Use</Label> : ''
    }
  }, {
    label: 'Imported',
    key: 'imported',
    value: (value, field, checked, imported) => {
      if (value.doNotUseFlag) return <Label>Imported</Label>
      return imported ? (<span><Icon name='check circle' color='green' /><br/><Label><DtrmID id={value.entityId || imported} /></Label></span>) : 'Not yet imported'
    }
  }, {
    label: 'Name',
    value: 'companyName'
  }, {
    label: 'City',
    value: 'city'
  },
  {
    label: 'Status Type',
    key: 'companyStatusTypeId',
    sourceFromState: 'companyStatusType',
    // value: 'companyStatusType',
    value: (value, field) => {
      return value[field];
    }
  }, {
    label: 'Company Type',
    value: 'companyType'
  }, {
    label: 'Address',
    value: 'streetAddress'
  }, {
    label: 'Address 2',
    value: 'streetAddress2'
  }, {
    label: 'Address 3',
    value: 'streetAddress3'
  }, {
    label: 'Address 4',
    value: 'streetAddress4'
  }, {
    label: 'Zip Code',
    value: 'zipCode'
  }, {
    label: 'Country ID',
    value: 'countryId'
  }, {
    label: 'Year founded',
    value: 'yearFounded',
  }, {
    label: 'Month founded',
    value: 'monthFounded',
  }, {
    label: 'Day founded',
    value: 'dayFounded',
  }, {
    label: 'Incorporation Country',
    value: 'incorporationCountryId'
  }, {
    label: 'Incorporation state',
    value: 'incorporationStateId'
  }, {
    label: 'Webpage',
    value: 'webpage'
  }]
})
