import { SET_SETTINGS } from '../actions/actionTypes';

const initalState = {};

export default (state = initalState, action) => {
  switch(action.type) {
    case SET_SETTINGS: {
      return {
        ...state,
        // users: action.settings.users,
        users: action.settings.users && action.settings.users.reduce((a, c) => {
          a[c._id] = c;
          return a;
        }, {}),
        roles: action.settings.roles && action.settings.roles.reduce((a, c) => {
          a[c.identifier] = c;
          return a;
        }, {}),
        clients: action.settings.clients && action.settings.clients.reduce((a, c) => {
          a[c._id] = c;
          return a;
        }, {}),
        capiq: action.settings.capiq,
        languages: action.settings.languages && action.settings.languages.reduce((a, c) => {
          a[c._id] = c;
          return a
        }, {}),
        sources: action.settings.sources && action.settings.sources.reduce((a, c) => {
          a[c.sourceId] = c;
          return a
        }, {}),
        riskTypes: action.settings.riskTypes && action.settings.riskTypes.reduce((a, c) => {
          a[c.riskTypeId] = c;
          return a
        }, {})

      }
    }
    default:
      return state;
  }
}
