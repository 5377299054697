import React from 'react';
import { Input, Form, Button, Header, Label, Segment, Message } from 'semantic-ui-react';
import { searchPepByName } from '../../utils/api';
import ListView from './ListView';

const MIN_LENGTH = 3;

class SearchByName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      nameList: this.props.multiple && [''],
    };
    this.nameOnChange = this.nameOnChange.bind(this);
    this.namesOnChange = this.namesOnChange.bind(this);
    this.search = this.search.bind(this);
    this.setLoadingStatus = this.setLoadingStatus.bind(this);
    this.addTerm = () => {
      this.setState(prevState => ({
        nameList: prevState.nameList.concat('')
      }))
    };
    this.clear = (e) => {
      e.preventDefault();
      this.setState(() => ({
        loading: false,
        name: null,
        searchResult: null,
        onError: null,
        searchMultipleResult: null,
        nameList: this.props.multiple && ['']
      }))
    }
  }

  nameOnChange(e, { value }) {
    this.setState(() => ({
      name: value,
    }))
  }

  namesOnChange(e, { value, name }) {
    this.setState(prevState => {
      const newNameList = prevState.nameList;
      newNameList[name] = value;
      return {
        nameList: newNameList,
      }
    })
  }

  async search(e, { value }) {
    e.preventDefault();
    const isMultiple = value && value === 'all';
    if (isMultiple) {
      const { nameList } = this.state;
      if (!nameList) return;
      const filterNameList = nameList.filter(p => p && p.length > MIN_LENGTH);
      if (filterNameList.length === 0) {
        this.setState(() => ({
          onError: 'Invalid name input'
        }));
        return;
      };
      this.setLoadingStatus(true);
      const result = await searchPepByName(filterNameList, true);
      this.setState(() => ({
        searchMultipleResult: result.reduce((a, c, index) => {
          a[filterNameList[index]] = c;
          return a;
        }, {}),
        loading: false,
      }))
    } else {
      const { name } = this.state;
      if (!name) return;
      this.setLoadingStatus(true);
      const result = await searchPepByName(name);
      this.setState(() => ({
        searchResult: result,
        loading: false,
      }))
    }
  }

  setLoadingStatus(status) {
    this.setState(() => ({
      loading: status,
    }))
  }

  render() {
    const { loading, searchResult, nameList, searchMultipleResult, onError } = this.state;
    const { multiple } = this.props;

    const disableField = loading || !!searchResult;
    const validNames = multiple && nameList && nameList.filter(p => p && p.length > MIN_LENGTH);

    return (
      <>
        <Button onClick={() => this.props.history.push('/research/pepblacklist')} basic>Back</Button>
        <Segment>
          <Header>Non-Datarama Entity: Search by Name{multiple && 's (Multiple)'}</Header>
          {onError && (
            <Message warning>{onError}</Message>
          )}
          {multiple ? (
            <>
              <Form onSubmit={this.addTerm} styled={{ display: 'inline-block'}}>
                {nameList && nameList.map((name, index) => (
                  <Input
                    key={index}
                    type="text"
                    placeholder={`Name #${index + 1}`}
                    onChange={this.namesOnChange}
                    name={index}
                    minLength={MIN_LENGTH}
                    disabled={!!searchMultipleResult}
                  />
                ))}
                <Button type="submit" disabled={!!searchMultipleResult} basic>+ Add Term</Button>
                <br/>
                <Button onClick={this.search} value='all' disabled={validNames.length === 0 || !!searchMultipleResult} positive>Search All {validNames.length} name</Button>
                <Button basic onClick={this.clear}>Cancel</Button>
              </Form>
              {searchMultipleResult && Object.keys(searchMultipleResult).map(nameKey => (
                <Segment key={nameKey}>
                  <Label size='large' basic color='purple'>{nameKey}</Label>
                  <ListView data={searchMultipleResult[nameKey]} nonSelective />
                </Segment>
              ))}
            </>
          ) : (
            <>

              <Form onSubmit={this.search} styled={{ display: 'inline-block'}}>
                <Input
                  type="text"
                  placeholder="Search Term (Name)"
                  onChange={this.nameOnChange}
                  disabled={disableField}
                  minLength={MIN_LENGTH}
                  />
                <Button type="submit" loading={loading} disabled={disableField} positive>Search</Button>
                {disableField && (
                  <Button basic onClick={this.clear}>Cancel</Button>
                )}
              </Form>
              {searchResult && (
                <ListView data={searchResult} nonSelective />
              )}
            </>
          )}
        </Segment>
      </>

    )
  }
}

export default SearchByName
