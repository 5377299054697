import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Grid } from './styles';
import { Tag } from './Tag';
import moment from 'moment';

const FullArticleWrapper = styled.div`
padding: 10px;
margin: 5px 10px;
border: 1px solid #444;
border-radius: 3px;
background: #fff;
`;

const MatchGrid = styled(Grid).attrs({ col: '20% 80%'})`
margin-bottom: 5px;
padding-bottom: 5px;
border-bottom: 1px solid #ececec;
b {
  font-weight: 700;
  font-size: 15px;
  padding: 5px 2px 0 5px;
  border-bottom: 2px solid red;
  margin-bottom: 5px;
}
`;

const HighlightedWord = styled.span`
  font-weight: 700;
  font-size: 15px;
  padding: 1px 5px;
  border-radius: 2px;
  margin-bottom: 5px;
  background: ${({ main }) => main ? '#696969' : '#f75353'};
  color: #fff;
`;

const WordIndex = styled.span`
  vertical-align: super;
  font-size: 8px;
  color: #fff;
`;

const FullArticle = styled.div`
  padding: 10px;
  margin: 20px 5px 5px 5px !important;
  background: #fff;
  border-radius: 2px;
  margin: 5px 0;
  box-shadow: 0 0 8px #bbb;
  h3 {
    margin: 0 0 10px 0;
  }
`;

const Hint = styled.div`
  margin-bottom: 5px;
  color: #666;
`;

const Paragraph = styled.div`
  display: inline;
`;


export function FullArticleRow({
  art,
  isNLP
}) {

  return (
    <FullArticleWrapper>
      <MatchGrid>
        <div><h3>Summary</h3></div>
        {/* <TermSummary>
        {art.meta.adverseTermsOverview && Object.keys(art.meta.adverseTermsOverview).map(term => {
          const refName = this.props.searchScope.name[erToDtrmLang[art.lang]];
          return (
            <div key={term}>
              <HighlightedWord
                main={term.toLowerCase() === refName}
              >{term}</HighlightedWord>
              {art.meta.adverseTermsOverview[term].length}
            </div>
          )
        })}
        </TermSummary> */}
      </MatchGrid>
      {/* { isNLP &&
        <MatchGrid>
        { art.composedNLPHighlights.map(highlight => {
          return(
            <>
            <Tag label={<HighlightedWord>{highlight.term}</HighlightedWord>} isTerm={true} />
            <div dangerouslySetInnerHTML={{ __html: highlight.extract }} />
            </>
          )}) }
        </MatchGrid> } */}

      {!isNLP && (art.composedHighlights || []).map((highlight, highlightIndex) => {
        if (!highlight.text) {
          return null;
        }
        return (
          <MatchGrid key={ highlightIndex }>
            <div>
              {highlight.matches.map((match, matchIndex) => (
                <Tag
                  key={matchIndex}
                  label={<HighlightedWord
                    main={match.isEntity}
                  >{match.term}<WordIndex>{match.fullTextIndex}</WordIndex></HighlightedWord>}
                  isMainEntity={match.isEntity}
                  size='large'
                  isTerm={!match.isEntity}
                />
              ))}
            </div>
            <div dangerouslySetInnerHTML={{ __html: highlight.text }} />
          </MatchGrid>
        )
      })}
      <FullArticle>
        <h3>{art.title}</h3>
        <Hint>{moment(art.date).format()}</Hint>
        { art.composedBody &&
          art.composedBody.map((part, partIndex) => {
            return <Fragment key={ partIndex }>
            { part.type &&
            <HighlightedWord main={part.type === 'entity'}>
              { part.content }
              {/* <WordIndex>{ part.index }</WordIndex> */}
            </HighlightedWord> }
            { !part.type &&
            <Paragraph dangerouslySetInnerHTML={{ __html: part.content }} /> }
            </Fragment>
          })
        }
      </FullArticle>
    </FullArticleWrapper>
  )
}