import React, { useState} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const ClickWrapper = styled.span`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  margin-left: 5px;
`;
const CopiedMessage = styled.div`
  font-size: 11px;
  font-weight: 600;
`;


export default ({ content }) => {
  const [copied, setCopied] = useState(false);
  const setTimeoutMessage = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }
  return (
    <React.Fragment>
     <CopyToClipboard text={content}
       onCopy={() => setTimeoutMessage()}>
       <ClickWrapper>
         <Icon name='copy' color='grey' />
       </ClickWrapper>
     </CopyToClipboard>
     {copied && (
       <CopiedMessage>Copied to Clipboard</CopiedMessage>
     )}
    </React.Fragment>
  )
}
