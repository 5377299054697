import React from 'react';
import { connect } from 'react-redux';
import { Message, Form, Button, Header, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { getSettings, getAuth } from '../../reducers/selectors';
import { checkPermissions, createClient } from '../../utils/api';
import { updateSelfPermission } from '../../actions';
import Client from '../../model/entity/client';
import Dropdown from '../ingestion/fields/Dropdown';
import Text from '../ingestion/fields/Text';
import DateField from '../ingestion/fields/DateField';
import AccessDenied from '../AccessDenied';

const ResultRow = styled.div`
  margin: 5px 0;
`;

class NewClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
    this.client = new Client();
    this.checkSelfPermission = this.checkSelfPermission.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.addNewClient = this.addNewClient.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.input = {}

    this.validateForm = (e, { value }) => {
      const shouldProceedValidation = value === 'cancel' ? false : true;
      const fields = this.client.getFields();
      if (shouldProceedValidation) {

      const outputFields = Object.keys(fields).reduce((a, fieldName) => {
        const field = fields[fieldName];
        if (field.required && !this.input[fieldName]) {
          a.errors[fieldName] = true;
        }
        if (this.input[fieldName]) {
          if (field.fieldTransform) {
            a.fields[fieldName] = field.fieldTransform(this.input[fieldName]);

          } else {
            a.fields[fieldName] = this.input[fieldName];
          }
        }
        return a;
      }, { errors: {}, fields: {}})
      if (Object.keys(outputFields.errors).length > 0) {
        this.setState(() => ({
          errors: outputFields.errors
        }))
      } else {
        this.setState(() => ({
          isValidating: true,
          pending: outputFields.fields,
        }))
      }
    } else {
      this.setState(() => ({
        isValidating: false,
      }))
    }

    }
  }


  componentDidMount() {
    this.checkSelfPermission();
  }

  clearAll() {
    this.input = {};
    this.setState(() => ({
      addSuccess: null,
      error: null,
      isValidating: false,
      pending: false,
      errors: {}
    }))
  }

  handleFieldChange(e, { name, value }) {
    if (!value) {
      delete this.input[name];
    } else {
      this.input[name] = value;
    }
  }

  async addNewClient() {
    const result = await createClient(this.state.pending);
    if (result) {
      if (!result.error) {
        this.setState(() => ({
          addSuccess: result,
        }));
      } else {
        this.setState(() => ({
          addError: result.error,
        }))
      }
    }
  }

  async checkSelfPermission() {
    const checkResult = await checkPermissions('client', this.props.loggedInUser);
    if (checkResult) {
      this.props.updateSelfPermission('general', null, checkResult.general)
    }
  }

  render() {
    const groupedFields = this.client.getNewGroupedFields();
    const { users, canAdd } = this.props;
    const { isValidating, addSuccess, addError, errors } = this.state;

    return (
      <div>
        <Header>New client</Header>
        {addError && (
          <Message negative content={addError.message} />
        )}
        {!canAdd ? (
          <AccessDenied action='admin' resourceType='client'/>
        ) : (
          <React.Fragment>
          {!addSuccess ? (
            <Form onSubmit={this.validateForm}>
              {groupedFields && groupedFields.map((group, groupId) => (
                <Form.Group widths='equal' key={groupId}>
                  {group.map(field => {
                    switch (field.input.type) {
                      case 'dropdown':
                        return (
                          <Dropdown
                            key={field.id}
                            field={{
                              ...field,
                              multiple: field.input.multiple,
                              options: field.id === 'accountManager' && users && (
                                Object.keys(users).map(userId => {
                                  const user = users[userId];
                                  return { id: user._id, display: user.email }
                                })
                              )
                            }}
                            isValidating={isValidating}
                            error={errors[field.id]}
                            defaultValue={field.defaultValue || (field.input.multiple ? [] : '')}
                            handleFieldChange={this.handleFieldChange}
                          />)
                      case 'date':
                        return (
                          <DateField
                            key={field.id}
                            field={field}
                            isValidating={isValidating}
                            error={errors[field.id]}
                            defaultValue=""
                            noMax={field.input.noMax}
                            handleFieldChange={this.handleFieldChange}
                          />
                        )
                      default:
                        return (
                          <Text
                            key={field.id}
                            field={field}
                            meta={field.input.meta}
                            error={errors[field.id]}
                            defaultValue=""
                            isValidating={isValidating}
                            handleFieldChange={this.handleFieldChange}
                          />
                        )
                    }
                  })}
                </Form.Group>
              ))}
              {!isValidating && (
                <Button type='submit' fluid size='large'>Add Client</Button>
              )}
              {isValidating && (
                <React.Fragment>
                  <br/>
                  <Button onClick={this.addNewClient} fluid color='green'>Confirm and add new Client</Button>
                  <br/>
                  <Button fluid onClick={this.validateForm} color='grey' basic value="cancel">Edit Again</Button>
                </React.Fragment>
              )}
            </Form>
          ) : (
            <React.Fragment>
              <Message positive>
                <Message.Header>Successfully Added new Client</Message.Header>
                {groupedFields.map((group, groupId) => (
                  <div key={groupId}>
                  {group.map(field => {
                    switch (field.type) {
                      case 'array':
                        return (
                          <ResultRow key={field.id}>
                            {field.Header}
                            {addSuccess[field.id].map(val => (
                              <Label key={val} horizontal>{field.id === 'accountManager' ? (users[val] || {}).email : val} </Label>
                            ))}
                          </ResultRow>
                        )
                      default:
                        return (
                          <ResultRow key={field.id}>
                            {field.Header} <Label horizontal>{addSuccess[field.id] || 'N/A'}</Label>
                          </ResultRow>
                        )
                    }
                  })}
                  </div>
                ))}
              </Message>
              <Button onClick={this.clearAll}>Add Another Client</Button>
            </React.Fragment>
          )}
          </React.Fragment>
        )}

      </div>
    )
  }
}

const mapStateToProps = state => {
  const settings = getSettings(state);
  const authState = getAuth(state);
  return {
    users: settings.users,
    roles: settings.roles,
    loggedInUser: authState.loggedInUser,
    canAdd: authState.permissions.general['client.admin'],
  }
};

export default withRouter(connect(mapStateToProps, { updateSelfPermission })(NewClient));
