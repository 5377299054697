import React, { useState } from 'react';
import { Form, Button, Icon } from 'semantic-ui-react';
import { FINANCIAL_STATEMENT_TYPES } from '../constants';

export function LineItemForm({ state, setState }) {
  const [form, setForm] = useState({});

  const handleCreateLineItem = () => {
    setState(prevState => {
      const modified = prevState.financialLineItems[form.financialStatementType] || [];
      modified.splice(form.position + 1, 0, {label: form.lineItem, type: form.financialStatementType});
      return {
        ...prevState,
        financialLineItems: {
          ...prevState.financialLineItems,
            [form.financialStatementType]: modified
        }
      }
    })
  }

  const handleOnChange = (evt, data) => {
    setForm(prevState => (
      { ...prevState, [data.id]: data.value }  
    ))
  }

  return <>
  <Form size='small' style={{paddingLeft: '20px'}} onSubmit={ handleCreateLineItem }>
    <Form.Group widths='equal'>
      <Form.Input
        id='lineItem'
        label='Line Item Name'
        value={form.lineItem || ''}
        onChange={ handleOnChange } />
      <Form.Select
        fluid
        id='financialStatementType'
        value={form.financialStatementType || ''}
        onChange={handleOnChange}
        upward={true}
        label='Line Item Type'
        options={FINANCIAL_STATEMENT_TYPES}
        placeholder='Line Item Type' />
      <Form.Select
        placeholder='Position (after)'
        label='Position (after)'
        id='position'
        fluid
        value={form.position || ''}
        upward={true}
        onChange={ handleOnChange }
        options={
          (state.financialLineItems[form.financialStatementType] || []).map((item, idx) => (
            { key: idx, value: idx, text: item.label }
          ))
        } />
        <Form.Field style={{display: 'flex', alignItems: 'flex-end' }}>
          <Button type='submit' icon>
            <Icon name='plus' />
          </Button>
        </Form.Field>
    </Form.Group>
  </Form>
  <br /><br /><br /><br />
</>
}