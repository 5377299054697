import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import omit from 'lodash.omit';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Table, Header, Icon, Placeholder, Form, Message, Checkbox, Segment, Label, Button } from 'semantic-ui-react';
import { updateAccessRights, checkPermissions } from '../utils/api';
import { getSettings, getAuth } from '../reducers/selectors';
import Dropdown from './ingestion/fields/Dropdown';
import AccessDenied from './AccessDenied';
import Loading from './Loading';

const PermissionOption = styled.div`
  margin: 5px 0;
`;

class Permissions extends React.Component {
  constructor(props) {
    super(props);
    this.initState = {
      selectedUsers: {},
      editAccessListMode: false,
      editPermissionForm: {},
      loading: {},
      message: null,
      tempSelectedUser: null,
      currentUserPermissions: null,
    }
    this.state = this.initState;
    this.clearAll = () => {
      this.setState(() => ({
        ...this.initState
      }))
    }
    this.updateAccessRight = this.updateAccessRight.bind(this);
    this.selectTemporaryUser = this.selectTemporaryUser.bind(this);

    this.handleCheckBoxChange = (e, { value, name, checked }) => {
      this.setState(prevState => ({
        editPermissionForm: Object.assign({}, prevState.editPermissionForm, {
          permission: checked ? Object.assign({}, prevState.editPermissionForm.permission || {}, {
            [name]: checked,
          }) : omit(prevState.editPermissionForm.permission || {}, name),
        })
      }))
    }
    this.getUserPermission = this.getUserPermission.bind(this);

    this.toggleAccessListMode = (e, { value }) => {
      this.setState(prevState => ({
        editAccessListMode: value,
        message: null,
        editPermissionForm: value ? {
          userId: this.state.currentUserPermissions.userId,
          permission: (this.state.currentUserPermissions.permissions.general && this.state.currentUserPermissions.permissions.general) || {},
        } : {},
        currentUserPermissions: value ? prevState.currentUserPermissions : null,
      }))
    }

    this.getActionMessage = (success) => {
      return success ? {
        positive: true,
        header: 'Permission for user updated',
      } : {
        negative: true,
        header: 'Unable to update permissions',
        content: 'Please check with tech team or analyst team if problem persists'
      }
    }
  }

  async updateAccessRight() {
    const { userId, permission } = this.state.editPermissionForm || {};
    const prevPermissions = (this.state.currentUserPermissions.permissions && this.state.currentUserPermissions.permissions.general) || {};
    const removedPermissions = Object.keys(prevPermissions).reduce((a, role) => {
      if (!permission[role] && this.props.roles[role]) {
        return a.concat({
          roleId: this.props.roles[role].roleId,
          isRemoving: true,
        })
      }
      return a;
    }, []);
    const addedPermissions = Object.keys(permission || {}).reduce((a, role) => {
      if (this.props.roles[role]) {
        return a.concat({
          roleId: this.props.roles[role].roleId,
        })
      }
      return a;
    }, []);
    this.setState(prevState => ({
      loading: {
        ...prevState.loading,
        updating: true,
      },
    }));
    const result = await updateAccessRights(userId, addedPermissions.concat(removedPermissions));
    this.setState(prevState => {
      return {
        loading: {
          ...prevState.loading,
          updating: false,
        },
        editAccessListMode: false,
        message: this.getActionMessage(!!result),
        currentUserPermissions: result ? {
          ...prevState.currentUserPermissions,
          permissions: {
            ...prevState.currentUserPermissions.permissions,
            general: result,
          }
        } : prevState.currentUserPermissions,
      }
    })
   }

  selectTemporaryUser(e, { value, name }) {
    const currentlySelectedUser = value ? JSON.parse(value) : null;
    this.setState(() => ({
      tempSelectedUser: currentlySelectedUser,
    }))
  }

  async getUserPermission() {
    const { tempSelectedUser } = this.state;
    if (!tempSelectedUser) return;

    const userId = tempSelectedUser.id;

    this.setState(prevState => ({
      loading: {
        ...prevState.loading,
        getUserPermission: true,
      },
    }));

    const permissions = await checkPermissions('general', userId);
    this.setState((prevState) => {
      return {
        loading: {
          ...prevState.loading,
          getUserPermission: false,
        },
        currentUserPermissions: permissions ? {
          userId,
          permissions,
        } : prevState.currentUserPermissions
      }
    })
  }

  render() {
    const { users, roles, userPermissions } = this.props;

    const {
      editPermissionForm,
      message,
      loading,
      currentUserPermissions,
      editAccessListMode,
    } = this.state;
    const isPermissionAdmin = userPermissions && userPermissions.general['permission.admin'];
    if (!isPermissionAdmin) {
      return (
        <AccessDenied />
      )
    }

    const generalPermissions = currentUserPermissions && currentUserPermissions.permissions && currentUserPermissions.permissions.general;
    return (
      <div>
        <Header>Update Permissions</Header>
        {message && (
          <Message
            positive={message.positive}
            negative={message.negative}
            header={message.header}
            content={message.content}
            onDismiss={this.clearMessage}
          />
        )}

        <Segment.Group>
          <Segment>
            {!users && (
              <Placeholder>
                <Placeholder.Header image>
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Placeholder>
            )}
            {users && (
              <React.Fragment>
                {currentUserPermissions && currentUserPermissions.userId  ? (
                  <div>
                    <b>Selected User</b><br/>
                    <Label size='large'>{users[currentUserPermissions.userId].email}</Label><Button onClick={this.clearAll} compact basic size='small'>Select another user</Button>
                  </div>
                ) : (
                  <Form onSubmit={this.getUserPermission}>
                    <Form.Group>
                      <Form.Field width={6}>
                        <Dropdown
                          field={{
                            id: 'user',
                            Header: 'Select Datarama Users',
                            options: Object.keys(users).map(userId => {
                              const user = users[userId];
                              return { id: user._id, display: user.email }
                            })
                          }}
                          defaultValue={''}
                          handleFieldChange={this.selectTemporaryUser}
                        />
                      </Form.Field>
                      <Form.Field>
                        <br/>
                        <Button color='green' type='submit'>Check Permission</Button>
                      </Form.Field>
                    </Form.Group>
                  </Form>
                )}

              </React.Fragment>
            )}
          </Segment>
          <br/>
          {currentUserPermissions && currentUserPermissions.userId && (
            <React.Fragment>
              <Segment>
                <Header as='h3'>General Permissions</Header>
                  <Button
                    onClick={this.toggleAccessListMode}
                    value={!editAccessListMode}
                    color={editAccessListMode ? 'grey' : 'green'}
                    icon
                    size='small'
                    basic
                    compact
                    labelPosition='left'
                  >
                    <Icon name={editAccessListMode ? 'delete' : 'write'} />
                    {editAccessListMode ? 'Exit' : 'Edit General Access Permissions for user'}
                  </Button>
                  <Segment>
                {roles && Object.keys(roles).map(role => !roles[role].resourceSpecific && (
                  <PermissionOption key={role}>
                    {editAccessListMode ? (
                      <Checkbox
                        defaultChecked={editPermissionForm && editPermissionForm.permission[role]}
                        label={`${roles[role].name} - ${roles[role].description}`}
                        name={role}
                        onChange={this.handleCheckBoxChange}
                      />
                    ) : (
                      <div>
                        {(generalPermissions && generalPermissions[role]) ?  (
                          <Icon name='check circle' color='green' />
                        ) : (
                          <Icon name='delete' color='grey'/>
                        )} <b>{roles[role].name}</b> {roles[role].description}
                      </div>
                    )}
                  </PermissionOption>
                ))}
                {editAccessListMode && (
                  <Button
                    onClick={this.updateAccessRight}
                    fluid
                    color='green'
                    loading={loading.updating}
                    disabled={loading.updating}
                  >
                    Save Permission for user
                  </Button>
                )}
                </Segment>
              </Segment>
              <br/>
              <Segment color='olive'>
                <Header as='h3'>Projects Permission (Only can be updated at project page)</Header>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Project Id</Table.HeaderCell>
                      <Table.HeaderCell>Admin</Table.HeaderCell>
                      <Table.HeaderCell>Read</Table.HeaderCell>
                      <Table.HeaderCell>Modify</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {currentUserPermissions.permissions && currentUserPermissions.permissions.project && Object.keys(currentUserPermissions.permissions.project).map((projectId) => (
                    <Table.Row key={projectId}>
                      <Table.Cell><Link to={`/project/${projectId}`}>{projectId}</Link></Table.Cell>
                      {['admin', 'read', 'modify'].map(action => (
                        <Table.Cell key={action}>
                          {currentUserPermissions.permissions.project[projectId][`project.${action}`] && (
                            <Icon name='check circle' color='green' />
                          )}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                  </Table.Body>
                </Table>
              </Segment>
            </React.Fragment>
          )}
        </Segment.Group>
          <Loading active={loading.getUserPermission} content='Fetching permission of the user' />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const settings = getSettings(state);
  const authState = getAuth(state);
  return {
    users: settings.users,
    roles: settings.roles,
    // roles: settings.roles && Object.keys(settings.roles).reduce((a, c) => {
    //   if (!settings.roles[c].resourceSpecific) {
    //     a[c] = settings.roles[c];
    //   }
    //   return a;
    // }, {}),
    loggedInUser: authState.loggedInUser,
    userPermissions: authState.permissions,
  }
};

export default withRouter(connect(mapStateToProps)(Permissions));
