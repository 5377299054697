import React, { useState } from 'react';
import { Button, Form, Checkbox } from 'semantic-ui-react';
import { updateKeystoneEntity } from '../../../../utils/api';
import { CustomTextArea } from '../CustomTextArea';
import { ENTITY_TYPE } from '../constants';

export function Wealth({ state, setState }) {

  const [componentState, setComponentState] = useState({
    hasAnalystNote: false
  });

  const handleOnSubmit = async evt => {
    evt.preventDefault();
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))
    const payload = {
      entityType: ENTITY_TYPE,
      dataramaId: state.dataramaEntityId,
      otherAssetsValue: state.otherAssetsValue,
      otherAssetsUnit: 'Millions',
      wealthResearchCompleted: true
    };
    if (componentState.hasAnalystNote) {
      payload.analystNote = state.analystNote;
    }
    await updateKeystoneEntity(JSON.stringify(payload));

    setState(prevState => (
      { ...prevState,
        isProcessing: false,
        completed: { ...prevState.completed, wealth: true }
      }
    ))
  }

  const handleOnChange = (evt, data) => {
    setState(prevState => (
      { ...prevState, [data.id]: data.value }  
    ))
  }

  const handleCheckbox = () => {
    setComponentState(prevState => (
      {...prevState, hasAnalystNote: !prevState.hasAnalystNote }
    ))
  }

  
  return <Form size='small' onSubmit={ handleOnSubmit }>
    <div className='segment'>
      <div className='justify-space-between'>
        <Button size='mini' content='Save' type='submit' loading={ state.isProcessing } disabled={ state.isProcessing } />
      </div>
      <br />
      <Form.Group>
        <Form.Input
          id='otherAssetsValue'
          size='mini'
          type='Number'
          label='Other Assets Estimate Value (Millions)'
          value={state.otherAssetsValue}
          onChange={ handleOnChange } />
      </Form.Group>
      <Checkbox
        label='Has Analyst Note'
        disabled={state.isProcessing}
        onClick={handleCheckbox}
        checked={componentState.hasAnalystNote} />
      <br /><br />
      { componentState.hasAnalystNote &&
        <CustomTextArea setState={ setState } state={ state } /> }
    </div>
  </Form>
  
} 