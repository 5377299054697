import React from 'react';
import DtrmID from '../../components/DtrmID';

class TmpEntity {
  constructor(data) {
    const langType = {
      fieldTransform: (val) => {
        return JSON.parse(val).id
      },
      displayTransform: (data) => data && JSON.parse(data).display,
      input: {
        type: 'dropdown',
        sourceFromState: 'languages',
        displayField: 'lang',
      }
    }

    this.getEntityType = () => this.type;

    this.basicFieldDetails = {
      'entityId': {
        Header: 'Datarama ID',
        id: 'entityId',
        accessor: (value) => {
          return (<DtrmID id={value.entityId} type={value.entityType} link />)
        },
        type: 'integer',
        width: 150,
        input: {
          type: 'text'
        }
      },
      entryType: {
        Header: 'Entry Type',
        id: 'entryType',
        accessor: 'entryType',
        type: 'string'
      },
      entityName: {
        Header: 'Name',
        id: 'entityName',
        accessor: 'entityName',
        type: 'string',
        required: true,
        input: {
          type: 'text',
        }
      },
      entityAlias1: {
        Header: 'Alias 1',
        id: 'entityAlias1',
        accessor: 'entityAlias1',
        type: 'string',
        input: {
          type: 'text'
        }
      },
      langId1: {
        Header: 'Alias 1 lang',
        id: 'langId1',
        accessor: 'langId1',
        type: 'string',
        ...langType
      },
      entityAlias2: {
        Header: 'Alias 2',
        id: 'entityAlias2',
        accessor: 'entityAlias2',
        type: 'string',
        input: {
          type: 'text'
        }
      },
      langId2: {
        Header: 'Alias 2 lang',
        id: 'langId2',
        accessor: 'langId2',
        type: 'string',
        ...langType
      },
      entityAlias3: {
        Header: 'Alias 3',
        id: 'entityAlias3',
        accessor: 'entityAlias3',
        type: 'string',
        input: {
          type: 'text'
        }
      },
      langId3: {
        Header: 'Alias 3 lang',
        id: 'langId3',
        accessor: 'langId3',
        type: 'string',
        ...langType
        // fieldTransform: (val) => {
        //   return JSON.parse(val).id
        // },
        // displayTransform: (data) => data && JSON.parse(data).display,
        // input: {
        //   type: 'dropdown',
        //   source: 'lang'
        //   //
        //   // sourceFromState: 'languages',
        //   // displayField: 'lang',
        // }
      },
      'otherRegistrationNo1': {
        Header: 'Other Registration Code',
        id: 'otherRegistrationNo1',
        accessor: 'otherRegistrationNo1',
        type: 'string',
        input: {
          type: 'text',
        }
      },
      'RegistrationCountry': {
        Header: 'Other Registration Country',
        id: 'RegistrationCountry',
        accessor: 'RegistrationCountry',
        input: {
          type: 'dropdown',
          source: 'country',
        }
      },
      entityStartDate: {
        Header: 'DOB',
        id: 'entityStartDate',
        accessor: 'entityStartDate',
        type: 'string',
        input: {
          type: 'date'
        }
      },
      entityEndDate: {
        Header: 'Date of Death',
        id: 'entityEndDate',
        accessor: 'entityEndDate',
        type: 'string',
        input: {
          type: 'date'
        }
      },
      entityStatus: {
        Header: 'Status',
        id: 'entityStatus',
        accessor: 'entityStatus',
        type: 'string',
        required: true,
        input: {
          type: 'dropdown',
          source: 'entityStatus',
        }
      },
      Blacklist: {
        Header: 'Blacklist',
        id: 'Blacklist',
        accessor: 'Blacklist',
        type: 'string',
        required: true,
        input: {
          type: 'dropdown',
          source: 'yesNo',
        }
      },
      'Blacklist detail': {
        Header: 'Blacklist detail',
        id: 'Blacklist detail',
        accessor: 'Blacklist detail',
        type: 'string',
        input: {
          type: 'text'
        }
      },
      addressStr: {
        Header: 'Address',
        id: 'addressStr',
        accessor: 'addressStr',
        type: 'string',
        input: {
          type: 'text'
        }
      },
      addressStrInOtherLang: {
        Header: 'Address in other lang',
        id: 'addressStrInOtherLang',
        accessor: 'addressStrInOtherLang',
        type: 'string',
        input: {
          type: 'text'
        }
      },
      langId4: {
        Header: 'Other Address (Lang)',
        id: 'langId4',
        accessor: 'langId4',
        type: 'string',
        ...langType
      },
      country: {
        Header: 'Country',
        id: 'country',
        accessor: 'country',
        type: 'string',
        required: true,
        input: {
          type: 'dropdown',
          source: 'country',
        }
      },
      source: {
        Header: 'Source',
        id: 'source',
        accessor: 'source',
        type: 'string',
        required: true,
        input: {
          type: 'dropdown',
          source: 'source',
        }
      },
      QOS: {
        Header: 'Quality of Source',
        id: 'QOS',
        accessor: 'QOS',
        type: 'string',
        required: true,
        input: {
          type: 'dropdown',
          source: 'qos',
        }
      },
      ins_date: {
        Header: 'Created at',
        id: 'ins_date',
        accessor: 'ins_date',
        type: 'string',
      },
      upd_date: {
        Header: 'Updated at',
        id: 'upd_date',
        accessor: 'upd_date',
        type: 'string',
      },
      creator: {
        Header: 'Created by',
        id: 'creator',
        accessor: 'creator',
        type: 'string',
      },
    };

    this.riskDetailsFieldDetails = {
      riskTypeId: {
        Header: 'Risk Type',
        id: 'riskTypeId',
        accessor: 'riskTypeId',
        type: 'string',
        required: true,
        fieldTransform: (val) => {
          return JSON.parse(val).id
        },
        displayTransform: (data) => data && JSON.parse(data).display,
        input: {
          type: 'dropdown',
          sourceFromState: 'riskTypes',
          displayField: 'riskTypeDescStr',
          id: 'riskTypeId',
        }
      },
      riskDetail: {
        Header: 'Risk Details',
        id: 'riskDetail',
        accessor: 'riskDetail',
        type: 'string',
        input: {
          type: 'text',
        }
      },
      sourceUrlStr: {
        Header: 'Source URL',
        id: 'sourceUrlStr',
        accessor: 'sourceUrlStr',
        type: 'string',
        input: {
          type: 'text',
        }
      },

    }

  }

  getColumns() {
    return this.columns && this.columns.map(col => {
      return this._fieldDetails[col];
    });

  }

  getDigestedColumns() {
    return this.digestFields && this.digestFields.map(col => {
      return this._fieldDetails[col];
    });
  }

  getNewEntityGroupedFields() {
    return this.newEntityGroupFields && this.newEntityGroupFields.map(group => group.map(field => this._fieldDetails[field]));
  }

  transformFields() {
    return this._fieldDetails;
  }

  getFields() {
    return this._fieldDetails;
  }

  getRiskFields() {
    return ['riskTypeId', 'riskDetail', 'sourceUrlStr'].map(c => this.riskDetailsFieldDetails[c])
  }

  transformData(data) {
    const removeNull = (value) => value === 'null' || value === 'NULL' ? null : value;

    const output = Object.keys(data).reduce((a, c) => {
      if (c === 'aliases' && data[c]) {
        const aliases = data[c].split('||||');
        let count = 1;
        aliases.forEach(alias => {
          if (alias) {
            const aliasDetails = alias.split(';');
            a[`entityAlias${count}`] = aliasDetails[0];
            a[`langId${count}`] = aliasDetails[1];
            count++;
          }
        })
      } else if (c === 'risks' && data[c]) {
        const risks = data[c].split('||||');
        a.risks = risks.reduce((allRisks, risk) => {
          if (risk) {
            const riskDetails = risk.split(';;;;') || [];
            allRisks.push({
              riskTypeId: riskDetails[0],
              sourceUrlStr: riskDetails[1],
              riskDetail: riskDetails[2]
            });
          }
          return allRisks;
        }, [])

      } else if (this._fieldDetails[c] &&
        this._fieldDetails[c].input &&
        this._fieldDetails[c].input.source === 'yesNo') {
          if (data[c] === true || data[c] === 'yes') {
            a[c] = 'yes';
          } else if (data[c] === false || data[c] === 'no') {
            a[c] = 'no';
          }
      } else {
        a[c] = removeNull(data[c]);
      }
      return a;
    }, {});
    return output;
  }

  getRestrictedFieldsByCountry() {
    return this.restrictedFieldsByCountry || {};
  }
}

export default TmpEntity;
