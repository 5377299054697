import React, { useRef } from 'react';
import { Button } from './Button';
import { CONCEPTS } from './constants';
import moment from 'moment';

import {
  A4Wrapper,
  Btn,
  FormatRow,
  FormatTool,
  FormatHeader,
  FormatRowTable,
  FormatSummaryRow,
  FormatSummaryTable,
  Modal,
  RefLink,
} from './styles.js';

const BASE_URL = 'https://s3.ap-northeast-2.amazonaws.com/t.doc.datarama';

export function FormattedModeWidget({
  currentSearch,
  dispatchableArticles,
  displayableArticles,
  handleFormatForReport,
  stats,
  SearchTermNames,
  uploaded
}) {

  const summary = useRef(null);

  const handleCopyFormatReport = () => {
    window.getSelection().selectAllChildren(summary.current);
    document.execCommand('copy');
  }

  return(
    <Modal>
      <div>
        <FormatTool>
          <div>
            <Button
              onClick={ handleFormatForReport }
              label='Close'
              component={Btn}
            />
          </div>
          <div>
            <Button
              onClick={ handleCopyFormatReport }
              label='Copy All'
              component={Btn}
            />
          </div>
        </FormatTool>
        <A4Wrapper ref={(c) => { summary.current = c; }} id='summary-para'>
          <FormatHeader>
            Adverse Media Analysis
          </FormatHeader>
          <FormatSummaryTable>
            <tbody>
              <FormatSummaryRow>
                <td>Total number of media articles generated on {SearchTermNames}</td>
                <td>{stats.valid.all}</td>
              </FormatSummaryRow>
              <FormatSummaryRow>
                <td>Total number of adverse media articles generated on {SearchTermNames}</td>
                <td>{stats.valid.adv} ({(stats.valid.adv * 100 / stats.valid.all).toFixed(2)}% of total coverage)</td>
              </FormatSummaryRow>
              <FormatSummaryRow>
                <td>Date range checked</td>
                <td>From {moment(currentSearch.dateRange.start).format('YYYY-MM-DD')} to {moment(currentSearch.dateRange.end).format('YYYY-MM-DD')}</td>
              </FormatSummaryRow>
            </tbody>
          </FormatSummaryTable>
          <FormatRowTable>
            <tbody>
            <FormatRow header>
              <td>Risk Indicator</td>
              <td>Summary</td>
              <td>Number of articles</td>
              <td>Links</td>
            </FormatRow>
            {displayableArticles.map(bucket => (
              <FormatRow key={bucket}>
                <td>{CONCEPTS.filter(c => c.code === bucket)[0].label}</td>
                <td>
                  {(dispatchableArticles[bucket] || []).length === 0 ? (
                    <div>We found no articles in local or international media mentioning {currentSearch && currentSearch.name.english} in relation to {(CONCEPTS.filter(c => c.code === bucket)[0].label).toLowerCase()} risks.</div>
                  ) : dispatchableArticles[bucket].map((art, idx) => (
                    <div key={`${art.id}formatted`}>
                      {idx + 1}. {art.title} (<i>{art.sourceName}</i>) <a href={art.link} target='_blank' rel='noopener noreferrer'>Url</a>
                    </div>
                  ))}
                </td>
                <td>{(dispatchableArticles[bucket] || []).length}</td>
                <td>{(dispatchableArticles[bucket] || []).map(art => (
                  <RefLink key={`${art.id}formatted_inner`}>
                    <a href={uploaded && uploaded.uploaded[art.id] ? `${BASE_URL}/${uploaded.uploaded[art.id]}` : art.link} target='_blank' rel='noopener noreferrer'>Ref</a>
                  </RefLink>
                ))}</td>
              </FormatRow>
            ))}
            </tbody>
          </FormatRowTable>
        </A4Wrapper>
      </div>
    </Modal>
  )
}