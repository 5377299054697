const BASIC_INFORMATION = "basicInformation";
const BLACKLIST = "blacklist";
const POLITICAL_EXPOSURE = "politicalExposure";
const TOTAL_ARTICLES_COUNT = 'totalArticlesCount';

// api key for Tinymce
const TINYMCE_API_KEY = 'ndj9bl9z5x5mdqguzelrwkmwdbed2sexj5h0eqqa1ba2miqk';

const LANGUAGES = {
  'eng': 'English',
  'ind': 'Bahasa Indonesian',
  'zho': 'Chinese',
  'vie': 'Vietnamese',
  'tha': 'Thai',
  'hin': 'Hindi',
  'tam': 'Tamil',
  'jap': 'Japanese',
  'kor': 'Korean'
}

const ENTITY_TYPES = [
  { key: 'company', value: 'company', text: 'Company' },
  { key: 'individual', value: 'individual', text: 'Individual' }
]

const RISK_FACTORS = [
  { code: 'sanctions_and_blacklists', label: 'Sanctions/ Blacklists', isCompany: true, isIndividual: true },
  { code: 'politically_exposed_person', label: 'Politically Exposed Persons', isCompany: true, isIndividual: true },
  { code: 'terrorist_involvement', label: 'Terrorist Involvement', isCompany: true, isIndividual: true },
  { code: 'corruption_bribery_and_fraud', label: 'Corruption, Bribery and Fraud', isCompany: true, isIndividual: true },
  { code: 'malfeasance', label: 'Malfeasance', isCompany: true, isIndividual: true },
  { code: 'serious_and_organised_crime', label: 'Serious and Organised Crime', isCompany: true, isIndividual: true },
  { code: 'money_laundering', label: 'Money Laundering', isCompany: true, isIndividual: true },
  { code: 'counterfeiting_activity', label: 'Counterfeiting Activity', isCompany: true, isIndividual: true },
  { code: 'tax_evasion_and_avoidance', label: 'Tax Evasion', isCompany: true, isIndividual: true },
  { code: 'regulatory_breaches', label: 'Regulatory Breaches', isCompany: true, isIndividual: false },
  { code: 'war_crimes_and_weapons_trafficking', label: 'War Crimes and Weapons Trafficking', isCompany: true, isIndividual: true},
  { code: 'labour_abuses', label: 'Labour Abuses', isCompany: true, isIndividual: true },
  { code: 'bankruptcy_insolvency', label: 'Bankruptcy/ Insolvency', isCompany: true, isIndividual: true },
  { code: 'human_rights_abuses', label: 'Human Rights Abuses', isCompany: true, isIndividual: true },
  { code: 'intellectual_property_violations', label: 'Intellectual Property Violations', isCompany: true, isIndividual: true },
  { code: 'cyber_breaches', label: 'Cyber Breaches', isCompany: true, isIndividual: false },
  { code: 'relationship_risks', label: 'Relationship Risks', isCompany: true, isIndividual: true },
  { code: 'social_accountability_risks', label: 'Social Accountability Risks', isCompany: true, isIndividual: true },
  { code: 'adverserial_social_media_activity', label: 'Adversarial Social Media Activity', isCompany: true, isIndividual: false },
  { code: 'operational_risks', label: 'Operational Risks', isCompany: true, isIndividual: false },
  { code: 'criminality', label: 'Criminality', isCompany: false, isIndividual: true },
  { code: 'debt', label: 'Debt', isCompany: false, isIndividual: true },
  { code: 'others', label: 'Others', isCompany: true, isIndividual: true }
]

const RISK_FACTORS_MAPPING = RISK_FACTORS.reduce((acc, cur) => {
  acc[cur['code']] = cur;
  return acc;
}, {});

const CONTENT_PAGES = [
  {
    label: 'Entity',
    code: 'entityNamePage'
  },
  {
    label: 'Basic Information',
    code: 'basicInformationPage'
  },
  {
    label: 'Adverse Media Analysis',
    code: 'adverseMediaAnalysisPage'
  },
  {
    label: 'Blacklist and Political Exposure Checks',
    code: 'blacklistAndPoliticalExposureChecksPage'
  },
  {
    label: 'International blacklists/sanctions lists',
    code: 'blacklistsAndSanctionsPage'
  },
  {
    label: 'Political Exposure',
    code: 'politicalExposurePage'
  }
];


const RISK_FACTORS_TREE = {
  'sanctions_and_blacklists': {
    company: 'Deal-Breakers',
    individual: 'Sanctions'
  },
  'terrorist_involvement': {
    company: 'Deal-Breakers',
    individual: "Wrongful acts"
  },
  'corruption_bribery_and_fraud': {
    company: 'Deal-Breakers',
    individual: "Integrity"
  },
  'war_crimes_and_weapons_trafficking': {
    company: "Deal-Breakers",
    individual: "Wrongful acts"
  },
  'money_laundering': {
    company: "Deal-Breakers",
    individual: "Financial Risks"
  },
  'malfeasance': {
    company: "Deal-Breakers",
    individual: "Integrity"
  },
  'serious_and_organised_crime': {
    company: "Deal-Breakers",
    individual: "Wrongful acts"
  },
  'social_accountability_risks': {
    company: "Reputational Risks",
    individual: "Wrongful acts"
  },
  'human_rights_abuses': {
    company: "Reputational Risks",
    individual: "Wrongful acts"
  },
  'labour_abuses': {
    company: "Reputational Risks",
    individual: "Wrongful acts"
  },
  'adverserial_social_media_activity': {
    company: "Reputational Risks"
  },
  'regulatory_breaches': {
    company: "Institutional Risks"
  },
  'intellectual_property_violations': {
    company: "Institutional Risks",
    individual: "Integrity"
  },
  'counterfeiting_activity': {
    company: "Institutional Risks",
    individual: "Integrity"
  },
  'cyber_breaches': {
    company: "Institutional Risks"
  },
  'operational_risks': {
    company: "Institutional Risks"
  },
  'tax_evasion_and_avoidance': {
    company: "Financial Risks",
    individual: "Financial Risks"
  },
  'bankruptcy_insolvency': {
    company: "Financial Risks",
    individual: "Financial Risks"
  },
  'debt': {
    individual: "Financial Risks"
  },
  'relationship_risks': {
    company: "Third Party Risks",
    individual: "Third Party Risks"
  },
  'politically_exposed_person': {
    company: "Third Party Risks",
    individual: "Third Party Risks"
  },
  'criminality': {
    individual: "Wrongful acts"
  },
  'others': {
    individual: "Others",
    company: "Others"
  }
}

const RISK_FACTORS_MAJOR_CATEGORIES = {
  company: ["Deal-Breakers", "Reputational Risks", "Institutional Risks","Financial Risks", "Third Party Risks", "Others" ],
  individual: ["Sanctions", "Wrongful acts", "Integrity","Financial Risks", "Third Party Risks", "Others" ],
}

const WEBSOCKET_EVENTS = {
  RETRIEVE_ARTICLES: 'RETRIEVE_ARTICLES',
  NLP: 'NLP',
  START_OF_ARTICLES_CHAIN: 'START_OF_ARTICLES_CHAIN',
  ARTICLES_COUNT: 'ARTICLES_COUNT',
  SENDING_ARTICLES: 'SENDING_ARTICLES',
  END_OF_ARTICLES_CHAIN: 'END_OF_ARTICLES_CHAIN',
  END_CONNECTION: 'END_CONNECTION'
}

export {
  LANGUAGES,
  RISK_FACTORS,
  RISK_FACTORS_MAPPING,
  RISK_FACTORS_TREE,
  RISK_FACTORS_MAJOR_CATEGORIES,
  ENTITY_TYPES,
  WEBSOCKET_EVENTS,
  BASIC_INFORMATION,
  BLACKLIST,
  CONTENT_PAGES,
  POLITICAL_EXPOSURE,
  TOTAL_ARTICLES_COUNT,
  TINYMCE_API_KEY
}