import React, { useState } from 'react';
import styled from 'styled-components';
import { Loader, Step, Message } from 'semantic-ui-react';
import { RelationshipForm } from './RelationshipForm';
import { getDtrmResult } from '../../utils/litigation';
import { TempLitigation } from '../../model/litigation/tempLitigation';
import { LitigationCases } from './LitigationCases';

const Wrapper = styled.div`
  overflow: auto;
	width: 100%;
	padding-top: 10px;
`;

const tempLitigation = new TempLitigation();

export function NewLitigation(props) {
  const { match: { params } } = props;
	// Declare active step variables
	const INPUT_FORM = 'inputForm';
	const VALIDATE = 'validate';

	// Declare component states
  const [activeCaseNum, setActiveCaseNum] = useState('');

	const [activeStep, setActiveStep] = useState(VALIDATE);
	const [completed, setCompleted] = useState({});
	const [errors, setErrors] = useState({});
	const [httpResponse, setHttpResponse] = useState(null);
	const [inputs, setInputs] = useState({});
	const [loading, setLoading] = useState({});
	const [, setResults] = useState(null);
  const [mappedResults, setMappedResults] = useState(null);

  const receiveHttpReponse = (result) => {
    const mappedResult = getDtrmResult(tempLitigation.fieldDetails, result);
    const caseNumStr = Object.keys(mappedResult)[0];
    setHttpResponse(mappedResult[caseNumStr]);
  }
	const validateComplete = (event, { value }) => {
		window.scrollTo(0, 0);
		const status = !!value;
		setCompleted(prevState => (
			{ ...prevState, validate: status }
		));
		if (status) {
			const { entityId, entity2Id } = inputs;
			setInputs({ entityId, entity2Id });
			setActiveStep(INPUT_FORM);
		} else {
			setActiveStep(VALIDATE);
		}
	}

	return (
		<Wrapper>
			<Loader active={loading.adding}>Loading...</Loader>
      <Step.Group widths={2} size='small' ordered>
        <Step completed={completed.validate} active={activeStep === 'validate'}>
          <Step.Content>
            <Step.Title>
              Validate Case Num
            </Step.Title>
            <Step.Description>
              Find case with the case number
            </Step.Description>
          </Step.Content>
        </Step>
        <Step completed={completed.inputForm} active={activeStep === 'inputForm'} disabled={!completed.validate}>
          <Step.Content>
            <Step.Title>
              ADD
            </Step.Title>
            <Step.Description>
              Enter details of case
            </Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>

      {activeStep === 'validate' && (
        <>
          <LitigationCases
            setActiveCaseNum={setActiveCaseNum}
            activeCaseNum={activeCaseNum}
            validateComplete={validateComplete}
            setResults={setResults}
            results={setResults}
            mappedResults={mappedResults}
            setMappedResults={setMappedResults}
            setLoading={setLoading}
            loading={loading}
            readOnly
          />
        </>
      )}
      {activeStep === INPUT_FORM && !httpResponse &&
				<RelationshipForm
					params={params}
          formType='litigation'
					isNewRelationship={true}
					inputs={inputs}
					errors={errors}
					setErrors={setErrors}
					setHttpResponse={receiveHttpReponse}
					setInputs={setInputs} />}
      {httpResponse && (
        <Message positive>
          {httpResponse.caseNumStr} Created.
        </Message>
      )}
		</Wrapper>
	);
}
