import React from 'react';
import styled from 'styled-components';
import { Header, Menu } from 'semantic-ui-react';
import { Route } from "react-router-dom";
import { withRouter } from 'react-router';
import ValidateEntity from './ValidateEntity';
import { ENTITY_TYPE, ENTITY_BY_TYPE } from '../utils/ref/entityType';

const entityTypeOrder = [ENTITY_TYPE.person, ENTITY_TYPE.company, ENTITY_TYPE.family, ENTITY_TYPE.otherOrganisation, ENTITY_TYPE.governmentAgency];

const Content = styled.div`
  margin-top: 20px;
`;

class Validation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.switchMode = (e, { value }) => {
      this.props.history.push('/validation/' + value)
    }
  }
  render() {
    const pathname = this.props.location && this.props.location.pathname;

    return (
      <div>
        <Header
          content='Find / Validate Entity by Name'
          subheader='Search entity in Capital IQ, Datarama and Datarama Search engine simultaneously'
        />

        <Route path="/validation" component={() => {
          return (
            <div>
              <Menu>
              {entityTypeOrder.map(type => (
                <Menu.Item
                  key={type}
                  name={ENTITY_BY_TYPE[type].header}
                  active={pathname === `/validation/${type}`}
                  onClick={this.switchMode}
                  value={type}
                />
              ))}
              </Menu>
            </div>
          )
        }} />
        <Content>
        {entityTypeOrder.map(type => (
          <Route
            key={type}
            path={`/validation/${type}`}
            component={() => (
              <ValidateEntity
                type={type}
                {...this.props}
                scope={ENTITY_BY_TYPE[type].searchScope && ENTITY_BY_TYPE[type].searchScope.dtrmOnly ? { dtrm: true } : null}
                />
              )}
          />
        ))}
        </Content>
      </div>
    )
  }
}

export default withRouter(Validation);
