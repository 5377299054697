export const SEARCH_FIELDS = {
  entityId: {
    label: 'Entity 1 Id',
    placeholder: 'Entity 1 Id',
    required: true
  },
  entity2Id: {
    label: 'Entity 2 Id',
    placeholder: 'Entity 2 Id',
    required: true
  }
}