import React from 'react';
import styled from 'styled-components';
import { Step } from 'semantic-ui-react';

const Scrollable = styled.div`
  overflow: auto;
  margin-bottom: 10px;
`;

export function RelationshipStepper({ completed, activeStep, isNewRelationship }) {
  const WIDTHS = isNewRelationship ? 2 : 3;
  return (
    <Scrollable>
      <Step.Group widths={WIDTHS} size='small' ordered>
        <Step completed={completed.validate} active={activeStep === 'validate'}>
          <Step.Content>
            <Step.Title>
            { isNewRelationship
                ? 'Validate Relationship'
                : 'Find Relationship' }
            </Step.Title>
            <Step.Description>
              { isNewRelationship
                ? 'Validate if Relationship to be added is NOT already in the database'
                : 'To get relationship from database to be updated' }
            </Step.Description>
          </Step.Content>
        </Step>
        <Step completed={completed.inputForm} active={activeStep === 'inputForm'} disabled={!completed.validate}>
          <Step.Content>
            <Step.Title>
            { isNewRelationship
                ? 'Add new Relationship'
                : 'Update Details' } 
            </Step.Title>
            <Step.Description>
              Enter details of relationship
            </Step.Description>
          </Step.Content>
        </Step>
        { !isNewRelationship &&
          <Step completed={completed.confirm} active={activeStep === 'confirm'} disabled={!completed.inputForm}>
            <Step.Content>
              <Step.Title>Confirm Details</Step.Title>
              <Step.Description>Check updated details before updating</Step.Description>
            </Step.Content>
          </Step> }
      </Step.Group>
    </Scrollable>
  )
}