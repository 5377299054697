import { SET_PROJECTS, UPDATE_PROJECT } from '../actions/actionTypes';

const initalState = {
  projects: {}
};

export default (state = initalState, action) => {
  switch(action.type) {
    case SET_PROJECTS: {
      const newProjects = action.projects.reduce((a, p) => {
        a[p.projectId] = {
          ...p,
          ...(action.isOneOff ? {
            lastFetched: new Date()
          } : {})
        }
        return a;
      }, {})
      return {
        ...state,
        projects: action.forceUpdate ? newProjects : {
          ...state.projects,
          ...newProjects
        },
        lastFetched: action.isOneOff ? state.lastFetched : new Date(),
      }
    }
    case UPDATE_PROJECT: {
      let additionalMeta = {};
      if (action.updatedField === 'overallStatus') {
        additionalMeta.allowUpdates = action.updatedValue === 'active';
      }
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.projectId]: {
            ...state.projects[action.projectId],
            ...additionalMeta,
            [action.updatedField]: action.updatedValue
          }
        },
      }
    }
    default:
      return state;
  }
}
