import React from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Header, Button, Icon, Message } from 'semantic-ui-react';
import ReactTable from 'react-table';
import values from 'lodash.values';
import { connect } from 'react-redux';
import { getAllProjects } from '../utils/api';
import Loading from './Loading';
import { getAuth, getSettings, getProjects } from '../reducers/selectors';
import { setProjects } from '../actions';
import Project from '../model/project';
import Options from './Options';
import ProjectStatus from './table/projectStatus';
import OverallStatus from './table/overallStatus';
import LastUpdated from './LastUpdated';

const customCell = {
  projectStatus: ProjectStatus,
  overallStatus: OverallStatus,
}

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      errors: null,
      isLoading: false,
      pageError: this.props.location.state && this.props.location.state.error,
    };
    this.project = new Project();
    this.fetchProjects = this.fetchProjects.bind(this);
    this.setErrors = (errors) => {
      this.setState(() => ({
        errors,
      }))
    };
    this.clearPageError = () => {
      this.setState(() => ({
        pageError: null,
      }))
    }
  }

  componentDidMount() {
    const { lastFetched } = this.props;
    // Fetch only if not previously fetched or last fetched > 5 minutes
    const shouldFetch = !lastFetched || (
      moment(lastFetched).isBefore(moment().subtract(5, 'minutes'))
    );
    if (shouldFetch) {
      this.fetchProjects();
    }
  }

  async fetchProjects(e, props) {
    let forceUpdate = false;
    if (props && props.value === 'hard') {
      forceUpdate = true;
    }
    this.setState(() => ({
      isLoading: true,
    }));
    const data = await getAllProjects();
    if (data) {
      this.props.setProjects(data.projects, false, forceUpdate);
      this.setState(() => ({
        isLoading: false,
      }))
    }
  }

  render() {
    const { projects, lastFetched } = this.props;
    const { pageError, isLoading } = this.state;
    const columns = this.project.getReadColumns();
    columns.forEach((col) => {
      if (col.input && col.input.sourceFromState) {
        col.Cell = ({ value }) => {
          if (!this.props[col.input.sourceFromState]) return null;
          if (col.rawDataTransform && value) {
            return col.rawDataTransform(value).map(p => this.props[col.input.sourceFromState][p] && this.props[col.input.sourceFromState][p][col.input.displayField]).join(', ');
          }
          if (!this.props[col.input.sourceFromState][value]) return null
          const val = this.props[col.input.sourceFromState][value][col.input.displayField];
          return val;
        }
      }
      if (col.input && customCell[col.id]) {
        col.Cell = ({ value }) => {
          const Element = customCell[col.id];
          return <Element col={col} value={value} />
        }
      }
      if (col.input && col.input.filter) {
        col.Filter = ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : ""}
          >
            <option value="">Show All</option>
            <Options field={col.input.source} />
          </select>)

      }
    })

    return (
      <div>
        <Header
          as='h2'
          content='All Projects'
          subheader='Show all projects that are/were assigned to you'
        />
        {pageError && pageError === 401 && (
          <Message
            negative
            onDismiss={this.clearPageError}
            header="Unable to access project. Please check your permission."
            content="You cannot access the project because you do not have the correct rights to do so. Please check with tech team or analyst team for assistance"
          />
        )}
        {projects && (
          <div>
            <Button compact onClick={this.fetchProjects} value="hard" icon basic><Icon name='refresh'/> Refresh</Button>
            <LastUpdated
              loading={isLoading}
              date={lastFetched}
            />
            <br/><br/>
            <ReactTable
              data={values(projects)}
              columns={columns}
              defaultPageSize={10}
              className="-striped -highlight"
              filterable
              style={{ cursor: 'pointer' }}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    if (rowInfo && rowInfo.original) {
                      this.props.history.push('/project/' + rowInfo.original.projectId)
                    }
                  }
                }
              }}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value}
            />
          </div>
        )}
        <Loading active={isLoading} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const authState = getAuth(state);
  // const permissions = (authState.permissions || {}).general;
  const settings = getSettings(state);
  const projects = getProjects(state);
  return {
    users: settings.users,
    clients: settings.clients,
    projects: projects.projects,
    lastFetched: projects.lastFetched,
    loggedInUser: authState.loggedInUser,
  }
};

export default withRouter(connect(mapStateToProps, { setProjects })(Projects));
