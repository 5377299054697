import React from 'react';
import styled from 'styled-components';

const TagDecorator = styled.div`
  ${({ inline }) => inline ? `
    display: inline-block;
    ` : `
    display: block;
    width: 80%;
  `};
  padding: 2px 6px;
  font-size: ${({ size }) => {
    if (size) {
      if (size === 'large') return '15px';
    }
    return '10px';
  }};
  border-radius: 2px;
  margin: 5px 0;
  background: #60bdff;
  text-align: center;
  ${({ isMain }) => isMain && `
    background: #696969;
    color: #fff;
  `}
  ${({ bordered }) => bordered && `
    border: 1px solid #444;
    background: #fff;
  `}
  ${({ isTerm }) => isTerm && `
    background: #f75353;
    color: #fff;
  `}
`;

export const Tag = ({ label, size, nested, inline, isTerm, isMainEntity, bordered }) => {
  return (
    <TagDecorator
      isMain={isMainEntity}
      size={size}
      bordered={bordered}
      inline={inline}
      isTerm={isTerm}
      nested={nested}
    >
      {label}
    </TagDecorator>
  )
}
