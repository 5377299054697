import React, { useEffect } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { getEntityBasicInfo, updatePreviewReport } from '../../../utils/api';
import { BASIC_INFORMATION } from '../../../constants';
import { CustomTextArea } from '../CustomTextArea';

export function BasicInfo({ setState, state }) {
  useEffect(() => {
    async function fetching() {
      fetchBasicInfo();
      return () => {
        new AbortController().abort();
      };
    }
    if (state[BASIC_INFORMATION] === "") {
      fetching();
    }
  }, []);

  const fetchBasicInfo = async () => {
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))
    const { basicInfo } = await getEntityBasicInfo(state.dataramaEntityId);
    setState(prevState => (
      { ...prevState, [BASIC_INFORMATION]: basicInfo, isProcessing: false }
    ))
  }

  const handleOnSubmit = async evt => {
    evt.preventDefault();
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))
    await updatePreviewReport(JSON.stringify({
      reportId: state.reportId,
      [BASIC_INFORMATION]: state[BASIC_INFORMATION]
    }));
    setState(prevState => (
      { ...prevState,
        completed: { ...prevState.completed, basicInfo: true },
        isProcessing: false }
    ))
  }

  // const handleOnClick = evt => {
  //   evt.preventDefault();
  //   setState(prevState => (
  //     { ...prevState,
  //       active: 'pep' }
  //   ))
  // }
  
  return <Form size='small' onSubmit={ handleOnSubmit } className='basic-info'>
    <div className='segment'>
      <div className='justify-space-between'>
        <Button content='Refresh' onClick={ fetchBasicInfo } size='mini' loading={ state.isProcessing } disabled={ state.isProcessing } />
        <Button size='mini' content='Save' type='submit' loading={ state.isProcessing } disabled={ state.isProcessing } />
        {/* <Button size='mini' content='Next' onClick={handleOnClick} type='button' loading={ state.isProcessing } disabled={ state.isProcessing } /> */}
      </div>
      <CustomTextArea setState={ setState } state={ state } />
    </div>
  </Form>

}