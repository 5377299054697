/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import { getSettings } from '../reducers/selectors';
import * as OPTIONS from '../utils/ref/formConstants';
import Dropdown from './ingestion/fields/Dropdown';
import Text from './ingestion/fields/Text';
import DateField from './ingestion/fields/DateField';

const Input = (props) => {
  let { field, compact, defaultValue, options = {}, errors = {}, isValidating, handleFieldChange } = props;
  if (!field.input.type) return null;
  const handleChange = (e, { value, name }) => {
    let newValue = value;
    if (field.fieldTransform) {
      newValue = value && field.fieldTransform(value)
    }
    handleFieldChange(null, { value: newValue, name })
  }
  switch (field.input.type) {
    case 'dropdown': {
      const opts = (field.input.source && OPTIONS[field.input.source]) || (field.input.sourceFromState && props[field.input.sourceFromState] && Object.keys(props[field.input.sourceFromState]).map(keyId => {
        const opt = props[field.input.sourceFromState][keyId];
        if (opt) {
          return { id: opt[field.input.id || '_id'], display: opt[field.input.displayField] }
        }
      })) || options[field.id] || [];
      if (field.rawDataTransform && props[field.input.sourceFromState]) {
        defaultValue = defaultValue && field.rawDataTransform(defaultValue).map(p => {
          const f = props[field.input.sourceFromState][p];
          return JSON.stringify({
            id: f[field.input.id || '_id'],
            display: f[field.input.displayField]
          })
        })
      }
      if (field.arrayTransform) {
        defaultValue = field.arrayTransform(defaultValue);
      }
      return (
        <Dropdown
          key={field.id}
          compact={compact}
          field={{
            ...field,
            multiple: field.input.multiple,
            allowAdditions: field.input.allowAdditions,
            search: field.input.search,
            selection: field.input.selection,
            fieldType: field.input.fieldType,
            options: opts,
          }}
          isValidating={isValidating}
          error={errors[field.id]}
          defaultValue={defaultValue && (field.input.displayField ? (field.input.multiple ? defaultValue : JSON.stringify(opts.filter(p => p.id === defaultValue)[0])) : defaultValue) || (field.input.multiple ? [] : '')}
          handleFieldChange={handleChange}
        />
      )
    }
    case 'date':
      return (
        <DateField
          key={field.id}
          field={field}
          compact={compact}
          isValidating={isValidating}
          error={errors[field.id]}
          defaultValue={defaultValue && moment(defaultValue).format('YYYY-MM-DD')}
          noMax={field.input.noMax}
          handleFieldChange={handleChange}
        />
      )
    default:
      return (
        <Text
          key={field.id}
          field={field}
          compact={compact}
          meta={field.input.meta}
          error={errors[field.id]}
          defaultValue={defaultValue}
          isValidating={isValidating}
          handleFieldChange={handleChange}
        />
      )
  }
}

const mapStateToProps = state => {
  const settings = getSettings(state);
  return {
    users: settings.users,
    clients: settings.clients,
    riskTypes: settings.riskTypes,
    languages: settings.languages,
  }
};


export default withRouter(connect(mapStateToProps)(Input));
