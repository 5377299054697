import React from 'react';
import { Step, Header, Button, Icon, Form, Label, Message } from 'semantic-ui-react';
import styled from 'styled-components';

import { Redirect } from 'react-router'
import FindEntity from '../FindEntity';
import { getDisplayType } from '../../utils/conversion/entityType';
import { checkIfValidEntityType } from '../../utils/routeHelper';
import EntityDetails from '../EntityDetails';
import getEntityModel from '../../model/entity/getEntityModel';
import RiskDetails from './RiskDetails';
import GroupedForm from '../GroupedForm';
import FieldErrors from './FieldErrors';
import { addRiskDetails, removeRiskDetails } from '../../utils/api';

const Hint = styled.div`
  padding: 10px;
  color: #ccc;
`;

const Wrapper = styled.div`
  padding: 10px 0 50px 0;

`;
const RiskHeaderWrapper = styled.div`
  > * {
    display: inline-block;
  }
`;
const MESSAGE_ELEM = 'page-message';

const RiskHeader = styled(Header)`
  margin-right: 10px;
`;

const RiskForm = styled(Form)`
  border: 1px solid #ececec;
  border-radius: 2px;
  padding: 10px;
  margin: 10px 0;
`;

class UpdateRisk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEntity: null,
      activeStep: 'validate',
      completed: {},
      message: null,
      loading: {},
      pendingRisk: null,
      validating: false,
    }
    this.processEntity = this.processEntity.bind(this);
    this.toggleAddPendingRisk = this.toggleAddPendingRisk.bind(this);
    this.toggleRemoveRisk = this.toggleRemoveRisk.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.clearMessage = this.clearMessage.bind(this);

    this.errorMessage = {
      negative: true,
      header: 'Unable to process',
    }
    this.successMessage = (action) => ({
      positive: true,
      header: `Successfully ${action} risk detail`,
    });

    this.fieldChange = this.fieldChange.bind(this);
    this.riskAction = this.riskAction.bind(this);

    this.clearAll = this.clearAll.bind(this);
    this.cancel = this.cancel.bind(this);
    this.addRisk = this.addRisk.bind(this);
    this.removeRisk = this.removeRisk.bind(this);
  }

  componentDidMount() {
    this.setFields();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.type !== this.props.match.params.type) {
      this.setFields();
    }
  }

  setFields() {
    const type = this.props.type || this.props.match.params.type;
    this.baseEntity = getEntityModel(type);
  }

  scrollTo(elementId) {
    const message = document.getElementById(MESSAGE_ELEM);
    if (message) {
      const { y } = message.getBoundingClientRect();
      const newHeight = y + window.scrollY;
      window.scrollTo(0, newHeight);
    }
  }

  cancel(e) {
    e.preventDefault();
    this.setState(() => ({
      validating: false,
      duplicateError: null,
      errors: null,
    }))
  }

  clearAll() {
    this.setState(() => ({
      selectedEntity: null,
      activeStep: 'validate',
      completed: {},
      pendingRisk: null,
      validating: false,
      duplicateError: null,
      errors: null,
      updateMode: false,
    }))
  }

  fieldChange(e, { value, name }) {
    this.setState(prevState => ({
      pendingRisk: {
        ...prevState.pendingRisk,
        [name]: value,
      }
    }))
  }

  toggleAddPendingRisk() {
    this.setState(prevState => ( {
      pendingRisk: prevState.pendingRisk ? null : {},
      duplicateError: null,
      errors: null,
    }), () => {
      const height = document.body.scrollHeight;
      window.scrollTo(0, height);
    })
  }

  clearMessage() {
    this.setState(() => ({
      message: null,
    }))
  }

  toggleRemoveRisk() {
    this.setState(prevState => ({
      updateMode: !prevState.updateMode,
    }))
  }

  riskAction() {
    if (this.state.validating)  {
      this.addRisk();
    } else {
      const { pendingRisk } = this.state;

      const fields = this.baseEntity && this.baseEntity.riskDetailsFieldDetails;
      const outputFields = Object.keys(fields).reduce((a, fieldName) => {
        const field = fields[fieldName];
        if (fieldName === 'riskTypeId') {
          const riskTypeExisted = (this.state.selectedEntity.risks || []).filter(risk => risk.riskTypeId === pendingRisk[fieldName]);
          if (riskTypeExisted.length > 0) {
            a.duplicate = pendingRisk[fieldName];
          }
        }
        if (field.required && !pendingRisk[fieldName]) {
          a.errors[fieldName] = true;
        } else if (fieldName)
        if (pendingRisk[fieldName]) {
          a.fields[fieldName] = pendingRisk[fieldName];
        }
        return a;
      }, { errors: {}, fields: {}})

      if (Object.keys(outputFields.errors).length > 0 || outputFields.duplicate) {
        this.setState(() => ({
          errors: outputFields.errors,
          duplicateError: outputFields.duplicate,
        }))
      } else {
        this.setState(() => ({
          duplicateError: null,
          errors: null,
          validating: true,
        }))
      }

    }
  }

  async addRisk() {
    const { pendingRisk, selectedEntity } = this.state;
    const { projectId } = this.props;
    this.setState(() => ({
      loading: {
        add: true,
      }
    }))
    const result = await addRiskDetails(projectId, selectedEntity.entityId, pendingRisk);

    this.setState(prevState => {
      let newState = {
        loading: {},
        message: this.errorMessage
      }
      if (result) {
        return {
          ...newState,
          selectedEntity: {
            ...selectedEntity,
            risks: (selectedEntity.risks || []).concat(result)
          },
          message: this.successMessage('added'),
          pendingRisk: null,
          validating: false,
          completed: {
            ...prevState.completed,
            inputForm: true,
          }
        }
      }
      return newState;
    }, () => {
      this.scrollTo(MESSAGE_ELEM)
    })
  }

  async removeRisk(e, { value }) {
    const { selectedEntity } = this.state;
    const { projectId } = this.props;
    if (!value) return;
    this.setState(() => ({
      loading: {
        remove: value,
      }
    }))

    const result = await removeRiskDetails(projectId, selectedEntity.entityId, value);

    this.setState(prevState => {
      let newState = {
        loading: {},
        message: this.errorMessage
      }
      if (result) {
        return {
          ...newState,
          message: this.successMessage('removed'),
          selectedEntity: {
            ...prevState.selectedEntity,
            risks: (prevState.selectedEntity.risks || []).filter(risk => risk.riskTypeId !== `${value}`)
          },
          pendingRisk: null,
          validating: false,
          completed: {
            ...prevState.completed,
            inputForm: true,
          }
        }
      }
      return newState;
    }, () => {
      this.scrollTo(MESSAGE_ELEM)

    })
  }



  processEntity(body) {
    if (body && body.entityId) {
      this.setState(prevState => ({
        selectedEntity: body,
        activeStep: 'inputForm',
        completed: {
          ...prevState.completed,
          validate: true,
        }
      }))
    }
  }

  render() {
    const { match: { params } } = this.props;
    const { type, projectId } = params;
    const {
      selectedEntity,
      validating,
      errors,
      activeStep,
      completed,
      pendingRisk,
      loading,
      duplicateError,
      updateMode,
      message,
    } = this.state;

    if (!checkIfValidEntityType(type)) {
      return <Redirect to={`/project/${projectId}/actions`} />
    }

    const displayType = getDisplayType(type);
    const columns = this.baseEntity && this.baseEntity.getColumns();

    const riskColumns = this.baseEntity && this.baseEntity.getRiskFields();

    return (
      <Wrapper>
        <Step.Group widths={3} size='small' ordered>
          <Step completed={completed.validate} active={activeStep === 'validate'}>
            <Step.Content>
              <Step.Title>Find {displayType}</Step.Title>
              <Step.Description>To get {displayType} from database to be updated</Step.Description>
            </Step.Content>
          </Step>
          <Step completed={completed.inputForm} active={activeStep === 'inputForm'} disabled={!completed.validate}>
            <Step.Content>
              <Step.Title>Update Details</Step.Title>
              <Step.Description>Enter updated details of entity</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>

        {activeStep === 'validate' && (
          <FindEntity
            type={type}
            searchByName
            searchById
            selectEntityAction={this.processEntity}
            {...this.props}
          />
        )}
        {activeStep === 'inputForm' && (
          <div>
            <Button onClick={this.clearAll} size='small' basic><Icon name='arrow left' />Find another Entity instead.</Button>
            <br/><br/>
            {selectedEntity && (
              <React.Fragment>
                <EntityDetails
                  columns={columns}
                  results={selectedEntity}
                  type={type}
                  collapse
                  omitFields={{ risks: true }}
                />
                <RiskHeaderWrapper>
                  <RiskHeader as='h3'>Risk Details ({(selectedEntity.risks && selectedEntity.risks.length) || 0})</RiskHeader>
                  {!pendingRisk && (
                    <Button
                      onClick={this.toggleAddPendingRisk}
                      size='small'
                      color='green'
                      basic
                    ><Icon name='add' /> Add new Risk Detail</Button>
                  )}
                  <Button
                    onClick={this.toggleRemoveRisk}
                    size='small'
                    {...(!updateMode ? {
                      color: 'green'
                    } : {})}
                    basic
                  >{updateMode ? 'Exit Update' : 'Update current list'}</Button>
                </RiskHeaderWrapper>
                {message && (
                  <Message
                    id={MESSAGE_ELEM}
                    positive={message.positive}
                    negative={message.negative}
                    header={message.header}
                    content={message.content}
                    onDismiss={this.clearMessage}
                  />
                )}
                {updateMode && (
                  <Hint>Only some risk types can be removed. For other types, please contact data admin.</Hint>
                )}

                <RiskDetails
                  risks={selectedEntity.risks}
                  isUpdating={updateMode}
                  updateAction={this.removeRisk}
                  loading={loading.remove}
                />
              </React.Fragment>
            )}

            <br/>
            {pendingRisk && (
              <React.Fragment>
                <Header as='h3'>New Risk Detail</Header>
                <RiskForm onSubmit={this.riskAction}>
                  <GroupedForm
                    groupedFields={[riskColumns]}
                    isValidating={validating}
                    handleFieldChange={this.fieldChange}
                  />
                  {duplicateError && (
                    <React.Fragment>
                      <Label color='red'>Risk Type already exists for this entity! Please change the risk type.</Label>
                      <br/>
                      <br/>
                    </React.Fragment>
                  )}
                  <Button type='submit' color='green' loading={loading.add} disabled={loading.add}>{validating ? 'Add Risk' : 'Confirm before adding'}</Button>
                  {errors && (
                    <FieldErrors errors={errors} />
                  )}
                  {validating ? (
                    <Button onClick={this.cancel} basic>Cancel</Button>
                  ) : (
                    <Button onClick={this.toggleAddPendingRisk} basic>Exit</Button>
                  )}

                </RiskForm>
              </React.Fragment>
            )}
            <Button onClick={this.clearAll} basic><Icon name='arrow left' />Find another Entity instead.</Button>
          </div>
        )}

      </Wrapper>
    )
  }
}

export default UpdateRisk;
