import React from 'react';
import { Form } from 'semantic-ui-react';

export function DtrmEntityId({ setState, state }) {

  const handleOnInputEntityId = (evt, data) => {
    setState(prevState => (
      { ...prevState, dataramaEntityId: data.value }
    ))
  }

  return <Form.Group className='segment'>
    <Form.Input
      type='text'
      label='Datarama Entity Id'
      value={ state.dataramaEntityId }
      onChange={ handleOnInputEntityId } />
  </Form.Group>
}