import React from 'react';
import { Hyperlink } from '../utils/styles';

export default ({ label, url }) => {
  if (!url) return null;
  return (
    <Hyperlink
      href={url}
      target='_blank'
      rel='noreferrer noopener'
    >{label || url}</Hyperlink>
  )
}
