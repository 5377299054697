import React from 'react';
import uuid from 'uuid';
import axios from 'axios';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Header, Segment, Button, Form, Input, } from 'semantic-ui-react';
import { uploadFiles } from '../../utils/api';
import { getAuth } from '../../reducers/selectors';
import AccessDenied from '../AccessDenied';

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      activePage: 1,
      file: null,
      isUploading: false,
      uploadedFile: {},
      list: null,
      error: null,
      entityType: null,
      uploading: {},
      isFetching: false,
      dataramaId: this.props.dataramaId,
      customName: null,
      activeTab: null,
      entity: this.props.entity,
    };

    this.handleTabChange = (e, { activeIndex }) => {
      this.setState(() => ({
        activeTab: activeIndex
      }))
    }
    this.navigateToPage = (e, { activePage }) => {
      this.setState(() => ({
        activePage,
      }))
    }
    this.handleIdChange = (e, { value, name }) => {
      this.setState(() => ({
        dataramaId: value,
      }))
    }
    this.handleNameChange = (e, { value, name }) => {
      this.setState(() => ({
        customName: value,
      }))
    }

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.cancel = this.cancel.bind(this);
    this.cancelUpload = (e) => {
      e.preventDefault();
      this.setState(() => ({
        uploading: {},
      }))

    }
    this.submitFile = this.submitFile.bind(this);
    this.setSelectionRange = this.setSelectionRange.bind(this);

    this.listFile = this.listFile.bind(this);

    this.actualUpload = this.actualUpload.bind(this);

  }

  componentDidMount() {
    // if (this.props.dataramaId) {
    //   this.listFile();
    // }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customName && !this.props.customName) {
      this.cancel();
    }
  }

  async submitFile(e) {
    e.preventDefault();
    const { dataramaId, file, fileMapping } = this.state;
    if (!dataramaId) return;
    this.setState(() => ({
      isUploading: true,
      error: null,
      uploading: Object.keys(file).reduce((a, c) => {
        a[c] = true;
        return a;
      }, {})
    }))

    const presigned = await uploadFiles(dataramaId, Object.keys(fileMapping));
    if (!presigned) return;

    await Promise.all(presigned.map(p => {
      const index = fileMapping[p.fileId].index;
      if (file[index]) {
        return this.actualUpload(p, file[index], index)
      }
      return false;
    }))
  }

  actualUpload(presignedPostData, file, fileIndex) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
      });

      formData.append("file", file);

      axios.post(presignedPostData.url, formData)
      .then((result) => {
        const { key } = presignedPostData.fields;
        const url = `${presignedPostData.url}/${key}`;
        this.setState(prevState => ({
          uploading: Object.assign({}, prevState.uploading, {
            [fileIndex]: false,
          }),
          uploadedFile: Object.assign({}, prevState.uploadedFile, {
            [fileIndex]: {
              Location: url,
            }
          }),
          list: Object.assign({}, prevState.list || {}, {
            files: ((prevState.list || {}).files || []).concat({
              Key: key,
            })
          })
        }))
        resolve({ Key: key });
      })
      .catch(err => {
        console.log('error!');
      })
    });


  }

  handleFileUpload(event) {
    const file = event.target.files;
    const fileMapping = Object.keys(file).reduce((a, f) => {
      const fileId = uuid.v4();
      a[fileId] = {
        fileId,
        name: file.name,
        index: f,
      };
      return a;
    }, {});

    this.setState(() => ({
      file,
      fileMapping,
    }));

  }

  cancel(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState(() => ({
      isUploading: false,
      file: null,
      uploadedFile: null,
      list: null,
      error: null,
      dataramaId: null,
      customName: null,
      uploading: {},
      entity: null,
      disabled: false,
    }))
  }



  setSelectionRange(e) {
    e.target.select();
  }



  async listFile() {
    const { customName } = this.state;
    if (!customName) return;
    this.setState(() => ({
      disabled: true,
    }))
    this.props.setCustomName(customName);
  }

  render () {
    const { canUpload } = this.props;
    if (!canUpload) return (
      <AccessDenied action='upload' resourceType='toDocument' />
    )
    const {
      customName,
      list,
      disabled,
    } = this.state;
    return (
        <Segment>
          <Header
            as='h3'
            content='Custom Folder Name'
            subheader='(White spaces will be replaced in kebabcase)'
          />

          <Form onSubmit={this.listFile}>
            <Input
              icon='search'
              disabled={disabled}
              name='customName'
              iconPosition='left'
              placeholder='Custom Folder (case-insensitive)'
              onChange={this.handleNameChange}
              value={customName || ''}
            />
            {list ? (
              <Button onClick={this.props.cancel}>Search Again</Button>
            ) : (
              <Button
              type="submit"
              disabled={!customName}
              >List All Files</Button>
            )}
          </Form>

        </Segment>
    );
  }
}

const mapStateToProps = state => {
  const authState = getAuth(state);
  const permissions = (authState.permissions || {}).general;
  return {
    loggedInUser: authState.loggedInUser,
    canUpload: permissions && permissions['document.create'],
  }
};

export default withRouter(connect(mapStateToProps)(FileUpload));
