import React, { useEffect, useState } from 'react';
import { SearchWidget } from './SearchWidget';
import { PreviewWidget } from './PreviewWidget';
import { SubHeader } from './styles.js';
import { ViewBucketsWidget } from './ViewBucketsWidget';
import { CONCEPTS } from './constants';


export function AdverseMediaResearch() {

  const [categories, setCategories] = useState([]);
  const [viewBuckets, setViewBuckets] = useState(null);
  const [otherLangs, setOtherLangs] = useState([]);
  const [activeStep, setActiveStep] = useState('input');
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    async function fetching() {
      setCategories(CONCEPTS.map(concept => concept.code));
      return () => {
        new AbortController().abort();
      };
    }
    fetching();
  }, []);

  const handleViewBuckets = bucket => {
    if (!bucket) {
      setViewBuckets(null);
      return;
    }
    setViewBuckets({ bucket });
  }

  const handleCancel = () => {
    setPreview(null);
    setActiveStep('input');
    setCategories(CONCEPTS.map(concept => concept.code));
    setOtherLangs([]);
    setViewBuckets(null);
  }

  return (
    <div>
      <SubHeader>Adverse Media Analysis</SubHeader>
      { activeStep === 'input' &&
        <SearchWidget
          categories={ categories }
          handleCancel={ handleCancel }
          handleViewBuckets={ handleViewBuckets }
          otherLangs={ otherLangs }
          setActiveStep={ setActiveStep }
          setCategories={ setCategories }
          setPreview={ setPreview }
          setOtherLangs={ setOtherLangs } /> }
      { viewBuckets &&
        <ViewBucketsWidget
          handleViewBuckets={ handleViewBuckets }
          viewBuckets={ viewBuckets } /> }
      { activeStep === 'preview' &&
        <PreviewWidget
          handleCancel={ handleCancel }
          preview={ preview } /> }
      </div>
  )
}