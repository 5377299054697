/* eslint-disable */
import React, { useState } from 'react';
import { Menu, Icon } from 'semantic-ui-react'
import styled from 'styled-components';
import { withRouter } from "react-router";
import Logout from './Logout';
import auth from '../utils/auth';
import Greeting from './Greeting';

const Wrapper = styled.div`
  background: #445877;
  padding: 10px;
  border-radius: 5px;
  max-width: 250px;
`;
const Logo = styled.div`
  img {
    padding: 5px 10px;
    width: 150px;
  }
  border-radius: 2px;
  text-align: center;
`;

function Nav (props) {
  const [activeItem, setActive] = useState('enterprise');
  const goToItem = (e, { value }) => {
    setActive(value);
    props.history.push(value)
  }
  const logout = () => {
    auth.logout(() => {
      props.onAuthStateChange(false);
    })
  }
  return (
    <Wrapper>
      <Logo>
        <img src={`/datarama_logo.png`} alt='datarama' />
        <Greeting />
      </Logo>
      <Menu vertical fluid>
        <Menu.Item
          name='My Fast Tracker'
          active={activeItem === 'top'}
          onClick={goToItem}
          value='/'
        />
        <Menu.Item>
          <Menu.Header>Project</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name='View All Projects'
              active={activeItem === '/projects'}
              onClick={goToItem}
              value='/projects'
            />
            <Menu.Item
              name='New Project'
              active={activeItem === '/projects/add'}
              onClick={goToItem}
              value='/projects/add'
            />
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          <Menu.Header>Research</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name='Risk Indicators'
              active={activeItem === '/riskfactors'}
              onClick={goToItem}
              value='/research/riskfactors' />
            <Menu.Item
              name='Sanctions'
              active={activeItem === '/sanctions'}
              onClick={goToItem}
              value="/research/sanctions" />
              <Menu.Item
                name='PEP'
                active={activeItem === '/pepblacklist'}
                onClick={goToItem}
                value='/research/pepblacklist' />
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          <Menu.Header>Documents</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name='Upload Document'
              active={activeItem === '/files/add'}
              onClick={goToItem}
              value="/files/add"
            />
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          <Menu.Header>Entity Management</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name='find by name'
              active={activeItem === '/validation'}
              onClick={goToItem}
              value="/validation" />
            <Menu.Item
              name='find by Datarama ID'
              active={activeItem === '/find/id'}
              onClick={goToItem}
              value="/find/id" />
            <Menu.Item
              name='Deduplication'
              active={activeItem === '/entities/deduplicate'}
              onClick={goToItem}
              value="/entities/deduplicate" />
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          <Menu.Header>User Management</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name='Manage Permissions'
              active={activeItem === '/permissions'}
              onClick={goToItem}
              value="/permissions"
            />
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          <Menu.Header>Client Management</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name='Add Clients'
              active={activeItem === '/clients/add'}
              onClick={goToItem}
              value="/clients/add"
            />
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item
          name='Sitemap'
          active={activeItem === '/sitemap'}
          onClick={goToItem}
          value='sitemap'
        >
          Sitemap
        </Menu.Item>
        <Menu.Item
          name='logout'
          active={activeItem === '/logout'}
          onClick={logout}
        >
          Logout <Icon name='sign-out' color='grey'/>
        </Menu.Item>
      </Menu>
    </Wrapper>

  )
}

export default withRouter(Nav);
