import React from 'react';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { value } = e.target;
    const isNull = !parseInt(value, 10);
    this.props.onChange(isNull ? null : value, this.props.value);
  }

  render() {
    return (
      <select onChange={this.onChange}>
        <option value={0}>No Match</option>
        {this.props.list.map(l => (
          <option key={l.DataramaID} value={l.DataramaID}>
            {l.name} {l.position && `- ${l.position}`}
          </option>
        ))}
      </select>
    )
  }
}

export default Dropdown;
