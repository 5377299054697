import TmpOtherEntity from './tmpOtherEntity';
import TmpPerson from '../../model/entity/tmpPerson';
import TmpCompany from '../../model/entity/tmpCompany';

export default (type) => {
  if (type === 'person') {
    return new TmpPerson();
  } else if (type === 'company') {
    return new TmpCompany();
  } else {
    return new TmpOtherEntity({ type });
  }
}
