import React, { useRef, useState } from 'react';
import Popover from 'react-text-selection-popover';
import { Button } from 'semantic-ui-react';
import { RISK_FACTORS, RISK_FACTORS_MAPPING } from '../../../constants';
import { addRiskFactor } from '../../../utils/api';

export function FullArticleRow({
  article
}) {
  const handleMouseUp = () => {
    setSelectedText(window.getSelection().toString());
  }
  const handleOnClick = event => {
    event.preventDefault();
    const concept = ((event.target || {}).dataset || {}).riskFactor;
    if (concept && selectedText !== '') {
      const requestBody = JSON.stringify({
        name: selectedText,
        language: article.lang,
        company: RISK_FACTORS_MAPPING[concept].isCompany ? concept : null,
        individual: RISK_FACTORS_MAPPING[concept].isIndividual ? concept : null
      })
      addRiskFactor(requestBody);
    }
    setIsPopoverOpen(false);
    setSelectedText('');
  }
  const reference = useRef();
  const [selectedText, setSelectedText] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const translateArticle = (article) => {
    let text = article.text;
    for (let word of article.bagOfWords) {
      if (article.lang === 'eng' && word === word.toUpperCase()) {
        let position = text.indexOf(word);
        while (position !== -1) {
          if (text.charAt(position - 1) === ' ' && text.charAt(position + word.length) === ' ') {
            text = text.replace(
              new RegExp(word,'gi'),
              `<span class='adverse-term'>${word}</span>`)
          }
          position = text.indexOf(word, position + 1);
        }
      } else {
        text = text.replace(
          new RegExp(word,'gi'),
          `<span class='adverse-term'>${word}</span>`)
      }
    }
    for (let word of article.entities) {
      text = text.replace(
        new RegExp(word,'gi'),
        `<span class='entity'>${word}</span>`)
    }
    return text;
  }


  return (
    <div className='full-article-row'>
      <h3>Article Content</h3>
      <div>
        <span
          dangerouslySetInnerHTML={ {__html: translateArticle(article) } }
          ref={ reference }
          onMouseUp={ handleMouseUp }>
        </span>
        <Popover
          onTextSelect={ () => setIsPopoverOpen(true) }
          onTextUnselect={ () => setIsPopoverOpen(false) }
          isOpen={ isPopoverOpen }
          selectionRef={ reference }>
          <div style={{
            padding: '0 5rem',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center' }}>
            { RISK_FACTORS.map(factor => (
              <Button
                key={ factor.code }
                data-risk-factor={ factor.code }
                onClick={ handleOnClick }
                color='blue'
                size='mini'
                compact
                content={ factor.label } />
            )) }
          </div>
        </Popover>
      </div>
    </div>
  )
}