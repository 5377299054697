import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import omit from 'lodash.omit';
import moment from 'moment';
import { Icon, Button, Header, Divider, Segment, Checkbox, Label, Table, Form, Message } from 'semantic-ui-react';
import { updateProjectAccessRight, updateProject, deleteProject } from '../utils/api';
import { getSettings, getAuth } from '../reducers/selectors';
import Dropdown from './ingestion/fields/Dropdown';
import Project from '../model/project';
import Input from './Input';
import { ColorLabel } from '../utils/styles';

const FieldRow = styled.div`
  height: ${({ editing }) => editing ? '100px' : '35px'};
  > div:first-of-type {
    width: 100px;
    display: inline-block;
  }
`;
const Wrapper = styled.div`
  width: 95%;
  padding-bottom: 100px;
`;


const PROJECT_ACCESS_ACTION = {
  read: {
    value: 101,
    label: 'Read',
  },
  modify: {
    value: 104,
    label: 'Modify',
  },
  admin: {
    value: 105,
    label: 'Admin',
  }
}

const ACTIONS = ['admin', 'read', 'modify'];

class ProjectDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentlySelectedUser: null,
      selectedUsers: {},
      updatePermissionList: {},
      editAccessListMode: false,
      editPermissionForm: {},
      message: null,
      loading: {},
      editProjectForm: {},
      editMode: {},
      fieldOnEdit: {},
    };
    this.project = new Project(this.props.project);
    this.toggleEditMode = (e, { value }) => {
      this.setState(prevState => {
        const nextStatus = !prevState.editMode[value];
        return {
          editMode: nextStatus ? {
            [value]: nextStatus
          } : Object.assign({}, prevState.editMode, {
            [value]: nextStatus,
          }),
        }
      })
    }

    this.deleteProject = this.deleteProject.bind(this);

    this.handleEditFormChange = (e, { value, name }) => {
      this.setState(() => ({
        fieldOnEdit: {
          name,
          value,
          body: {
            [name]: value
          }
        }
      }))
    }

    this.updateProjectProp = async () => {
      const fieldUpdate = this.state.fieldOnEdit;
      const { projectId } = this.props.project;
      if (!fieldUpdate || !fieldUpdate.body) return;
      const statusProps = `updateProject${fieldUpdate.name}`;
      this.setState(() => ({
        loading: {
          [statusProps]: true,
        },
      }));

      const result = await updateProject(projectId, fieldUpdate.body);
      this.setState(() => ({
        loading: {
          [statusProps]: false,
        },
        editMode: {},
        message: {
          positive: true,
          header: `${fieldUpdate.name} Updated`,
        }
      }))
      if (result) {
        this.props.updateProject(fieldUpdate.name, result[fieldUpdate.name])
      }

    }

    this.clearMessage = () => {
      this.setState(() => ({
        message: null,
      }))
    }

    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.updateProjectStatus = this.updateProjectStatus.bind(this);

    this.removeUser = (e, { value }) => {
      this.setState(prevState => ({
        selectedUsers: omit(prevState.selectedUsers, value)
      }))
    }
    this.updateAccessRight = async () => {
      this.setState(() => ({
        loading: {
          update: true,
        },
      }))
      const { project } = this.props;

      const { editPermissionForm } = this.state;
      if (project && project.projectId && editPermissionForm.user && editPermissionForm.permission) {
        // const userList = Object.keys(this.state.selectedUsers);
        const result = await updateProjectAccessRight(project.projectId, [editPermissionForm.user], Object.keys(editPermissionForm.permission).map(p => parseInt(p)));

        if (result)  {
          this.props.updateProjectPermission(result);
          this.setMessageForUpdateResult(true, {
            editPermissionForm: {},
            editAccessListMode: false,
          })
        } else {
          this.setMessageForUpdateResult(false);
        }
      }
    }

    this.setMessageForUpdateResult = (success, otherStateUpdates = {}) => {
      this.setState(() => ({
        ...otherStateUpdates,
        loading: {},
        message: success ? {
          positive: true,
          header: 'Access right for project updated',
        } : {
          negative: true,
          header: 'Unable to update access right for project',
          content: 'Please check with tech team or analyst team if problem persists'
        }
      }))
    }

    this.removeAccessRight = async (e, { value }) => {
      if (value) {
        this.setState(() => ({
          loading: {
            [`${value.userId}.${value.role}`]: true,
          },
        }))
        const { userId, role } = value;

        const { projectId } = this.props.project;
        if (!userId || !role || !projectId) return;
        const result = await updateProjectAccessRight(projectId, [userId], [role], true);
        if (result)  {
          this.props.updateProjectPermission(result);
        }
        this.setMessageForUpdateResult(!!result);
      }
    }

    this.toggleAccessListMode = (e, { value }) => {
      this.setState(() => ({
        editAccessListMode: value,
        message: null,
      }))
    }

    this.handleUserSelectionChange = (e, { value }) => {
      this.setState(prevState => {
        const currentlySelectedUser = JSON.parse(value);
        const prevUsersList = prevState.selectedUsers;
        return {
          selectedUsers: prevUsersList[currentlySelectedUser.id] ? (
            omit(prevUsersList, currentlySelectedUser.id)
          ) : (Object.assign({}, prevUsersList, {
            [currentlySelectedUser.id]: currentlySelectedUser,
          }))
        }
      })
    }
    this.handleCheckBoxChange = (e, { value, name, checked }) => {
      this.setState(prevState => ({
        editPermissionForm: Object.assign({}, prevState.editPermissionForm, {
          permission: checked ? Object.assign({}, prevState.editPermissionForm.permission || {}, {
            [name]: checked,
          }) : omit(prevState.editPermissionForm.permission || {}, name),
        })
      }))
    }
    this.handleFieldChange = (e, { value, name }) => {
      const currentlySelectedUser = value ? JSON.parse(value) : null;
      this.setState(prevState => ({
        editPermissionForm: currentlySelectedUser ? Object.assign({}, prevState.editPermissionForm, {
          [name]: currentlySelectedUser.id,
        }) : omit(prevState.editPermissionForm, name)
      }))
    }
  }

  async updateProjectStatus() {
    const { projectStatus } = this.state.editProjectForm;
    const { projectId } = this.props.project;
    this.setState(() => ({
      loading: {
        updateProjectStatus: true,
      },
    }))
    const result = await updateProject(projectId, { projectStatus: projectStatus });

    this.setState(() => ({
      loading: {
        updateProjectStatus: false,
      },
      message: {
        positive: true,
        header: 'Project status updated',
      }
    }))
    if (result) {
      this.props.updateProject('projectStatus', result.projectStatus)
    }

  }

  handleStatusChange(e, { value, name }) {
    this.setState(prevState => ({
      editProjectForm: {
        ...prevState.editProjectForm,
        [name]: value,
      }
    }))
  }

  async deleteProject () {
    const { projectId } = this.props.project;
    this.setState(() => ({
      loading: {
        delete: true,
      },
    }));

    await deleteProject(projectId);
    this.setState(() => ({
      loading: {}
    }), () => {
      window.location.reload();
    })
  }

  render() {
    const { project, users, roles, loggedInUser, permitted } = this.props;
    const { message, loading, selectedUsers, editAccessListMode, editPermissionForm, editMode } = this.state;
    // if (!permitted) return <AccessDenied action='act as admin' resourceType='project' />
    if (!project) return null;
    const allowUpdates = project.allowUpdates;
    const isSelfProjectAdmin = project.roles && loggedInUser && project.roles[loggedInUser]['project.admin'];

    const groupedFields = this.project.getReadableGroup();

    const valueField = (field) => {
      let value = project[field.id];
      if (!field.input || !value) return (<Label basic size='large' horizontal>{value || 'N/A'} </Label>);
      if (field.input.type === 'dropdown') {
        if (field.input.sourceFromState && this.props[field.input.sourceFromState]) {
          if (field.rawDataTransform && value) {
            return field.rawDataTransform(value).map(p => (
              <Label key={p} basic size='large' horizontal>
                {this.props[field.input.sourceFromState][p][field.input.displayField]}
              </Label>
            ))
          }
          if (!this.props[field.input.sourceFromState][value]) return null;
          return (
            <Label horizontal basic size='large'>{this.props[field.input.sourceFromState][value][field.input.displayField]}</Label>
          )
        }
      } else if (field.input.type === 'date') {
        value = value && moment(value).format('DD MMM YYYY (ddd)');
      }
      let meta = {};
      if (field.input.colorSpectrum) {
        const condVal = field.input.cellConditionValue ? field.input.cellConditionValue(value) : value;
        meta = {
          bgcolor: field.input.colorSpectrum[condVal],
        };
      }
      return value ? (
        <ColorLabel basic {...meta} size='large' horizontal>{value}</ColorLabel>
      ) : (<Label basic>N/A</Label>)
    }

    return (
      <Wrapper>
      {groupedFields && groupedFields.map((group, groupId) => (
        <Segment key={groupId}>
          {group.map(field => field && (
            <FieldRow key={field.id} editing={editMode[field.id]}>
              <div>{field.Header}</div> {editMode[field.id] ? (
                <Form>
                  <Input compact field={field} defaultValue={project[field.id]} handleFieldChange={this.handleEditFormChange} />
                </Form>
              ) : valueField(field)}
              {isSelfProjectAdmin && field.displayWhen && (allowUpdates ? field.displayWhen.create : field.displayWhen.adminOnly) && (
                <React.Fragment>
                  <Button onClick={this.toggleEditMode} basic compact size='small' value={field.id} icon><Icon name={editMode[field.id] ? 'delete' : 'edit'} /></Button>
                  {editMode[field.id] && (
                    <Button onClick={this.updateProjectProp} compact basic loading={loading[`updateProject${field.id}`]}>Save</Button>
                  )}
                </React.Fragment>
              )}
            </FieldRow>
          ))}
        </Segment>
      ))}
      {message && (
        <Message
          positive={message.positive}
          negative={message.negative}
          header={message.header}
          content={message.content}
          onDismiss={this.clearMessage}
        />
      )}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>
            {isSelfProjectAdmin && allowUpdates && (
              <Button
                onClick={this.toggleAccessListMode}
                value={!editAccessListMode}
                basic
                size='mini'
                color={editAccessListMode ? 'grey' : 'green'}
                icon
                labelPosition='left'
              >
                <Icon name={editAccessListMode ? 'delete' : 'write'} />
                {editAccessListMode ? 'Exit' : 'Edit Access List'}
              </Button>
            )}
            </Table.HeaderCell>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>Admin</Table.HeaderCell>
            <Table.HeaderCell>Read</Table.HeaderCell>
            <Table.HeaderCell>Modify</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {users && roles && project.roles && Object.keys(project.roles).map(userId => {
          const userRoles = project.roles[userId];

          if (!userRoles || Object.keys(userRoles).length === 0) return null;

          return (
            <Table.Row key={userId}>
              <Table.Cell />
              <Table.Cell>
                <Label basic>{users[userId].email}</Label>
                {project.creator === userId && (
                  <Label color='red' size='small'>Creator</Label>
                )}
              </Table.Cell>
              {ACTIONS.map(action => (
                <Table.Cell key={action}>
                  {userRoles[`project.${action}`] && (
                    <React.Fragment>
                    <Icon name='check circle' size='large' color='green' />
                    {editAccessListMode && (
                      <Button
                        className='btn-plain'
                        onClick={this.removeAccessRight}
                        value={{
                          userId,
                          role: roles[`project.${action}`].roleId,
                        }}
                        size='mini'
                        color='red'
                        basic
                        compact
                        loading={loading[`${userId}.${roles[`project.${action}`].roleId}`]}

                      >Remove</Button>
                    )}
                    </React.Fragment>
                  )}
                </Table.Cell>
              ))}
            </Table.Row>
          )
        })}
        </Table.Body>
        {editAccessListMode && users && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <Label color='green'>New</Label>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Dropdown
                field={{
                  id: 'user',
                  Header: 'Select Datarama Users',
                  options: Object.keys(users).map(userId => {
                    const user = users[userId];
                    return { id: user._id, display: user.email }
                  })
                }}
                defaultValue={''}
                handleFieldChange={this.handleFieldChange}
              />
            </Table.HeaderCell>
            {isSelfProjectAdmin && ACTIONS.map(action => (
              <Table.HeaderCell key={action}>
                <Checkbox label={PROJECT_ACCESS_ACTION[action].label} name={`${PROJECT_ACCESS_ACTION[action].value}`} onChange={this.handleCheckBoxChange} />
              </Table.HeaderCell>
            ))}
          </Table.Row>
          <Table.Row>
          <Table.HeaderCell />
            <Table.HeaderCell colSpan={5}>
          {selectedUsers && Object.keys(selectedUsers).map((user, count) => (
            <Label key={user} as='a' onClick={this.removeUser} value={user}>
              {count + 1} - {selectedUsers[user].display}
              <Icon name='delete' />
            </Label>
          ))}
          <br/>
          <Button
            onClick={this.updateAccessRight}
            fluid
            color='green'
            loading={loading.update}
            disabled={!editPermissionForm.user || !editPermissionForm.permission || (editPermissionForm.permission && Object.keys(editPermissionForm.permission).length < 1)}
          >Confirm and update Access List</Button>
          </Table.HeaderCell>

          </Table.Row>
        </Table.Footer>
      )}

      </Table>
        {permitted && (
          <div>
            <Divider/>
            <Header color='red'>Danger Zone</Header>
            <Button
              fluid
              onClick={this.deleteProject}
              color='red'
              loading={loading.delete}
              disabled={loading.delete}
            >
            Delete Project</Button>
          </div>

        )}
      </Wrapper>
    )
  }
}

const mapStateToProps = state => {
  const settings = getSettings(state);
  const authState = getAuth(state);
  return {
    users: settings.users,
    clients: settings.clients,
    roles: settings.roles,
    loggedInUser: authState.loggedInUser,
  }
};

export default withRouter(connect(mapStateToProps)(ProjectDetails));
