import React from 'react';
import styled from 'styled-components';
import { Loader, Dimmer, Button, Icon, Segment, Message } from 'semantic-ui-react';
import { withRouter } from 'react-router';

import {
  fetchPendingEntityDetails,
  confirmEntity,
  restoreEntity,
  postCombineProfile
} from '../../utils/api';
import Breadcrumb from './Breadcrumb';
import Prompt from './Prompt';
import CombinePrompt from './CombinePrompt';
import NotFound from '../404';
import ListView from './ListView';
import { recordCount, nameDisplay } from './labelling';
import CombinedProfile from './CombinedProfile';

const PLATFORM_URL = 'https://platform.datarama.com';
const validType = {
  person: true,
  company: true,
}
const Float = styled.div`
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 5;
  padding: 10px;
  background: #fff;
  box-shadow: 0 0 10px #ccc;
`;
const TabBtn = styled(Button)`
  padding: 10px 20px;
  font-size: 15px;
  margin: 5px !important;
  text-align: left !important;
  ${({ selected }) => selected && `
		background: #e0b637 !important;
		font-weight: 700;
		font-size: 17px;
	`}
	${({ highlighted }) => highlighted && `
    font-size: 18px;
    position: relative;
    &::before {
      content: 'Platform Result';
      position: absolute;
			background: #6315ab;
      right: -5px;
      font-size: 12px;
			border-radius: 2px;
      top: -8px;
      color: #fff;
			padding: 2px 5px;
    }
	`}
`;


const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ grid }) => grid || '30% 70%'};
  margin-bottom: 10px;
  ${({ header }) => header && `font-weight: 700;`}
  ${({ inner }) => !inner && `
    padding: 10px;
    border-bottom: 1px solid #ececec;
  `}
  padding: 5px;
  ${({ highlight }) => highlight && `
    background: #ececec;
  `}
`;
const PaddedDiv = styled.div`
  padding: 5px;
`;

const Row = styled.div`
  display: grid;
  margin: 5px 0;
  grid-template-columns: 20% 80%;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 700;
  padding: 10px 0;
`;

const BLACKLIST_FIELDS = [
  'isSanctionsCurrent',
  'isSanctionsPrevious',
  'isLawEnforcement',
  'isFinancialregulator',
  'isDisqualifiedDirector',
  'isInsolvent'
];

const PEP_FIELDS = [
  'isPEP',
];

const combineProfileInit = {
  isPEP: null,
  isSanctionsCurrent: null,
  isSanctionsPrevious: null,
  isLawEnforcement: null,
  isFinancialregulator: null,
  isDisqualifiedDirector: null,
  isInsolvent: null,
  politicalPositions: [],
  articles: [],
  linkedPersons: [],
  linkedBusinesses: []
};

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: null,
      combinedProfile: combineProfileInit,
      openCombineAlert: false,
      promptType: null,
      loading: false,
      done: null,
      onError: false,
      promptMeta: {},
    };

    this.switchActiveTab = this.switchActiveTab.bind(this);
    this.fetch = this.fetch.bind(this);
    this.createCombine = this.createCombine.bind(this);
    this.addToCombineProfile = this.addToCombineProfile.bind(this);
    this.toggleConfirmPrompt = this.toggleConfirmPrompt.bind(this);
    this.postCombineProfile = this.postCombineProfile.bind(this);
    this.updateCombineProfilePepTier = this.updateCombineProfilePepTier.bind(this);
    this.clearSelection = this.clearSelection.bind(this);

    this.confirmPrompt = (promptType, meta = {}) => {
      if (promptType) {
        this.setState({
          openAlert: true,
          promptType,
          promptMeta: meta,
        })
      }
    }

    this.closeAlert = this.closeAlert.bind(this);
    this.confirm = this.confirm.bind(this);
    this.onRequestDone = this.onRequestDone.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  createCombine() {
    this.setState(prevState => ({
      onEditCombine: !prevState.onEditCombine,
    }))
  }

  toggleConfirmPrompt() {
    this.setState(prevState => ({
      openCombineAlert: !prevState.openCombineAlert,
    }))
  }

  addToCombineProfile(field, value, isChecked, type, uniqueField) {
    if (!isChecked) {
      this.setState(prevState => ({
        combinedProfile: type === 'array' ?
          Object.assign({}, prevState.combinedProfile, {
            [field]: prevState.combinedProfile[field] ? prevState.combinedProfile[field].filter(p => p[uniqueField] !== value[uniqueField]) : []
          })
          :
          Object.assign({}, prevState.combinedProfile, {
            [field]: null
          }, field === 'isPEP' ? { pepLevel: null } : {})
      }))

    } else {
      let additional = {};
      if (field === 'isPEP') {
        if (value >= 0 && value <= 4) {
          additional.pepLevel = value;
          value = true;
        }
      }

      this.setState(prevState => ({
        combinedProfile: Object.assign({}, prevState.combinedProfile, type === 'array' ? {
          [field]: prevState.combinedProfile[field] ? prevState.combinedProfile[field].concat(value) : [value],
        } : {
          [field]: value,
        }, additional)
      }))
    }
  }

  async fetch() {
    const { entityId, entityType } = this.props.match.params;
    const isValidType = validType[entityType];
    if (!isValidType) {
      this.props.history.push('/research/pepblacklist');
      return;
    }
    if (entityId) {
      this.setState({
        loadingData: true,
      });
      try {
        const result = await fetchPendingEntityDetails(entityType, entityId);
        if (!result) {
          throw Error();
        };
        const { pending } = result || {};
        this.setState({
          details: result.entityRecord,
          data: result.data,
          c6Data: pending.c6,
          caData: pending.ca,
          activeTab: result.data && result.data.entity && result.data.entity.matchType,
          loadingData: false,
          additional: result.data && result.data.entity && result.data.entity.matchType === 'combined' && result.data.entity,
          previousData: result.previousCandidates,
          restoreToken: result.restoreToken,
          noMatchToken: result.noMatchToken
        });
      } catch (err) {
        this.setState({
          loadingData: false,
          initError: true,
        })
      }
    }
  }

  switchActiveTab(tab) {
    this.setState(() => ({
      activeTab: tab,
    }))
  }

  updateCombineProfilePepTier(e) {
    let tiering, pepLevel;
    if (e && e.target) {
      tiering = e.target.value;
    }
    if (this.state.combinedProfile.isPEP) {
      if (tiering >= 0 && tiering <= 4) {
        pepLevel = parseInt(tiering, 10);
      }
      this.setState(prevState => ({
        combinedProfile: Object.assign({}, prevState.combinedProfile, { pepLevel })
      }))
    }
  }

  async postCombineProfile() {
    const { entityId } = this.props.match.params;
    const { combinedProfile } = this.state;
    this.setState({
      loading: true,
      done: false,
    });
    const createCombineProfile = Object.assign({}, combinedProfile, PEP_FIELDS.concat(BLACKLIST_FIELDS).reduce((acc, f) => {
      const newAcc = acc;
      if (!combinedProfile[f]) {
        newAcc[f] = false;
      }
      return newAcc;
    }, {}));
    await postCombineProfile(entityId, createCombineProfile);

    this.setState({
      loading: false,
      done: true,
    });
  }

  async confirm(type, value) {
    const { entityId, entityType } = this.props.match.params;
    const { restoreToken, noMatchToken, promptMeta } = this.state;

    this.setState({
      loading: true,
    });
    try {
      if (type === 'match') {
        const { tokens } = (promptMeta || {}).selectedCandidate || {};
        if (!tokens) return;
        await confirmEntity(entityType, entityId, tokens.confirmation);
        this.setState({
          loading: false,
          done: true,
          nextStatus: 'confirmed',
        })
      } else if (type === 'noMatch') {
        await confirmEntity(entityType, entityId, noMatchToken);
        this.setState({
          loading: false,
          done: true,
          nextStatus: 'confirmed',
        })
      } else if (type === 'restore') {
        await restoreEntity(entityType, entityId, restoreToken);
        this.setState({
          loading: false,
          done: true,
          nextStatus: 'pending',
        })
      }
    } catch (err) {
      this.setState({
        loading: false,
        onError: true,
      })
    }
  }

  closeAlert() {
    this.setState({
      openAlert: false,
      promptType: null,
      done: null,
      loading: false,
      promptMeta: {},
      onError: false,
    })
  }

  onRequestDone() {
    this.setState({
      openAlert: false,
      promptType: null,
      onError: false,
      done: null,
      loading: false,
      openCombineAlert: false,
      combinedProfile: combineProfileInit,
      onEditCombine: false,
      candidateView: null,
    }, async () => {
      this.fetch();
    });
  }

  clearSelection() {
    this.setState({
      onEditCombine: false,
      candidateView: null,
    })
  }

  render() {
    const {
      data,
      c6Data,
      caData,
      loadingData,
      initError,
      additional,
      onEditCombine,
      combinedProfile,
      activeTab,
      openCombineAlert,
      promptType,
      loading,
      done,
      onError,
      promptMeta,
      openAlert,
      previousData,
      details
    } = this.state;

    const { entityType } = this.props.match.params;
    const platformEntityType = (entityType === 'company' && 'organisation') || entityType;

    const previouslyConfirmed = !!additional || (details && details.analyst);
    const autoConfirmed = details && !details.analyst && details.c6 && details.c6.status === 'confirmed';
    if ((!details || initError) && !loadingData) {
      return (
        <div>
          <NotFound details="Error! Could likely be: Entity not found."/>
        </div>
      )
    }

    return (
      <div>
        <Breadcrumb />
        {openCombineAlert &&
          <CombinePrompt
            onClose={this.toggleConfirmPrompt}
            value={combinedProfile}
            entityId={details.dataramaid}
            entityName={details.name}
            type={promptType}
            entityType={entityType}
            loading={loading}
            done={done}
            onError={onError}
            doneAction={this.onRequestDone}
            combine={this.postCombineProfile}
            combineProfile={combinedProfile}
          />
        }
        {openAlert &&
          <Prompt
            onClose={this.closeAlert}
            onConfirm={this.confirm}
            entityId={details.dataramaid}
            entityName={details.name}
            matchName={promptMeta && promptMeta.selectedCandidate && nameDisplay(entityType, promptMeta.selectedCandidate)}
            type={promptType}
            loading={loading}
            done={done}
            onError={onError}
            doneAction={this.onRequestDone}
          />
        }
        {details &&
          <Grid grid="70% 30%">
            <div>
              <Header>Datarama Entity Details</Header>
              <Segment>
                <Row><div>Name</div><div>{details.name}</div></Row>
                <Row><div>Datarama ID</div><div>{details.dataramaid}</div></Row>
                <Row><div>Platform URL</div><div><a href={`${PLATFORM_URL}/${platformEntityType}/${details.dataramaid}`} target='_blank' rel='noreferrer noopener'>{`${PLATFORM_URL}/${platformEntityType}/${details.dataramaid}`}</a></div></Row>
                <Row><div>Other Names</div><div>{details.othernames}</div></Row>
                <Row><div>Nationality</div><div>{details.nationality}</div></Row>
                <Row><div>Alive</div><div>{details.alive ? 'Yes' : 'No'}</div></Row>
              </Segment>
              {data && (
                <>
                <Segment>
                  <Row>
                    <div>Risky Entity</div>
                    <div>
                      {data.riskyEntityList && data.riskyEntityList.length > 0 ?
                        <div>
                          <Icon size='large' color='red' alt='risky' name='dont' />
                          {`Yes ${recordCount(data.riskyEntityList.length)}`}
                          <ul>
                          {data.riskyEntityList.map((risk, riskId) => (
                            <li key={riskId}>
                              <div>[{risk.country}] {risk.riskTypeDesc}</div>
                              <div>
                                {risk.riskDetail} <a href={risk.sourceUrl} target="_blank" rel="noopener noreferrer">Source</a>
                              </div>
                            </li>
                          ))}
                          </ul>
                        </div>
                      : (
                        <div>Not on risky entity list</div>
                      )}
                    </div>
                  </Row>
                  <Row>
                    <div>PEP</div>
                    <div>
                      {(data.entity && Object.keys(data.entity).length > 0) ? (
                        <div>
                          {(data.entity.isPEP) ? (
                            <>
                              <Icon size='large' color='red' alt='risky' name='flag' /> {data.entity && data.entity.isPEP && `(PEP Level: ${data.entity.pepLevel})`}
                            </>
                          ) : 'No'}
                        </div>
                      ) : 'No'}
                    </div>
                  </Row>
                </Segment>
                </>
              )}
            </div>
            <div>
              <Header>Other Actions</Header>
              <PaddedDiv>
                {((data && data.entity) || details.analyst) ? (
                  <div>
                    {!previouslyConfirmed ? (
                      <div>
                        <Button onClick={this.createCombine} basic fluid>Create Combined Profile</Button>
                        <br/>
                        <Button onClick={() => this.confirmPrompt('noMatch')} basic fluid>Mark as No Match found</Button>
                      </div>
                    ) : (
                      <div>
                        <Button fluid onClick={() => this.confirmPrompt('restore')} basic>Restore as Default</Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>No available action</div>
                )}
              </PaddedDiv>
            </div>
          </Grid>
        }
        {loadingData && (
          <Dimmer active>
            <Loader active>Loading</Loader>
          </Dimmer>
        )}
        {onEditCombine && (
          <Float>
            <Grid grid="80% 20%">
            <div>
              <Button fluid positive onClick={this.toggleConfirmPrompt}>Confirm Create Combine Profile</Button>
            </div>
            <Button onClick={this.clearSelection}>Cancel</Button>
            </Grid>
          </Float>
        )}
        {additional ? (
          <CombinedProfile additional={additional} />
        ) : (
          <div>
            {data && data.entity ? (
              <>
              {caData && (
                <TabBtn
                  selected={activeTab === 'complyAdvantage'}
                  onClick={() => this.switchActiveTab('complyAdvantage')}
                  highlighted={data.entity.matchType === 'complyAdvantage' ? 'true' : undefined}
                >ComplyAdvantage Candidates {recordCount(caData.length)} {previousData && data.entity.matchType === 'complyAdvantage' && `Previous: ${(previousData.caId || []).length}`}</TabBtn>
              )}
              {c6Data && (
                <TabBtn
                  active={activeTab === 'c6'}
                  selected={activeTab === 'c6'}
                  onClick={() => this.switchActiveTab('c6')}
                  highlighted={data.entity.matchType === 'c6' ? 'true' : undefined}
                >C6 Candidates {recordCount(c6Data.length)} {previousData && data.entity.matchType === 'c6' && `Previous: ${(previousData.c6Id || []).length}`}</TabBtn>
              )}
              </>
            ) : (
              <>
                {!loadingData && (
                  <Message>No Candidates found.</Message>
                )}
              </>
            )}
          </div>
        )}

        {c6Data && activeTab === 'c6' && (
          <ListView
            dataType="c6"
            data={c6Data}
            entityType={entityType}
            previousData={previousData && previousData.c6Id}
            autoConfirmed={autoConfirmed}
            previouslyConfirmed={previouslyConfirmed}
            matchIds={details.c6.c6Id}
            additional={additional}
            onEditCombine={onEditCombine}
            combinedProfile={combinedProfile}
            currentResult={data.entity}
            confirmPrompt={this.confirmPrompt}
            addToCombineProfile={this.addToCombineProfile}
            toggleConfirmPrompt={this.toggleConfirmPrompt}
            updateCombineProfilePepTier={this.updateCombineProfilePepTier}
          />
        )}
        {caData && (activeTab ==='complyAdvantage') && (
          <ListView
            dataType="complyAdvantage"
            data={caData}
            entityType={entityType}
            previousData={previousData && previousData.caId}
            autoConfirmed={autoConfirmed}
            previouslyConfirmed={previouslyConfirmed}
            matchIds={details.ca.matches}
            additional={additional}
            onEditCombine={onEditCombine}
            combinedProfile={combinedProfile}
            currentResult={data.entity}
            confirmPrompt={this.confirmPrompt}
            addToCombineProfile={this.addToCombineProfile}
            toggleConfirmPrompt={this.toggleConfirmPrompt}
            updateCombineProfilePepTier={this.updateCombineProfilePepTier}
          />
        )}
      </div>
    )
  }
}


export default withRouter(Main);
