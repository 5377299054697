import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  padding: 0;
`;
const Corner = styled.div`
  position: absolute;
  top: -30px;
  right: -70px;
  width: 200px;
  height: 100px;

  background: ${({ status }) => {
    if (status === 'active') return '#1a9e3f';
    if (status === 'inactive') return '#f97e7e';
    if (status === 'archived') return '#adadad';
    return '#ccc'
  }};
  z-index: -1;

  left: auto;
  transform: rotate(45deg);
`;
const Content = styled.div`
  font-weight: 600;
  text-align: right;
  color: #fff;
  letter-spacing: 2px;
  user-select: none;
  font-family: inherit;
  padding: 3px 3px 0 0;
  font-size: 13px;
`;

export default ({ status }) => {
  if (!status) return null;
  return (
    <Wrapper>
      <Corner status={status} />
      <Content>
        {`${status}`.toUpperCase()}<br/>PROJECT
      </Content>
    </Wrapper>
  )
}
