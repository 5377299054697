import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router'

import { Segment, Form, Button, Label, Loader, Icon, Message, Step } from 'semantic-ui-react';
import getEntityModel from '../../model/entity/getEntityModel';
import { checkIfValidEntityType } from '../../utils/routeHelper';

import Text from './fields/Text';
import DateField from './fields/DateField';
import { addNewEntity } from '../../utils/api';
import ValidateEntity from '../ValidateEntity';
import UpdatedMessage from './UpdatedMessage';
import Input from '../Input';
import { getDisplayType } from '../../utils/conversion/entityType';
import { ENTITY_BY_TYPE } from '../../utils/ref/entityType';
import { stringCleaning } from '../../utils/conversion/specialCharacter';

const Wrapper = styled.div`
  overflow: auto;
  width: 100%;
`;

const Errors = styled.div`
  margin: 10px 0;
  background: #ececec;
  padding: 10px;
`;
const ActionWrapper = styled.div`
  > * {
    display: inline-block;
  }
  text-align: center;
  margin: 10px 0 50px 0;

`;
const Wrap = styled.div`
`;

class NewEntity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      fieldsRestriction: {},
      completed: {},
      loading: {},
      activeStep: 'validate',
      showValidateAction: false,
    };
    this.input = {};
    this.setGroupFeilds = this.setGroupFeilds.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.cancelAll = this.cancelAll.bind(this);
    this.getRestrictedFieldsByCountry = this.getRestrictedFieldsByCountry.bind(this);
    this.signValidation = (e, { checked }) => {
      this.setState(() => ({
        validated: checked,
      }))
    }

    this.setLoadingStatus = this.setLoadingStatus.bind(this);

    this.addNewEntity = this.addNewEntity.bind(this);

    this.validateComplete = (e, { value }) => {
      window.scrollTo(0, 0);
      this.setState(prevState => {
        const status = !!value;
        return {
          completed: {...prevState.completed, validate: status },
          activeStep: status ? 'add' : 'validate',
          showValidateAction: false,
        }
      })
    }

    this.updateStepProgress = (status) => {
      this.setState(() => ({
        showValidateAction: status,
      }))
    }
  }

  componentDidMount() {
    this.setGroupFeilds();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.type !== this.props.match.params.type) {
      this.setGroupFeilds();
    }
  }

  validateForm(e, { value }) {
    const shouldProceedValidation = value === 'cancel' ? false : true;

    const fields = this.baseEntity && this.baseEntity.getFields();
    if (shouldProceedValidation) {
      const outputFields = Object.keys(fields).reduce((a, fieldName) => {
        const field = fields[fieldName];
        if (field.required && !this.input[fieldName]) {
          a.errors[fieldName] = true;
        }
        if (this.input[fieldName]) {
          a.fields[fieldName] = this.input[fieldName];
        }
        return a;
      }, { errors: {}, fields: {}})

      if (!this.state.validated) {
        outputFields.errors.validated = true;
      }

      if (Object.keys(outputFields.errors).length > 0) {
        this.setState(() => ({
          errors: outputFields.errors
        }))
      } else {
        this.setState(() => ({
          isValidating: true,
          pending: outputFields.fields,
        }))
      }
    } else {
      this.setState(() => ({
        isValidating: false,
      }))
    }
  }

  getRestrictedFieldsByCountry(country) {
    // restrictedFields are fields that should NOT be displayed per selected country
    const restrictedFields = Object.keys(this.fieldRestrictionByCountry).reduce((a, field) => {
      if (!this.fieldRestrictionByCountry[field][country]) {
        this.input[field] = null;
        a[field] = true;
      }
      return a;
    }, {});
    return restrictedFields;
  }

  handleFieldChange(e, { name, value }) {
    if (name === 'country') {
      this.setState(() => ({
        fieldsRestriction: this.getRestrictedFieldsByCountry(value),
      }));
    }
    if (this.state.errors[name]) {
      this.setState(prevState =>({
        errors: Object.assign({}, prevState.errors, {
          [name]: false,
        })
      }))
    }
    if (!value) {
      delete this.input[name];
    } else {
      this.input[name] = value;
    }
  }

  setGroupFeilds() {
    const { match: { params } } = this.props;
    const { type } = params;

    this.baseEntity = getEntityModel(type);

    this.input = {};
    this.fieldRestrictionByCountry = (this.baseEntity && this.baseEntity.getRestrictedFieldsByCountry()) || {};
    this.setState(() => ({
      groupedFields: this.baseEntity && this.baseEntity.getNewEntityGroupedFields(),
      loading: {},
      isValidating: false,
      validated: false,
      errors: {},
      pending: null,
      completed: {},
      activeStep: 'validate',
    }))
  }

  cancelAll() {
    this.input = {};
    this.setState(() => ({
      loading: {},
      isValidating: false,
      validated: false,
      errors: {},
      pending: null,
      addSuccess: false,
      showValidateAction: false,
    }))

  }

  setLoadingStatus(field, status) {
    this.setState(prevState => ({
      loading: Object.assign({}, prevState.loading, {
        [field]: status
      }),
    }))
  }

  async addNewEntity() {
    const fields = this.baseEntity.getFields();

    const parsedPayload = JSON.parse(JSON.stringify(this.state.pending));
    Object.keys(parsedPayload).forEach((key) => {
      if (parsedPayload[key] == null) {
        delete parsedPayload[key]
      } else {
        parsedPayload[key] = stringCleaning(fields[key] && fields[key].type, parsedPayload[key])
      }
    });

    this.setLoadingStatus('adding', true);
    const result = await addNewEntity(this.props.projectId, {...parsedPayload, entityType: this.baseEntity.getEntityType() })
    this.setState(() => {
      return {
        addSuccess: result,
        loading: {}
      }
    });
  }

  render() {
    const { match: { params } } = this.props;
    let { type, projectId } = params;
    if (!checkIfValidEntityType(type)) {
      return <Redirect to={`/project/${projectId}/actions`} />
    }
    const displayType = getDisplayType(type);
    const {
      groupedFields,
      showValidateAction,
      fieldsRestriction,
      isValidating,
      validated,
      errors,
      addSuccess,
      activeStep,
      completed,
      loading,
    } = this.state;

    const stepContent = () => {
      const dtrmOnly = ENTITY_BY_TYPE[type].searchScope && ENTITY_BY_TYPE[type].searchScope.dtrmOnly;

      switch (activeStep) {
        case 'validate':
          return (
            <React.Fragment>
              <ValidateEntity
                type={type}
                onDataFetch={this.updateStepProgress}
                dtrmOnly={dtrmOnly}
                scope={dtrmOnly ? { dtrm: true } : null}
              />
              <ActionWrapper>
              {showValidateAction ? (
                <Button  onClick={this.validateComplete} value={true} color='green' size='large' >I have confirmed that the entity to be added has not been previously ingested</Button>
              ) : (
                <Button  onClick={this.validateComplete} value={true} size='large'>Skipped (Please make sure before progressing)</Button>
              )}
              </ActionWrapper>
            </React.Fragment>
          )
        case 'add':
          return (
            <React.Fragment>
              <Button onClick={this.validateComplete} basic><Icon name='arrow left' /> Back to validation</Button>
              <Segment>
                <Form onSubmit={this.validateForm}>
                  {groupedFields && groupedFields.map((group, groupId) => (
                    <Form.Group widths='equal' key={`${type}-${groupId}`}>
                      {group.map(field => {
                        if (fieldsRestriction[field.id]) return null;
                        switch (field.input.type) {
                          case 'dropdown':
                            return (
                              <Input
                                key={field.id}
                                field={field}
                                isValidating={isValidating}
                                defaultValue={field.defaultValue || ''}
                                handleFieldChange={this.handleFieldChange}
                              />)

                          case 'date':
                            return (
                              <DateField
                                key={field.id}
                                field={field}
                                entityType={type}
                                isValidating={isValidating}
                                error={errors[field.id]}
                                defaultValue=""
                                handleFieldChange={this.handleFieldChange}
                              />
                            )
                          default:
                            return (
                              <Text
                                key={field.id}
                                field={field}
                                entityType={type}
                                error={errors[field.id]}
                                defaultValue=""
                                isValidating={isValidating}
                                handleFieldChange={this.handleFieldChange}
                              />
                            )
                        }
                      })}
                    </Form.Group>
                  ))}
                  <Form.Field required>
                    <Form.Checkbox
                      onChange={this.signValidation}
                      name="validated"
                      checked={validated}
                      label='I have checked the new entity has no previous record in Database'
                      required
                      error={errors.validated}
                      disabled={isValidating}
                    />
                  </Form.Field>
                  {Object.keys(errors).length > 0 && (
                    <Errors>
                    <Label>Missing fields</Label> {Object.keys(errors).map(error => (
                      <Label color='red' key={error}>{error}</Label>
                    ))}
                    </Errors>
                  )}
                  {!isValidating && (
                    <Button type='submit' fluid size='large'>To Confirm adding new {displayType}</Button>
                  )}
                </Form>
                {isValidating && (
                  <React.Fragment>
                    <br/>
                    <Button onClick={this.addNewEntity} fluid color='green' loading={loading.adding} disabled={loading.adding} size='large'>Confirm and add new {displayType}</Button>
                    <br/>
                    <Button fluid onClick={this.validateForm} size='large' color='grey' basic value="cancel" disabled={loading.adding}>Edit Again</Button>
                  </React.Fragment>
                )}
              </Segment>
            </React.Fragment>

          );
          default:
            return null;
      }
    }
    return (
      <Wrap>
        <Loader active={loading.adding}>Loading...</Loader>
        <Step.Group widths={3} size='small' ordered>
          <Step completed={completed.validate} active={activeStep === 'validate'}>
            <Step.Content>
              <Step.Title>Validate {displayType}</Step.Title>
              <Step.Description>Validate if {displayType} to be added is NOT already in the database</Step.Description>
            </Step.Content>
          </Step>
          <Step completed={completed.add} active={activeStep === 'add'}>
            <Step.Content>
              <Step.Title>Add new Entity</Step.Title>
              <Step.Description>Enter details of the new entity</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
        {addSuccess ? (
          <Message positive>
            <Message.Header>Entity successfully added.</Message.Header>
            <div>
              Entry ID: {(addSuccess || {}).entryId}
            </div>
            <div>
              <UpdatedMessage entityId={(addSuccess || {}).entityId} type={type} />
            </div>
            <br/>
            <Button onClick={this.cancelAll} basic color='green'>Add More {displayType}</Button>
          </Message>
        ) : (
        <Wrapper>
          {stepContent()}
        </Wrapper>
      )}

      </Wrap>
    )
  }
}

export default NewEntity;
