import {
  SET_SETTINGS,
  SET_AUTH_STATE,
  UPDATE_SELF_PERMISSION,
  SET_PROJECTS,
  UPDATE_PROJECT,
} from './actionTypes';

export const setSettings = settings => ({
  type: SET_SETTINGS,
  settings,
});

export const setAuthState = (userId, permissions) => ({
  type: SET_AUTH_STATE,
  userId,
  permissions,
})

export const updateSelfPermission = (resourceType, resourceId, permissions) => ({
  type: UPDATE_SELF_PERMISSION,
  resourceType,
  resourceId,
  permissions,
})

export const setProjects = (projects, isOneOff, forceUpdate) => ({
  type: SET_PROJECTS,
  projects,
  isOneOff,
  forceUpdate
})

export const updateProject = (projectId, updatedField, updatedValue) => ({
  type: UPDATE_PROJECT,
  projectId, updatedField, updatedValue
})
