import React, { useState, useEffect } from 'react';
import { Button, Icon, Message } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';

const ClickWrapper = styled.span`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  margin-left: 5px;
`;
const CopiedMessage = styled.div`
  position: absolute;
  font-size: 11px;
  font-weight: 600;
`;

export function RelationshipMessage({
  setInputs,
  setLoading,
  setErrors,
  setHttpResponse,
  setResults,
  setActiveStep,
  VALIDATE,
  httpResponse = {},
  isNewRelationship
}) {

  // Declare component states
  const [copied, setCopied] = useState(false);

  // Declare side effects
  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
      new AbortController().abort();
    };
  }, [copied]);

  const handleCancelAll = () => {
    setInputs({});
    setLoading({});
    setErrors({});
    setHttpResponse(null);
    setResults(null);
    setActiveStep(VALIDATE);
  }

  return (
    <Message positive>
      <Message.Header>
        { isNewRelationship
          ? 'Relationship successfully added.'
          : 'Relationship successfully updated.' }
      </Message.Header>
      <div>
        Entry ID: {httpResponse.entryId}
      </div>
      <div>
        { isNewRelationship
          ? `New Datarama ID ${httpResponse.relid}:`
          : `Datarama ID ${httpResponse.relid} updated:` }
        <CopyToClipboard
          text={`${httpResponse.relid}`}
          onCopy={() => setCopied(true)}>
          <ClickWrapper>
            <Icon name='copy' color='grey' />
          </ClickWrapper>
        </CopyToClipboard>
        { copied && <CopiedMessage>Copied to Clipboard</CopiedMessage> }
      </div>
      <br/>
      <Button onClick={ handleCancelAll } basic color='green'>
        Back</Button>
    </Message>
  )
}