import React from 'react';
import { Grid, Form } from 'semantic-ui-react';

const FIELDS = [
  { value: 'entityName', label: 'First Entity Name', type: 'input', required: false },
  { value: 'secondEntityName', label: 'Second Entity Name', type: 'input', required: false },
];


export function EntitySearchFields({search, setSearch}) {
  return <Grid.Row>
    { FIELDS.map((field => (
    <Grid.Column key={ field.value }>
      <Form.Field>
        <Form.Input
          fluid
          disabled={search.activeLang === ''}
          required={ field.required }
          name={ field.value }
          label={ field.label }
          value={ (search.requestPayload[search.activeLang] || {})[field.value] || '' }
          placeholder={ field.label }
          onChange={
            (event, data) => {
              setSearch(prevState => {
                return { ...prevState, requestPayload:
                  { ...prevState.requestPayload, [prevState.activeLang]:
                    { ...(prevState.requestPayload[prevState.activeLang] || {}), [field.value]: data.value }
                  }
                }        
              })  
            }
          } />
      </Form.Field> 
    </Grid.Column>
  )))}
</Grid.Row>
}