import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Label } from 'semantic-ui-react'
import styled from 'styled-components';
import { getAuth, getSettings } from '../reducers/selectors';

const Wrapper = styled.div`
  padding: 0 10px 10px 10px;
  color: #fff;
  user-select: none;
`;

class Greeting extends React.Component {
  render() {
    const { loggedInUser } = this.props;
    if (!loggedInUser) return null;
    return (
      <Wrapper>
        Logged in as <Label horizontal><u>{loggedInUser.name.first} {loggedInUser.name.last}</u></Label>
      </Wrapper>
    )
  }
}
const mapStateToProps = state => {
  const selfId = getAuth(state).loggedInUser;
  return {
    loggedInUser: (getSettings(state).users || {})[selfId],
  }
}
export default withRouter(connect(mapStateToProps)(Greeting));
