import React from 'react';
import { Header, Divider, Icon, Loader, Table } from 'semantic-ui-react';
import * as tableUtils from '../../utils/tableUtils';
import DtrmID from '../DtrmID';
import ValueFromState from '../ValueFromState';
import styled from 'styled-components';
import { flagRelationship, validateRelationship } from '../../utils/api';

const LoaderWrap = styled.div`
  position: relative;
`;

const FlagIcon = styled(Icon)`
color: rgba(0,0,0,.5);
&:hover {
    font-size: 20px;
    padding: 0;
  } 
`;

export function RelationshipTable({
  findRelById,
  uniqueKey = 'relId',
  recordType,
  inputs,
  isNewRelationship,
  loading,
  results,
  setResults,
  params }) {
  const RECORD_TYPE = recordType || 'relationship';
  const cellContent = (col, value, disabled) => {
    if (col.isBoolean) {
      return value ? 'Yes' : 'No'
    }
    if (col.isStateValue) {
      return <ValueFromState field={col.stateKey} value={value} displayField={col.stateValue} />
    }
    if (col.isLink && !disabled) {
      return (
        <DtrmID id={value} type={RECORD_TYPE} />
      )
    }
    return value;
  }

  const handleOnClick = (id) => {
    if (isNewRelationship) {
      return;
    }
    return findRelById(id, true);
  }

  const handleIndicateErroneousRel = async (event, {value}) => {
    event.stopPropagation();
    const body = JSON.stringify(value);
    const { relId } = await flagRelationship(body);
    if (relId) {
      const { type, projectId } = params;
      const rels = await validateRelationship(type, { ...inputs, 'scope': ['dtrm'], projectId });
      setResults(rels);
    }
  }

  const headerContent = recordType === 'litigation' ? 'Find by case number' : 'Find by name';
  return (
    <>
    <Divider horizontal>
      <Header as='h3'>
        {headerContent} results ({results.dtrm ? results.dtrm.length : 0})
        {recordType === 'relationship' && (
          <Header.Subheader>Select Datarama ID by clicking respective row</Header.Subheader>
        )}
      </Header>
    </Divider>
    <LoaderWrap>
    { loading.findById && <Loader active>Loading...</Loader> }
    </LoaderWrap>
    <Table celled striped selectable className='table-selectable'>
      <Table.Header>
        <Table.Row>
          {tableUtils.dtrmColumns[RECORD_TYPE].map(col => (
            <Table.HeaderCell
              key={col.label}
              rowSpan={col.items ? 1 : 2}
              colSpan={col.items ? col.items.length : 1}
            >{col.label}</Table.HeaderCell>
          ))}
          <Table.HeaderCell>Indicate if relationship were erroneous</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          {tableUtils.dtrmColumns[RECORD_TYPE].map(col => col.items ? col.items.map(item => (
            <Table.HeaderCell key={item.value}>{item.label}</Table.HeaderCell>
          )) : null)}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {results.dtrm.map(match => (
          <Table.Row key={match[uniqueKey]} onClick={ () => handleOnClick(match[uniqueKey]) }>
            {tableUtils.dtrmColumns[RECORD_TYPE].map(col => col.items ? col.items.map(item => (
              <Table.Cell key={item.value}>
                {cellContent(item, match[item.value])}
              </Table.Cell>
            )) : (
              <Table.Cell key={col.value}>
                {cellContent(col, match[col.value], match.errorLinkFlag)}
              </Table.Cell>
            ))}
            <Table.Cell>
            <FlagIcon name='flag' onClick={handleIndicateErroneousRel} value={{errorLinkFlag: match.errorLinkFlag ? 0 : 1, relId: match.relId}}/>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      {results.dtrm.length === 0 && (
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan='10'>
                No results found
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  </>
  )
}
