import React, { useState, useEffect } from 'react';
import { CONCEPTS, LANGUAGES, RISK_FACTORS } from '../adverseMediaResearch/constants';
import Loading from '../Loading';
import { Icon, Label, Menu, Segment, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { getRiskIndicators, addRiskIndicator, removeRiskIndicator } from '../../utils/api';

const MenuWrapper = styled(Menu)`
display: flex;
flex-direction: row;
flex-wrap: wrap;
`;

export function RiskIndicators() {
  return RiskIndicatorsAbstract(CONCEPTS, 'general');
}

export function RiskIndicatorsV2() {
  return RiskIndicatorsAbstract(RISK_FACTORS, 'v2_sanctionsAndBlacklists', 'v2');
}

function RiskIndicatorsAbstract(concepts, defaultConcept, version = null) {
  const [buckets, setBuckets] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({term: '', language: ''});
  const [isFormProcessing, setIsFormProcessing] = useState(false);

  useEffect(() => {
    async function fetching() {
      await loadRiskIndicators(defaultConcept);
      return () => {
        new AbortController().abort();
      };
    }
    fetching();
  }, []);

  const loadRiskIndicators = async concept => {
    const results = await getRiskIndicators(concept);
    setBuckets(results);
    setActiveItem(concept);
  }

  const handleConceptChange = async (event, { value }) => {
    event.preventDefault();
    setActiveItem(null);
    const results = await getRiskIndicators(value);
    setBuckets(results);
    setActiveItem(value);
  }

  const handleSubmit = async event => {
    event.preventDefault();
    setIsFormProcessing(true);
    setFormError(null);
    if (!form.language || !form.term) {
      setFormError(prevState => {
        if (!form.language && !form.term) {
          return { ...prevState, language: true, term: true }
        }
        if (!form.term) {
          return { ...prevState, term: true }
        }
        if (!form.language) {
          return { ...prevState, language: true }
        }
      });  
      return;
    }

    const requestBody = JSON.stringify({
      concept: activeItem,
      term: form.term,
      language: form.language,
      type: version === 'v2' ? 'risk-factor' : 'risk-indicator'
    })

    await addRiskIndicator(requestBody);
    await loadRiskIndicators(activeItem);
    setForm({term: '', language: ''});
    setIsFormProcessing(false);
  }

  const handleOnChange = async (event, { id, value }) => {
    event.preventDefault();
    setForm(prevState => {
      if (id === 'term') {
        return { ...prevState, term: value }
      }
      if (id === 'language') {
        return { ...prevState, language: value }
      }
    })    
  }

  const handleOnRemove = async (event, id, lang) => {
    event.preventDefault();
    const result = buckets.map(bucket => {
      if (Object.keys(bucket)[0] === lang) {
        return {
          [lang]: Object.values(bucket)[0].filter(obj => obj._id !== id)
        }
      }
      return bucket;
    })
    setBuckets(result);
    await removeRiskIndicator(id);
  }

  const options = LANGUAGES.map(lang => (
    { key: lang.code, text: lang.label, value: lang.code }
  ));

  return <>
    <div>
      <MenuWrapper>
        {concepts.map(concept => (
          <Menu.Item
            key={concept.code}
            value={concept.code}
            name={concept.label}
            active={activeItem === concept.code}
            onClick={handleConceptChange} />
        ))}
      </MenuWrapper>
      { activeItem &&
        <Segment attached='bottom'>
          <Form size='mini' onSubmit={handleSubmit}>
            <Form.Group widths='equal'>
              <Form.Input
                error={!!formError && !!formError.term}
                id='term'
                disabled={isFormProcessing}
                value={form.term || ''}
                onChange={handleOnChange}
                fluid label='Risk Indicator'
                placeholder='Risk Indicator' />
              <Form.Select
                error={!!formError && !!formError.language}
                fluid
                id='language'
                disabled={isFormProcessing}
                value={form.language || ''}
                onChange={handleOnChange}
                label='Language'
                options={options}
                placeholder='Language'
              />
            </Form.Group>
            <Form.Button size='mini' loading={isFormProcessing} disabled={isFormProcessing}>Add Risk Indicator</Form.Button>
          </Form>
          <br /><br />

          {buckets.map(buck => {
            let key = Object.keys(buck)[0];
            let value = buck[key];
            return (
              <div key={key}>
                <h3>
                  { LANGUAGES.filter(c => c.code === key)[0].label } ({value.length} terms)
                </h3>
                <br />
                <Label.Group>
                { value.map((k) => (
                  <Label key={k._id}>
                    {k.term.replace(/_/g, ' ')}
                    <Icon
                      name='delete'
                      onClick={ evt => handleOnRemove(evt, k._id, LANGUAGES.filter(c => c.code === key)[0].code) } />
                  </Label>)) }
                </Label.Group>
                <br /><br />
              </div>
            )})}
        </Segment>            
      }
      { !activeItem && <Loading active={true} /> }
    </div>
  </>;
}
