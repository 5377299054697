import React from 'react';
import styled from 'styled-components';

const Highlight = styled.div`
  padding: 5px 10px;
  background: ${({ isEmpty }) => isEmpty ? '#ececec' : '#dddf63'};
  font-weight: 700;
`;

export default ({value, displayTransform}) => (
  <Highlight isEmpty={!value}>
  {displayTransform ? displayTransform(value) : (
    value || (<span>&nbsp;</span>)
  )}
  </Highlight>
);
