import styled from 'styled-components';
import { Label } from 'semantic-ui-react';

export const FlexGrid = styled.div`
  display: flex;
  > div:last-of-type {
    flex: 1;
  }
`;

export const ColorLabel = styled(Label)`
  ${({ bgcolor }) => bgcolor && `
    background: ${bgcolor} !important;
    color: #fff !important;
  `}
`;

export const Hyperlink = styled.a`
  color: #444;
  border-bottom: 2px solid #4d7fed;
  text-decoration: italic;
  font-size: 11px;
  padding: 2px 5px;
  border-radius: 2px;
`;

export const Grid = styled.div`
	display: grid;
	grid-template-columns: ${({ col }) => col || '50% 50%'};
`;
