import React, { useEffect, useState } from 'react';
import { CustomTextArea } from '../CustomTextArea';
import { Checkbox } from 'semantic-ui-react';
import { POLITICAL_EXPOSURE } from '../../../constants';
import {
  updateKeystoneEntity,
  retrieveEntityRels,
  getEntityFromKeystone,
  updatePreviewReport
} from '../../../utils/api';
import { Button, Table } from 'semantic-ui-react';

const FAMILY_REL_TYPE_ID = 102;
const POLITICAL_REL_TYPE_ID = 104;

export function PEP({ state, setState }) {

  useEffect(() => {
    async function fetching() {
      setState(prevState => (
        { ...prevState, isProcessing: true }
      ))
      const { response } = await getEntityFromKeystone({
        entityId: state.dataramaEntityId,
        entityType: state.isCompany ? 'company' : 'person' });
      if (response && response.politicalExposure) {
        if (!state.isCompany) {
          await fetchConnections();
        }
        setLocalState(prevState => (
          {...prevState, isPEP: response.politicalExposure}
        ))
        setState(prevState => (
          { ...prevState, [POLITICAL_EXPOSURE]: response.politicalExposureText, isProcessing: false }
        ))
      } else {
        handleFetchDefault();
      }
      return () => {
        new AbortController().abort();
      };
    }
    if (state[POLITICAL_EXPOSURE] === "") {
      fetching();
    }
  }, []);

  const [localState, setLocalState] = useState({
    personalConns: [],
    personalConnsContent: [],
    politicalConns: [],
    isPEP: false
  });

  const handleUpdatePEP = async event => {
    event.preventDefault();
    try {
      setState(prevState => (
        { ...prevState, isProcessing: true }
      ));
      await updatePreviewReport(JSON.stringify({
        reportId: state.reportId,
        [POLITICAL_EXPOSURE]: state[POLITICAL_EXPOSURE]
      }));
      if (localState.isPEP) {
        const payload = JSON.stringify({
          name: state.entityName,
          entityType: state.isCompany ? 'company' : 'person',
          dataramaId: state.dataramaEntityId,
          politicalExposure: localState.isPEP,
          politicalExposureText: state[POLITICAL_EXPOSURE],
        });
        await updateKeystoneEntity(payload);
      } else {
        const payload = JSON.stringify({
          name: state.entityName,
          entityType: state.isCompany ? 'company' : 'person',
          dataramaId: state.dataramaEntityId,
          politicalExposure: localState.isPEP
        });
        await updateKeystoneEntity(payload);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setState(prevState => (
        { ...prevState, isProcessing: false }
      ));  
    }
  }

  // Company is non PEP by default in liteEDD context. Analysts are expected to do their own due dilligence.
  const buildCompanyPEPText = () => {
    return '<p>Datarama carried out searches on privately run risk-and-compliance databases listing politically exposed persons, and online public records. The company was not named on any of these records. These databases are not deemed comprehensive.</p>';
  }

  const buildIndividualPEPSentence = (politicalConns) => {
    if (politicalConns.length === 0) {
      return '';
    }
    let sentence = `<br /><br />The entity was `
    return politicalConns.reduce((acc, cur, index) => {
      acc += `${ cur.relDescStr.charAt(0).toLowerCase() + cur.relDescStr.slice(1) }`;
      if (index === politicalConns.length - 1) {
        acc += '.'
      } else {
        acc += ', '
      }
      return acc;
    }, sentence);
  }

  const buildIndividualPEPSentence2 = (personalConns) => {

    return personalConns.reduce((acc, cur) => {
      acc += '<br /><br />';
      acc += cur;
      return acc;
    }, '');
  }

  const buildPersonPEPText = (politicalConns = [], personalConns = []) => {
    if (politicalConns.length === 0 && personalConns.length === 0) {
      return 'Datarama carried out searches on privately run risk-and-compliance databases listing politically exposed persons, and online public records. The person was not named on any of these records. These databases are not deemed comprehensive.'
    }
    let standardTexts = `Datarama carried out searches on privately run risk-and-compliance databases listing politically exposed persons, and online public records. ${ state.entityName } was identified as a Politically Exposed Person.`
    if (politicalConns.length > 0) {
      standardTexts += buildIndividualPEPSentence(politicalConns);
    }
    if (personalConns.length > 0) {
      standardTexts += buildIndividualPEPSentence2(personalConns);
    }
    return '<p>' + standardTexts + '</p>';
  }

  const fetchConnections = async () => {
    let personalConns = [];
    let politicalConns = [];
    let personalConnsContent = [];
    if (!state.isCompany) {
      const conns = await retrieveEntityRels(state.dataramaEntityId);
      politicalConns = conns.filter(conn => conn.relTypeId === POLITICAL_REL_TYPE_ID);
      const rawPersonalConns = conns.filter(conn => conn.relTypeId === FAMILY_REL_TYPE_ID);

      for (let rel of rawPersonalConns) {
        const rels = await retrieveEntityRels(state.dataramaEntityId === rel.entityId ? rel.entity2Id : rel.entityId);
        const politicalRels = rels.filter(conn => conn.relTypeId === POLITICAL_REL_TYPE_ID);
        if (politicalRels.length > 0) {
          let content = `${state.dataramaEntityId === rel.entityId ? rel.entityTwoName : rel.entityOneName} (${rel.relDescStr} of the entity), is `;
          for (let i = 0; i < politicalRels.length; i++) {
            content += `${ politicalRels[i].relDescStr.charAt(0).toLowerCase() + politicalRels[i].relDescStr.slice(1) + ' of ' + politicalRels[i].entityTwoName }`;
            if (i === politicalRels.length - 1) {
              content += '.'
            } else {
              content += ', '
            }
            personalConns.push(politicalRels[i]);
          }
          personalConnsContent.push(content);
        }
      }  
    }
    setLocalState({
      personalConns,
      politicalConns,
      personalConnsContent
    })
    return { personalConns, politicalConns, personalConnsContent };
  }

  const handleFetchDefault = async () => {
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))
    const { politicalConns, personalConnsContent } = await fetchConnections();

    const politicalExposureText = state.isCompany
      ? buildCompanyPEPText()
      : buildPersonPEPText(politicalConns, personalConnsContent);
    setState(prevState => (
      { ...prevState, [POLITICAL_EXPOSURE]: politicalExposureText, isProcessing: false }
    ))
  }

  const handleCheckbox = () => {
    setLocalState(prevState => (
      {...prevState, isPEP: !prevState.isPEP }
    ))
  }

  return <>
    <div className='justify-space-between'>
      <Button
        content='Refresh'
        onClick={ handleFetchDefault }
        size='mini'
        loading={state.isProcessing}
        disabled={state.isProcessing} />
      <Button
        content='Save'
        onClick={ handleUpdatePEP }
        size='mini'
        loading={state.isProcessing}
        disabled={state.isProcessing} />
    </div>
    <Checkbox
      label='Entity is politically exposed'
      disabled={state.isProcessing}
      onClick={handleCheckbox}
      checked={localState.isPEP} />
    <br /><br />
    <CustomTextArea setState={ setState } state={ state } />
    <br />
    { !state.isCompany && localState &&
      (localState.politicalConns.length > 0 || localState.personalConns.length > 0) &&
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Rel Type</Table.HeaderCell>
            <Table.HeaderCell>Rel Id</Table.HeaderCell>
            <Table.HeaderCell>Rel Desc.</Table.HeaderCell>
            <Table.HeaderCell>Corresponding entity</Table.HeaderCell>
            <Table.HeaderCell>Source Url</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { localState.politicalConns.map(conn => (
            <Table.Row key={ conn.relId }>
              <Table.Cell>Political</Table.Cell>
              <Table.Cell>{ conn.relId }</Table.Cell>
              <Table.Cell>{ conn.relDescStr }</Table.Cell>
              <Table.Cell>{state.dataramaEntityId === conn.entityId ? conn.entityTwoName + ' - ' + conn.entity2Id : conn.entityOneName + ' - ' + conn.entityId}</Table.Cell>
              <Table.Cell>{ conn.sourceUrlStr ? <a href={conn.sourceUrlStr} target='_blank' rel='noopener noreferrer'>Ref</a> : 'N/A' }</Table.Cell>
            </Table.Row>
          ))}
          { localState.personalConns.map(conn => (
            <Table.Row key={ conn.relId }>
              <Table.Cell>Personal</Table.Cell>
              <Table.Cell>{ conn.relId }</Table.Cell>
              <Table.Cell>{ conn.relDescStr }</Table.Cell>
              <Table.Cell>{state.dataramaEntityId === conn.entityId ? conn.entityTwoName + ' - ' + conn.entity2Id : conn.entityOneName + ' - ' + conn.entityId}</Table.Cell>
              <Table.Cell>{ conn.sourceUrlStr ? <a href={conn.sourceUrlStr} target='_blank' rel='noopener noreferrer'>Ref</a> : 'N/A' }</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table> }
  </>
}