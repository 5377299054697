import React, { useRef } from 'react';
import moment from 'moment';
import uuid from 'uuid';
import { Button } from './Button';
import { CONCEPTS, LANGUAGES } from './constants';

import {
  Btn,
  FinePrint,
  Form,
  Hint,
  Icon,
  InfoWrapper,
  OptionBtn,
  OptionGrid,
  SearchGrid,
  Settings
} from './styles.js';

export function SearchWidget({
  categories,
  handleCancel,
  handleViewBuckets,
  otherLangs,
  setActiveStep,
  setCategories,
  setPreview,
  setOtherLangs
}) {

  const engName = useRef(null);
  const langs = useRef({});
  const locals = useRef({});
  const disableCache = useRef(null);

  const removeFromList = category => {
    setCategories(prevState => {
      return prevState.reduce((a, c) => {
        if (category === 'all') return a;
        if (c === category) return a;
        return a.concat(c);
      }, [])
    });
  }

  const addToList = category => {
    setCategories(prevState => {
      return prevState.concat(category)
    })
  }

  const handleAddNewLanguage = () => {
    const id = uuid.v4();
    if (otherLangs.length < 2) {
      setOtherLangs(prevState => (
        prevState.concat([{ id }])
      ))
    }
  }

  const handlePreviewLanguageOption = (e) => {
    e.preventDefault();
    if (!engName.current.value) {
      return;
    }

    const start = moment(moment().subtract(3, 'years').format('YYYY')).format('YYYY-MM-DD');
    const end = moment().format('YYYY-MM-DD');
    const langValue = otherLangs.reduce((a, d) => {
      if (langs.current[d.id].value && locals.current[d.id].value) {
        return Object.assign({}, a, {
          [langs.current[d.id].value]: locals.current[d.id].value,
        })
      }
      return a;
    }, {});
    setActiveStep('preview');
    setPreview({
      engName: engName.current.value,
      otherLangs: langValue,
      buckets: categories,
      disableCache: disableCache.current.checked,
      dateRange: { start, end }
    });
  }

  return (
    <>
    <Form>
    <div>
    <SearchGrid>
      <div>Name (English)</div>
      <div>
        <input type="text" placeholder="Entity English Name" ref={(c) => { engName.current = c; }} />
      </div>
    </SearchGrid>
    { otherLangs.map(l => (
      <SearchGrid key={l.id}>
        <div>
          <select ref={(c) => { langs.current[l.id] = c; }}>
            <option value={null}>Select Language</option>
            {LANGUAGES.map(lang => (
              <option key={lang.code} value={lang.code}>
                {lang.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <input type="text" placeholder="Name (Local Language)" ref={(c) => { locals.current[l.id] = c; }} />
        </div>
      </SearchGrid>
    )) }
      <br />
      <FinePrint>To perform boolean search, you can use either <u>%AND%</u> or <u>%OR%</u>. <br />For instance, the search term for a query with both Jack Ma and Alibaba would be <b><u>Jack Ma%AND%Alibaba</u></b>. </FinePrint>
      <br />
      <br />
      <Btn onClick={ handleAddNewLanguage }><Icon>add</Icon> Add New Language</Btn>
    </div>

    <InfoWrapper>
    {categories && (
      <Hint>
        Selected <b>{categories.length}</b> buckets
      </Hint>
    )}
    <Button
      component={OptionBtn}
      label="Deselect All Bucket"
      onClick={ removeFromList }
      value='all'
    />
    { CONCEPTS.map(c => (
      <OptionGrid key={c.code} disable={categories.indexOf(c.code) === -1}>
        <div>{c.label}</div>
        <div>
          {categories.indexOf(c.code) === -1 ? (
            <Button
              component={OptionBtn}
              label="Select Bucket"
              onClick={ addToList }
              value={c.code} />
          ) : (
            <Button
              component={OptionBtn}
              label="Deselect Bucket"
              onClick={ removeFromList }
              value={c.code} />
          )}
          {categories.indexOf(c.code) !== -1 && (
            <Button
              onClick={handleViewBuckets}
              value={c.code}
              label="View terms"
              component={OptionBtn} />
          )}
        </div>
      </OptionGrid>

    ))}
    </InfoWrapper>
  </Form>
  <Settings>
  <div>
    <input type="checkbox" ref={(c) => { disableCache.current = c; }} id="disableCache" name="disableCache" />
    <label htmlFor="disableCache">Disable Cache (Must check if searching for different time span / different client)</label>
  </div>
</Settings>
<Btn onClick={ handlePreviewLanguageOption }>Preview Selections</Btn>
<Btn onClick={ handleCancel }>Cancel</Btn>
</>)
}