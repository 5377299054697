import React from 'react';

export default ({ col, value }) => {
  const rowValue = col.input.cellConditionValue ? col.input.cellConditionValue(value) : value;
  if (isNaN(rowValue)) return rowValue || null;
  const colorSpectrum = col.input.colorSpectrum || {};
  const color = colorSpectrum[rowValue] || '#adadad';
  return (
    <div
      style={{
        width: '100%',
        height: '50%',
        backgroundColor: '#dadada',
        borderRadius: '2px',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: `${rowValue * 100 / 6}%`,
          height: '100%',
          backgroundColor: color,
          borderRadius: '2px',
          transition: 'all .2s ease-out'
        }}
      />
      <div style={{
        fontSize: '12px',
      }}>
        {value}
      </div>
    </div>

  )
}
