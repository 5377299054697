import React from 'react';
import { Message } from 'semantic-ui-react';

export default (props) => {
  return (
    <Message
      header={`Project has status ${props.status || ''}. Update has been disabled.`}
      content='Please check with tech team or analyst team if you need help'
    />
  )
}
