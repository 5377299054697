import React from 'react';
import { CONCEPTS, LANGUAGES } from './constants';
import {
  Btn,
  BucketTag,
  InfoRow,
  InfoWrapper
} from './styles.js';
import { withRouter } from 'react-router';

function PreviewWidgetClass({
  handleCancel,
  history,
  preview
}) {

  const handleFetchResults = async event => {
    event.preventDefault();
    const { buckets, dateRange, disableCache, engName, otherLangs } = preview;

    history.push({
      pathname: '/research/adverseMedia/search',
      search: `?client=default&dateRange=${JSON.stringify(dateRange)}&engName=${engName}&otherLangs=${JSON.stringify(otherLangs)}&buckets=${JSON.stringify(buckets)}`,
      state: { disableCache }
    });
  }

  if (!preview) {
    return null;
  }
  return <InfoWrapper>
    <InfoRow><div>English</div><div>{preview.engName}</div></InfoRow>
    {preview.otherLangs && Object.keys(preview.otherLangs).map(l => (
      <InfoRow key={l}><div>{ LANGUAGES.filter(c => c.code === l)[0].label }</div><div>{preview.otherLangs[l]}</div></InfoRow>
    ))}
    <InfoRow><div>Start</div><div>{preview.dateRange.start}</div></InfoRow>
    <InfoRow><div>End</div><div>{preview.dateRange.end}</div></InfoRow>
    <InfoRow>
      <div>Buckets Selected ({preview.buckets && preview.buckets.length})</div>
      <div>{preview.buckets && preview.buckets.map(b => (
        <BucketTag key={b}>{ CONCEPTS.filter(c => c.code === b)[0].label }</BucketTag>
      ))}</div>
    </InfoRow>
    <Btn onClick={handleFetchResults}>Confirm Details and Get Results</Btn>
    <Btn onClick={handleCancel}>Cancel</Btn>
  </InfoWrapper>
}

export const PreviewWidget = withRouter(PreviewWidgetClass);