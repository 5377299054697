import moment from 'moment';

export const getTodaysDateFormatted = () => moment().format('YYYY-MM-DD');

export const isTimeAfter = (lastFetched) => {
  moment(lastFetched).isBefore(moment().subtract(5, 'minutes'))
}

export const defaultTimeDisplay = date => (
  moment(date).format('YYYY-MM-DD HH:mm:ss')
);
