import React, { useState, useEffect } from 'react';
import { DEFAULT_FINANCIAL_LINE_ITEMS } from '../constants';
import { Config } from './Config';
import { LineItemForm } from './LineItemForm';
import { Statements } from './Statements';

export function Financials({ state, setState }) {

  const [values, setValues] = useState({});
  const [componentState, setComponentState] = useState({
    currency: 'USD',
    years: [],
    active: 'config'
  });

  useEffect(() => {
    if (Object.keys(state.rawFinancials).length > 0 && Object.keys(state.financialLineItems).length > 0) {
      const { layout, values } = JSON.parse(state.financials);
      setComponentState(prevState => ({
        ...prevState,
        currency: layout[0].currency,
        years: layout[0].dates,
        magnitude: layout[0].magnitude
      }));
      setValues(values);
    } else {
      setState(prevState => (
        { ...prevState, financialLineItems: DEFAULT_FINANCIAL_LINE_ITEMS }
      ))
    }
  }, []);


  return <div className='financials'>
    {state.gsFileId && <div>Financials have been created via keystone previously. Kindly use keystone to update financials instead.</div>}
    {!state.gsFileId &&
    <>
      { componentState.active === 'config' &&
        <Config
          state={ state }
          setState={ setState }
          componentState={ componentState }
          setComponentState={ setComponentState } /> }
      { componentState.active === 'fs' &&
      <>
        <Statements
          state={ state }
          setState={ setState }
          componentState={ componentState }
          setComponentState={ setComponentState }
          values={ values }
          setValues={ setValues } />
        <LineItemForm state={state} setState={setState} />
      </> }
    </>}


    </div>
}