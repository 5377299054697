import React from 'react';

export function Disclaimer() {
  return <>
  <div className='disclaimer'>
    <div className='title'>
      Disclaimer:
    </div>
    <div className='body'>
      The information contained herein does not constitute a guarantee or warranty by its author. It has been prepared following consultation with and on the basis of instructions received from the Client and reflects the priorities and knowledge of the Client as communication to the author. No obligation is undertaken by the author to provide the Client with further information, to update this information or any other information for events or changes of circumstances which take place after the date hereof or to correct any information contained herein or any omission therefrom. The author’s work and findings shall not in any way constitute an endorsement of future business actions or provide a guarantee of success for the Client. The author will not be held liable for any decisions made by the Client 
      <br />
      This report is for the benefit of the Client only (including its directors, officers and employees) and may not be disclosed to any third parties without the prior written consent of Datarama Technology Pte Ltd (‘Datarma’). Any reproduction without authorisation shall be considered an infringement of Datarama’s copyright.
    </div>
  </div>
  </>

}