import React from 'react';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';

const Wrapper = styled.div`
  position: fixed;
  height: 100vh;
  z-index: 100;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(30, 30, 30, 0.8)
`;
const Box = styled.div`
  margin: auto;
  background: #fff;
  border-radius: 5px;
  width: 400px;
`;

const Header = styled.h3`
  color: #7d73d7;
  margin: 0;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 20px;
  border-bottom: 2px solid #7d73d7;
`;

const Content = styled.div`
  padding: 10px 20px;
  position: relative;
`;

const Stress = styled.div`
  background: #ccc;
  padding: 10px;
  margin: 10px 0;
`;

const Action = styled.div`
  display: grid;
  grid-template-columns: ${({ grid }) => grid || '50% 50%'};
  button {
    padding: 10px 20px;
    border-radius: 0;
    border: 0;
    border-top: 1px solid #ececec;
    border-right: 1px solid #ececec;
    cursor: pointer;
    outline: none;
    font-size: 15px;
    ${({ disabled }) => !disabled && `
      &:hover {
        color: #7d73d7;
      }
    `}
  }
`;

const PATTERNS = {
  match: {
    header: 'Confirm as a Match',
    content: 'This record will appear as a match on check tab for this Datarama Entity',
    onComplete: 'Successfully Confirmed'
  },
  noMatch: {
    header: 'Confirm No Match Found',
    content: 'This Datarama Entity will appear in the platform with no match. You can add additional check details via Keystone CMS.',
    onComplete: 'Successfully Confirmed'

  },
  restore: {
    header: 'Restore to default',
    content: 'Previously confirmed record will be reset. The match list will be restored for this Datarama Entity.',
    onComplete: 'Successfully Restored'
  },
  combine: {
    header: 'Confirm Combine Profile',
    content: ''
  }
}

class Prompt extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.close = this.close.bind(this);
    this.confirm = this.confirm.bind(this);
    this.doneAction = this.doneAction.bind(this);
  }

  onClick() {
    this.props.onClick(this.props.value);
  }

  close() {
    this.props.onClose();
  }

  doneAction() {
    this.props.doneAction();
  }

  confirm() {
    this.props.onConfirm(this.props.type, this.props.value);
  }

  render() {
    const { type, loading, done, onError, combineProfile } = this.props;
    if (type === 'combine') {
      return (
        <Wrapper>
          <Box>
            <Header>{PATTERNS[type].header}</Header>
            <Content>
              {Object.keys(combineProfile).map(field => (
                <div key={field}>
                  {combineProfile[field]}
                </div>
              ))}
            </Content>
            <Action disabled={loading}>
              <button onClick={this.close} disabled={loading}>Cancel</button>
              <button onClick={this.combine} disabled={loading}>Confirm Combine</button>
            </Action>
          </Box>
        </Wrapper>
      )
    }
    return (
      <Wrapper>
        <Box>
          <Header>{PATTERNS[type].header}</Header>
          <Content>
            {loading &&
              <Loader active>Please Wait</Loader>
            }
            {onError ?
              <div>
                There was an error when processing the request. Please try again.
              </div>
              :
              (done ?
                (<div>
                  {PATTERNS[type].onComplete}
                </div>)
                :
                (<div>
                  {type === 'match' &&
                  <Stress>{this.props.matchName}</Stress>
                  }
                  {PATTERNS[type].content}
                  <Stress>ID: {this.props.entityId}<br/>{this.props.entityName}</Stress>
                </div>))
            }
          </Content>
          {done ?
            <Action grid="100%">
              <button onClick={this.doneAction}>Done</button>
            </Action>
            :
            (onError ?
              <Action grid="100%">
                <button onClick={this.close}>Close</button>
              </Action>
              :
              <Action disabled={loading}>
                <button onClick={this.close} disabled={loading}>Cancel</button>
                <button onClick={this.confirm} disabled={loading}>Confirm</button>
              </Action>
            )
          }
        </Box>
      </Wrapper>
    )
  }
}

export default Prompt;
