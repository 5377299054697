import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
  padding: 5px;
  > * {
    font-size: 30px;
  }
`;
class Radio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked || false,
    }
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.item.id !== nextProps.item.id) {
      this.setState({
        checked: nextProps.checked || false,
      })
    }
  }

  onChange(e) {
    const { customOnChange } = this.props;
    const { checked } = e.target;
    this.setState({
      checked,
    });
    if (customOnChange) return customOnChange(e);
    this.props.onChange(this.props.field, this.props.value, e.target.checked, this.props.type, this.props.uniqueField);
  }

  render() {
    return (
      <Wrapper>
        <input type='checkbox' onChange={this.onChange} checked={this.state.checked}/>
      </Wrapper>
    )
  }
}

export default Radio;
