import React from 'react';
import ReactDOM from 'react-dom';

class Title extends React.Component {
  constructor(props) {
    super(props);
    this.titleEl = document.getElementsByTagName('title')[0];
  }

  render() {
    let title = this.props.siteTitle;
    if (this.props.pageTitle) {
      title = `${this.props.pageTitle} - ${title}`
    }
    return ReactDOM.createPortal(title, this.titleEl);
  }
}

Title.defaultProps = {
  siteTitle: ''
}

export default Title;
