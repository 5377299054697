export const getDtrmResult = (fields, result = []) => {
  return result.reduce((a, c) => {
    const baseCase = c.caseNumStr;
    a[baseCase] = a[baseCase] || {};
    Object.keys(c).reduce((j, k) => {
      if (fields[k]) {
        a[baseCase][k] = a[baseCase][k] || c[k];
      } else if (k === 'defendantId'){
        a[baseCase].defendant = {
          ...(a[baseCase].defendant || {}),
          [c.defendantId]: {
            defendantId: c.defendantId,
            defendantName: c.defendantName,
            entryId: (((a[baseCase].defendant || {})[c.defendantId] || {}).entryId || []).concat(c.entryId),

            litigationId: (((a[baseCase].defendant || {})[c.defendantId] || {}).litigationId || []).concat(c.litigationId),
          }
        }
      } else if (k === 'plaintiffId'){
        a[baseCase].plaintiff = {
          ...(a[baseCase].plaintiff || {}),
          [c.plaintiffId]: {
            plaintiffId: c.plaintiffId,
            plaintiffName: c.plaintiffName,
            entryId: (((a[baseCase].plaintiff || {})[c.plaintiffId] || {}).entryId || []).concat(c.entryId),
            litigationId: (((a[baseCase].plaintiff || {})[c.plaintiffId] || {}).litigationId || []).concat(c.litigationId),
          }
        }
      } else if (k === 'courtEntityName') {
        a[baseCase].court = {
          courtEntityName: c.courtEntityName,
          courtEntityId: c.courtEntityId,
        }
      }
      return j;
    }, {});
    return a;
  }, {});
}
