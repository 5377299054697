import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Header, Divider } from 'semantic-ui-react';
import { TempRelationship } from '../../model/relationship/tempRelationship';

const Modal = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: rgba(0,0,0,.85);
  > div {
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 1px 3px 3px 0 rgba(0,0,0,.2), 1px 3px 15px 2px rgba(0,0,0,.2);
    height: 50vh;
    display: flex;
    flex-direction: column;
    width: 70vw;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  > div:first-of-type {
    flex: 1;
  }
`;

const Content = styled.div`
  overflow-y: scroll;
  flex: 1;
`;
const Row = styled.div`
  > div:first-of-type {
    width: 30%;
    background: #ececec;
    font-weight: 600;
  }
  > div {
    display: inline-block;
    padding: 5px 10px;
  }
  margin-bottom: 5px;
`;

export function RelationshipEntry({ entry, allowUpdates, updateEntry, close }) {

  const tempRelationship = new TempRelationship();

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns(tempRelationship.columns);
  }, []);

  return (
    <Modal>
        <div>
          <HeaderWrapper>
            <div>
              <Header>
                View {entry.entryType} entry {entry.relId && allowUpdates && (
                  <Button
                    onClick={updateEntry}
                    color='olive'
                    compact
                    value={{
                      relId: entry.relId,
                      type: 'relationship'
                    }}
                  >
                    Click to Update Entry
                  </Button>
                )}
                <Header.Subheader>Datarama ID: {entry.relId}</Header.Subheader>
              </Header>
            </div>
            <div>
              <Button onClick={close} size='mini'>Close</Button>
            </div>
          </HeaderWrapper>
          <Divider />
          <Content>
          {entry && columns && columns.map((col) => (
            <Row key={col.id}>
              <div>{col.Header}</div>
              <div>
                {typeof col.accessor === 'function' ? col.accessor(entry) : (
                  entry[col.id]
                )}
              </div>
            </Row>
          ))}
          </Content>
        </div>
      </Modal>
  )
}