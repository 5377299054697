import React from 'react';
import { Message } from 'semantic-ui-react';

export default (props) => {
  return (
    <Message
      negative
      header={`Seems like you do not have the  ${props.resourceType || 'page'} ${props.action || 'view'} rights`}
      content='Please check with tech team or analyst team if you need help'
    />
  )
}
