import { ENTITY_BY_TYPE, ENTITY_TYPE } from '../ref/entityType';

const kebabToCamel = {
  'government-agency': ENTITY_TYPE.governmentAgency,
  'other-org': ENTITY_TYPE.otherOrganisation,
}

const typeToCamel = {
  'Government Agency': ENTITY_TYPE.governmentAgency,
  'other organisation': ENTITY_TYPE.otherOrganisation,
}

export const getDisplayType = (type) => {
  return ENTITY_BY_TYPE[type].header || type;
}

export const entityTypeCamelCase = (type) => {
  return kebabToCamel[type] || type;
}

export const entityTypeDefault = (type) => {
  return typeToCamel[type] || type;
}
