import Request from './request';
import * as validation from './validation';

class Auth {
  constructor(props) {
    this.request = new Request();
    this.requestOps = new Request('ops');
    this.isLoggedIn = this._checkAuthState;
  }


  async _checkAuthState(loggedInUser, cb) {
    const result = await this.requestOps.get('session', true);

    const output = result || false;
    // if (output) {
    //   // this.setAuthCheckTimer();
    // }
    if (cb) return cb(output);
    return output;
  }

  async login(body, clearCb) {
    // Logout before logging in (to clear cookies)
    await this.requestOps.post('logout', {});
    const validBody = validation.loginFields(body);
    if (!validBody) return false;
    const loggedInUser = await this.requestOps.post('login', validBody);

    if (loggedInUser) {
      // Successfully logged in. Set timer to observe payload in cookie.
      // new payload is accompanied with requests
      // if check back isLogged out state

      // this.setAuthCheckTimer();
    }
    if (clearCb) {
      return clearCb(loggedInUser);
    }
    return loggedInUser;
  }

  setAuthCheckTimer(cb) {
    console.log('auth timer set');
    // Set Auth Timer Check for 30 minutes since last activity
    this.timer = setTimeout(() => {
      this.isLoggedIn(null, (userId) => {
        if (!userId) {
          this.logout();
        }
        return cb && cb(userId);
      });
    }, (60 * 30) * 1000)
  }

  async logout(cb) {
    await this.requestOps.post('logout', {});
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (cb) {
      return cb();
    }
  }
}

export default new Auth();
