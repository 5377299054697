import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Loader } from 'semantic-ui-react';
import { RelationshipForm } from './RelationshipForm';
import { RelationshipMessage } from './RelationshipMessage';
import { RelationshipStepper } from './RelationshipStepper';
import { RelationshipTable } from './RelationshipTable';
import { RelationshipSearchPanel } from './RelationshipSearchPanel';

const Wrapper = styled.div`
  overflow: auto;
	width: 100%;
	padding-top: 10px;
`;

const ActionWrapper = styled.div`
  > * {
    display: inline-block;
  }
  text-align: center;
  margin: 10px 0 50px 0;
`;

export function NewRelationship({ match: { params } }) {

	// Declare active step variables
	const INPUT_FORM = 'inputForm';
	const VALIDATE = 'validate';

	// Declare component states
	const [activeStep, setActiveStep] = useState(VALIDATE);
	const [completed, setCompleted] = useState({});
	const [errors, setErrors] = useState({});
	const [httpResponse, setHttpResponse] = useState(null);
	const [inputs, setInputs] = useState({});
	const [loading, setLoading] = useState({});
	const [results, setResults] = useState(null);

	const validateComplete = (event, { value }) => {
		window.scrollTo(0, 0);
		const status = !!value;
		setCompleted(prevState => (
			{ ...prevState, validate: status }
		));
		if (status) {
			const { entityId, entity2Id } = inputs;
			setInputs({ entityId, entity2Id });
			setActiveStep(INPUT_FORM);
		} else {
			setActiveStep(VALIDATE);
		}
	}

	return (
		<Wrapper>
			<Loader active={loading.adding}>Loading...</Loader>
			<RelationshipStepper completed={completed} activeStep={activeStep} isNewRelationship={true} />
			{activeStep === VALIDATE && !httpResponse &&
				<RelationshipSearchPanel
					isNewRelationship={true}
					loading={loading}
					setInputs={setInputs}
					setLoading = { setLoading }
          params = { params }
          inputs = { inputs }
          setResults = { setResults } />}
			{activeStep === VALIDATE && !httpResponse && results && results.dtrm &&
				<RelationshipTable
					isNewRelationship={true}
					loading={loading}
					params = { params }
					inputs = { inputs }
          setResults = { setResults }
					results={results} />}
			{activeStep === VALIDATE && !httpResponse &&
				<ActionWrapper>
					{results != null
						? <Button content='I have confirmed that the relationship to be added has not been previously ingested'
							onClick={validateComplete}
							color='green' value={true} size='large' />
						: <Button content='Skipped (Please make sure before progressing)'
							onClick={validateComplete} value={true} size='large' />}
				</ActionWrapper>}
			{activeStep === INPUT_FORM && !httpResponse &&
				<RelationshipForm
					params={params}
					isNewRelationship={true}
					inputs={inputs}
					errors={errors}
					setErrors={setErrors}
					setHttpResponse={setHttpResponse}
					setInputs={setInputs} />}
			{httpResponse && httpResponse.relid &&
				<RelationshipMessage
					setInputs={ setInputs }
					setLoading={ setLoading }
					setErrors={ setErrors }
					setHttpResponse={ setHttpResponse }
					setResults={ setResults }
					setActiveStep={ setActiveStep }
					VALIDATE={ VALIDATE }
					isNewRelationship={true}
					httpResponse={httpResponse} />}
		</Wrapper>
	);
}