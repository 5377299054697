import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Form, Checkbox } from 'semantic-ui-react';
import { BLACKLIST } from '../../../constants';
import {
  fetchPendingEntityDetails,
  updateKeystoneEntity,
  getEntityFromKeystone,
  updatePreviewReport } from '../../../utils/api';
import { CustomTextArea } from '../CustomTextArea';

export function Sanctions({ state, setState }) {
  useEffect(() => {
    function fetching() {
      fetchBlacklists();
      return () => {
        new AbortController().abort();
      };
    }
    if (state[BLACKLIST] === "") {
      fetching();
    }
  }, []);

  const [localState, setLocalState] = useState({
    isBlacklisted: false
  });

  const fetchBlacklists = async () => {
    try {
      setState(prevState => (
        { ...prevState, isProcessing: true }
      ))

      const { response } = await getEntityFromKeystone({
        entityId: state.dataramaEntityId,
        entityType: state.isCompany ? 'company' : 'person' });
      if (response && response.doneBlacklistResearch && response.blacklisted) {
        setState(prevState => (
          { ...prevState, [BLACKLIST]: response.blacklistText, isProcessing: false }
        ))
      } else {
        const results = await fetchPendingEntityDetails(state.isCompany ? 'company': 'person', state.dataramaEntityId);
        if (results !== null && results.data) {
          const { riskyEntityList } = results.data;
          setState(prevState => (
            { ...prevState, [BLACKLIST]: buildText(state.isCompany, riskyEntityList), isProcessing: false }
          ))
        } else {
          setState(prevState => (
            { ...prevState, [BLACKLIST]: buildText(state.isCompany, []), isProcessing: false }
          ))
        }
      }



    } catch (err) {
      console.error(err);
    }
  }

  const buildText = (isCompany, blacklisted=[]) => {
    if (blacklisted.length > 0) {
      return buildBlacklistedText(isCompany, blacklisted);
    }
    return buildNonBlacklistedText(isCompany);
  }


  const buildNonBlacklistedText = (isCompany) => (
    `<p>This ${ isCompany ? 'company': 'person' } was checked against lists from sanctioning bodies, law enforcement agencies and financial regulators worldwide. These lists include, but are not restricted to: the Specially Designated Nationals List maintained by the Office of Foreign Asset Control, a division of the US Treasury; the Denied Persons List maintained by the Bureau of Industry & Security, a division of the US Department of Commerce; the UK's HM Treasury sanctions lists; the World Bank Listing of Ineligible Firms; and the Excluded Parties Listing System maintained by the US government.<br />The ${ isCompany ? 'company': 'person' } was not found mentioned in the records of any lists. Datarama notes that the aforementioned lists may not be comprehensive.</p>`
  );

  const buildBlacklistedText = (isCompany, blacklisted) => {
    let text = `<p>This ${ isCompany ? 'company': 'person' } was checked against lists from sanctioning bodies, law enforcement agencies and financial regulators worldwide. These lists include, but are not restricted to: the Specially Designated Nationals List maintained by the Office of Foreign Asset Control, a division of the US Treasury; the Denied Persons List maintained by the Bureau of Industry & Security, a division of the US Department of Commerce; the UK's HM Treasury sanctions lists; the World Bank Listing of Ineligible Firms; and the Excluded Parties Listing System maintained by the US government.<br />A check on privately run risk-and-compliance databases as well as extensive public domain research found that ${state.entityName} had been blacklisted by `;
    let additionalText = '';
    for (let i = 0; i < blacklisted.length; i++) {
      additionalText += `${blacklisted[i].riskTypeDesc}${i === (blacklisted.length - 1) ? ` as at ${moment(Date.now()).format('DD-MMM-YYYY')}.`: ', '}`
    }
    return text + additionalText + '</p>';
  };

  const handleOnSubmit = async evt => {
    evt.preventDefault();
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))
    await updatePreviewReport(JSON.stringify({
      reportId: state.reportId,
      [BLACKLIST]: state[BLACKLIST]
    }));
    if (localState.isBlacklisted) {
      const payload = JSON.stringify({
        name: state.entityName,
        entityType: state.isCompany ? 'company' : 'person',
        dataramaId: state.dataramaEntityId,
        blacklisted: localState.isBlacklisted,
        blacklistText: state[BLACKLIST],
        doneBlacklistResearch: true
      });
      await updateKeystoneEntity(payload);
    } else {
      const payload = JSON.stringify({
        name: state.entityName,
        entityType: state.isCompany ? 'company' : 'person',
        dataramaId: state.dataramaEntityId,
        blacklisted: localState.isBlacklisted,
        doneBlacklistResearch: true
      });
      await updateKeystoneEntity(payload);
    }

    setState(prevState => (
      { ...prevState,
        isProcessing: false,
        completed: { ...prevState.completed, sanctions: true }
      }
    ))
  }

  const handleCheckbox = () => {
    setLocalState(prevState => (
      {...prevState, isBlacklisted: !prevState.isBlacklisted }
    ))
  }
  
  return <Form size='small' onSubmit={ handleOnSubmit }>
    <div className='segment'>
      <div className='justify-space-between'>
        <Button content='Refresh' onClick={ fetchBlacklists } size='mini' loading={ state.isProcessing } disabled={ state.isProcessing } />
        <Button size='mini' content='Save' type='submit' loading={ state.isProcessing } disabled={ state.isProcessing } />
      </div>
      <br />
      <div>1. By default, blacklist details from C6 are populated in the textbox.</div>
      <div>2. <b><u><a href='/research/sanctions' target="_blank" rel='noopener noreferrer'>Click here</a></u></b> to perform additional sanctions list check.</div>
      <br /><br />
      <Checkbox
        label='Entity is blacklisted'
        disabled={state.isProcessing}
        onClick={handleCheckbox}
        checked={localState.isBlacklisted} />
      <br /><br />
      <CustomTextArea setState={ setState } state={ state } />
    </div>
  </Form>
  
} 