import React from 'react';

export function Sitemap() {
  return <div>
    <b>Home</b><br />
    <a href='/' target="_blank" rel='noopener noreferrer'>Home</a><br />
    <br /><b>Files</b><br />
    <a href='/files/add' target="_blank" rel='noopener noreferrer'>Add File</a><br />
    <br /><b>Entities</b><br />
    <a href='/validation' target="_blank" rel='noopener noreferrer'>Find By Name</a><br />
    <a href='/find/id' target="_blank" rel='noopener noreferrer'>Find By Datarama Id</a><br />
    <a href='/entities/deduplicate' target="_blank" rel='noopener noreferrer'>Deduplication</a><br />
    <br /><b>Projects</b><br />
    <a href='/projects' target="_blank" rel='noopener noreferrer'>Projects</a><br />
    <a href='/projects/add' target="_blank" rel='noopener noreferrer'>Add Project</a><br />
    <br /><b>Research</b><br />
    <a href='/research/riskfactors' target="_blank" rel='noopener noreferrer'>Risk Factors</a><br />
    <a href='/research/sanctions' target="_blank" rel='noopener noreferrer'>Sanctions</a><br />
    <a href='/research/pepblacklist' target="_blank" rel='noopener noreferrer'>PEP and blacklist check</a><br />
    <br /><b>Settings</b><br />
    <a href='/permissions' target="_blank" rel='noopener noreferrer'>Permissions</a><br />
    <a href='/clients/add' target="_blank" rel='noopener noreferrer'>Add Client</a><br />
  </div>
}