import React from 'react';
import { Grid, Form } from 'semantic-ui-react'

const FIELDS = [
  { value: 'entityName', label: 'First Entity Name (English)', type: 'input', required: true, disabled: false },
  { value: 'secondEntityName', label: 'Second Entity Name (English)', type: 'input', required: false, disabled: false },
]

export function DefaultFields({state, setState}) {
    return <Grid.Row>
      { FIELDS.map((field => (
      <Grid.Column key={field.value}>
        <Form.Field>
          { field.type === 'input' &&
            <Form.Input
              fluid
              required={field.required}
              disabled={field.disabled}
              name={field.value}
              label={field.label}
              value={state.requestPayload.eng[field.value]}
              placeholder={field.label}
              onChange={
                (event, data) => {
                  setState(prevState => {
                    return { ...prevState, requestPayload:
                      { ...prevState.requestPayload, eng:
                        { ...(prevState.requestPayload.eng || {}), [field.value]: data.value }
                      }
                    }        
                  })  
                }
              } /> }
        </Form.Field>                     
      </Grid.Column>
    )))}
  </Grid.Row>
}