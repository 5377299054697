import React from 'react';
import { Button, Grid, Header, Form, Divider, Label, Segment } from 'semantic-ui-react';
import Text from './fields/Text';
import { SEARCH_FIELDS } from '../../model/relationship/relationshipName';
import { validateRelationship } from '../../utils/api';

export function RelationshipSearchPanel(
  {
    handleFindRelByRelId,
    setInputs,
    isNewRelationship,
    loading,
    setLoading,
    params,
    inputs,
    setResults
  }
) {

  const handleFieldChange = (event, { name, value }) => {
    setInputs(prevState => (
      { ...prevState, [name]: value }
    ));
  }

  const handleFindRelByEntityIds = async () => {
    try {
      setLoading({ findByName: true });
      const { type, projectId } = params;
      const rels = await validateRelationship(type, { ...inputs, 'scope': ['dtrm'], projectId });
      setResults(rels);
      setLoading({});
    } catch (err) {
      return null;
    }
  }

  if (isNewRelationship) {
    return (
      <Segment placeholder>
        <Grid columns={1}>
          <Grid.Row stretched>
            <Grid.Column>
              <Header icon>
                Find by Name
              </Header>
              <Form onSubmit={handleFindRelByEntityIds} name='name'>
                {Object.keys(SEARCH_FIELDS).map(field => (
                  <Text
                    key={field}
                    field={{
                      Header: SEARCH_FIELDS[field].label,
                      id: field,
                      required: SEARCH_FIELDS[field].required,
                      minLength: 4,
                      type: 'integer'
                    }}
                    handleFieldChange={handleFieldChange}
                    meta={{ type: 'number' }}
                  />
                ))}
                <Button type="submit" loading={loading.findByName} disabled={loading.findByName}>Find Relationship</Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    )  
  }

  return (
    <Segment placeholder>
      <Grid columns={2} stackable textAlign='center'>
        <Grid.Row  stretched verticalAlign='middle'>
          <Grid.Column>
            <Header icon>
              Find<Label color='black'>Relationship</Label> by Datarama ID
            </Header>
            <Form onSubmit={handleFindRelByRelId} name='dataramaId'>
              <Text
                field={{
                  Header: 'Datarama ID',
                  id: 'dataramaId',
                  type: 'integer',
                }}
                handleFieldChange={ handleFieldChange }
                meta={{ type: 'number' }}
              />
              <Button type="submit" loading={loading.findById} disabled={loading.findById}>Find Relationship</Button>
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Header icon>
              Find by Name
            </Header>
            <Form onSubmit={handleFindRelByEntityIds} name='name'>
              {Object.keys(SEARCH_FIELDS).map(field => (
                <Text
                  key={field}
                  field={{
                    Header: SEARCH_FIELDS[field].label,
                    id: field,
                    required: SEARCH_FIELDS[field].required,
                    minLength: 4,
                    type: 'integer'
                  }}
                  handleFieldChange={handleFieldChange}
                  meta={{ type: 'number' }}
                />
              ))}
              <Button type="submit" loading={loading.findByName} disabled={loading.findByName}>Find Relationship</Button>
            </Form>
          </Grid.Column>
          <Divider vertical>Or</Divider>
        </Grid.Row>
      </Grid>
    </Segment>

  )
}