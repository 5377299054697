import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Table, Icon } from 'semantic-ui-react';
import { getSettings } from '../../reducers/selectors';
import ExternalLink from '../ExternalLink';
import { riskType as editableRiskType } from '../../utils/ref/formConstants';

const mappedEditableRiskType = editableRiskType.reduce((a, c) => ({ ...a, [c]: c }),{})
const RiskDetails = (props) => {
  const { risks, riskTypes, isUpdating, updateAction, loading } = props;

  return (
    <Table celled collapsing>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Country</Table.HeaderCell>
          <Table.HeaderCell>Risk Type Description</Table.HeaderCell>
          <Table.HeaderCell width={10}>Risk Details</Table.HeaderCell>
          <Table.HeaderCell>Source URL</Table.HeaderCell>
          {isUpdating && (
            <Table.HeaderCell>To remove</Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {(risks || []).map((risk, riskIndex) => {
          const riskType = riskTypes[risk.riskTypeId] || {};
          return (
            <Table.Row key={riskIndex}>
              <Table.Cell>
                {riskType.country}
              </Table.Cell>
              <Table.Cell>
                {riskType.riskTypeDescStr}
              </Table.Cell>
              <Table.Cell>
                {risk.riskDetail}
              </Table.Cell>
              <Table.Cell>
                <ExternalLink
                  url={risk.sourceUrlStr}
                />
              </Table.Cell>
              {isUpdating && (
                <Table.Cell>
                {updateAction && mappedEditableRiskType[riskType.riskTypeDescStr] && (
                    <Button
                      onClick={updateAction}
                      icon
                      value={risk.riskTypeId}
                      loading={loading === risk.riskTypeId}
                      disabled={loading === risk.riskTypeId}
                    >
                      <Icon name='delete' />
                    </Button>
                  )}
                </Table.Cell>
              )}

            </Table.Row>

          )
        })}

      </Table.Body>
      {(risks || []).length === 0 && (
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan='10'>
              No risk details found
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  )
}

const mapStateToProps = state => {
  return {
    riskTypes: getSettings(state).riskTypes,
  }
}

export default withRouter(connect(mapStateToProps)(RiskDetails));
