import React, { Component } from 'react';
import styled from 'styled-components';
import { Form, Segment, Loader, Input, Message, Dimmer, Divider } from 'semantic-ui-react';
import auth from '../utils/auth';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #445877;
`;

const HeaderWrapper = styled.div`
  padding: 10px 20px;
  background: #445877;
  border-radius: 2px;
  margin-bottom: 10px;
  > img {
    width: 150px;
    vertical-align: middle;
  }
  > * {
    display: inline-block;
  }
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.input = {};
    this.login = this.login.bind(this);
  }

  handleChange(e, { value, name }) {
    this.input[name] = value;
    this.setState(prevState => ({
      input: Object.assign({}, prevState.input, {
        [name]: value,
      })
    }))
  }

  async login() {
    this.setState(() => ({
      loading: true,
    }))
    const success = await auth.login(this.input);
    if (!success) {
      this.setState(() => ({
        error: 'Unable to login.',
        input: {},
        loading: false,
      }))
      // this.props.setAuthState(userId, permissions);

    } else {
      this.props.onAuthStateChange(success);
    }
  }

  render() {
    const { error, input, loading } = this.state;

    return (
      <Wrapper>
        {loading && (
          <Loader size='large' active>Please wait...</Loader>
        )}
        <Dimmer.Dimmable as={Segment} blurring dimmed={loading} raised>
          {error && (
            <Message negative>{error}</Message>
          )}
          <HeaderWrapper>
            <img src={`/datarama_logo.png`} alt='datarama' />
          </HeaderWrapper>
          <Divider />
          <Form onSubmit={this.login}>
            <Form.Group>
              <Form.Field>
                <Input placeholder='Email' name='email' value={input.email || ''} onChange={this.handleChange} />
              </Form.Field>
              <Form.Field>
                <Input
                  placeholder='Password'
                  name='password'
                  type='password'
                  onChange={this.handleChange}
                  value={input.password || ''}
                />
              </Form.Field>
              <Form.Button content='Login' />
            </Form.Group>
          </Form>
        </Dimmer.Dimmable>
      </Wrapper>
    );
  }
}

export default Login;
