import React from 'react';

export default ({ col, value }) => {
  const colorSpectrum = col.input.colorSpectrum || {};
  const color = colorSpectrum[value] || '#444';

  return (
    <span>
      <span style={{
        color: color,
        transition: 'all .3s ease'
      }}>
        &#x25cf;
      </span> {value || 'N/A'}
    </span>

  )
}
