import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router'
import { Header, Step, Grid, Segment, Table, Form, Icon, Button, Divider, Label, Message, Loader, Dimmer } from 'semantic-ui-react';
import getEntityModel from '../../model/entity/getEntityModel';
import { checkIfValidEntityType } from '../../utils/routeHelper';
import { getDisplayType } from '../../utils/conversion/entityType';

import Text from './fields/Text';
import DateField from './fields/DateField';
import { findDataramaId, validateEntity, addNewUpdateEntry } from '../../utils/api';
import FIELDS from '../../model/entity/entityName';
import * as tableUtils from '../../utils/tableUtils';
import UpdatedMessage from './UpdatedMessage';
import Input from '../Input';
import DtrmID from '../DtrmID';
import ValueFromState from '../ValueFromState';
import { stringCleaning } from '../../utils/conversion/specialCharacter';

const Wrapper = styled.div`
  padding-top: 10px;
`;

const FullHeight = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 5 !important;
  right: 0;
`;

const LoaderWrap = styled.div`
  position: relative;
`;
const Scrollable = styled.div`
  overflow: auto;
  margin-bottom: 10px;
`;
const NABlock = styled.span`
  color: #ccc;
`;
const Row = styled.div`
  > div:first-of-type {
    width: 30%;
    background: #ececec;
    font-weight: 600;
  }
  > div {
    display: inline-block;
    padding: 5px 10px;
  }
  margin-bottom: 5px;
`;

const ActionWrapper = styled.div`
   > * {
     display: inline-block;
   }
   width: 100%;
   text-align: center;
`;

const NO_DATA = {
  dataramaId: 'Cannot find Datarama ID %s. Try different entity type or different ID',
  name: 'Cannot find Entity. Try another name'
}

class UpdateEntity extends React.Component {
  constructor(props){
    super(props);
    let loading = {};
    if (this.props.location.state && this.props.location.state.entityId) {
      loading.all = true;
    }
    this.state = {
      completed: {},
      activeStep: 'validate',
      validating: null,
      errors: {},
      noData: null,
      searchFields: {},
      loading,
      fieldsRestriction: {},
      findByNameResults: null,
      updatedEntity: null,
    };
    this.input = {};
    this.setGroupFeilds = this.setGroupFeilds.bind(this);
    this.updateInputValue = this.updateInputValue.bind(this);
    this.findEntityByDataramaId = this.findEntityByDataramaId.bind(this);
    this.handleDismissMessage = () => {
      this.setState(() => ({
        noData: null,
      }))
    }

    this.clearValidate = (e, { value }) => {
      let clearNameSearchResults = true;
      if (value && value === 'result') {
        clearNameSearchResults = false;
      }
      this.setState(prevState => ({
        validating: null,
        isValidating: false,
        updatedEntity: null,
        completed: {},
        activeStep: 'validate',
        findByNameResults: clearNameSearchResults ? null : prevState.findByNameResults,
      }))
    }
    this.findEntity = this.findEntity.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.getRestrictedFieldsByCountry = this.getRestrictedFieldsByCountry.bind(this);
    this.validateForm = (e, { value }) => {
      // if cancel form (edit form again) => status must be false;
      const shouldProceedValidation = value === 'cancel' ? false : true;
      const fields = this.baseEntity && this.baseEntity.getFields();
      this.setState(prevState => {
        if (shouldProceedValidation) {
          const outputFields = Object.keys(fields).reduce((a, fieldName) => {
            const field = fields[fieldName];
            if (field.required && !this.input[fieldName]) {
              a.errors[fieldName] = true;
            }
            if (this.input[fieldName]) {
              a.fields[fieldName] = this.input[fieldName];
            }
            return a;
          }, { errors: {}, fields: {}})
          if (Object.keys(outputFields.errors).length > 0) {
            return {
              errors: outputFields.errors
            }
          }
          window.scrollTo(0, 0);
        }
        return {
          isValidating: shouldProceedValidation,
          completed: Object.assign({}, prevState.completed, {
            inputForm: shouldProceedValidation,
          }),
          activeStep: shouldProceedValidation ? 'confirm' : 'inputForm'
        }
      })
    }

    this.updateEntity = this.updateEntity.bind(this);
  }

  componentDidMount() {
    const { entityId, type } = this.props.location.state || {};
    if (entityId && type) {
      this.findEntityByDataramaId(type, entityId);
    }
    this.setGroupFeilds();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.type !== this.props.match.params.type) {
      this.setGroupFeilds();
    }
  }
  getRestrictedFieldsByCountry(country) {
    const { validating } = this.state;
    // restrictedFields are fields that should NOT be displayed per selected country
    const restrictedFields = Object.keys(this.fieldRestrictionByCountry).reduce((a, field) => {
      if (!this.fieldRestrictionByCountry[field][country]) {
        this.input[field] = null;
        a[field] = true;
      } else {
        if (validating) {
          this.input[field] = validating[field];
        }
      }
      return a;
    }, {});
    return restrictedFields;
  }
  handleFieldChange(e, { name, value }) {
    if (name === 'country') {
      this.setState(() => ({
        fieldsRestriction: this.getRestrictedFieldsByCountry(value),
      }));
    }
    if (this.state.errors[name]) {
      this.setState(prevState =>({
        errors: Object.assign({}, prevState.errors, {
          [name]: false,
        })
      }))
    }
    if (!value) {
      this.input[name] = null;
      // delete this.input[name];
    } else {
      this.input[name] = value;
    }
  }

  async findEntity (e, { name }) {
    const { match: { params } } = this.props;
    const { type } = params;
    if (name === 'name') {

      let error = false;
      const body = Object.keys(FIELDS[type]).reduce((a, field) => {
        if (this.input[field]) {
          if (this.input[field].length < 3) {
            error = true;
          }
          a[field] = this.input[field];
        }
        return a;
      }, {});
      if (error) return;
      this.setState(() => ({
        loading: {
          findByName: true,
        },
      }))
      const result = await validateEntity(type, Object.assign({}, body, {
        scope: ['dtrm']
        // scope: ['dtrm', 'es']
      }));

      this.setState(() => {
        return {
          findByNameResults: result,
          loading: {},
        }
      })
    } else if (name === 'dataramaId') {
      const id = this.input[name];
      this.findEntityByDataramaId(type, id);
    }
  }

  async findEntityByDataramaId(type, id, keepSearchResults) {
    this.setState(() => ({
      loading: {
        findById: true,
      },
    }))
    const result = await findDataramaId(type, id);
    if (result) {
      window.scrollTo(0, 0);
      const output = this.baseEntity.transformData(result);
      this.input = Object.assign({}, output);
      const fieldsRestriction = this.getRestrictedFieldsByCountry(output.country);
      this.setState(prevState => ({
        validating: Object.assign({}, output),
        fieldsRestriction,
        updatedEntity: null,
        findByNameResults: keepSearchResults ? prevState.findByNameResults : null,
        activeStep: 'inputForm',
        completed: Object.assign({}, prevState.completed, {
          validate: true,
        }),
        loading: {}
      }))
    } else {
      this.setState(() => ({
        noData: NO_DATA.dataramaId.replace(/%s/g, id),
        loading: {}
      }))
    }
  }

  async updateEntity() {
    if (this.props.projectId) {
      const fields = this.baseEntity.getFields();

      const parsedPayload = JSON.parse(JSON.stringify(this.input));
      Object.keys(parsedPayload).forEach((key) => {
        if (parsedPayload[key] == null) {
          delete parsedPayload[key]
        } else {
          parsedPayload[key] = stringCleaning(fields[key] && fields[key].type, parsedPayload[key])
        }
      });

      this.setState(() => ({
        loading: {
          update: true,
        }
      }));
      const result = await addNewUpdateEntry(this.props.projectId, {...parsedPayload,
        entityType: this.baseEntity.getEntityType(),
        lastCopy: this.state.validating,
      })
      this.setState(prevState => {
        if (result) {
          const updatedOutput = this.baseEntity.transformData(result);
          window.scrollTo(0, 0);
          return {
            activeStep: 'completed',
            completed: Object.assign({}, prevState.completed, {
              confirm: true,
            }),
            updatedEntity: updatedOutput,
            loading: {}
          }
        }
        return {
          loading: {}
        }
      })
    }

  }


  setGroupFeilds() {
    const { match: { params } } = this.props;
    const { type } = params;
    this.baseEntity = getEntityModel(type);

    const groupedFields = this.baseEntity && this.baseEntity.getNewEntityGroupedFields();
    this.fieldRestrictionByCountry = (this.baseEntity && this.baseEntity.getRestrictedFieldsByCountry()) || {};

    this.setState(() => ({
      groupedFields,
      searchFields: {},
      noData: null,
      updatedEntity: null,
      completed: {},
      activeStep: 'validate',
      validating: null,
      errors: {},
    }))
  }

  updateInputValue(e, { name, value }) {
    this.input[name] = value;
    this.setState(prevState => ({
      searchFields: Object.assign({}, prevState, {
        [name]: value,
      })
    }))
  }

  render() {
    const { match: { params } } = this.props;
    const { type, projectId } = params;
    if (!checkIfValidEntityType(type)) {
      return <Redirect to={`/project/${projectId}/actions`} />
    }

    const displayType = getDisplayType(type);

    const { groupedFields,
        completed,
        activeStep,
        validating,
        isValidating,
        errors,
        noData,
        loading,
        fieldsRestriction,
        findByNameResults,
        updatedEntity,
      } = this.state;


    const cellContent = (col, value, dataramaId, disabled) => {
      if (col.isBoolean) {
        return value ? 'Yes' : 'No'
      }
      if (col.isStateValue) {
        return <ValueFromState field={col.stateKey} value={value} displayField={col.stateValue} />
      }
      if (col.isLink && !disabled) {
        return (
          <DtrmID id={value} type={type}/>
        )
      }
      return value;
    }

    return (
      <React.Fragment>
        {(loading.all || loading.findById) && (
          <FullHeight>
            <Dimmer active>
              <Loader content='Loading' />
            </Dimmer>
          </FullHeight>
        )}
        <Wrapper>
          <Scrollable>
            <Scrollable>
              <Step.Group widths={3} size='small' ordered>
                <Step completed={completed.validate} active={activeStep === 'validate'}>
                  <Step.Content>
                    <Step.Title>Find {displayType}</Step.Title>
                    <Step.Description>To get {displayType} from database to be updated</Step.Description>
                  </Step.Content>
                </Step>
                <Step completed={completed.inputForm} active={activeStep === 'inputForm'} disabled={!completed.validate}>
                  <Step.Content>
                    <Step.Title>Update Details</Step.Title>
                    <Step.Description>Enter updated details of entity</Step.Description>
                  </Step.Content>
                </Step>
                <Step completed={completed.confirm} active={activeStep === 'confirm'} disabled={!completed.inputForm}>
                  <Step.Content>
                    <Step.Title>Confirm Details</Step.Title>
                    <Step.Description>Check updated details before updating</Step.Description>
                  </Step.Content>
                </Step>
              </Step.Group>
            </Scrollable>
            <div>
            {noData && (
              <Message
                error
                onDismiss={this.handleDismissMessage}
              >{noData}</Message>
            )}
              {activeStep === 'completed' && (
                <div>
                  <Message positive>
                    <Message.Header>Successfully Updated the entity</Message.Header>
                    {updatedEntity && (
                      <div>
                        <UpdatedMessage entityId={updatedEntity.entityId} type={type} />
                      </div>
                    )}
                    <Segment>
                    {updatedEntity && groupedFields && groupedFields.map((group, groupId) => (
                      <React.Fragment key={`group-${groupId}`}>
                        {group.map(field => !fieldsRestriction[field.id] && (
                          <Row key={field.id}>
                            <div>{field.Header}</div>
                            <div>
                              {typeof field.accessor === 'function' ? field.accessor(updatedEntity) : (
                                updatedEntity[field.id]
                              ) || (<NABlock>N/A</NABlock>)}
                            </div>
                          </Row>
                        ))}
                      </React.Fragment>

                    ))}
                    </Segment>

                  </Message>
                  <Button onClick={this.clearValidate} size='large' color='green' fluid>Update another Entity</Button>
                </div>
              )}
              {activeStep === 'validate' && (
                <Segment placeholder>
                  <Grid columns={2} stackable textAlign='center'>
                    <Grid.Row  stretched verticalAlign='middle'>
                      <Grid.Column>
                        <Header icon>
                          Find<Label color='black'>{displayType}</Label> by Datarama ID
                        </Header>
                        <Form onSubmit={this.findEntity} name='dataramaId'>
                          <Text
                            field={{
                              Header: 'Datarama ID',
                              id: 'dataramaId',
                            }}
                            entityType={type}
                            handleFieldChange={this.updateInputValue}
                            defaultValue={''}
                            type="number"
                          />
                          <Button type="submit" loading={loading.findById} disabled={loading.findById}>Find {displayType}</Button>
                        </Form>
                      </Grid.Column>
                      <Grid.Column>
                        <Header icon>
                          Find by Name
                        </Header>
                        <Form onSubmit={this.findEntity} name='name'>
                          {FIELDS[type] && Object.keys(FIELDS[type]).map(field => (
                            <Text
                              key={field}
                              field={{
                                Header: FIELDS[type][field].label,
                                id: field,
                                required: FIELDS[type][field].required,
                                minLength: 2,
                              }}
                              entityType={type}
                              handleFieldChange={this.updateInputValue}
                              defaultValue={''}
                            />
                          ))}
                          <Button type="submit" loading={loading.findByName} disabled={loading.findByName}>Find {displayType}</Button>
                        </Form>
                      </Grid.Column>
                      <Divider vertical>Or</Divider>
                    </Grid.Row>
                  </Grid>
                </Segment>
              )}
              {(activeStep === 'inputForm' || activeStep === 'confirm') && validating && (
                <React.Fragment>
                    {activeStep === 'inputForm' && (
                      <div>
                        {findByNameResults && findByNameResults.dtrm && (
                          <Button icon labelPosition='left' basic size='small' value="result" onClick={this.clearValidate}>
                            <Icon name='left arrow' />
                            Back to last search results
                          </Button>

                        )}
                        <Button onClick={this.clearValidate} basic size='small'><Icon name='arrow left' />Find another Entity instead.</Button>
                      </div>
                    )}
                    <Segment>
                    {activeStep === 'confirm' && (
                      <Message warning>
                        Please check the following details if correct before submitting.
                      </Message>
                    )}
                    <Form onSubmit={this.validateForm}>
                      <Form.Group>
                        <Text
                          field={{
                            Header: 'Datarama ID',
                            id: 'entityId',
                          }}
                          defaultValue={validating.entityId || ''}
                          disabled
                        />
                      </Form.Group>
                      {groupedFields && groupedFields.map((group, groupId) => (
                        <Form.Group widths='equal' key={`${type}-${groupId}`}>
                          {group.map(field => {
                            if (!field.input) return null;
                            if (fieldsRestriction[field.id]) return null;
                            switch (field.input.type) {
                              case 'dropdown':
                                return (
                                  <Input
                                    key={field.id}
                                    field={field}
                                    isValidating={isValidating}
                                    defaultValue={validating[field.id] || ''}
                                    handleFieldChange={this.handleFieldChange}
                                  />)
                              case 'date':
                                return (
                                  <DateField
                                    key={field.id}
                                    field={field}
                                    isValidating={isValidating}
                                    error={errors[field.id]}
                                    defaultValue={validating[field.id] || ''}
                                    handleFieldChange={this.handleFieldChange}
                                  />
                                )
                              default:
                                return (
                                  <Text
                                    key={field.id}
                                    field={field}
                                    error={errors[field.id]}
                                    defaultValue={validating[field.id] || ''}
                                    isValidating={isValidating}
                                    handleFieldChange={this.handleFieldChange}
                                  />
                                )
                            }
                          })}
                        </Form.Group>
                      ))}
                      {!isValidating && (
                        <Button type='submit' size='large' fluid color='green'>Proceed to confirm updating new {displayType}</Button>
                      )}
                    </Form>
                    {isValidating && (
                      <ActionWrapper>
                      <Divider section />
                        <Button onClick={this.updateEntity} size='large' color='green' loading={loading.update} disabled={loading.update} fluid>Confirm and Update</Button>
                        <br/>
                        <Button onClick={this.validateForm} size='large' color='grey' basic value="cancel" disabled={loading.update} fluid>Edit Again</Button>
                      </ActionWrapper>
                    )}
                  </Segment>
                </React.Fragment>

              )}

              {activeStep === 'validate' && findByNameResults && findByNameResults.dtrm && (
                <React.Fragment>
                  <Divider horizontal>
                    <Header as='h3'>
                      Find by name results ({findByNameResults.dtrm ? findByNameResults.dtrm.length : 0})
                      <Header.Subheader>Select Datarama ID by clicking respective row</Header.Subheader>
                    </Header>
                  </Divider>
                  <LoaderWrap>
                  {loading.findById && (
                    <Loader active>Loading...</Loader>
                  )}
                  </LoaderWrap>

                  <Table celled striped selectable className='table-selectable'>
                    <Table.Header>
                      <Table.Row>
                        {tableUtils.dtrmColumns[type].map(col => (
                          <Table.HeaderCell
                            key={col.label}
                            rowSpan={col.items ? 1 : 2}
                            colSpan={col.items ? col.items.length : 1}
                          >{col.label}</Table.HeaderCell>
                        ))}
                      </Table.Row>
                      <Table.Row>
                        {tableUtils.dtrmColumns[type].map(col => col.items ? col.items.map(item => (
                          <Table.HeaderCell key={item.value}>{item.label}</Table.HeaderCell>
                        )) : null)}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {findByNameResults.dtrm.map(match => (
                        <Table.Row key={match.entityId} onClick={() => { this.findEntityByDataramaId(type, match.entityId, true)}}>
                          {tableUtils.dtrmColumns[type].map(col => col.items ? col.items.map(item => (
                            <Table.Cell key={item.value}>
                              {cellContent(item, match[item.value], match.entityId)}
                            </Table.Cell>
                          )) : (
                            <Table.Cell key={col.value}>
                              {cellContent(col, match[col.value], match.entityId, match.doNotUseFlag)}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      ))}
                    </Table.Body>
                    {findByNameResults.dtrm.length === 0 && (
                      <Table.Footer fullWidth>
                        <Table.Row>
                          <Table.HeaderCell colSpan='10'>
                              No results found
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    )}
                  </Table>
                </React.Fragment>
              )}
            </div>
          </Scrollable>
        </Wrapper>
      </React.Fragment>
    )
  }
}

export default UpdateEntity;
