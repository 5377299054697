const CONSTANTS = {
  newRelationshipGroupFields: [
    ['entity1Id', 'entity2Id'],
    ['courtEntityId','caseNumStr', 'caseDetailsStr'],
    ['decisionStatusStr', 'decisionStr', 'decisionDateStr'],
    ['sourceUrlStr', 'fullSource', 'sourceStr'],
  ],
  entryType: {
    Header: 'Entry Type',
    id: 'entryType',
    accessor: 'entryType',
    type: 'string',
  },
  entity1Id: {
    Header: 'Plaintiff Id(s)',
    id: 'entity1Id',
    accessor: 'entity1Id',
    type: 'integer',
    required: true,
    displayTransform: (data) => {
      if (Array.isArray(data)) {
        return data.join(',')
      }
      return data;
    },
    input: {
      type: 'dropdown',
      search: true,
      selection: true,
      allowAdditions: true,
      multiple: true,
      fieldType: 'number',
    }
  },
  entity2Id: {
    Header: 'Defendent Id(s)',
    id: 'entity2Id',
    accessor: 'entity2Id',
    type: 'integer',
    required: true,
    displayTransform: (data) => {
      if (Array.isArray(data)) {
        return data.join(',')
      }
      return data;
    },
    input: {
      type: 'dropdown',
      search: true,
      selection: true,
      allowAdditions: true,
      multiple: true,
      fieldType: 'number',
    }
  },
  courtEntityId: {
    Header: 'Court Entity Id',
    id: 'courtEntityId',
    accessor: 'courtEntityId',
    type: 'integer',
    required: true,
    input: {
      type: 'text',
      meta: {
        type: 'number'
      }
    }
  },
  sourceStr: {
    Header: 'Source',
    id: 'sourceStr',
    accessor: 'sourceStr',
    type: 'string',
    required: true,
    input: {
      type: 'dropdown',
      source: 'sourceList'
    }
  },
  caseNumStr: {
    Header: 'Case Number',
    id: 'caseNumStr',
    accessor: 'caseNumStr',
    type: 'string',
    required: true,
    input: {
      type: 'text',
      meta: {
        message: 'If case number is not known, please put N/A instead. However putting N/A will disable case to be edited unless via admin.'
      }
    }
  },
  caseDetailsStr: {
    Header: 'Case details',
    id: 'caseDetailsStr',
    accessor: 'caseDetailsStr',
    type: 'string',
    required: true,
    update: true,
    input: {
      type: 'text',
    }
  },
  sourceUrlStr: {
    Header: 'Source Url',
    id: 'sourceUrlStr',
    accessor: 'sourceUrlStr',
    type: 'string',
    required: true,
    update: true,
    input: {
      type: 'text'
    }
  },
  fullSource: {
    Header: 'Full Source',
    id: 'fullSource',
    accessor: 'fullSource',
    type: 'string',
    input: {
      type: 'text'
    }
  },
  decisionStatusStr: {
    Header: 'Decision Status',
    id: 'decisionStatusStr',
    accessor: 'decisionStatusStr',
    type: 'string',
    required: true,
    update: true,
    input: {
      type: 'dropdown',
      source: 'decisionStatus'
    }

  },
  decisionStr: {
    Header: 'Decision',
    id: 'decisionStr',
    accessor: 'decisionStr',
    update: true,
    type: 'string',
    input: {
      type: 'text'
    }
  },
  decisionDateStr: {
    Header: 'Decision Date',
    id: 'decisionDateStr',
    accessor: 'decisionDateStr',
    type: 'string',
    update: true,
    input: {
      type: 'date'
    }
  },
  creator: {
    Header: 'Created by',
    id: 'creator',
    accessor: 'creator',
    type: 'string',
    input: {
      type: 'text',
    }
  },
  ins_date: {
    Header: 'Created at',
    id: 'ins_date',
    accessor: 'ins_date',
    type: 'string',
  },
  ins_name: {
    Header: 'Created by',
    id: 'ins_name',
    accessor: 'ins_name',
    type: 'string'
  },
  upd_date: {
    Header: 'Updated at',
    id: 'upd_date',
    accessor: 'upd_date',
    type: 'string',
  },
  upd_name: {
    Header: 'Updated by',
    id: 'upd_name',
    accessor: 'upd_name',
    type: 'string'
  },
  sourceId: {
    Header: 'source id',
    id: 'sourceId',
    accessor: 'sourceId',
    type: 'integer'
  },
  entryId: {
    Header: 'source id',
    id: 'sourceId',
    accessor: 'sourceId',
    type: 'integer'
  },
};

let instance = null;

export class TempLitigation {

  static get CONSTANTS() {
    return CONSTANTS;
  }

  constructor() {
    if(!instance){
      instance = this;
    }

    return instance;
  }

  get newRelationshipGroupedFields() {
    return TempLitigation.CONSTANTS.newRelationshipGroupFields.map(group => {
      return group.map(field => this.fieldDetails[field]);
    });
  }

  get displayColumns() {
    return Object.keys(this.displayFieldDetails).map(key => this.displayFieldDetails[key]);
  }

  get editFormFields() {
    const {
      caseDetailsStr,
      decisionStatusStr, decisionStr, decisionDateStr,
      sourceUrlStr } = TempLitigation.CONSTANTS;
    return {
      caseDetailsStr,
      decisionStatusStr, decisionStr, decisionDateStr,
      sourceUrlStr }
  }

  get fieldDetails() {
    const {
      entryId,
      entity1Id, entity2Id,
      courtEntityId, caseNumStr, caseDetailsStr,
      decisionStatusStr, decisionStr, decisionDateStr,
      sourceUrlStr, fullSource,
      sourceId,
      sourceStr, creator } = TempLitigation.CONSTANTS;
    return {
      entryId,
      entity1Id, entity2Id,
      courtEntityId,caseNumStr, caseDetailsStr,
      decisionStatusStr, decisionStr, decisionDateStr,
      sourceUrlStr, fullSource, sourceId,
      sourceStr, creator };
  }

  get displayFieldDetails() {
    const {
      entryType,
      entity1Id, entity2Id,
      courtEntityId,caseNumStr, caseDetailsStr,
      decisionStatusStr, decisionStr, decisionDateStr,
      sourceUrlStr, fullSource,
      sourceStr, creator,
      ins_date, ins_name,
      upd_date, upd_name } = TempLitigation.CONSTANTS;
    entity1Id.header = 'Plaintiff ID';
    entity2Id.header = 'Defendant ID';
    return {
      entryType,
      entity1Id, entity2Id,
      courtEntityId,caseNumStr, caseDetailsStr,
      decisionStatusStr, decisionStr, decisionDateStr,
      sourceUrlStr, fullSource,
      sourceStr, creator,
      ins_date, ins_name,
      upd_date, upd_name }
  }
}
