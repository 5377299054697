import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { CONCEPTS, LANGUAGES } from './constants';
import { getRiskIndicators } from '../../utils/api';
import Loading from '../Loading';

import {
  Btn,
  FormatSummaryRow,
  FormatSummaryTable,
  Modal,
  Term
} from './styles.js';

export function ViewBucketsWidget({
  handleViewBuckets,
  viewBuckets
}) {

  const [buckets, setBuckets] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetching() {
      setLoading(true);
      const results = await getRiskIndicators(viewBuckets.bucket);
      setBuckets(results);
      setLoading(false);
      return () => {
        new AbortController().abort();
      };
    }
    fetching();
  }, []);

  return(
    <Modal>
      <div>
        <FormatSummaryTable>
          <tbody>
            <FormatSummaryRow>
              <td>
                <h3>{ CONCEPTS.filter(c => c.code === viewBuckets.bucket)[0].label }</h3>
              </td>
              <td>
                <Button
                  onClick={ handleViewBuckets }
                  value={ null }
                  label="Close"
                  component={ Btn } />
              </td>
            </FormatSummaryRow>
          </tbody>
          { loading &&
            <Loading active={true} /> }
          <tbody>
          { !loading &&
            buckets.map(buck => {
              let key = Object.keys(buck)[0];
              let value = buck[key];
              return (
                <tr key={key}>
                  <h3>
                    { LANGUAGES.filter(c => c.code === key)[0].label } ({value.length} terms)
                  </h3>
                  { value.map((k, idx) => (
                    <Term key={`${k.term}-${idx}`}>
                      {k.term.replace(/_/g, ' ')}
                    </Term>)) }
                  <br /><br />
                </tr>
              )}) }

          </tbody>
        </FormatSummaryTable>
      </div>
    </Modal>
  )
}