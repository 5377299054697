/* eslint-disable no-mixed-operators */
import styled, { keyframes } from 'styled-components';

const FinePrint = styled.small`
  color: rgba(0,0,0,.5);
`;

const Icon = styled.span`
	font-family: 'Material Icons';
  vertical-align: middle;
  color: ${({ color }) => color ? color : 'black'}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: ${({ col }) => col || '50% 50%'};
`;

const Tabs = styled.div`
  border-bottom: 1px solid #ccc;
`;

const TabBtn = styled.button`
  padding: 5px 20px;
  font-family: inherit;
  font-size: 15px;
  border-radius: 2px;
  border: 0px;
  border-bottom: 2px solid transparent;
  background: #f2cc04;
  outline: none;
  ${({ selected }) => selected ? `
    font-weight: 600;
    border-bottom: 2px solid #444;
  ` : `
    background: #faeb9d;
    color: #666;
  `}
  cursor: pointer;
  margin: 0 15px -1px 10px;
`;

const RequestQueue = styled.div`
  display: inline-block;
  background: #ececec;
  padding: 10px;
`;

const ArticleActions = styled.div`
  display: inline-block;
`;

const UtilsBar = styled(Grid)`
  > div:last-of-type {
    text-align: right;
  }
  margin-bottom: 10px;
`;
const Nametag = styled.span`
  padding-right: 5px;
`;

const Warning = styled.div`
  padding: 10px;
  background: #f13c3c;
  color: #fff;
`;
const Duplicates = styled.span`
  padding: 3px 10px;
  font-size: 10px;
  border: 1px solid #e24242;
  color: #e24242;
  background: #fff;
  font-weight: 600;
  border-radius: 2px;
`;
const Statistics = styled(Grid).attrs({ col: '80% 20%'})`
  > div:last-of-type {
    padding: 10px;
  }
`
const SubHeader = styled.h3`
	padding: 10px 0;
	margin: 0;
	font-size: 20px;
`;

const SummaryRow = styled(Grid).attrs({ col: '60% 20% 20%'})`
  ${({ header }) => header && `
    font-weight: 600;
    background: #ececec;
  `}
  padding: 5px;
`;

const SearchGrid = styled(Grid).attrs({ col: '40% 60%'})`
  > div {
    vertical-align: middle;
  }
  > div:first-of-type {
  }
  select {
    width: 160px !important;
  }
`;

const Term = styled.div`
  padding: 2px 15px;
  margin: 0 10px 10px 0;
  display: inline-block;
  border-radius: 20px;
  font-size: 13px;
  background: #ececec;
  user-select: none;
`;
const Summary = styled.div`
  border: 1px solid #ccc;
  margin: 10px 0;
  display: inline-block;
`;
const FieldHint = styled.div`
  font-size: 12px;
  color: #666;
`;
const PageFetchHint = styled.div`
  background: #ffe000;
  margin-bottom: 5px;
  padding: 5px;
  font-size: 12px;
`;
const Align = styled.div`
  ${({ right }) => right && `
    text-align: right;
  `}
`;

const UploadSummary = styled.div`
  display: inline-block;
  width: 25%;
  vertical-align: top;
  border: 1px solid #ccc;
  margin: 10px 0 10px 10px;
`;
const Hint = styled.div`
  margin-top: 10px;
  border-bottom: 1px solid #444;
  margin-bottom: 5px;
  background: #ececec;
  padding: 5px 10px;
`;
const InfoRow = styled(Grid).attrs({ col: '40% 60%'})`
  margin-bottom: 5px;
  padding: 5px;
  ${({ highlight }) => highlight && `
    background: ${highlight};
  `}
`;
const Settings = styled.div`
  > div {
    display: inline-block;
  }
  padding: 10px;
  user-select: none;
  background: #ecb40d;
  border-radius: 3px;
  margin-bottom: 10px;
`;
const FormatTool = styled(Grid).attrs({ col: '50% 50%'})`
  > div:last-of-type {
    text-align: right;
  }
`;
const A4Wrapper = styled.div`
  width: 16cm;

`;
const LangOpt = styled.div`
  select, input {
    display: inline-block;
  }
  > div {
    select {
      width: 200px !important;
    }
  }
`;
const FormatHeader = styled.h1`
  font-family: 'Neuzeit Grotesk', 'Karla', sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #df600d;
`;
const FormatSummaryTable = styled.table`
  font-size: 11pt;
  table-layout: fixed;
  width: 100%;
  padding-bottom: 24px;
`;
const FormatRowTable = styled.table`
  font-size: 11pt;
  table-layout: fixed;
  width: 100%;
  margin-top: 30px;
`;
const HintTag = styled.div`
  font-size: 10px;
  text-align: center;
  margin-top: 10px;
`;
const Centered = styled.div`
  text-align: center;
`;
const FormatSummaryRow = styled.tr`
  font-family: 'Neuzeit Grotesk', 'Karla', sans-serif;
  &:nth-of-type(2n) {
    background: #d5e0ee;
  }
  > td {
    font-size: 11pt;
    padding: 5px;
    width: 50%;
  }
  > td:nth-of-type(1) {
    width: 70%;
  }
  > td:nth-of-type(2) {
    width: 30%;
    text-align: right;
  }

`;
const FormatRow = styled.tr`
  font-family: 'Neuzeit Grotesk', 'Karla', sans-serif;
  td {
    vertical-align: top;
  }
  ${({ header }) => header ? `
    font-weight: 600;
    padding: 5px;
    margin-top: 30px;
    > td:nth-of-type(1) {
      width: 18%;
    }
    > td:nth-of-type(2) {
      width: 55%;
    }
    > td:nth-of-type(3) {
      width: 20%;
    }

  ` : `
    > td {
      font-size: 11pt;
      padding: 15px 5px;
    }
    &:nth-of-type(2n+1) {
      background: #d5e0ee;
    }
`}`;

const RefLink = styled.div`
  margin-bottom: 5px;
`;
const ALink = styled.a`
  border-radius: 20px;
  padding: 5px 10px;
  white-space: nowrap;
  text-decoration: none !important;
  color: #fff;
  border: 1px solid #ccc;
  font-size: 12px;
  background: ${({ error }) => error ? '#bb2222' : '#22bb51'};
  &:hover {
    opacity: 0.7
  }
`;
const Tag = styled.div`
  display: inline-block;
  border-radius: 20px;
  padding: 5px 10px;
  white-space: nowrap;
  text-decoration: none !important;
  color: #fff;
  border: 1px solid #ccc;
  font-size: 12px;
  background: ${({ error, cancel }) => error && '#bb2222' || cancel && '#ececec' || '#22bb51'};
`;
const StatGrid = styled(Grid)`
  > div {
    font-size: 12px;
  }
`;

const Modal = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  > div {
    overflow: scroll;
    margin: auto;
    background: #fff;
    padding: 10px;
    width: 80vw;
    border: 1px solid #ececec;
    box-shadow: 0 0 20px #ccc;
    height: 80vh;
  }
`;
const ArticleRow = styled(Grid).attrs({ col: '15% 20% 30% 15% 15% 5%'})`
  ${({ header }) => header ? `
    font-weight: 600;
    background: #ececec;
  ` : `
    &:nth-of-type(2n+1) {
      background: #d5e0ee;
    }
  `}
  > div {
    word-wrap: break-word;
    padding: 5px;

  }
  ${({ disable }) => disable && `
    text-decoration: line-through;
    background: #444 !important;
  `}
`;

const BucketTag = styled.div`
  display: block;
  padding: 3px 10px;
  background: #ececec;
  margin-bottom: 5px;
  border-radius: 3px;
`;
const CountTag = styled.span`
  background: #fff;
  padding: 1px 5px;
  border-radius: 2px;
`;
const BucketRow = styled(Grid).attrs({ col: '20% 80%'})`
  > div:first-of-type {
    ${({ header }) => !header ? `
      background: #ccc;
      ${BucketTag} {
        font-size: 20px;
      }
    ` : `
      background: #ececec;
    `}
    ${Grid} {
      margin-bottom: 5px;
      > div:last-of-type {
        text-align: right;
      }
    }
  }
  > div {
    padding: 10px;
  }
  &:not(:first-of-type) {
    margin-bottom: 10px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 10px;

  }
`;
const OptionGrid = styled(Grid).attrs({ col: '50% 50%'})`
  margin: 5px 0;
  padding: 5px 10px;
  font-size; 12px;
  ${({ disable }) => disable && `
    text-decoration: line-through;
    background: #444;
  `}
`;
const InfoWrapper = styled.div`
  width: 500px;
`;

const Btn = styled.button`
  font-size: ${({ size }) => size || '15px'};
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
  font-family: inherit;
  border-radius: 3px;
  min-width: 100px;
  margin-right: 10px;
`;

const FormatBtn = styled(Btn)`
  font-size: 20px;
  background: #4a5f82;
  padding: 8px 20px;
  color: #fff;
`;

const blinking = keyframes`
	0% {
		opacity: 1
	}
  50% {
    opacity: 0.3
  }
	100% {
    opacity: 1
	}
`;

const LoadingTag = styled.div`
	animation: ${blinking} 1s linear infinite;
	margin: auto;
	text-align: center;
	font-size: 15px;
	background: #ececec;
`;


const UploadBtn = styled.button`
  padding: 7px 30px;
  cursor: pointer;
  outline: none;
  font-family: inherit;
  border-radius: 20px;
  font-size: 15px;
  background: #216ba5;
  border: 1px solid #ccc;
  margin-left: 20px;
  color: #fff;
  transition: 0.1s ease-in;
  &:hover {
    background: #ebb71d
  }
`;

const OptionBtn = styled(Btn).attrs({ size: '12px'})`
  ${({ selected }) => selected && ``}
  margin-right: 10px;
`;

const Form = styled(Grid)`
  input[type=text] {
    font-size: 15px;
    padding: 5px 10px;
    display: block;
    width: 250px;
    margin: 5px 0;
  }
  select {
    width: 300px;
    padding: 5px 10px;
    font-size: 15px;
    display: block;
    margin: 5px 0;
    option {
      padding: 5px 10px;
      font-size: 15px;
    }
  }
`;

export {
  Align,
  ArticleActions,
  ArticleRow,
  ALink,
  A4Wrapper,
  Btn,
  BucketRow,
  BucketTag,
  Centered,
  CountTag,
  Duplicates,
  FieldHint,
  FinePrint,
  Form,
  FormatBtn,
  FormatRow,
  FormatTool,
  FormatHeader,
  FormatRowTable,
  FormatSummaryRow,
  FormatSummaryTable,
  Grid,
  Hint,
  HintTag,
  Icon,
  InfoRow,
  InfoWrapper,
  LangOpt,
  LoadingTag,
  Modal,
  Nametag,
  OptionBtn,
  OptionGrid,
  PageFetchHint,
  RefLink,
  RequestQueue,
  SearchGrid,
  Settings,
  StatGrid,
  Statistics,
  SubHeader,
  Summary,
  SummaryRow,
  Tabs,
  TabBtn,
  Tag,
  Term,
  UploadBtn,
  UploadSummary,
  UtilsBar,
  Warning
}