import React, { useState, Fragment } from 'react';
import { Accordion, Header, Pagination, Divider, List } from 'semantic-ui-react';
import styled from 'styled-components';

const Title = styled.span`
padding-left: 10px;
font-weight: bold;
&:hover {
    text-decoration: underline;
  } 
`;

const TitleDetails = styled.span`
padding-left: 36px;
color: rgba(0,0,0,.5);
text-transform: capitalize;
`;

const ListTitle = styled.div`
padding-left: 24px;
text-transform: uppercase;
font-weight: bold;
`;

const ListContent = styled(List.Item)`
padding-left: 64px !important;
padding-bottom: 12px !important;
color: rgba(0,0,0,.5);
`;

export function SanctionsTable({ activePage, count, results, setActivePage, PAGE_LIMIT, handleFetchRecords }) {
  const [activeIndex, setActiveIndex] = useState({});
  const handleOnClick = (event, { index }) => {
    setActiveIndex(prevState => (
			{ ...prevState, [index]: prevState[index] ? !prevState[index] : true }
    ));
  }
  const handlePageChange = (activePage) => {
    setActivePage(activePage);
    handleFetchRecords(activePage);
  }

  return (
    <>
      <Divider horizontal>
        <Header as='h3'>
          Search results ({count ? count : 0})
        </Header>
      </Divider>
      { count > 5 && <><Pagination
          floated='right'
          size='mini'
          activePage={ activePage }
          totalPages={ Math.ceil(count / PAGE_LIMIT) }
          onPageChange={ (e, { activePage }) => handlePageChange(activePage) }
        /><br /><br /></> }
      {results.map(result => {
      return <Fragment key={result._id}>
        <Accordion>
          <Accordion.Title active={activeIndex[result._id]} index={result._id} onClick={handleOnClick}>
            <Title>{ `${result.name}` }</Title>
            { result.type !== null && <TitleDetails>{`(${result.type})`}</TitleDetails> }
          </Accordion.Title>
          <Accordion.Content active={activeIndex[result._id]}>
            <List bulleted>
              { Object.keys(result).map(item => {
                if (['_id', 'name'].indexOf(item) !== -1) return null;
                if (result[item] == null) return null;
                if (item === 'sourceUrl') {
                  return <>
                  <ListTitle>{item}</ListTitle>
                  <ListContent>
                    <a href={result[item]} target='_blank' rel='noopener noreferrer'>{result[item]}</a>
                  </ListContent></>
                }
                return <>
                <ListTitle>{item}</ListTitle>
                <ListContent>{result[item]}</ListContent></>
              }) }
            </List>
          </Accordion.Content>
      </Accordion>
      <Divider /></Fragment>
      })}
    </>
  )
}