import React from 'react';
import styled from 'styled-components';
import { Button, Input, Header, Segment, Divider } from 'semantic-ui-react';
import { withRouter, Route } from 'react-router';
import PEPBLacklistConfirmation from './Confirmation';
import Breadcrumb from './Breadcrumb';
import SearchByName from './SearchByName';

const Inline = styled.div`
  display: inline-block;
`;

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.selectEntityType = (e, { value }) => {
      this.props.history.push(`/research/pepblacklist/byId/${value}`);
      this.setState(() => ({
        entityType: value,
      }));
    }

    this.entityIdChange = (e, { value }) => {
      this.setState(() => ({
        entityId: value,
      }))
    }

    this.getDetails = async () => {
      const { entityId, entityType } = this.state;
      if (entityId && entityType) {
        this.props.history.push(`/research/pepblacklist/byId/${entityType}/${entityId}`)
      }
    }
  }
  render() {
    const {entityType} = this.state;
    const { location: { pathname } } = this.props;
    return (
      <div>
        <Route
          path='/research/pepblacklist'
          exact
          render={(props) => {
          return (
            <div>
              <Segment>
                <Header>For Datarama Entity - search by id</Header>
                <Breadcrumb />
                <Button
                  basic
                  onClick={this.selectEntityType}
                  value='person'
                  active={pathname === '/research/pepblacklist/byId/person'}>Person</Button>
                <Button
                  basic
                  onClick={this.selectEntityType}
                  value='company'
                  active={entityType === 'research/pepblacklist/byId/company'}>Company</Button>
              </Segment>
              <Divider />
              <Segment>
                <Header>For non-Datarama Entity</Header>
                <Button
                  basic
                  onClick={() => this.props.history.push('/research/pepblacklist/byName')}
                >Search by name</Button>
                <Button
                  basic
                  onClick={() => this.props.history.push('/research/pepblacklist/byNames')}
                >Search by Multiple Names</Button>
              </Segment>


            </div>
          )
        }} />
        <Route path='/research/pepblacklist/byName' exact render={() => <SearchByName { ...this.props } />} />
        <Route path='/research/pepblacklist/byNames' exact render={() => <SearchByName multiple { ...this.props } />} />

        <Route
          path='/research/pepblacklist/byId/:entityType'
          exact
          render={(props) => {
          const { entityType: pageEntityType } = props.match.params
          return (
            <div>
              <Breadcrumb />
              <Inline>
                <Input type="integer" placeholder={`Enter ${pageEntityType} Datarama Id`} onChange={this.entityIdChange} />
                <Button onClick={this.getDetails}>Search</Button>
              </Inline>
            </div>
          )
        }} />
        <Route path='/research/pepblacklist/byId/:entityType/:entityId' exact render={() => <PEPBLacklistConfirmation { ...this.props } />} />

      </div>
    )
  }
}

export default withRouter(Main);
