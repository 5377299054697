import React from 'react';

export function ContentTable({ report, lastPage }) {
  let rows = [
    { title: report.entityName, page: 3 },
    { title: 'Basic Information', page: 3 },
    { title: 'Adverse Media Analysis', page: 3 },
    { title: 'Blacklist and Political Exposure Checks', page: lastPage },
    { title: 'International blacklists/sanctions lists', page: lastPage },
    { title: 'Political Exposure', page: lastPage }
  ];

  return <>
  <div className='contentTable'>
    { rows.map((row, index) => {
      return <div className={`row-${index}`} key={ index } style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>{ row.title }</div>
        <span style={{ flex: 1, overflow: 'hidden' }}>...........................................................................................................................................................</span>
        <div>{ row.page }</div>
      </div>
    })}
  </div>
  </>

}