import React from 'react';
import { Route } from "react-router-dom";
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button, Header } from 'semantic-ui-react';
import FileUpload from './File';
import FindEntity from '../FindEntity';
import { getAuth } from '../../reducers/selectors';
import AccessDenied from '../AccessDenied';
import CustomNameSearch from './CustomNameSearch';
import { ENTITY_TYPE, ENTITY_BY_TYPE } from '../../utils/ref/entityType';

const Or = styled.span`
  font-weight: 600;
`;
const ButtonHeader = styled.div`
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  margin-right: 10px;
`;

class UploadIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entityType: null,
      displayResult: false,
      mode: null,
      selectedDtrmId: null,
      selectedDtrmEntity: null,
    }

    this.switchMode = (e, { value }) => {
      this.setState(() => ({
        mode: value,
        entityType: null,
        selectedDtrmId: null,
        selectedDtrmEntity: null,
        selectedCustomName: null,
        displayResult: false,
      }))
      this.props.history.push('/files/add');
    }

    this.selectEntityId = (type, value, entity) => {
      if (!value) return;
      this.setState(() => ({
        selectedDtrmId: value,
        selectedDtrmEntity: entity,
        displayResult: true,
      }))
    }

    this.selectEntityType = (e, { value }) => {
      this.setState(() => ({
        entityType: value,
        mode: 'search',
        selectedDtrmId: null,
        selectedDtrmEntity: null,
        selectedCustomName: null,
      }))
      this.props.history.push('/files/add/' + value)
    }

    this.setCustomName = this.setCustomName.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  async setCustomName(searchId) {
    this.setState(() => ({
      selectedCustomName: searchId,
      selectedDtrmId: null,
      selectedDtrmEntity: null,
    }))
  }

  cancel(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState(() => ({
      displayResult: false,
      selectedCustomName: null,
      selectedDtrmId: null,
      selectedDtrmEntity: null,
    }))
  }


  render() {
    const { entityType, displayResult, mode, selectedDtrmId, selectedCustomName, selectedDtrmEntity } = this.state;
    const { canUpload } = this.props;
    if (!canUpload) return (
      <AccessDenied
        action='upload'
        resourceType='document'
      />
    )


    return (
      <div>
        <Header as='h2'>
          Upload Document
          <Header.Subheader>Uploading documents and get public accessible URL</Header.Subheader>
        </Header>
        <ButtonHeader>Search</ButtonHeader>
        <React.Fragment>
        </React.Fragment>
        {[ENTITY_TYPE.person, ENTITY_TYPE.company, ENTITY_TYPE.family, ENTITY_TYPE.otherOrganisation, ENTITY_TYPE.governmentAgency].map(type => (
          <Button
            onClick={this.selectEntityType}
            basic
            key={type}
            value={type}
            active={entityType === type}
          >{ENTITY_BY_TYPE[type].header}</Button>

        ))}
        <Or> - OR - </Or>
        <Button
          onClick={this.switchMode}
          value="known" basic
          active={mode === 'known'}
          >Custom Folder Name</Button>
        {mode === 'known' && canUpload && (
          <CustomNameSearch
            setCustomName={this.setCustomName}
            cancel={this.cancel}
            customName={selectedCustomName}
          />
        )}
        {mode === 'search' && canUpload && (
          <Route path="/files/add/:type" render={(props) => (
            <FindEntity
              upload
              searchById
              searchByName
              selectEntityId={this.selectEntityId}
              displayResult={displayResult}
              {...props}
            />
          )} />
        )}
        {canUpload && (
          <FileUpload
            dataramaId={selectedDtrmId}
            customName={selectedCustomName}
            entity={selectedDtrmEntity}
            cancel={this.cancel}
          />
        )}
      </div>

    )
  }
}

const mapStateToProps = state => {
  const authState = getAuth(state);
  const permissions = (authState.permissions || {}).general;
  return {
    loggedInUser: authState.loggedInUser,
    canUpload: permissions && permissions['document.create'],
  }
};

export default withRouter(connect(mapStateToProps)(withRouter(UploadIndex)));
