import React from 'react';
import { Grid } from './styles';
import styled from 'styled-components';
import { Tag } from './Tag';
import moment from 'moment';
import { Button } from './Button';
import { EnglishArticle } from './EnglishArticle';

const Wrapper = styled(Grid).attrs({ col: '5% 10% 20% 35% 15% 15%'})`
  ${({ header }) => header && `
    font-weight: 600;
    background: #ececec;
  `}
  > div {
    word-wrap: break-word;
    padding: 5px;

  }
  ${({ disable }) => disable && `
    text-decoration: line-through;
    background: #444 !important;
  `}
`;

const PageTag = styled.div`
  text-align: center;
  font-size: 9px;
  display: inline-block;
  background: #462e2e;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  color: #fff;
  padding-top: 2px;
`;

const TermSummary = styled.div`
  h3 {
    margin: 0;
  }
  > div {
    display: inline-block;
    margin: 0 5px;
    > span {
      margin-right: 5px;
    }
  }
  padding: 10px 0;
`;

const HighlightedWord = styled.span`
  font-weight: 700;
  font-size: 15px;
  padding: 1px 5px;
  border-radius: 2px;
  margin-bottom: 5px;
  background: ${({ main }) => main ? '#696969' : '#f75353'};
  color: #fff;
`;
const Paragraph = styled.div`
  display: inline;
`;

const Extract = styled(Paragraph)`
  display: inline;
  b {
    border-bottom: 2px solid red;
  }
`;

const TextBtn = styled.button`
  font-family: inherit;
  border: 0;
  font-size: 12px;
  padding: 4px 15px 2px 15px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 2px;
  background: #f0ebeb;
  border-bottom: 2px solid #60bdff;
  &:hover {
    font-weight: 600;
  }
  outline: none;
`;

const Btn = styled.button`
  font-size: ${({ size }) => size || '15px'};
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
  font-family: inherit;
  border-radius: 3px;
  min-width: 100px;
  margin-right: 10px;
`;

const OptionBtn = styled(Btn).attrs({ size: '12px'})`
  ${({ selected }) => selected && ``}
  margin: 5px 0;
  ${({ color }) => color && `
    color: ${color};
  `}
  display: block;
  width: 100%;
`;

const ArticleActions = styled.div`
  display: inline-block;
`;

const NeutralTag = styled.span`
  margin: 5px;
  padding: 5px 10px;
  font-size: 10px;
  background: #70c51c;
  border-radius: 2px;
  ${({ warning }) => warning && `
    background: #ff2525;
  `}
`;

export function ArticleRow({
  mlSentences,
  articlesToIgnore,
  articlesNotAdverse,
  art,
  artIndex,
  category,
  deselectDuplicates,
  displayFullArticles,
  duplicates,
  getFullArticle,
  isNLP,
  loggedInUser,
  markNotAdverse,
  titleDuplicates,
  page,
  pageSize,
  searchScope,
  setValidation
}) {

  return <Wrapper disable={articlesToIgnore[art.uri]}>
    <div>
      <PageTag>{page * pageSize + artIndex + 1}</PageTag>
      <Tag label={(art.dataType || '').toUpperCase()} />
      <Tag label={art.lang} bordered />
    </div>
    <div>
      {moment(art.date).format()}
    </div>
    <div><b>{art.title}</b></div>
    <div>
      { (art.lang === 'eng' || art.lang === 'zho') &&
        <EnglishArticle
          article={art}
          isNLP={isNLP}
          category={category}
          loggedInUser={loggedInUser}
          mlSentences={mlSentences}
          searchScope={searchScope} /> }

      { !isNLP && art.lang !== 'eng' && art.lang !== 'zho' &&
      <><TermSummary>{ Object.keys(art.summary).map(term => {
        const refName = searchScope.name[art.lang];
        return <div key={term}>
          <HighlightedWord main={term.toLowerCase() === refName}>{term}</HighlightedWord>
          { art.summary[term].length }
        </div>}) }
      </TermSummary>
      <Extract dangerouslySetInnerHTML={{ __html: '...' + art.keyHighlight + '...' }} /></> }

      <br />
      <Button
        onClick={getFullArticle}
        value={art}
        label={displayFullArticles[art.id] ? '- Hide' : '+ Read full article'}
        component={TextBtn} />
    </div>
    <div>
      <a href={ art.link } target="_blank" rel='noopener noreferrer'>{ art.sourceName }</a>
    </div>
    <div>
      { articlesNotAdverse[art.uri] && <NeutralTag>#Not Adverse</NeutralTag> }
      { articlesToIgnore[art.uri] && <NeutralTag warning>#Irrelevant</NeutralTag> }

      {(articlesToIgnore[art.uri] || articlesNotAdverse[art.uri]) ? (
        <Button
          component={OptionBtn}
          label="Clear tags"
          onClick={setValidation}
          value={{ id: art.id, valid: true, clearBoth: true, uri: art.uri }} />
      ) : (
        <ArticleActions>
          <Button
            component={OptionBtn}
            label="Mark as Irrelevant"
            onClick={setValidation}
            value={{ id: art.id, valid: false, clearBoth: false, uri: art.uri }}
            meta={{
              color: '#ff2525'
            }}
          />
          <Button
            component={OptionBtn}
            label={'Marked as Related but not adverse'}
            onClick={markNotAdverse}
            value={{ id: art.id, valid: false, uri: art.uri }}
            meta={{
              color: '#70c51c'
            }}
          />

        </ArticleActions>
      )}
    </div>
  </Wrapper>
}