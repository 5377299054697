import { combineReducers } from 'redux';
import auth from './auth';
import settings from './settings';
import projects from './projects';

export default combineReducers({
  settings,
  auth,
  projects,
})
