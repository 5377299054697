const CONSTANTS = {
  newRelationshipGroupFields: [
    ['entityId', 'entity2Id'],
    ['sourceStr', 'sourceUrlStr'],
    ['relTypeStr', 'relDescStr'],
    ['relStartDateStr', 'relEndDateStr', 'shareholdingPercentage']
  ],
  entityId: {
    Header: 'Entity id 1',
    id: 'entityId',
    accessor: 'entityId',
    type: 'integer',
    required: true,
    input: {
      type: 'text',
      meta: {
        type: 'number'
      }
    }
  },
  entity2Id: {
    Header: 'Entity id 2',
    id: 'entity2Id',
    accessor: 'entity2Id',
    type: 'integer',
    required: true,
    input: {
      type: 'text',
      meta: {
        type: 'number'
      }
    }
  },
  sourceStr: {
    Header: 'Source',
    id: 'sourceStr',
    accessor: 'sourceStr',
    type: 'string',
    required: true,
    input: {
      type: 'dropdown',
      source: 'sourceList'
    }
  },
  relTypeStr: {
    Header: 'Relationship Type',
    id: 'relTypeStr',
    accessor: 'relTypeStr',
    required: true,
    input: {
      type: 'dropdown',
      source: 'relType',
    }
  },
  relDescStr: {
    Header: 'Relationship Description',
    id: 'relDescStr',
    accessor: 'relDescStr',
    type: 'string',
    required: true,
    input: {
      type: 'text',
      meta: {
        message: 'Possible commercial relationship descriptions include Strategic Alliance, Creditor-Borrower, Franchisor-Franchisee, Investor Relations, Firm-Client, Landlord-Tenant, Lessor-Lessee, Licensor-Licensee, Supplier-Customer, Supplier-Distributor, Transfer Agent-Client, Vendor-Client and Former Shareholder.'
      }
    }
  },
  sourceUrlStr: {
    Header: 'Source Url',
    id: 'sourceUrlStr',
    accessor: 'sourceUrlStr',
    type: 'string',
    input: {
      type: 'text'
    }
  },
  relStartDateStr: {
    Header: 'Relationship Start Date',
    id: 'relStartDateStr',
    accessor: 'relStartDateStr',
    type: 'string',
    input: {
      type: 'date'
    }
  },
  relEndDateStr: {
    Header: 'Relationship End Date',
    id: 'relEndDateStr',
    accessor: 'relEndDateStr',
    type: 'string',
    input: {
      type: 'date'
    }
  },
  shareholdingPercentage: {
    Header: 'Ownership Percentage',
    id: 'shareholdingPercentage',
    accessor: 'shareholdingPercentage',
    type: 'integer',
    input: {
      type: 'text',
      meta: {
        type: 'decimal',
        min: 0,
        max: 100
      }
    }
  },
  creator: {
    Header: 'Created by',
    id: 'creator',
    accessor: 'creator',
    type: 'string',
    input: {
      type: 'text',
    }
  },
  ins_date: {
    Header: 'Created at',
    id: 'ins_date',
    accessor: 'ins_date',
    type: 'string',
  },
  ins_name: {
    Header: 'Created by',
    id: 'ins_name',
    accessor: 'ins_name',
    type: 'string'
  },
  upd_date: {
    Header: 'Updated at',
    id: 'upd_date',
    accessor: 'upd_date',
    type: 'string',
  },
  upd_name: {
    Header: 'Updated by',
    id: 'upd_name',
    accessor: 'upd_name',
    type: 'string'
  },
  relTypeDescStr: {
    Header: 'Relationship Type',
    id: 'relTypeDescStr',
    accessor: 'relTypeDescStr',
    type: 'string'
  }
};

let instance = null;

export class TempRelationship {

  static get CONSTANTS() {
    return CONSTANTS;
  }

  constructor() {
    if(!instance){
      instance = this;
    }    

    return instance;
  }

  get newRelationshipGroupedFields() {
    return TempRelationship.CONSTANTS.newRelationshipGroupFields.map(group => {
      return group.map(field => this.fieldDetails[field]);
    });
  }

  get displayColumns() {
    return Object.keys(this.displayFieldDetails).map(key => this.displayFieldDetails[key]);
  }

  get fieldDetails() {
    const {
      entityId, entity2Id,
      sourceStr, relTypeStr,
      relDescStr, sourceUrlStr,
      relStartDateStr, relEndDateStr,
      shareholdingPercentage, creator } = TempRelationship.CONSTANTS;
    return {
      entityId, entity2Id,
      sourceStr, relTypeStr,
      relDescStr, sourceUrlStr,
      relStartDateStr, relEndDateStr,
      shareholdingPercentage, creator };
  }

  // get recordFieldsDetails() {

  // }

  get displayFieldDetails() {
    const {
      entityId, entity2Id,
      sourceStr,
      relTypeDescStr,
      relDescStr, sourceUrlStr,
      relStartDateStr, relEndDateStr,
      shareholdingPercentage,
      ins_date, ins_name,
      upd_date, upd_name } = TempRelationship.CONSTANTS;

    return {
      entityId, entity2Id,
      sourceStr,
      relTypeDescStr,
      relDescStr, sourceUrlStr,
      relStartDateStr, relEndDateStr,
      shareholdingPercentage,
      ins_date, ins_name,
      upd_date, upd_name }
  }
}
