import React from 'react';
import styled from 'styled-components';
import uuid from 'uuid';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import kebabCase from 'lodash.kebabcase';
import { Table, Icon, Pagination,Loader, Label, Segment, Button, Form, Input, Tab } from 'semantic-ui-react';
import { listFilesById, uploadFiles } from '../../utils/api';
import CopiableContent from '../CopiableContent';
import { getAuth } from '../../reducers/selectors';
import AccessDenied from '../AccessDenied';
import EntityDetails from '../EntityDetails';

const FileBox = styled.div`
  padding: 5px 10px;
  margin: 5px;
  width: 500px;
  background: #ececec;
  min-height: 50px;
  border-radius: 5px;
`;

const Wrapper = styled.div`
  padding: 20px 0;
`;

const ListHeader = styled.div`
  h4 {
    padding: 0;
    display: inline-block;
  }
`;

const Error = styled.span`
  padding: 5px;
  background: #ed1a3b;
  color: #fff;
`;

const UploadedMeta = styled.div`
  background: #fff;
  border-radius: 5px;
  margin: 10px 0 5px 0;
  padding: 10px;
`;

const Loading = styled.span`
  padding: 5px;
  background: #fff;
  font-size: 12px;
`;

const UrlBox = styled.input`
  width: 100%;
  margin: 5px 0;
  border: 1px solid #999;
  padding: 3px;
`;


class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      file: null,
      isUploading: false,
      uploadedFile: {},
      list: null,
      error: null,
      entityType: null,
      uploading: {},
      isFetching: false,
      dataramaId: this.props.dataramaId,
      customName: this.props.customName,
      activeTab: null,
      entity: this.props.entity,
    };

    this.handleTabChange = (e, { activeIndex }) => {
      this.setState(() => ({
        activeTab: activeIndex
      }))
    }
    this.navigateToPage = (e, { activePage }) => {
      this.setState(() => ({
        activePage,
      }))
    }
    this.handleIdChange = (e, { value, name }) => {
      this.setState(() => ({
        dataramaId: value,
      }))
    }
    this.handleNameChange = (e, { value, name }) => {
      this.setState(() => ({
        customName: value,
      }))
    }

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.cancel = this.cancel.bind(this);
    this.cancelUpload = (e) => {
      e.preventDefault();
      this.setState(() => ({
        uploading: {},
        file: null,
      }))

    }
    this.submitFile = this.submitFile.bind(this);
    this.setSelectionRange = this.setSelectionRange.bind(this);

    this.listFile = this.listFile.bind(this);

    this.actualUpload = this.actualUpload.bind(this);
  }

  componentDidMount() {
    if (this.props.dataramaId || this.props.customName) {
      this.listFile();
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.dataramaId || prevProps.customName) && (!this.props.dataramaId && !this.props.customName)) {
      this.cancel();
    }
    if (!prevProps.dataramaId && this.props.dataramaId) {
      this.setState(() => ({
        dataramaId: this.props.dataramaId,
        customName: null,
      }), () => {
        this.listFile();
      });
    }
    if (!prevProps.customName && this.props.customName) {
      this.setState(() => ({
        dataramaId: null,
        customName: this.props.customName
      }), () => {
        this.listFile();
      });
    }
  }

  async submitFile(e) {
    e.preventDefault();
    const { dataramaId, customName, file, fileMapping } = this.state;
    if (!dataramaId && !customName) return;
    const searchTerm = dataramaId || customName;
    this.setState(() => ({
      isUploading: true,
      error: null,
      uploading: Object.keys(file).reduce((a, c) => {
        a[c] = true;
        return a;
      }, {})
    }))


    const presigned = await uploadFiles(searchTerm, Object.keys(fileMapping));
    if (!presigned) return;

    await Promise.all(presigned.map(p => {
      const index = fileMapping[p.fileId].index;
      if (file[index]) {
        return this.actualUpload(p, file[index], index)
      }
      return false;
    }))
    this.setState(() => ({
      isUploading: false,
    }))
  }

  actualUpload(presignedPostData, file, fileIndex) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
      });

      formData.append("file", file);

      axios.post(presignedPostData.url, formData)
      .then((result) => {
        const { key } = presignedPostData.fields;
        const url = `${presignedPostData.url}/${key}`;
        this.setState(prevState => ({
          uploading: Object.assign({}, prevState.uploading, {
            [fileIndex]: false,
          }),
          uploadedFile: Object.assign({}, prevState.uploadedFile, {
            [fileIndex]: {
              Location: url,
            }
          }),
          list: Object.assign({}, prevState.list || {}, {
            files: ((prevState.list || {}).files || []).concat({
              Key: key,
            })
          })
        }))
        resolve({ Key: key });
      })
      .catch(err => {
        console.log('error!');
      })
    });
  }

  handleFileUpload(event) {
    const file = event.target.files;
    const fileMapping = Object.keys(file).reduce((a, f) => {
      const fileId = uuid.v4();
      a[fileId] = {
        fileId,
        name: file.name,
        index: f,
      };
      return a;
    }, {});

    this.setState(() => ({
      file,
      fileMapping,
    }));
  }

  cancel(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState(() => ({
      isUploading: false,
      file: null,
      uploadedFile: null,
      list: null,
      error: null,
      dataramaId: null,
      customName: null,
      uploading: {},
      entity: null
    }))
    if (this.props.cancel) {
      this.props.cancel();
    }
  }


  setSelectionRange(e) {
    e.target.select();
  }

  async listFile(e) {
    if (e) {
      e.preventDefault();
    }
    const { dataramaId, customName } = this.state;
    if (!dataramaId && !customName) return;
    let searchId = dataramaId || customName;
    const stateUpdates = {
      loading: true,
    }
    if (customName) {
      searchId = kebabCase(searchId.replace(/\\\//g, ''));
      stateUpdates.customName = searchId;
    }
    this.setState(() => stateUpdates);
    const files = await listFilesById(searchId);
    this.setState(() => ({
      error: null,
      loading: false,
      list: {
        id: searchId,
        files: files.filter(l => l.Key !== `${searchId}/`),
      },
      activeTab: 0,
    }))
  }

  render () {
    const { canUpload, entity } = this.props;
    if (!canUpload) return (
      <AccessDenied action='upload' resourceType='toDocument' />
    )
    const {
      activeTab,
      loading,
      uploadedFile,
      dataramaId,
      uploading,
      activePage,
      list,
      error,
      isUploading,
      customName,
    } = this.state;
    const fileList = this.state.file && Array.apply(null, Array(this.state.file.length)).map((f, k) => this.state.file[k])
    const urlPrefix = 'https://s3-ap-southeast-1.amazonaws.com/docs.datarama';
    const pageSize = 10;
    const columns = [{
      label: 'Url'
    }, {
      label: 'Key',
      value: 'Key',
    }, {
      label: 'Open'
    }];

  const tableContent = (fileList) => {
    return (
      <Table compact size='small' celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='16'>
              <Pagination
                size='mini'
                activePage={activePage}
                totalPages={Math.ceil(list.files.length / pageSize)}
                onPageChange={this.navigateToPage}
              />
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            {columns.map(col => (
              <Table.HeaderCell
                key={col.label}
              >{col.label}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {fileList.map(match => (
            <Table.Row key={match.Key}>
            <Table.Cell>
              <UrlBox type="text" onMouseOver={this.setSelectionRange} value={`${urlPrefix}/${match.Key}`} readOnly />
              <CopiableContent content={`${urlPrefix}/${match.Key}`} />
            </Table.Cell>
            <Table.Cell>
              {match.Key}
            </Table.Cell>
            <Table.Cell>
              <a href={`${urlPrefix}/${match.Key}`} target="_blank" rel='noopener noreferrer'>Open URL</a>
            </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    )
  }
    const displayFiles = list && list.files && list.files.slice((activePage - 1) * pageSize, (activePage - 1) * pageSize + pageSize)

    const panes = [
      { menuItem: 'List', render: () =>
        <React.Fragment>
          <div>
          {tableContent(displayFiles)}
          </div>
        </React.Fragment>
      },
      { menuItem: 'Upload New', render: () => this.state.file ? (
        <div>
          Selected: <b>{fileList.length}</b> Files. If confirm, click 'Upload'.
          {fileList.map((f, i) => (
            <FileBox key={f.name}>
              Original File Name: <b>{f.name}</b>
              {uploading[i] && (
                <Loading>Uploading...</Loading>
              )}
              {uploadedFile && uploadedFile[i] && (
                <UploadedMeta>
                  Successfully Uploaded!
                  <UrlBox type="text" onMouseOver={this.setSelectionRange} value={uploadedFile[i].Location} readOnly /><a href={uploadedFile[i].Location} target="_blank" rel='noopener noreferrer'>Open URL</a>
                </UploadedMeta>
              )}
            </FileBox>
          ))}
          <br/><br/>
          <Button onClick={this.submitFile} fluid color='green' basic disabled={isUploading} loading={isUploading}>Upload</Button>
          <br/>
          {!isUploading && (
            <Button onClick={this.cancelUpload} fluid>Cancel</Button>
          )}
        </div>
      ) : (
        <Form onSubmit={this.submitFile}>
          <Input label={`Upload file for ${dataramaId || customName}`} type='file' fluid multiple onChange={this.handleFileUpload} />
        </Form>
      )
     },
    ]

    return (
      <div>
        {error &&
          <Error>{error}</Error>
        }
        <Wrapper>
            {loading && (
              <Loader active>Loading...</Loader>
            )}
            {list && (
              <Button basic compact onClick={this.cancel} icon labelPosition='left'>
                <Icon name='left arrow' />
                Search Again
              </Button>
            )}
            {entity && (
              <Segment>
                <EntityDetails type={entity.type} collapse results={entity}/>
              </Segment>
            )}
            {list && (
              <Segment>
                <ListHeader>
                  <h4>Current files with <Label color='blue' horizontal>{(entity && entity.entityName) || list.id}</Label></h4>(<u>{list.id}</u>)<Label horizontal size='large'>{list.files.length || 0} Files Found.</Label>
                </ListHeader>
                <Tab
                  panes={panes}
                  activeIndex={activeTab}
                  menu={{ secondary: true, pointing: true, color: 'green' }}
                  onTabChange={this.handleTabChange}
                />
              </Segment>
            )}
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const authState = getAuth(state);
  const permissions = (authState.permissions || {}).general;
  return {
    loggedInUser: authState.loggedInUser,
    canUpload: permissions && permissions['document.create'],
  }
};

export default withRouter(connect(mapStateToProps)(FileUpload));


// <Grid columns={2} stackable>
//   <GridColumn>
//     <Header as ='h3'>Datarama ID</Header>
//     <Form onSubmit={this.listFile}>
//       <Input
//         disabled={!!list}
//         icon='search'
//         type='number'
//         iconPosition='left'
//         placeholder='Datarama ID'
//         onChange={this.handleIdChange}
//         name="dataramaId"
//         value={dataramaId || ''}
//       />
//       {list ? (
//         <Button onClick={this.cancel}>Search Again</Button>
//       ) : (
//         <Button
//         type="submit"
//         disabled={!dataramaId}
//         >List All Files</Button>
//       )}
//     </Form>
//   </GridColumn>
//
//   <GridColumn>
//     <Header
//       as='h3'
//       content='Custom Folder Name'
//       subheader='(White spaces will be replaced in kebabcase)'
//     />
//
//     <Form onSubmit={this.listFile}>
//       <Input
//         icon='search'
//         disabled={!!list}
//         name='customName'
//         iconPosition='left'
//         placeholder='Custom Folder'
//         onChange={this.handleNameChange}
//         value={customName || ''}
//       />
//       {list ? (
//         <Button onClick={this.cancel}>Search Again</Button>
//       ) : (
//         <Button
//         type="submit"
//         disabled={!customName}
//         >List All Files</Button>
//       )}
//     </Form>
//   </GridColumn>
// </Grid>
