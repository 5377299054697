import { withRouter } from 'react-router';
import React from 'react';
import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';

const Item = styled(Menu.Item)`
  ${({ color }) => color && `
    background: ${color} !important;
  `}
`;

export default withRouter(({ location, exact, match, color, history, name, value }) => {
  const baseUrl = match.url;
  const activeItem = location.pathname;
  const switchToPath = (e, { name, value }) => {
    const baseUrl = match.url;
    history.push(`${baseUrl}/${value}`);
  };
  return (
    <Item
      color={color}
      name={name}
      value={value}
      active={exact ? (activeItem === baseUrl + '/' + value) : (activeItem.indexOf((baseUrl + '/' + value)) > -1)}
      onClick={switchToPath}
    />
  )
})
