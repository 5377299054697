import React, { Component } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import Nav from './Nav';
import { Route } from "react-router-dom";
import Validation from './Validation';
import FindById from './validate/FindById';
import Home from './Home';
import Projects from './Projects';
import Project from './Project';
import NewProject from './project/NewProject';
import Permissions from './Permissions';
import FileUpload from './upload/UploadIndex';
import { AdverseMediaResearch } from './adverseMediaResearch/AdverseMediaResearch';
import { AdverseMediaResearchSearchResults } from './adverseMediaResearch/AdverseMediaResearchSearchResults';
import { RiskIndicators } from './riskIndicators/RiskIndicators';
import { RiskFactors } from './riskFactors/RiskFactors';
import { Sanctions } from './sanctions/Sanctions';
import { EntitiesDeduplication } from './entitiesDedup/EntitiesDeduplication';
import NewClient from './client/NewClient';
import { getSettings, checkPermissions } from '../utils/api';
import { connect } from 'react-redux';
import { setSettings, updateSelfPermission } from '../actions';
import { getAuth } from '../reducers/selectors';
import PEPBlacklist from './pepBlacklist/Main';
import Importer from './importer/Dashboard';
import { ImporterV2 } from './importerV2/ImporterV2';
import { LiteEDDTemplate } from './liteEDD/templates/LiteEDDTemplate';
import { Sitemap } from './Sitemap';

const Wrapper = styled.div`
  display: flex;
  width: 97vw;
  > div {
    padding: 10px;
  }
  > div:last-of-type {
    flex: 1;
    margin-top: 10px;
  }
`;



class MainContent extends Component {
  constructor(props) {
    super(props);
    this.checkSelfPermission = this.checkSelfPermission.bind(this);
 
    this.renderRoutes = [
      { path: '/files/add', component: <FileUpload { ...props }/> },
      { path: '/importer', component: <Importer { ...props } /> },
      { path: '/importer-v2', component: <ImporterV2 { ...props } /> },
      { path: '/project/:projectId', component: <Project { ...props } /> },
      { path: '/permissions', component: <Permissions { ...props } />, isVisible: props.userPermissions['permission.admin'] },
      { path: '/clients/add', component: <NewClient { ...props } /> },
      { path: '/research/sanctions', component: <Sanctions { ...props }/> },
      { path: '/research/pepblacklist', component: <PEPBlacklist { ...props } /> },
      { path: '/validation', component: <Validation { ...props }/> },
      { path: '/find/id', component: <FindById { ...props }/> },
      { path: '/entities/deduplicate', component: <EntitiesDeduplication { ...props }/> }
    ]

    this.exactRenderRoutes = [
      { path: '/', component: <Home {...props} /> },
      { path: '/sitemap', component: <Sitemap {...props} /> },
      { path: '/projects', component: <Projects {...props} /> },
      { path: '/research/adverseMedia', component: <AdverseMediaResearch { ...props } /> },
      { path: '/research/adverseMedia/search', component: <AdverseMediaResearchSearchResults { ...props } />},
      { path: '/research/riskIndicators', component: <RiskIndicators { ...props } />},
      { path: '/research/riskfactors', component: <RiskFactors { ...props } />},
      { path: '/projects/add', component: <NewProject { ...props } checkPermission={this.checkSelfPermission} /> }
    ]
    
  }

  componentDidMount() {
    this.fetchSettings();
  }

  async checkSelfPermission() {
    const { loggedInUser } = this.props;
    if (!loggedInUser) return false;
    const checkResult = await checkPermissions('general', this.props.loggedInUser);
    if (checkResult) {
      this.props.updateSelfPermission('general', null, checkResult.general)
    }
  }

  async fetchSettings() {
    const settings = await getSettings();
    if (settings){
      this.props.setSettings(settings)
    }
  }



  render() {
    return (
      <>
        { this.exactRenderRoutes.map(({path, component}) => (
          <Route
            key={ path }
            exact
            path={ path }
            render={ () =>
              <Wrapper>
                <div><Nav {...this.props}/></div>
                <div>{ component }</div>
              </Wrapper> } />
        ))}
        { this.renderRoutes.map(({path, component, isVisible=true}) => (
          <Route
          key={ path }
          path={ path }
          render={ () =>
            <Wrapper>
              <div><Nav {...this.props}/></div>
              <div>{ isVisible && component }</div>
            </Wrapper> } />
        ))}
        <Route
          path='/reports/liteedd/preview/:reportId'
          render={ () => <LiteEDDTemplate { ...this.props } /> } />
      </>
    );
  }
}


const mapStateToProps = state => {
  const authState = getAuth(state);
  return {
    loggedInUser: authState.loggedInUser,
    userPermissions: (authState.permissions || {}).general || {},
  }
};

export default withRouter(connect(mapStateToProps, {
  setSettings,
  updateSelfPermission
})(MainContent));
