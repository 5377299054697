import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { Button } from './common';
import ValueBox from './ValueBox';
import { getTodaysDateFormatted } from '../../../utils/time';

export default ({ field, compact, error, noMax, handleFieldChange, isValidating, defaultValue, entityType }) => {
  const [value, setValue] = useState(defaultValue);
  const setFieldValue = (e, { name, value }) => {
    setValue(value);
    handleFieldChange(null, { name: field.id, value });
  }
  const maxDate = noMax? null : getTodaysDateFormatted();
  const clearValue = (e) => {
    e.preventDefault();
    setValue('');
    handleFieldChange(null, { name: field.id, value: null });
  }
  if (entityType) {
    // Effect to clear fields when switching entit type
    useEffect(() => {
      setValue('');
      handleFieldChange(null, { name: field.id, value: null });
    }, [entityType]);
  }

  return (
    <Form.Field required={field.required}>
    {!compact && (
      <label>{field.Header} {!isValidating && (
        <Button onClick={clearValue}>Clear</Button>
      )}</label>
    )}
    {isValidating ? (
      <ValueBox value={value} />
    ) : (
      <Form.Input
        placeholder={field.Header}
        type="date"
        value={value}
        onChange={setFieldValue}
        name={field.id}
        min="1900-01-01"
        max={maxDate}
        required={field.required}
        error={error}
      />
    )}
    </Form.Field>

  )
}
