import React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';

const defaultContent = 'Please Wait...';

export default ({ active, content, dim }) => {
  const baseLoader = (<Loader active={active}>{content || defaultContent}</Loader>);
  if (dim) {
    return (
      <Dimmer active={active}>
        {baseLoader}
      </Dimmer>
    )
  }
  return baseLoader
}
