import TmpEntity from './tmpEntity';
import { ENTITY_TYPE_MSSQL_REF } from '../../utils/ref/entityType';

class TmpOtherEntity extends TmpEntity {
  constructor(props) {
    super(props);
    if (props && props.type) {
      this.type = ENTITY_TYPE_MSSQL_REF[props.type] || props.type;
    }
    this._fieldDetails = Object.assign({}, this.basicFieldDetails, {
      phoneNumStr: {
        Header: 'Phone #',
        id: 'phoneNumStr',
        accessor: 'phoneNumStr',
        type: 'string',
        input: {
          type: 'text'
        }
      },
      faxNumStr: {
        Header: 'Fax #',
        id: 'faxNumStr',
        accessor: 'faxNumStr',
        type: 'string',
        input: {
          type: 'text'
        }
      },
      Blacklist: Object.assign({}, this.basicFieldDetails['Blacklist'], {
        required: false,
      }),
      entityStartDate: Object.assign({}, this.basicFieldDetails.entityStartDate, {
        Header: 'Start'
      }),
      entityEndDate: Object.assign({}, this.basicFieldDetails.entityEndDate, {
        Header: 'Termination'
      }),
      entityStatus: Object.assign({}, this.basicFieldDetails['entityStatus'], {
        required: false,
      }),
    });

    this.columns = [
    'entityId',
    'entityName',
    'country',
    'entityAlias1',
    'langId1',
    'entityAlias2',
    'langId2',
    'entityAlias3',
    'langId3',
    'entityStartDate',
    'entityEndDate',
    'Blacklist',
    'Blacklist detail',
    'addressStr',
    'addressStrInOtherLang',
    'langId4',
    'phoneNumStr',
    'faxNumStr',
    'source',
    'QOS',
    'ins_date',
    'upd_date',
    'creator'
  ];
  this.digestFields = [
    'entityId','entityName', 'country','source','QOS'
  ];

    this.newEntityGroupFields = [
      ['entityName', 'country'],
      ['entityAlias1', 'langId1'],
      ['entityAlias2', 'langId2'],
      ['entityAlias3', 'langId3'],
      ['entityStartDate', 'entityEndDate'],
      ['Blacklist', 'Blacklist detail'],
      ['addressStr', 'addressStrInOtherLang', 'langId4'],
      ['phoneNumStr', 'faxNumStr'],
      ['source', 'QOS']
    ]

    this.restrictedFieldsByCountry = {}

  }

}

export default TmpOtherEntity;
