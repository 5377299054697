import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';

const Btn = styled.button`
  padding: 6px 15px;
  background: #fff;
  font-family: inherit;
  font-size: 17px;
  border: 0;
  cursor: pointer;
  outline: none;
  &:not(:last-of-type) {
    border-right: 1px solid #ccc;
  }
  ${({ selected }) => selected && `
    font-weight: 700;
    background: #ececec;
  `}
  &:hover {
    opacity: 0.6
  }
`;

const Pages = styled.div`
  margin: 5px 0;
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 2px;
`;

export const Pagination = (props) => {
  const { totalPages, goToPage, currentPage } = props;
  const pages = new Array(totalPages).fill();
  return (
    <Pages>
      {pages.map((page, pageIndex) => (
        <Button
          onClick={goToPage}
          value={pageIndex}
          label={pageIndex + 1}
          component={Btn}
          key={pageIndex}
          meta={{
            selected: currentPage === pageIndex
          }}
        />

      ))}
    </Pages>
  )
}
