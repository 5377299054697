import React, { useState } from 'react';
import { Step } from 'semantic-ui-react';
import { EntityId } from './entityId/EntityId';
import { Blacklist } from './blacklist/Blacklist';
import { Biography } from './biography/Biography';
import { ExecutiveSummary } from './executiveSummary/ExecutiveSummary';
import { PublicProfile } from './publicProfile/PublicProfile';
import { Wealth } from './wealth/Wealth';

import './index.scss';

export function KeystonePeople({ projectId }) {
  const [state, setState] = useState({
    isError: false,
    dataramaEntityId: "",
    keystoneId: "",
    entityName: "",
    entityType: "",
    isProcessing: false,
    completed: {
      entityId: false,
      biography: false,
      executiveSummary: false,
      publicProfile: false,
      entityCheck: false,
      wealth: false
    },
    active: 'entityId'
  });

  const STEPS = [
    { key: 'entityId', title: 'Entity Id', description: 'Enter Entity Id' },
    { key: 'biography', title: 'Basic Information', description: 'Basic information' },
    { key: 'executiveSummary', title: 'Executive Summary', description: 'Executive Summary' },
    { key: 'publicProfile', title: 'Public Profile', description: 'Public Profile' },
    { key: 'entityCheck', title: 'Entity Check', description: 'Entity Check' },
    { key: 'wealth', title: 'Wealth', description: 'Wealth Estimates' }
  ];

  return <div className='keystone people'>
    <Step.Group widths={6} size='mini' ordered>
      { STEPS.map(step => (
        <Step key={step.key}
          // completed={state.completed[step.key]}
          onClick={() => {
            if (state.keystoneId !== '') {
            setState(prevState => (
              { ...prevState, active: step.key }
            ))
            }
          }}
          active={state.active === step.key}>
          <Step.Content>
            <Step.Title>
              { step.title }
            </Step.Title>
            <Step.Description>
              { step.description }
            </Step.Description>
          </Step.Content>
        </Step>
      )) }
    </Step.Group>

    { state.active === 'entityId' &&
      <EntityId state={ state } setState={ setState } /> }
    { state.active === 'biography' &&
      <Biography state={ state } setState={ setState } /> }
    { state.active === 'executiveSummary' &&
      <ExecutiveSummary state={ state } setState={ setState } /> }
    { state.active === 'publicProfile' &&
      <PublicProfile state={ state } setState={ setState } /> }
    { state.active === 'entityCheck' &&
      <Blacklist state={ state } setState={ setState } /> }
    { state.active === 'wealth' &&
      <Wealth state={ state } setState={ setState } /> }
  </div>;
}