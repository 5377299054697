import React from 'react';
import styled from 'styled-components';
import { Grid, LoadingTag } from './styles';
import { Pagination } from './Pagination';
import { ArticleRow } from './ArticleRow';
import { FullArticleRow } from './FullArticleRow';
import { processArticles } from './processArticles';

const pageSize = 10;

const FullArticleWrapper = styled.div`
  padding: 10px;
  margin: 5px 10px;
  border: 1px solid #444;
  border-radius: 3px;
  background: #fff;
`;

const Row = styled.div`
  ${({ header }) => header ? `
    font-weight: 600;
    background: #ececec;
  ` : `
    &:nth-of-type(2n+1) {
      background: #d5e0ee;
    }
  `}
`;

const ArticleRowWrapper = styled(Grid).attrs({ col: '5% 10% 20% 35% 15% 15%'})`
  ${({ header }) => header && `
    font-weight: 600;
    background: #ececec;
  `}
  > div {
    word-wrap: break-word;
    padding: 5px;

  }
  ${({ disable }) => disable && `
    text-decoration: line-through;
    background: #444 !important;
  `}
`;

class PaginatedArticles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      fullArticles: {},
      displayFullArticles: {},
      loading: {}
    }
    this.goToPage = (page) => {
      // window.scrollTo(0,0);
      this.setState(() => ({
        page,
      }), () => {
        this.setPageContent();
      })
    }
    this.getFullArticle = async (article) => {
      this.setState(prevState => ({
        displayFullArticles: {
          ...prevState.displayFullArticles,
          [article.id]: !prevState.displayFullArticles[article.id],
        }
      }))
      return;
    }

    this.setPageContent = this.setPageContent.bind(this);
  }

  componentDidMount() {
    if (!this.state.currentPage) {
      this.setPageContent();
    }
  }

  componentDidUpdate(prevProps) {
    const handleNewCategory = async () => {
      if(prevProps.category !== this.props.category) {
        await this.setState(() => ({
          page: 0
        }));
        this.setPageContent();
      }  
    }
    handleNewCategory();

  }

  setPageContent() {
    const { articles, terms, category, searchScope } = this.props;
    const { page } = this.state;

    const currentPageArticles = articles.slice(page * pageSize, page * pageSize + pageSize);

    const currentPage = processArticles(currentPageArticles, {
      allTerms: terms,
      category,
      names: searchScope.name,
    });
    return this.setState(() => ({
      currentPage,
    }))
  }

  render() {
    const {
      mlSentences,
      articles,
      articlesToIgnore,
      category,
      duplicates,
      loggedInUser,
      setValidation,
      markNotAdverse,
      articlesNotAdverse,
      titleDuplicates,
      deselectDuplicates
    } = this.props;
    const { page, currentPage, displayFullArticles, loading } = this.state;


    const totalPages = Math.ceil(articles.length / pageSize);

    return (
      <div>
        <Pagination
          totalPages={totalPages}
          currentPage={page}
          goToPage={this.goToPage}
        />
        <ArticleRowWrapper header>
          <div />
          <div>Date</div>
          <div>Title</div>
          <div>Content</div>
          <div>Source</div>
          <div>Relevancy</div>
        </ArticleRowWrapper>
        {currentPage && currentPage.map((art, artIndex) => (
          <Row key={`${art.id}_origin`}>
            <ArticleRow
              key={`${art.id}_origin_inner`}
              mlSentences={mlSentences}
              articlesToIgnore={articlesToIgnore}
              articlesNotAdverse={articlesNotAdverse}
              art={art}
              artIndex={artIndex}
              category={category}
              deselectDuplicates={deselectDuplicates}
              displayFullArticles={displayFullArticles}
              duplicates={duplicates}
              getFullArticle={this.getFullArticle}
              loggedInUser={loggedInUser}
              isNLP={art.meta && art.meta.response}
              markNotAdverse={markNotAdverse}
              titleDuplicates={titleDuplicates}
              page={page}
              pageSize={pageSize}
              searchScope={this.props.searchScope}
              setValidation={setValidation} />
            { loading[art.id] &&
              <FullArticleWrapper>
                <LoadingTag>Fetching...</LoadingTag>
              </FullArticleWrapper> }
            { displayFullArticles[art.id] &&
              <FullArticleRow key={ art.id } art={ art } isNLP={ art.meta && art.meta.response } /> }
          </Row>

        ))}
        <Pagination
          totalPages={totalPages}
          currentPage={page}
          goToPage={this.goToPage}
        />
      </div>
    )
  }
}

PaginatedArticles.defaultProps = {
  articles: []
}

export { PaginatedArticles };
