import React from 'react';
import { SearchConditions } from './SearchConditions';
import { SearchResults } from './SearchResults';

export function Search({ state, setState }) {

  return <>
    { state.active === 'searchResults'
      ? <SearchResults state={state} setState={setState} />
      : <SearchConditions state={state} setState={setState} /> }
  </>
}