import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Segment, Header, Label, Loader, Message } from 'semantic-ui-react';
import { runImporterV2, getImporterLogs } from '../../utils/api';

const matchLogsReg = new RegExp('Started|Finished|Export Completed|Import Completed|finished updating', 'g');

const HighlightLog = styled.div`
  background: #b0e3a9;
  font-weight: 600;
  margin: 1px 0;
`;

export function ImporterV2(props) {

  const [isChecked, setIsChecked] = useState(false);
  const [activeTab, setActiveTab] = useState('run_importer');
  const [logs, setLogs] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isStaging, setIsStaging] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  const runImporter = async () => {
    setIsLoading(true);
    let requestBody;
    if (isStaging) {
      requestBody = {
        'jobDefinition': 'importer-v2-stg',
        'jobQueue': 'i2-importer-v2-staging-queue'
      };  
    } else {
      requestBody = {
        'jobDefinition': 'importer-v2-production',
        'jobQueue': 'i2-importer-v2-production-queue'
      };
    }
    await runImporterV2(JSON.stringify(requestBody));
    setIsLoading(false);
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
    }, 5000);
  };
  const handleChangeEnv = () => {
    setIsChecked(!isChecked);
  };

  const switchTab = async tab => {
    setActiveTab(tab);
    if (tab === 'stg' || tab === 'production') {
      await fetchLog(tab);
    }
  }

  const fetchLog = async tab => {
    setIsLoading(true);
    if (tab === 'stg' || tab === 'production') {
      const logs = await getImporterLogs(tab);
      setIsLoading(false);
      if (logs) {
        setLogs(logs.logs);
        // setLogs(logs.logs.replace(/\\r/g,"").split(/\"|\\n/g).reverse());
      }  
    }
  }

  return (
    <>
      <Button active={activeTab === 'run_importer'}onClick={() => switchTab('run_importer')}>Run Importer</Button>
      <Button onClick={() => switchTab('stg')}>Read Staging Logs</Button>
      <Button onClick={() => switchTab('production')}>Read Production Logs</Button>
      { activeTab === 'run_importer' &&
        <Segment>
        <h3>Importer</h3>
          <Form onSubmit={runImporter}>
            <Form.Group inline>
              <label>Environment</label>
              <Form.Radio
                label='Staging'
                value='staging'
                checked={ isStaging }
                onClick={ () => setIsStaging(!isStaging) }
                name='env' />
              <Form.Radio
                label='Production'
                value='production'
                checked={ !isStaging }
                onClick={ () => setIsStaging(!isStaging) }
                name='env' />
            </Form.Group>
            <Form.Checkbox
              label='Confirm before running the importer'
              required
              onChange={handleChangeEnv}
              name='confirmed'
              checked={isChecked} />
            <Form.Button disabled={isLoading || !isChecked} positive>Run Importer</Form.Button>
          </Form>
          { isSuccess &&
            <Message positive size='mini' compact onDismiss={ () => setIsSuccess(false) }>
              <Message.Header>{`Importer job has been successfully submitted to the ${ isStaging ? 'STAGING' : 'PRODUCTION' } environment.`}</Message.Header>
            </Message> }
        </Segment> }
      { isLoading && <Loader active>Loading</Loader> }
      { (activeTab === 'stg' || activeTab === 'production') && logs && (
        <Segment>
          <Header>Displaying latest records in <Label color='green'>
            { activeTab === 'stg' && "Staging" }
            { activeTab === 'production' && "Production" }
          </Label></Header>
          {logs.map((p, pindex) => {
            const shouldHighlight = p.match(matchLogsReg);
            return (
              <div key={pindex}>
                {shouldHighlight ? (
                  <HighlightLog>{p}</HighlightLog>
                ) : p}
              </div>
            )
          })}
        </Segment>)}
    </>

  )
}