import React from 'react';
import { Segment } from 'semantic-ui-react';
import { ArticleRow } from './ArticleRow';

export function ResultsWidget({ articles }) {

  if (articles.length === 0) {
    return null;
  }

  return (
    <>
      <Segment size='massive'>{ articles.filter(article => article.relevance === 0).length } articles left to verify</Segment>
      <div className='articles-container'>
        <br /><br />
        <div className='articles-header'>
          <div />
          <div>Date</div>
          <div>Title</div>
          <div>Content</div>
          <div>Source</div>
          <div>Relevancy</div>
        </div>
        { articles.map((article, index) => (
          <ArticleRow
            key= { article.id }
            article={ article }
            index={ index } />
        )) }
      </div>
    </>
  )
}