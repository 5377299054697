import React from 'react';
import styled from 'styled-components';
import { defaultTimeDisplay } from '../../utils/time';

const Details = styled.div`
  padding: 10px;
`;

const Confirmed = styled.div`
  padding: 10px 20px;
  background: #e6d074;
  font-size: 20px;
`;
const HyperLink = styled.a`
  overflow-wrap: break-word;
`;
const Category = styled.div`
  display: inline-block;
  background: #ff5247;
  font-size: 13px;
  padding: 2px 5px;
  margin-right: 10px;
  color: #fff;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ grid }) => grid || '30% 70%'};
  margin-bottom: 10px;
  ${({ header }) => header && `font-weight: 700;`}
  ${({ inner }) => !inner && `
    padding: 10px;
    border-bottom: 1px solid #ececec;
  `}
  padding: 5px;
  ${({ highlight }) => highlight && `
    background: #ececec;
  `}
`;
const NumberLabel = styled.div`
  text-align: center;
  span {
    font-size: 18px;
    padding: 5px;
    font-weight: 700;
    background: #fff;
  }
`;

const PaddedDiv = styled.div`
  padding: 5px;
`;

export default ({ additional }) => {
  return (
    <div>
      <div>
        <Confirmed>A Combined Profile was created at {defaultTimeDisplay(additional.lastUpdated)}</Confirmed>
      </div>
      <Details>
        {additional &&
          <div>
            <Grid>
              <div>PEP</div>
              <div>{additional.isPEP ? '🚩' : 'No'} (PEP Tier: {additional.pepLevel})</div>
            </Grid>
            <Grid>
              <div>Sanction</div>
              <div>
                <Grid inner><div>Current</div><div>{additional.isSanctionsCurrent ? '🚩' : 'No'}</div></Grid>
                <Grid inner><div>Previous</div><div>{additional.isSanctionsPrevious ? '🚩' : 'No'}</div></Grid>
              </div>
            </Grid>
            <Grid>
              <div>Law Enforcement</div>
              <div>{additional.isLawEnforcement ? '🚩' : 'No'}</div>
            </Grid>
            <Grid>
              <div>Financial Regulator</div>
              <div>{additional.isFinancialregulator ? '🚩' : 'No'}</div>
            </Grid>
            <Grid>
              <div>Disqualified Director</div>
              <div>{additional.isDisqualifiedDirector ? '🚩' : 'No'}</div>
            </Grid>
            <Grid>
              <div>Insolvent</div>
              <div>{additional.isInsolvent ? '🚩' : 'No'}</div>
            </Grid>
            <Grid>
              <div>Political Positions</div>
              <div>
              {(additional.politicalPositions || []).map((pp, idx) => (
                <Grid inner key={idx} grid="10% 90%" highlight>
                  <NumberLabel><span>#{idx + 1}</span></NumberLabel>
                  <PaddedDiv>
                    <Grid inner grid="20% 80%">
                      <div>Description</div>
                      <div>{pp.description}</div>
                    </Grid>
                    <Grid inner grid="20% 80%">
                      <div>Country</div>
                      <div>{pp.country && pp.country.name}</div>
                    </Grid>
                    <Grid inner grid="20% 80%">
                      <div>From</div>
                      <div>{pp.from}</div>
                    </Grid>
                    <Grid inner grid="20% 80%">
                      <div>To</div>
                      <div>{pp.to}</div>
                    </Grid>
                  </PaddedDiv>
                </Grid>
              ))}
              </div>
            </Grid>
            <Grid>
              <div>Related Articles</div>
              <div>
              {(additional.articles || []).map((art, idx) => (
                <Grid inner key={idx} grid="10% 90%" highlight>
                  <NumberLabel><span>#{idx + 1}</span></NumberLabel>
                  <PaddedDiv>
                    <Grid inner grid="20% 80%">
                      <div>URL</div>
                      <HyperLink href={art.originalURL} target='_blank' rel='noreferrer noopener'>{art.originalURL}</HyperLink>
                    </Grid>
                    <Grid inner grid="20% 80%">
                      <div>Date Collected</div>
                      <div>{art.dateCollected}</div>
                    </Grid>
                    <Grid inner grid="20% 80%">
                      <div>Backup URL</div>
                      <HyperLink href={art.c6URL} target='_blank' rel='noreferrer noopener'>URL</HyperLink>
                    </Grid>
                    <Grid inner grid="20% 80%">
                      <div>Category</div>
                      <div>{(art.categories || []).map((cat => (<Category key={cat.name}>{cat.name}</Category>)))}</div>
                    </Grid>
                  </PaddedDiv>
                </Grid>
              ))}
              </div>
            </Grid>
            <Grid>
              <div>Linked Persons</div>
              <div>
                {(additional.linkedPersons || []).map((person, idx) => (
                  <Grid inner key={idx} grid="10% 90%" highlight>
                    <NumberLabel><span>#{idx + 1}</span></NumberLabel>
                    <PaddedDiv>
                      <Grid inner>
                        <div>Name</div>
                        <div>{person.name}</div>
                      </Grid>
                      <Grid inner>
                        <div>Association</div>
                        <div>{person.position}</div>
                      </Grid>
                      <Grid inner>
                        <div>Datarama Match</div>
                        <div>{person.DataramaID || 'N/A'}</div>
                      </Grid>
                    </PaddedDiv>
                  </Grid>
                ))}
              </div>
            </Grid>
            <Grid>
              <div>Linked Businesses</div>
              <div>
                {(additional.linkedBusinesses || []).map((business, idx) => (
                  <Grid inner key={idx} grid="10% 90%" highlight>
                    <NumberLabel><span>#{idx + 1}</span></NumberLabel>
                    <PaddedDiv>
                      <Grid inner>
                        <div>Name</div>
                        <div>{business.businessName}</div>
                      </Grid>
                      <Grid inner>
                        <div>Details</div>
                        <div>{business.linkDescription}</div>
                      </Grid>
                    </PaddedDiv>
                  </Grid>
                ))}
              </div>
            </Grid>

          </div>
        }
      </Details>
    </div>
  )
}
