import React from 'react';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';

const Wrapper = styled.div`
  position: fixed;
  height: 100vh;
  z-index: 100;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(30, 30, 30, 0.8)
`;
const Box = styled.div`
  margin: auto;
  background: #fff;
  border-radius: 5px;
  width: 500px;
`;

const Category = styled.div`
  font-size: 12px;
  display:inline-block;
  background: #ff5247;
  padding: 2px 5px;
  margin-right: 5px;
  color: #fff;
`;
const HyperLink = styled.a`
  overflow-wrap: break-word;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ grid }) => grid || '30% 70%'};
  margin-bottom: 10px;
  ${({ header }) => header && `font-weight: 700;`}
  ${({ inner }) => !inner && `
    padding: 10px;
    border-bottom: 1px solid #ececec;
  `}
  padding: 5px;
  ${({ highlight }) => highlight && `
    background: #ececec;
  `}
`;


const Header = styled.h3`
  color: #7d73d7;
  margin: 0;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 20px;
  border-bottom: 2px solid #7d73d7;
`;

const Content = styled.div`
  padding: 10px 20px;
  position: relative;
  max-height: 300px;
  overflow: scroll
`;

const Action = styled.div`
  display: grid;
  grid-template-columns: ${({ grid }) => grid || '50% 50%'};
  button {
    padding: 10px 20px;
    border-radius: 0;
    border: 0;
    border-top: 1px solid #ececec;
    border-right: 1px solid #ececec;
    cursor: pointer;
    outline: none;
    font-size: 15px;
    ${({ disabled }) => !disabled && `
      &:hover {
        color: #7d73d7;
      }
    `}
  }
`;

const Error = styled.div`
  margin: 5px;
  background: #ececec;
  padding: 5px;
  h3 {
    margin: 0;
  }
`;
const arrayFields = type => {
  const basic = ['articles', 'linkedPersons', 'linkedBusinesses'];
  if (type === 'person') {
    return basic.concat('politicalPositions');
  }
  return basic;
};

const boolToString = state => state ? '🚩' : 'No';

class CombinePrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      warning: null,
    }
    this.onClick = this.onClick.bind(this);
    this.close = this.close.bind(this);
    this.combine = this.combine.bind(this);
    this.doneAction = this.doneAction.bind(this);
  }

  onClick() {
    this.props.onClick(this.props.value);
  }

  close() {
    this.props.onClose();
  }

  doneAction() {
    this.props.doneAction();
  }

  combine() {
    this.props.combine(this.props.type);
  }

  componentDidMount() {
    const { value } = this.props;

    const emptyArrayFields = arrayFields(this.props.entityType).filter(e => value[e].length === 0);
    this.setState({
      warning: emptyArrayFields.length > 0 ? emptyArrayFields : null,
      // error: missingPEPBlacklistFields.length > 0 ? missingPEPBlacklistFields : null
    })
  }

  render() {
    const { loading, done, value = {} } = this.props;
    const { error, warning } = this.state;
    return (
      <Wrapper>
        <Box>
          <Header>Confirm Combine Profile</Header>
          {done ?
            <Content>
              Successfully Created Combine Profile for the entity!
            </Content>
            :
            <Content>
              {loading &&
                <Loader active>
                  Please Wait>
                </Loader>
              }
              {error &&
                <Error>
                  <h3>Error</h3>
                  {error.map((e, idx)=> (
                    <div key={idx}>Missing {e}</div>
                  ))}
                </Error>
              }
              {warning &&
                <Error>
                  <h3>Warning</h3>
                  {warning.map((e, idx)=> (
                    <div key={idx}>{e} is empty</div>
                  ))}
                </Error>
              }
              {!error &&
                <div>
                  <Grid>
                    <div>PEP</div>
                    <div>{boolToString(value.isPEP)}</div>
                  </Grid>
                  <Grid>
                    <div>PEP Tier</div>
                    <div>{value.pepLevel}</div>
                  </Grid>
                  <Grid>
                    <div>Sanction</div>
                    <div>
                      <Grid inner><div>Current</div><div>{boolToString(value.isSanctionsCurrent)}</div></Grid>
                      <Grid inner><div>Previous</div><div>{boolToString(value.isSanctionsPrevious)}</div></Grid>
                    </div>
                  </Grid>
                  <Grid>
                    <div>Law Enforcement</div>
                    <div>{boolToString(value.isLawEnforcement)}</div>
                  </Grid>
                  <Grid>
                    <div>Financial Regulator</div>
                    <div>{boolToString(value.isFinancialregulator)}</div>
                  </Grid>
                  <Grid>
                    <div>Disqualified Director</div>
                    <div>{boolToString(value.isDisqualifiedDirector)}</div>
                  </Grid>
                  <Grid>
                    <div>Insolvent</div>
                    <div>{boolToString(value.isInsolvent)}</div>
                  </Grid>
                </div>
              }
              <div>
                {this.props.entityType === 'person' &&
                  <Grid grid="20% 80%">
                    <div>Political Positions</div>
                    <div>
                    {value.politicalPositions.map((pp, idx) => (
                      <div key={idx}>
                        <b>#{idx + 1}</b>{pp.description}{(pp.from || pp.to) && (<Category>{pp.from} - {pp.to}</Category>)}
                      </div>
                    ))}
                    </div>
                  </Grid>
                }
                <Grid grid="20% 80%">
                  <div>Related Articles</div>
                  <div>
                  {value.articles.map((art, idx) => (
                    <div key={idx}>
                      <b>#{idx + 1}</b> {art.categories.map(cat => (<Category key={cat.name}>{cat.name}</Category>))}<HyperLink href={art.c6URL} target='_blank' rel='noreferrer noopener'>URL</HyperLink>

                    </div>
                  ))}
                  </div>
                </Grid>
                <Grid grid="20% 80%">
                  <div>Linked Persons</div>
                  <div>
                  {value.linkedPersons.map((per, idx) => (
                    <div key={idx}>
                      <b>#{idx + 1}</b> {per.name} - {per.position}
                    </div>
                  ))}
                  </div>
                </Grid>
                <Grid grid="20% 80%">
                  <div>Linked Businesses</div>
                  <div>
                  {value.linkedBusinesses.map((biz, idx) => (
                    <div key={idx}>
                      <b>#{idx + 1}</b> {biz.businessName} - {biz.linkDescription}
                    </div>
                  ))}
                  </div>
                </Grid>

              </div>
            </Content>
          }
          {done ?
            <Action grid="100%">
              <button onClick={this.doneAction}>Done</button>
            </Action>
            :
            (<Action disabled={loading} grid={error && '100%'}>
              <button onClick={this.close} disabled={loading}>Cancel</button>
              {!error &&
                <button onClick={this.props.combine} disabled={loading}>Confirm Combine</button>
              }
            </Action>)
          }
        </Box>
      </Wrapper>
    )
  }
}

export default CombinePrompt;
