import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { Button } from './common';
import ValueBox from './ValueBox';
import * as OPTIONS from '../../../utils/ref/formConstants';

const generateDropdownOptions = (options) => {
  return options.map(option => {
    if (typeof option === 'object') {
      return {
        key: option.id,
        text: option.display,
        value: JSON.stringify(option),
      }
    }
    return {
      key: option,
      text: option,
      value: option,
    }
  });
}

export default ({ field, compact, error, width, handleFieldChange, isValidating, defaultValue, entityType }) => {
  const [value, setValue] = useState(defaultValue);
  const initValue = field.defaultValue || (field.multiple ? [] : '');
  let defaultOptions = generateDropdownOptions(field.options || OPTIONS[field.input.source] || []);
  let [options, setOptions] = useState(defaultOptions);

  const setFieldValue = (e, { name, value }) => {
    setValue(value);
    handleFieldChange(null, { name: field.id, value });
  };

  const handleAddition = (e, { value }) => {
    if (field.allowAdditions) {
      setOptions(options.concat({ text: value, value }));
    }
  };
  const clearValue = (e) => {
    const { clientX, clientY, screenX, screenY } = e.nativeEvent;
    if (!clientX && !clientY && !screenX && !screenY) return;
    e.preventDefault();
    const resetValue = initValue;
    setValue(resetValue);
    handleFieldChange(null, { name: field.id, value: resetValue });
  }
  if (entityType) {
    // Effect to clear fields when switching entit type
    useEffect(() => {
      setValue(initValue);
      handleFieldChange(null, { name: field.id, value: defaultValue || null });
    }, [entityType]);
  }
  return (
    <Form.Field required={field.required}>
      {!compact && (
        <label>{field.Header}{!isValidating && (
          <Button onClick={clearValue}>Clear</Button>
        )}</label>
      )}
      {isValidating ? (
        <ValueBox value={value} displayTransform={field.displayTransform} />
      ) : (
        <Form.Select
          fluid={!compact}
          search
          selection
          value={value}
          multiple={field.multiple}
          name={field.id}
          allowAdditions={field.allowAdditions}
          noResultsMessage={field.allowAdditions && 'Insert value and press enter to add to list'}
          onAddItem={handleAddition}
          options={options}
          placeholder={field.Header}
          onChange={setFieldValue}
          required={field.required}
          error={error}
        />
      )}
    </Form.Field>
  )
}
