import React, { useEffect } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { getEntityBasicInfo, updateKeystoneEntity } from '../../../../utils/api';
import { ENTITY_TYPE } from '../constants';
import { CustomTextArea } from '../CustomTextArea';

export function Biography({ setState, state }) {

  useEffect(() => {
    async function fetching() {
      if (!state.biography) {
        await fetchDefaultBiography();
      }
      return () => {
        new AbortController().abort();
      };
    }
    fetching();
  }, []);

  const fetchDefaultBiography = async () => {
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))
    const { basicInfo } = await getEntityBasicInfo(state.dataramaEntityId);
    if (basicInfo) {
      setState(prevState => (
        { ...prevState, biography: basicInfo }
      ))  
    }
    setState(prevState => (
      { ...prevState, isProcessing: false }
    ))
  }

  const handleOnChange = (evt, data) => {
    setState(prevState => (
      { ...prevState, [data.id]: data.value }  
    ))
  }



  const handleOnSubmit = async evt => {
    evt.preventDefault();
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))
    const payload = {
      dataramaId: state.dataramaEntityId,
      biography: state.biography,
      entityType: ENTITY_TYPE,
      showCapitalIQData: false
    }
    if (state.nationality) {
      payload.nationality = state.nationality;
    }
    if (state.yearborn) {
      payload.yearborn = state.yearborn;
    }
    await updateKeystoneEntity(JSON.stringify(payload));
    setState(prevState => (
      { ...prevState,
        completed: { ...prevState.completed, biography: true },
        isProcessing: false }
    ))
  }

  return <><Form size='small' onSubmit={ handleOnSubmit }>
      <div className='segment biography'>
        <div className='justify-space-between'>
          <Button size='mini' content='Save' type='submit' loading={ state.isProcessing } disabled={ state.isProcessing } />
          <Button size='mini' content='Refresh' type='button' onClick={ fetchDefaultBiography } loading={ state.isProcessing } disabled={ state.isProcessing } />
        </div>
        <br />
        <Form.Group widths='equal'>
          <Form.Input
            id='yearborn'
            size='mini'
            type='Number'
            label='Year Born'
            value={state.yearborn}
            onChange={ handleOnChange } />
          <Form.Input
            id='nationality'
            size='mini'
            type='Text'
            label='Nationality'
            value={ state.nationality }
            onChange={ handleOnChange } />
        </Form.Group>
      </div>
    </Form>
    <CustomTextArea setState={ setState } state={ state } />
  </>
}