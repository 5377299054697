import React, { useState } from 'react';
import styled from 'styled-components';
import omit from 'lodash.omit';
import { Label, Button, Loader, Icon, Header, Form, Step, Segment, Message } from 'semantic-ui-react';
import { findDataramaId, updateRel } from '../../utils/api';
import { TempLitigation } from '../../model/litigation/tempLitigation';
import { RelationshipTable } from './RelationshipTable';
import Input from '../Input';
import { Grid } from '../../utils/styles';
import { LitigationCases } from './LitigationCases';
import { stringCleaning } from '../../utils/conversion/specialCharacter';

const Wrapper = styled.div`
  overflow: auto;
	width: 100%;
	padding-top: 10px;
`;
const InfoGrid = styled(Grid).attrs({ col: '25% 75%'})`
  margin: 5px 0;
`;

const CustomGrid = styled(Grid)`
  > div {
    padding: 0 10px;
  }
  > div:last-of-type {
    border-left: 1px solid #ececec;
  }
  margin: 5px 0;
`;

const INPUT_FORM = 'inputForm';
const UPDATE_CASE = 'updateParticipant';
const VALIDATE = 'validate';
const tempLitigation = new TempLitigation();


export function UpdateLitigationCase(props) {
  const { match: { params } } = props;
  const { projectId } = params;
	// Declare active step variables

	// Declare component states
  const [activeCaseNum, setActiveCaseNum] = useState('');

	const [activeStep, setActiveStep] = useState(VALIDATE);
	const [completed, setCompleted] = useState({});
	const [errors, setErrors] = useState({});
	const [, setHttpResponse] = useState(null);
  const [savedAlert, setSavedAlert] = useState(false);
	const [, setInputs] = useState({});
	const [loading, setLoading] = useState({});
	const [results, setResults] = useState(null);
  const [mappedResults, setMappedResults] = useState(null);
  const [activeLitigationCase, setActiveLitigationCase] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [editCase, setEditCase] = useState(null);
  const [participantName, setParticipantName] = useState(null);

  const toggleSaveAlert = () => {
    setSavedAlert(true);
    setTimeout(() => {
      setSavedAlert(false);
    }, 1000)
  };

  const saveInfo = async (field) => {
    setLoading({...loading, update: field });
    const litigationsIds = activeLitigationCase && Object.keys(activeLitigationCase.litigationId || {});
    if (litigationsIds.length > 0 && editCase && editCase.info && editCase.info[field]) {
      const result = await updateRel(projectId, {
        litigationsIds,
        update: {
          [field]: stringCleaning(tempLitigation.editFormFields[field].type, editCase.info[field]),
        },
        entityType: 'litigation'
      });
      toggleSaveAlert();
      const updatedResult = getDtrmResult(result);
      const updatedCase = updatedResult[activeLitigationCase.caseNumStr];

      setActiveLitigationCase({
        ...activeLitigationCase,
        [field]: updatedCase[field],
      });
      setEditMode(null);
    }
    setLoading({...loading, update: false });
  };

  const handleFieldChange = (e, { name, value }) => {
    const updateCase = {
      ...(editCase || {}),
      info: {
        ...((editCase || {}).info || {}),
        [name]: value,
      }
    }
    setEditCase(updateCase);
  }

  const selectCaseToUpdate = (caseNum) => {
    setActiveStep(UPDATE_CASE);
    if (mappedResults[caseNum]) {
      setCompleted({
        ...completed,
        validate: true,
      })
      setActiveLitigationCase(mappedResults[caseNum]);
    }
  }

  const caseContent = (litigationCase) => {
    const isUpdating = activeStep === UPDATE_CASE;
    return (
      <>
        {litigationCase.court && (
          <InfoGrid>
            <div>
              Court
            </div>
            <div>
              {litigationCase.court.courtEntityName} <Label size='small' basic>{litigationCase.court.courtEntityId}</Label>
            </div>
          </InfoGrid>
        )}
        {!isUpdating && (
          <>
            {litigationCase.plaintiff && (
              <InfoGrid>
                <div>
                  Plaintiff
                </div>
                <div>
                {Object.keys(litigationCase.plaintiff).map(plaintiff => (
                  <div key={plaintiff}>
                    {litigationCase.plaintiff[plaintiff].plaintiffName} <Label size='small' basic>{plaintiff}</Label>
                  </div>
                ))}
                </div>

              </InfoGrid>
            )}

            {litigationCase.defendant && (
              <InfoGrid>
                <div>
                  Defendant
                </div>
                <div>
                {Object.keys(litigationCase.defendant).map(defendant => (
                  <div key={defendant}>
                    {litigationCase.defendant[defendant].defendantName} <Label size='small' basic>{defendant}</Label>
                  </div>
                ))}
                </div>

              </InfoGrid>
            )}
            </>
          )}
        {Object.keys(tempLitigation.editFormFields).map(field => (
          <InfoGrid key={field}>
            <div>{tempLitigation.editFormFields[field].Header}</div>
            <div>
              {editMode === field ? (
                <Segment>
                  <Form onSubmit={() => saveInfo(field)}>
                  <Input
        						field={tempLitigation.editFormFields[field]}
        						defaultValue={litigationCase[field] || ''}
        						handleFieldChange={handleFieldChange} />
                  <Button
                    positive
                    basic
                    type="submit"
                    loading={loading.update === field}
                    disabled={(loading.update === field) || (!editCase) || (editCase && !editCase.info) || (editCase && editCase.info && !editCase.info[field])}
                  ><Icon name='save' />Save</Button>
                  {loading.update !== field && (
                    <Button
                      basic
                      size='small'
                      compact
                      onClick={() => setEditMode(null)}
                    ><Icon name='delete'/>Cancel</Button>
                  )}
                  </Form>
                </Segment>
              ) : litigationCase[field]}
              {' '}
              {isUpdating && tempLitigation.editFormFields[field].update && editMode !== field && (
                <Button
                  icon='edit'
                  basic
                  size='small'
                  compact
                  onClick={() => setEditMode(field)}
                />
              )}
            </div>
          </InfoGrid>
        ))}
      </>
    )
  };

  const getDtrmResult = (result = []) => {
    return result.reduce((a, c) => {
      const baseCase = c.caseNumStr;
      a[baseCase] = a[baseCase] || {};
      Object.keys(c).reduce((j, k) => {
        if (tempLitigation.fieldDetails[k]) {
          a[baseCase][k] = a[baseCase][k] || c[k];
        } else if (k === 'defendantId'){
          a[baseCase].defendant = {
            ...(a[baseCase].defendant || {}),
            [c.defendantId]: {
              defendantId: c.defendantId,
              defendantName: c.defendantName,
              entryId: (((a[baseCase].defendant || {})[c.defendantId] || {}).entryId || []).concat(c.entryId),

              litigationId: (((a[baseCase].defendant || {})[c.defendantId] || {}).litigationId || []).concat(c.litigationId),
            }
          }
          a[baseCase].litigationId = {
            ...(a[baseCase].litigationId || {}),
            [c.litigationId]: true,
          }
        } else if (k === 'plaintiffId'){
          a[baseCase].plaintiff = {
            ...(a[baseCase].plaintiff || {}),
            [c.plaintiffId]: {
              plaintiffId: c.plaintiffId,
              plaintiffName: c.plaintiffName,
              entryId: (((a[baseCase].plaintiff || {})[c.plaintiffId] || {}).entryId || []).concat(c.entryId),
              litigationId: (((a[baseCase].plaintiff || {})[c.plaintiffId] || {}).litigationId || []).concat(c.litigationId),
            }
          }
          a[baseCase].litigationId = {
            ...(a[baseCase].litigationId || {}),
            [c.litigationId]: true,
          }
        } else if (k === 'courtEntityName') {
          a[baseCase].court = {
            courtEntityName: c.courtEntityName,
            courtEntityId: c.courtEntityId,
          }
        }
        return j;
      }, {});
      return a;
    }, {});
  }

  const removeFromList = (participantType, id) => {
    setErrors({...errors, [participantType]: null});
    if (activeLitigationCase && activeLitigationCase[participantType]) {
      const idToBeRemoved = [id];
      if (editCase && editCase.delete && editCase.delete[participantType]) {
        idToBeRemoved.push(Object.keys(editCase.delete[participantType]));
      }
      if (Object.keys(omit(activeLitigationCase[participantType], idToBeRemoved)).length === 0) {
        setErrors({
          ...errors,
          [participantType]: 'cannot remove as participants need to be >= 1'
        });
        return;
      }
    }
    const updateDeleteList = {
      ...(editCase || {}),
      delete: {
        ...((editCase || {}).delete || {}),
        [participantType]: {
          ...(((editCase || {}).delete || {})[participantType] || {}),
          [id]: true,
        }
      },
      add: {
        ...((editCase || {}).add || {}),
        [participantType]: omit(((editCase || {}).add || {})[participantType] || {}, [id])
      }
    }
    setEditCase(updateDeleteList);
  }

  const addToList = (participantType, id) => {
    const updateDeleteList = {
      ...(editCase || {}),
      delete: {
        ...((editCase || {}).delete || {}),
        [participantType]: omit(((editCase || {}).delete || {})[participantType] || {}, [id])
      }
    }
    setEditCase(updateDeleteList);

  }

  const updateNewParticipant = (e, { value, name }) => {
    setParticipantName({
      ...(participantName || {}),
      [name]: value,
    })
  }

  const saveResult = async (type) => {
    const { add: addItems, delete: deleteItems } = editCase;

    const updateCase = Object.keys(tempLitigation.fieldDetails).reduce((a, c) => {
      if (activeLitigationCase[c]) {
        a[c] = activeLitigationCase[c];
      }
      return a;
    }, {});
    updateCase.litigationId = [];
    updateCase.plaintiff = {
      add: addItems && addItems.plaintiff && Object.keys(addItems.plaintiff),
      delete: deleteItems && deleteItems.plaintiff && Object.keys(deleteItems.plaintiff),
    }
    updateCase.defendant = {
      add: addItems && addItems.defendant && Object.keys(addItems.defendant),
      delete: deleteItems && deleteItems.defendant && Object.keys(deleteItems.defendant),
    }
    setLoading({ ...loading, [type]: true });
    const result = await updateRel(projectId, {...updateCase, entityType: 'litigation'});
    toggleSaveAlert();
    const updatedResult = getDtrmResult(result);
    setActiveLitigationCase(updatedResult[activeLitigationCase.caseNumStr]);
    setEditCase(null);
    setLoading({ ...loading, [type]: false });
    setMappedResults({
      ...mappedResults,
      updatedResult
    });
  }

  const reset = () => {
    setCompleted({});
    setMappedResults(null);
    setActiveStep(VALIDATE)
    setErrors({});
    setHttpResponse(null);
    setInputs({});
    setLoading({});
    setResults(null);
    setActiveLitigationCase(null);
    setEditMode(null);
    setEditCase(null);
    setParticipantName(null);
  }

  const findEntityByIdAndAdd = async (type) => {
    setLoading({...loading, findEntity: true });
    const id = participantName && participantName[`${type}Id`];
    const entityType = participantName && participantName[`${type}Type`];
    if (!id || !entityType) return;
    const result = await findDataramaId(entityType, id);

    if (result) {
      const updateDeleteList = {
        ...editCase,
        add: {
          ...((editCase || {}).add || {}),
          [type]: {
            ...(((editCase || {}).add || {})[type] || {}),
            [id]: result,
          }
        }
      }
      setEditCase(updateDeleteList);
      setEditMode(null);
      setParticipantName(null);
    } else {
      setErrors({
        ...errors,
        plaintiff: `${id} not found`
      })
    }
    setLoading({...loading, findEntity: false });
  }
  const content = {
    plaintiff: {
      header: 'Plaintiffs',
      name: 'plaintiffName'
    },
    defendant: {
      header: 'Defendants',
      name: 'defendantName'
    }
  }
  const hintMsg = (
    <div>Changes will not be saved before 'Saved' is clicked</div>
  )

  const participantContent = (type) => {
    const typeContent = content[type];
    return (
      <>
      <Header>{typeContent.header}</Header>
      {hintMsg}
      {errors[type] && (
        <Message negative content={errors[type]} />
      )}
      <Segment>
        <CustomGrid>
          <div>
            <Header as='h3'>Current</Header>
          </div>
          <div>
            <Header as='h3'>To be Deleted</Header>
          </div>
        </CustomGrid>
        {Object.keys(activeLitigationCase[type]).map(participant => (
          <CustomGrid key={participant}>
              {(editCase && editCase.delete && editCase.delete[type] && editCase.delete[type][participant]) ? (
                <>
                  <div/>
                  <div>
                    <Button basic positive onClick={() => addToList(type, participant)}>Add Back</Button> {activeLitigationCase[type][participant][typeContent.name]} ({participant})
                  </div>
                </>
              ) : (
                <>
                <div>
                  <Button icon='delete' basic negative onClick={() => removeFromList(type, participant)} /> {activeLitigationCase[type][participant][typeContent.name]} ({participant})
                </div>
                <div/>
                </>
              )}
            <div>
            </div>
          </CustomGrid>
        ))}
        {editCase && editCase.add && editCase.add[type] && Object.keys(editCase.add[type]).map(newParticipant => (
          <CustomGrid key={newParticipant}>
            <div>
              <Button icon='delete' basic negative onClick={() => removeFromList(type, newParticipant)} /> {editCase.add[type][newParticipant].entityName} ({newParticipant}) <Label color='green'>New (Not Saved Yet)</Label>
            </div>
            <div />
          </CustomGrid>
        ))}
        <CustomGrid>
          <div>
          {editMode === type && (
            <Segment>
              {errors[type] && (
                <Message warning>{errors[type]}</Message>
              )}
              <Form onSubmit={() => findEntityByIdAndAdd(type)}>
                <Form.Select
                  name={`${type}Type`}
                  placeholder='Choose entity type'
                  search
                  required
                  options={[
                    { key: 'person', text: 'Person', value: 'person' },
                    { key: 'company', text: 'Company', value: 'company' },
                  ]}
                  onChange={ updateNewParticipant }
                  label='Entity Type' />
                <Form.Input type='number' required name={`${type}Id`} placeholder={`Add ${typeContent.header} Id`} onChange={updateNewParticipant} />
                <Button type='submit' fluid positive loading={loading.findEntity} disabled={loading.findEntity}>Add {typeContent.header}</Button>
              </Form>
            </Segment>
          )}
            <Button positive basic fluid onClick={() => setEditMode(editMode === type ? null : type)}>{editMode === type ? 'Exit/Cancel' : `Add new ${typeContent.header}`}</Button>
          </div>
          <div />
        </CustomGrid>
        {editCase && ((editCase.add && editCase.add[type]) || (editCase.delete && editCase.delete[type])) && (
          <>
            <br/>
            <Button fluid positive onClick={() => saveResult(type)} loading={loading[type]} disabled={loading[type]}>SAVE</Button>
          </>
        )}
      </Segment>
      </>
    )
  }

	return (
		<Wrapper>
			<Loader active={loading.adding}>Loading...</Loader>
      <Step.Group widths={2} size='small' ordered>
        <Step completed={completed.validate} active={activeStep === 'validate'}>
          <Step.Content>
            <Step.Title>
              Find Case
            </Step.Title>
            <Step.Description>
              Find case with the case number
            </Step.Description>
          </Step.Content>
        </Step>
        <Step completed={completed.inputForm} active={activeStep === 'inputForm'} disabled={!completed.validate}>
          <Step.Content>
            <Step.Title>
              Update
            </Step.Title>
            <Step.Description>
              Edit Details of cases or edit participants
            </Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>

      {activeStep === 'validate' && (
        <>
          <LitigationCases
            setActiveCaseNum={setActiveCaseNum}
            activeCaseNum={activeCaseNum}
            mappedResults={mappedResults}
            setMappedResults={setMappedResults}
            setResults={setResults}
            saveInfo={saveInfo}
            loading={loading}
            setLoading={setLoading}
            editMode={editMode}
            setEditMode={setEditMode}
            selectCaseToUpdate={selectCaseToUpdate}
            handleFieldChange={handleFieldChange}
          />
        </>
      )}
      {activeStep === INPUT_FORM &&
        <>
          <RelationshipTable
            isNewRelationship={true}
            uniqueKey='litigationId'
            loading={false}
            recordType="litigation"
            results={results} />
        </>
      }
      {activeStep === UPDATE_CASE && activeLitigationCase &&
        <>

          {savedAlert && (
            <div style={{position: 'fixed',top: 10,zIndex: 5,width: '80%'}}>
            <Message
              success
              header='Case Information saved'
            />
            </div>
          )}
          <Button basic onClick={reset} size='small'><Icon name='arrow left'/> Back</Button>
          <Header>Update Participants for {activeCaseNum}</Header>
          <Segment>
            {caseContent(activeLitigationCase)}
          </Segment>
          {participantContent('plaintiff')}
          {participantContent('defendant')}

        </>
      }

		</Wrapper>
	);
}
