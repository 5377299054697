/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { ResultsWidget } from './ResultsWidget';
import { fbdb } from './firebase';
import {
  getArticlesWithQuery,
  getAllRiskIndicators,
  amendArticleFeedback,
  fetchArticleFeedback } from '../../utils/api';
import { Nametag, SubHeader } from './styles.js';
import { FormattedModeWidget } from './FormattedModeWidget';
import { CONCEPTS, REQUEST_PATH } from './constants';
import { withRouter } from 'react-router';

function AdverseMediaResearchSearchResultsClass({ loggedInUser, history }) {

  // if the appropriate params are not in the url, render 'go back' button
  const urlParams = new URLSearchParams(history.location.search);
  if (urlParams.get('client') === null ||
    urlParams.get('engName') === null ||
    urlParams.get('buckets') === null) {
      return <input type="button" onClick={()=> history.push('/research/adverseMedia') } value="Back to Search Page" />
  }

  const auth = {
    uid: loggedInUser
  }

  const [categories, setCategories] = useState({});
  const [activeCategory, setActiveCategory] = useState(null);
  const [terms, setTerms] = useState(null);
  const [results, setResults] = useState({});
  const [articlesNotAdverse, setArticlesNotAdverse] = useState({});
  const [articlesToIgnore, setArticlesToIgnore] = useState({});
  const [currentSearch, setCurrentSearch] = useState(null);
  const [dispatchableArticles, setDispatchableArticles] = useState({});
  const [isFormattedMode, setIsFormattedMode] = useState(false);
  const [uploaded, setUploaded] = useState(null);
  const [requestQueue, setRequestQueue] = useState(null);
  const [queue, setQueue] = useState({});
  const [defaultCachingDisabled, setDefaultCachingDisabled] = useState(false);
  const [isReportLoading, setIsReportLoading] = useState(false);

  useEffect(() => {
    async function fetching() {
      await fetchSettings();
      return () => {
        new AbortController().abort();
      };
    }
    fetching();
  }, []);

  useEffect(() => {
    if (terms === null) return;
    async function fetching() {
      await handleFetchResults();
      await handleFetchArticlesFeedback();
      return () => {
        new AbortController().abort();
      };
    }
    fetching();
  }, [terms]);

  const fetchSettings = async () => {
    const settings = await getAllRiskIndicators();
    setCategories(CONCEPTS.map(concept => concept.code));
    if (settings) {
      // setCategories(settings.terms.english);
      setTerms({ default: settings });
    }
  }

  const handleFetchArticlesFeedback = async () => {
    const feedbackMap = await fetchArticleFeedback();
    for (let i = 0; i < feedbackMap.length; i++) {
      const { isIrrelevant, isRelatedButNotAdverse, uri } = feedbackMap[i];
      if (isIrrelevant && typeof uri !== 'undefined') {
        setArticlesToIgnore(prevState => (
          { ...prevState, [uri]: true }
        ));  
      }
      if (isRelatedButNotAdverse && typeof uri !== 'undefined') {
        setArticlesNotAdverse(prevState => (
          { ...prevState, [uri]: true }
        ));
      }
    }
  }

  const handleFetchResults = async () => {

    const { client, dateRange, engName, otherLangs, buckets } = {
      'client': urlParams.get('client'),
      'dateRange': JSON.parse(urlParams.get('dateRange')),
      'engName': urlParams.get('engName'),
      'otherLangs': JSON.parse(urlParams.get('otherLangs')),
      'buckets': JSON.parse(urlParams.get('buckets'))
    }

    const languages = Object.assign({}, {
      eng: engName,
    }, otherLangs);

    // need to fetch 'all' first;
    const { uid } = auth;

    setCurrentSearch({ name: languages, dateRange, client });

    // Remove disableCache state in the event user hits refresh
    const state = { ...history.location.state };
    const disableCache = state.disableCache;
    setDefaultCachingDisabled(!!disableCache);
    delete state.disableCache;
    history.replace({ ...history.location, state });

    await Promise.all(['all'].concat(buckets).map(async category => {
      const isGeneral = category === 'all';
      const requestId = await getArticlesWithQuery(languages, {
        category,
        disableCache: typeof(history.location.state) === 'undefined' ? false : !!disableCache,
        client,
        duration: 5,
        uid,
        dateRange,
        buckets: isGeneral ? null : Object.keys(otherLangs).concat('eng').reduce((a, c) => {
          return Object.assign({}, a, {
            [c]: pick(terms[client][c], buckets)
          })
        }, {})
      });
      if (requestId) {
        setRequestQueue(prevState => (
          { ...prevState,
            [category]: { requestId, submittedAt: moment() }}
        ));
        const requestRefPath = `${REQUEST_PATH}/${uid}/${requestId}`;
        // register event
        const requestRef = fbdb.ref(`${requestRefPath}/success`);
        requestRef.on('value', async (snapshot) => {
          const updated = snapshot.val();
          if (updated) {
            const outputRef = fbdb.ref(`${requestRefPath}/results`);
            const snapshot = await outputRef.once('value');
            const output = snapshot.val();
            setRequestQueue(prevState => (
              { ...prevState,
                [category]: { ...prevState[category], status: true }}
            ));
            // results are set before activeCategory is set
            await setResults(prevState => (
              { ...prevState,
                [category]: ((output || {}).results || {})[category] || [] }));
            if (category !== 'all') {
              setActiveCategory(category);
            }
            requestRef.off('value');
          }
        })
      }
      return requestId;
    }))
  }

  const handleSaveCategoryAsCsv = (cat) => {
    const data = results[cat];
    if (data && data.result) {
      const extracts = data.result.map(r => ({
        id: r.id,
        uri: r.uri,
        title: r.title,
        body: JSON.stringify(r.body),
        source: r.link,
        sourceName: r.sourceName,
        dataType: r.dataType,
        dateTime: r.dateTime,
        keyHighlight: r.keyHighlight,
      }))
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        filename: `${currentSearch.name.english}-${cat}-results`,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };
      new ExportToCsv(options).generateCsv(extracts);
    }
  }

  const handleSetValidation = async ({ valid, clearBoth, uri }) => {
    let requestBody = {};
    if (clearBoth) {
      setArticlesToIgnore(prevState => (
        omit(prevState, uri)
      ));
      setArticlesNotAdverse(prevState => (
        omit(prevState, uri)
      ));
      requestBody = {
        uri,
        isIrrelevant: false,
        isRelatedButNotAdverse: false
      }
    }
    if (!clearBoth && valid) {
      setArticlesToIgnore(prevState => (
        omit(prevState, uri)
      ))
      requestBody = {
        uri,
        isIrrelevant: false
      }
    }
    if (!clearBoth && !valid) {
      setArticlesToIgnore(prevState => (
        { ...prevState, [uri]: true }
      ))
      requestBody = {
        uri,
        isIrrelevant: true
      }
    }
    await amendArticleFeedback(JSON.stringify(requestBody));
  }

  const handleMarkNotAdverse = async ({ valid, uri }) => {
    let requestBody = {};
    if (valid) {
      setArticlesNotAdverse(prevState => (
        omit(prevState, uri)
      ))
      requestBody = {
        uri,
        isRelatedButNotAdverse: false
      }
    } else {
      setArticlesNotAdverse(prevState => (
        { ...prevState, [uri]: true }
      ))
      requestBody = {
        uri,
        isRelatedButNotAdverse: true
      }
    }
    await amendArticleFeedback(JSON.stringify(requestBody));
  }

  const handleDeselectDuplicates = ({ id, duplicates }) => {
    const updates = duplicates.reduce((a, c) => {
      if (c === id) return a;
      return Object.assign({}, a, {
        [c]: true,
      })
    }, {})
    setArticlesToIgnore(prevState => (
      {...prevState, updates}
    ))
  }

  const handleFetchByPage = async ({category, page, tracker = null, fetchAll}) => {
    const { name, client, dateRange } = currentSearch;
    const { uid } = auth;
    if (!name) return;
    const requestId = await getArticlesWithQuery(name, {
      category: [category],
      page: `${page}`,
      dateRange,
      uid,
      disableCache: defaultCachingDisabled,
      buckets: Object.keys(name).reduce((a, c) => {
        return Object.assign({}, a, {
          [c]: pick(terms[client][c], category)
        })
      }, {}),
    });
    if (requestId) {
      handleRequest(category, page, requestId, uid, tracker, fetchAll);
    }
  }

  const handleRequest = (category, page, requestId, uid, tracker, fetchAll) => {
    setRequestQueue(prevState => (
      { ...prevState,
        [category]: { requestId, submittedAt: moment() }}
    ));
    const requestRefPath = `${REQUEST_PATH}/${uid}/${requestId}`;
    const requestRef = fbdb.ref(`${requestRefPath}/success`);
    requestRef.on('value', async (snapshot) => {
      const updated = snapshot.val();
      if (updated) {
        // Updated! -> fetch results
        const outputRef = fbdb.ref(`${requestRefPath}/results`);
        const snapshot = await outputRef.once('value');
        const output = snapshot.val();
        setRequestQueue(prevState => (
          { ...prevState,
            [category]: { ...prevState[category], status: true }}
        ));
        if (typeof(output.results[category].result) !== 'undefined') {
          await setResults(prevState => {
            return { ...prevState,
              [category]: Object.assign(
                {},
                prevState[category],
                { page },
                { filteredCount: prevState[category].filteredCount + (output.results[category].filteredCount || 0) },
                { result: (prevState[category].result || []).concat(output.results[category].result) },
                { total: output.results[category].total},
                output.results[category].result.length === 0 ? { isEnd: true } : {}),
            }});
          if (tracker === null) {
            const totalCount = results[category].total;
            const shownCount = results[category].result.length + output.results[category].result.length;
            const filteredCount = results[category].filteredCount + (output.results[category].filteredCount || 0);
            tracker = {
              totalCount,
              shownCount,
              filteredCount
            }  
          } else {
            tracker.shownCount += output.results[category].result.length;
            tracker.filteredCount += output.results[category].filteredCount;
          }
          if (fetchAll && (tracker.totalCount > (tracker.shownCount + tracker.filteredCount))) {
            handleFetchByPage({
              category,
              page: page + 1,
              tracker,
              fetchAll
            })
          }
    
        }
        requestRef.off('value');
      }
    })
  }

  const handleFormatForReport = () => {
    const articles = getDispatchableArticles();
    setDispatchableArticles(articles);
    setIsFormattedMode(!isFormattedMode);
  }

  const getDispatchableArticles = () => {
    return Object.keys(results).reduce((a, c) => {
      return Object.assign({}, a, {
        [c]: (results[c].result || []).filter(r =>
          !(articlesToIgnore[r.uri] || articlesNotAdverse[r.uri])
        )
      })
    }, {});
  }

  const displayableArticles = Object.keys(omit(results, 'all'));
  const SearchTermNames = currentSearch && Object.keys(currentSearch.name).map(n => (<Nametag key={n}>{currentSearch.name[n]}</Nametag>));

  let articleType;
  let dups = 0;

  if (results) {
    // Whether if article is unique within all cateory
    articleType = displayableArticles.reduce((a, cat) => {
      if (results[cat].result) {
        results[cat].result.forEach(art => {
          if (a.all[art.link]) {
            a.dups[art.link] = (a.dups[art.link] || 1) + 1;
          } else {
            a.all[art.link] = true;
          }
          if (a.byTitleAll[art.title]) {
            a.byTitleDups[art.title] = (a.byTitleDups[art.title] || [a.byTitleAll[art.title]]).concat(art.id)
          } else {
            a.byTitleAll[art.title] = art.id;
          }
        })
      }
      return a;
    }, { all: {}, dups: {}, byTitleAll: {}, byTitleDups: {} });
    dups = Object.keys(articleType.dups).reduce((a, c) => {
      return a + articleType.dups[c]
    }, 0);
    Object.keys(articleType.byTitleDups).reduce((a, c) => {
      return a + articleType.byTitleDups[c]
    }, 0);
  }

  const getStats = () => {
    if (!results) return;
    if (!results.all) return;
    return {
      raw: {
        all: results.all.total + dups,
        adv: displayableArticles.reduce((a, c) => a + (results[c].total || 0), 0),
      },
      valid: {
        all: results.all.total - Object.keys(articlesToIgnore).length + dups,
        adv: displayableArticles.reduce((a, c) => a + (results[c].result || []).filter(p =>
          !(articlesToIgnore[p.uri] || articlesNotAdverse[p.uri])
        ).length, 0),
      }
    }
  }

  return (
    <div>
      <SubHeader>Adverse Media Analysis</SubHeader>        
      { isFormattedMode &&
        <FormattedModeWidget
          articlesNotAdverse={ articlesNotAdverse }
          articlesToIgnore={ articlesToIgnore }
          currentSearch={ currentSearch }
          dispatchableArticles={ dispatchableArticles }
          displayableArticles={ displayableArticles }
          handleFormatForReport={ handleFormatForReport }
          results={ results }
          stats={ getStats() }
          SearchTermNames = { SearchTermNames }
          uploaded={ uploaded } /> }
      <br />
      <ResultsWidget
        activeCategory={ activeCategory }
        articlesNotAdverse={ articlesNotAdverse }
        articlesToIgnore={ articlesToIgnore }
        articleType={ articleType }
        categories={ categories }
        currentSearch={ currentSearch }
        handleDeselectDuplicates={ handleDeselectDuplicates }
        handleFetchByPage={ handleFetchByPage }
        handleFormatForReport= { handleFormatForReport }
        handleMarkNotAdverse={ handleMarkNotAdverse }
        handleSaveCategoryAsCsv={ handleSaveCategoryAsCsv }
        handleSetValidation={ handleSetValidation }
        loggedInUser={ loggedInUser }
        queue={ queue }
        requestQueue={ requestQueue }
        results={ results }
        setActiveCategory={ setActiveCategory }
        stats={ getStats() }
        terms={ terms } />
      </div>
  )
}

export const AdverseMediaResearchSearchResults = withRouter(AdverseMediaResearchSearchResultsClass);