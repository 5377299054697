import React from 'react';
import { updateKeystoneEntity } from '../../../../utils/api';
import { ENTITY_TYPE } from '../constants';

import { Button, Form } from 'semantic-ui-react';

export function MediaRiskRating({ state, setState }) {
  const handleOnSubmit = async evt => {
    evt.preventDefault();
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))
    const payload = {
      entityType: ENTITY_TYPE,
      dataramaId: state.dataramaEntityId
    };
    for (let attr of ['generalMediaRating', 'adverseMediaRating', 'mediaRatingRatio']) {
      if (state[attr]) {
        payload[attr] = state[attr];
      }
    }
    await updateKeystoneEntity(JSON.stringify(payload));

    setState(prevState => (
      { ...prevState,
        isProcessing: false
      }
    ))
  }

  const handleOnChange = (evt, data) => {
    let mediaRatingRatio = '';
    if (data.id === 'generalMediaRating') {
      mediaRatingRatio = state.adverseMediaRating / data.value;
    }
    if (data.id === 'adverseMediaRating') {
      mediaRatingRatio = data.value/ state.generalMediaRating;
    }
    if (mediaRatingRatio === '') {
      setState(prevState => (
        { ...prevState, [data.id]: data.value }  
      ))  
    } else {
      setState(prevState => (
        { ...prevState, [data.id]: data.value, mediaRatingRatio }  
      ))  
    }
  }

  const FORM_FIELDS = [
    [
      { key: 'generalMediaRating', label: 'General Media Rating', disabled: false, type: 'number' },
      { key: 'adverseMediaRating', label: 'Adverse Media Rating', disabled: false, type: 'number' },
      { key: 'mediaRatingRatio', label: 'Media Rating Ratio', disabled: true, type: 'number' }  
    ],
    [
      { key: 'sector', label: 'Sector', disabled: true, type: 'text' },
      { key: 'country', label: 'Country', disabled: true, type: 'text' }
    ]
  ]
  
  return <Form size='small' onSubmit={ handleOnSubmit }>
    <div className='segment'>
      <div className='justify-space-between'>
        <Button size='mini' content='Save' type='submit' loading={ state.isProcessing } disabled={ state.isProcessing } />
      </div>
      <br />
      { FORM_FIELDS.map(fieldGroup => {
        return <Form.Group widths='equal'>
          {fieldGroup.map(field => (
            <Form.Input
              id={ field.key }
              size='mini'
              type={ field.type }
              label={ field.label }
              disabled={ field.disabled }
              value={ state[field.key] }
              onChange={ handleOnChange } />
          ))}
        </Form.Group>
      })}
    </div>
  </Form>
}