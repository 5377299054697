export const recordCount = count => `(${count} Record${count > 1 ? 's' : ''})`;

export const nameDisplay = (type, candidate) => {
  if (type === 'person') {
    const { forename, middlename, surname } = candidate;
    if (!forename && !middlename && !surname) {
      return candidate.name
    }
    return `${forename}${middlename ? ` ${middlename}` : ''} ${surname}`
  }
  return candidate.businessName || candidate.name;
}
