import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { Button } from './common';
import ValueBox from './ValueBox';
import styled from 'styled-components';

const Note = styled.small`
  color: #666666;
`;

export default ({
  field,
  type,
  error,
  handleFieldChange,
  isValidating,
  defaultValue,
  entityType,
  disabled,
  meta,
  compact
}) => {
  const [value, setValue] = useState(defaultValue || '');

  const setFieldValue = (e, { name, value }) => {
    setValue(value);
    handleFieldChange(null, { name: field.id, value });
  }
  const clearValue = (e) => {
    const { clientX, clientY, screenX, screenY } = e.nativeEvent;
    if (!clientX && !clientY && !screenX && !screenY) return;
    e.preventDefault();
    setValue('');
    handleFieldChange(null, { name: field.id, value: null });
  }
  if (entityType) {
    // Effect to clear fields when switching entit type
    useEffect(() => {
      setValue('');
      handleFieldChange(null, { name: field.id, value: null });
    }, [entityType]);
  }

  useEffect(() => {
    if (disabled && field.id === 'shareholdingPercentage') {
      setValue('');
    }
  }, [disabled]);

  const charPatterns = field.minLength ? {
    pattern: `.{${field.minLength},}`,
    title: `Required ${field.minLength} minimum characters`
  } : {}
  const additionalProps = meta || {};

  return (
    <Form.Field required={field.required}>
      {!compact && (
        <label>{field.Header} {(!isValidating && !disabled) && (
          <Button onClick={clearValue}>Clear</Button>
        )}</label>
      )}
      {isValidating ? (
        <ValueBox value={value} />
      ) : (
        <>
        <Form.Input
          placeholder={field.Header}
          onChange={setFieldValue}
          name={field.id}
          type={type || 'text'}
          value={value}
          required={field.required}
          error={error}
          disabled={disabled}
          {...charPatterns}
          {...additionalProps}
        />
          { meta && meta.message && <Note>{meta.message}</Note>}</>
      )}
    </Form.Field>
  )
}
