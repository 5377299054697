/* eslint-disable no-fallthrough */
import { SET_AUTH_STATE, UPDATE_SELF_PERMISSION } from '../actions/actionTypes';

const initalState = {};

export default (state = initalState, action) => {
  switch(action.type) {
    case SET_AUTH_STATE: {
      return {
        ...state,
        loggedInUser: action.userId,
        permissions: action.permissions,
      }
    }
    case UPDATE_SELF_PERMISSION: {
      if (state.loggedInUser) {
        const updates = action.resourceId ? {
          [action.resourceId]: action.permissions
        } : action.permissions;
        return {
          ...state,
          permissions: {
            ...state.permissions,
            [action.resourceType]: {
              ...updates
            }
          }
        }
      }
    }
    default:
      return state;
  }
}
