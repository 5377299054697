import React, { useEffect, useState } from 'react';
import { ResultsWidget } from './ResultsWidget';
import { Button, Progress } from 'semantic-ui-react';

import { WEBSOCKET_EVENTS, RISK_FACTORS_TREE } from '../../../constants';
import { SearchContext } from './SearchContext';
import { updatePreviewReport, uploadDocument } from '../../../utils/api';

export function SearchResults({state, setState}) {

  // const URL = 'wss://8cf4mxey45.execute-api.ap-northeast-2.amazonaws.com/prod';
  const URL = 'wss://d3l14hr2rh.execute-api.ap-northeast-2.amazonaws.com/prod';
   
  const [results, setResults] = useState({
    progressPercent: 0,
    articles: {},
    currentPage: 0,
    isLoading: false,
    articlesCount: 0,
    mainEntity: state.search.requestPayload.eng.entityName,
    entityType: state.isCompany ? 'company' : 'individual',
    isCacheDisabled: state.search.isCacheDisabled,
    isEventRegistry: state.search.isEventRegistry,
    dateRange: state.search.dateRange,
    requestPayload: state.search.requestPayload
  });

  const getEntityAlias = () => {
    const payload = results.requestPayload;
    let list = [];

    for (let lang in payload) {
      if (payload[lang].entityName) {
        list = list.concat(payload[lang].entityName, payload[lang].entityName.split(' '));
      }
      if (payload[lang].secondEntityName) {
        list = list.concat(payload[lang].secondEntityName, payload[lang].secondEntityName.split(' '));
      }
    }
    return list.filter(x => x).filter((v, i, a) => a.indexOf(v) === i);
  }

  const handleFetchResults = async (ws, page) => {
    const query = {
      "keyword": results.requestPayload,
      "isEventRegistry": results.isEventRegistry,
      "dateRange": results.dateRange,
      "page": page,
      "entityType": results.entityType,
      "isCacheDisabled": results.isCacheDisabled
    };
    ws.send(JSON.stringify({ "action": "broadcast", "message": query }));
  }

  useEffect(() => {
    async function fetching() {

    const ws = new WebSocket(URL);
      ws.onopen = () => {
        console.log('connected');

        handleFetchResults(ws, results.currentPage);
      }

      ws.onmessage = async evt => {
        try {
          const message = JSON.parse(evt.data);

          if (message && message.type === WEBSOCKET_EVENTS.RETRIEVE_ARTICLES) {
            setResults(prevState=> (
              { ...prevState,
                progressPercent: 33,
                progressLabel: `Retrieving ${message.language} articles from ${results.isEventRegistry ? 'Event Registry...' : 'Crawled Articles Database...'}`
              }
            ))    
          }

          if (message && message.type === WEBSOCKET_EVENTS.NLP) {
            console.log('nlp');
            setResults(prevState=> (
              { ...prevState,
                progressPercent: 66,
                progressLabel: `Applying NLP for ${message.language} articles...`
              }
            ))
          }
          if (message && message.type === WEBSOCKET_EVENTS.START_OF_ARTICLES_CHAIN) {
            console.log('start of articles chain');
          }
          if (message && message.type === WEBSOCKET_EVENTS.SENDING_ARTICLES) {
            console.log('sending articles');
            const translatedArticle = translateArticle(message.language, message.article._id, message.article._source);
            setResults(prevState=> {
              if (state.articles[translatedArticle.id]) {
                return { ...prevState,
                  articles: { ...prevState.articles, [translatedArticle.id]: {...translatedArticle, relevance: state.articles[translatedArticle.id].relevance } }
                }  
              }
              return { ...prevState,
                articles: { ...prevState.articles, [translatedArticle.id]: translatedArticle }
              }
            })
          }
          if (message && message.type === WEBSOCKET_EVENTS.END_OF_ARTICLES_CHAIN) {
            console.log('end of articles chain');
            setResults(prevState=> (
              { ...prevState,
                progressPercent: 99,
                progressLabel: `${message.language} articles fetched for Page ${results.currentPage + 1}`,
                isLoading: false
              }
            ))
          }

          if (message && message.type === WEBSOCKET_EVENTS.END_CONNECTION) {
            setTimeout(() => {
              setResults(prevState=> (
                { ...prevState,
                  progressPercent: 0,
                  progressLabel: null
                }
              ))      
            }, 2000);
            ws.close();
          }
          if (message && message.message === 'Internal server error') {
            console.log('message: ', message)
            setResults(prevState=> (
              { ...prevState,
                progressPercent: 0,
                progressLabel: 'Internal server error'
              }
            ))
            ws.close();  
          }

        } catch (err) {
          console.log('error: ', err);
          return;
      }
      }

      ws.onclose = () => {
        console.log('disconnected');
      }

      return () => {
        new AbortController().abort();
      };
    }
    setResults(prevState=> (
      { ...prevState,
        progressPercent: 1,
        progressLabel: 'Initializing...',
        isLoading: true
      }
    ))
    fetching();
  }, [results.currentPage]);

  const translateArticle = (lang, articleId, article) => {
    return {
      id: articleId,
      date: article.date_publish,
      source: article.source_domain,
      url: article.url,
      title: article.title,
      text: article.text,
      meta: article.meta,
      riskFactors: article.meta.bagOfWordsDetails,
      bagOfWords: article.meta.bagOfWords,
      entities: getEntityAlias(),
      lang,
      relevance: 0
    }
  }

  const handleSaveReport = async evt => {
    setState(prevState => (
      { ...prevState,
        isProcessing: true }))

    evt.preventDefault();
    const adverseArticlesCount = Object.keys(results.articles).filter(articleId => results.articles[articleId].relevance === 1).length;
    let articles = addRiskCategoriesToArticles(results.articles);
    for (let articleId in articles) {
      const s3Url = await uploadDocument(JSON.stringify({
        url: articles[articleId].url,
        entityId: state.dataramaEntityId
      }));
      if (s3Url !== null) {
        articles[articleId].s3Url = s3Url.location;
      }
    }
    await updatePreviewReport(JSON.stringify({
      reportId: state.reportId,
      articles,
      adverseArticlesCount,
    }))
    setState(prevState => (
      { ...prevState,
        adverseArticlesCount,
        articles,
        isProcessing: false,
        completed: { ...prevState.completed, articles: true } }))
  }

  const addRiskCategoriesToArticles = articles => {
    return Object.keys(articles).reduce((acc, articleId) => {
      if (articles[articleId].relevance === 1) {
        acc[articleId] = {
          ...articles[articleId],
          riskCategories: [...new Set(articles[articleId].riskFactors.map(rf => RISK_FACTORS_TREE[rf][state.isCompany ? 'company' : 'individual']))]
        };  
      }
      return acc;
    }, {});
  }


  return <div className='adverse-media-results'>
  { results.progressPercent !== 0 &&
    <Progress percent={results.progressPercent} indicating>{ results.progressLabel }</Progress> }
    { Object.keys(results.articles).length > 0 &&
    <SearchContext.Provider value={{
      results: results,
      setResults: articles => setResults(prevState => (
        { ...prevState, articles }
        )) }}>
      <Button size='mini'
        content='Fetch more articles'
        disabled={results.progressPercent !== 0}
        onClick={() => setResults(prevState => (
          { ...prevState, currentPage: prevState.currentPage + 1 }
        ))} />
      <Button
        size='mini'
        disabled={state.isProcessing}
        loading={state.isProcessing}
        onClick={handleSaveReport}
        content='Save Articles' />
      <ResultsWidget
        articles={
          Object.keys(results.articles)
            .map(id => results.articles[id])
            .sort((a, b) => a.relevance - b.relevance) } />
    </SearchContext.Provider> }
  </div>;
}
