import React from 'react';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';

export default ({ date, loading }) => {
  return (
    <span style={{
      fontSize: '12px'
    }}> Last Updated: {date && moment(date).format('MMMM Do YYYY, h:mm:ss a')} {loading && (
      <React.Fragment>
      <span style={{
        padding: '2px 5px',
        background: '#ccc',
        borderRadius: '2px',
        marginRight: '10px'
      }}>Loading...</span><Loader active inline size='mini'/>
      </React.Fragment>
    )}</span>
  )
}
