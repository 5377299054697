import React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import { DtrmEntityId } from './DtrmEntityId';
import { createPreviewReport, getPreviewReportByEntityId, retrieveEntity } from '../../../utils/api';
import { BASIC_INFORMATION, BLACKLIST, POLITICAL_EXPOSURE } from '../../../constants';

export function EntityId({ state, setState }) {

  const handleOnSubmit = async evt => {
    evt.preventDefault();
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))
    const result = await retrieveEntity(state.dataramaEntityId);
    if (result.length === 0) {
      setState(prevState => (
        { ...prevState,
          entityName: '',
          isError: true,
          completed:
        { ...prevState.completed, entityId: false } }
      ))
    } else if (result.length === 1) {
      setState(prevState => (
        { ...prevState,
          isError: false,
          entityType: result[0].entityTypeDescStr, 
          entityName: result[0].cleanNameStr,
          isCompany: result[0].entityTypeDescStr !== 'person',
          completed: { ...prevState.completed, entityId: true } }
      ))
      const report = await getPreviewReportByEntityId(state.dataramaEntityId);
      if (Object.keys(report).length !== 0) {
        setState(prevState => (
          { ...prevState,
            [BASIC_INFORMATION]: report.basicInformation || '',
            [BLACKLIST]: report.blacklist || '',
            [POLITICAL_EXPOSURE]: report.politicalExposure || '',
            adverseArticlesCount: report.adverseArticlesCount,
            articles: report.articles || {},
            totalArticlesCount: report.totalArticlesCount || 0,
            completed: {
              ...prevState.completed,
              basicInfo: !!report.basicInformation,
              pep: !!report.politicalExposure,
              sanctions: !!report.blacklist,
              articles: !!report.articles,
              addSummary: !!report.totalArticlesCount
            },
            reportId: report._id }))
      }
    }
    setState(prevState => (
      { ...prevState, isProcessing: false }
    ))

  }

  const handleCreateReport = async evt => {
    evt.preventDefault();
    const payload = {
      entityName: state.entityName,
      dataramaEntityId: state.dataramaEntityId,
      isCompany: state.isCompany,
      isProcessing: true
    }
    const { reportId } = await createPreviewReport(payload);
    setState(prevState => (
      { ...prevState, active: 'basicInfo', reportId, isProcessing: false }
    ))

  }

  return <div className='entityId'>
    <Form size='small' onSubmit={ handleOnSubmit }>
      <DtrmEntityId setState= { setState } state={ state } />
      <Button type='submit' content='Submit'
        loading={state.isProcessing}
        disabled={state.isProcessing} />
    </Form>
  { !state.isProcessing && state.isError &&
    <Message negative>
      <span>Entity is not found in database. Please create entity before proceeding.</span>
    </Message>}
  { !state.isProcessing && state.completed.entityId &&
    <Message warning>
      <span>{state.entityName} is found.</span>
      <br />
      { state.reportId === '' &&
      <>
      <span onClick={ handleCreateReport } className='link'>Proceed</span>
      <span> to create liteEDD report?</span>
      </> }
      { state.reportId !== '' &&
      <>
      <span onClick={ () => {
        setState(prevState => (
          { ...prevState, active: 'basicInfo' }
        ))
      } } className='link'>Proceed</span>
      <span> to update liteEDD report?</span>
      </> }
    </Message> }

  </div>
}