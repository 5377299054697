import React, { useState, useEffect } from 'react';
import { Button, Dimmer, Accordion, Icon } from 'semantic-ui-react';
import ReactTable from 'react-table';
import styled from 'styled-components';
import LastUpdated from '../LastUpdated';
import { TempRelationship } from '../../model/relationship/tempRelationship';
import { TempLitigation } from '../../model/litigation/tempLitigation';
import { RelationshipEntry } from './RelationshipEntry';
const { listIngestionRecordsByProject } = require('../../utils/api');

const TopWrapper = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
`;

export function RelationshipRecords({
  loaded,
  entryPopup,
  permitted,
  updateRelRecords,
  history,
  toggleEntryPopup,
  relRecords,
  lastFetched,
  match,
  type,
  project }) {

  const tempRelationship = type === 'litigation' ? new TempLitigation() : new TempRelationship();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(!loaded) {
      fetchRecords();
    }
  },[]);

  const fetchRecords = async() => {
    setLoading(true);
    const { projectId } = project || {};
    if (!projectId || !permitted) {
      updateRelRecords(null);
      return;
    };
    const relScope = type || 'relationship';

    const records = await listIngestionRecordsByProject(projectId, relScope);
    updateRelRecords(records);
    setLoading(false);
  }

  const updateEntry = (e, { value }) => {
    if (!value) return;
    const { type, relId } = value || {};
    if (!type || !relId) return;
    const { projectId } = match.params;
    history.push({
      pathname: `/project/${projectId}/actions/${type}/update`,
      state: { relId, type }
    })
  }

  const cellOnClick = (state, rowInfo, column, instance) => {
    return {
      onClick: (e, handleOriginal) => {
        toggleEntryPopup(null, rowInfo && rowInfo.original);
      }
    }
  }

  return (
    <><TopWrapper>
      <Button size='small' compact onClick={ fetchRecords } icon basic>
        <Icon name='refresh'/>
      </Button>
      <LastUpdated loading={!loaded} date={lastFetched} />
      <br /><br />
      { relRecords && (
        <Dimmer.Dimmable
          as={ Accordion }
          dimmed={ loading }
          blurring fluid styled>
        <ReactTable
          data={ relRecords }
          columns={ tempRelationship.displayColumns }
          defaultPageSize={ 10 }
          filterable
          className='-striped -highlight'
          style={{ cursor: 'pointer' }}
          getTdProps={ cellOnClick }
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]).indexOf(filter.value) !== -1 }
        />
        </Dimmer.Dimmable>)}
        { entryPopup && <RelationshipEntry
          close={ toggleEntryPopup }
          entry={ entryPopup }
          updateEntry={ updateEntry }
          allowUpdates={ project && project.allowUpdates } /> }
    </TopWrapper></>
  )
}
