import React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import { DtrmEntityId } from './DtrmEntityId';
import { getEntityFromKeystone, retrieveEntity, updateKeystoneEntity, findDataramaId } from '../../../../utils/api';
import { ENTITY_TYPE, INDUSTRY_MAPPING } from '../constants.js';

export function EntityId({ state, setState }) {

  const handleOnSubmit = async evt => {
    evt.preventDefault();
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))
    const result = await retrieveEntity(state.dataramaEntityId);
    if (result.length === 0) {
      setState(prevState => (
        { ...prevState,
          entityName: '',
          errorMessage: 'No such entity found in database.',
          completed:
        { ...prevState.completed, entityId: false } }
      ))
    } else if (result.length === 1) {
      if (result[0].entityTypeDescStr !== ENTITY_TYPE) {
        setState(prevState => (
          { ...prevState,
            entityName: '',
            isProcessing: false,
            errorMessage: 'Entity found in database but is not an organisation',
            completed:
          { ...prevState.completed, entityId: false } }
        ))
        return;
      }

      setState(prevState => (
        { ...prevState,
          errorMessage: null,
          entityType: result[0].entityTypeDescStr, 
          entityName: result[0].cleanNameStr,
          completed: { ...prevState.completed, entityId: true } }
      ))
      const sqlEntity = await findDataramaId(ENTITY_TYPE, state.dataramaEntityId);
      console.log('sqlEntity: ', sqlEntity);
      setState(prevState => (
        { ...prevState,
          sqlData: sqlEntity === null ? {} : translateSQLQueryPayload(sqlEntity) }
      ))
      const { response } = await getEntityFromKeystone({
        entityId: state.dataramaEntityId,
        entityType: ENTITY_TYPE });
      console.log('rrreeesssponse:: ', response);
      if (response !== null) {
        const keystoneId = response._id;
        delete response._id;
        delete response.__v;
        setState(prevState => (
          { ...prevState,
            keystoneId,
            ...response
          }
        ))
      }
    }
    setState(prevState => (
      { ...prevState, isProcessing: false }
    ))

  }

  const translateSQLQueryPayload = payload => {
    return Object.keys(payload).reduce((acc, cur) => {
      if (cur === 'country' && payload[cur]) {
        acc.country = payload[cur];
      }
      if (cur === 'entityStartDate' && payload[cur]) {
        acc.yearfounded = payload[cur].split('-')[0]
      }
      if (cur === 'source') {
        if (payload.source === 'cap iq' && payload.identificationNumStr) {
          acc.capitalIQID = payload.identificationNumStr;
        } else {
          acc.capitalIQID = null;
        }
      }
      if (cur === 'addressStr' && payload[cur]) {
        acc.streetaddress = payload[cur];
      }
      if (cur === 'faxNumStr' && payload[cur]) {
        acc.officefax = payload[cur];
      }
      if (cur === 'phoneNumStr' && payload[cur]) {
        acc.officephone = payload[cur];
      }
      if ( cur === 'simpleIndustry' && payload[cur]) {
        acc.subsector = payload[cur];
        acc.sector = INDUSTRY_MAPPING[payload[cur]];
      }
      if (payload.country === 'China' && cur === 'businessRegistrationNo1' && payload[cur]) {
        acc.companyRegistrationNumber = payload[cur];
      }
      if (payload.country === 'Indonesia' && cur === 'NPWP94' && payload[cur]) {
        acc.companyRegistrationNumber = payload[cur];
      }
      if (['China', 'Indonesia'].indexOf(payload.country) === -1 && cur === 'otherRegistrationNo1' && payload[cur]) {
        acc.companyRegistrationNumber = payload[cur];
      }
      if (cur === 'entityLegalForm' && payload[cur]) {
        acc.detailedcompanytype = payload[cur];
      }
      if (cur === 'entityStatus' && payload[cur]) {
        acc.companystatus = payload[cur];
      }
      return acc;
    }, {});
  }

  const handleCreateKeystoneEntity = async evt => {
    evt.preventDefault();
    const payload = JSON.stringify({
      name: state.entityName,
      entityType: ENTITY_TYPE,
      dataramaId: state.dataramaEntityId,
      showCapitalIQData: false,
      showNotResearchedText: false,
      ...state.sqlData
    });
    const { response } = await updateKeystoneEntity(payload);
    const SKIPPED_ATTRIBUTES = ['_id', '__v'];
    const translatedResponse = Object.keys(response).reduce((acc, cur) => {
      if (SKIPPED_ATTRIBUTES.indexOf(cur) !== -1) {
        return acc;
      }
      if (typeof response[cur] !== 'undefined') {
        acc[cur] = response[cur];
      }
      return acc;
    }, {});
    setState(prevState => (
      { ...prevState,
        active: 'businessdescription',
        keystoneId: response._id,
        isProcessing: false,
        ...translatedResponse
      }
    ))
  }

  return <div className='entityId'>
    <Form size='small' onSubmit={ handleOnSubmit }>
      <DtrmEntityId setState= { setState } state={ state } />
      <Button type='submit' content='Submit'
        loading={state.isProcessing}
        disabled={state.isProcessing} />
    </Form>
  { !state.isProcessing && state.errorMessage &&
    <Message negative>
      <span>{ state.errorMessage }</span>
    </Message> }
  { !state.isProcessing && state.completed.entityId &&
    <Message warning>
      <span>{state.entityName} is found.</span>
      <br />
      { state.keystoneId === '' &&
      <>
      <span onClick={ handleCreateKeystoneEntity } className='link'>Proceed</span>
      <span> to create new entry?</span>
      </> }
      { state.keystoneId !== '' &&
      <>
      <span onClick={ () => {
        setState(prevState => (
          { ...prevState, active: 'businessdescription' }
        ))
      } } className='link'>Proceed</span>
      <span>{` to update existing entry [${ state.dataramaEntityId }]?`}</span>
      </> }
    </Message> }

  </div>
}