import React from 'react';
import styled from 'styled-components';
import { Label, Button, Icon, Header, Form, Segment, Message } from 'semantic-ui-react';
import { TempLitigation } from '../../model/litigation/tempLitigation';
import Text from './fields/Text';
import { Grid } from '../../utils/styles';
import { validateLitigation } from '../../utils/api';
import Input from '../Input';

const UPDATE_CASE = 'updateParticipant';

const InfoGrid = styled(Grid).attrs({ col: '25% 75%'})`
  margin: 5px 0;
`;

const getDtrmResult = (result = []) => {
  return result.reduce((a, c) => {
    const baseCase = c.caseNumStr;
    a[baseCase] = a[baseCase] || {};
    Object.keys(c).reduce((j, k) => {
      if (tempLitigation.fieldDetails[k]) {
        a[baseCase][k] = a[baseCase][k] || c[k];
      } else if (k === 'defendantId'){
        a[baseCase].defendant = {
          ...(a[baseCase].defendant || {}),
          [c.defendantId]: {
            defendantId: c.defendantId,
            defendantName: c.defendantName,
            entryId: (((a[baseCase].defendant || {})[c.defendantId] || {}).entryId || []).concat(c.entryId),

            litigationId: (((a[baseCase].defendant || {})[c.defendantId] || {}).litigationId || []).concat(c.litigationId),
          }
        }
        a[baseCase].litigationId = {
          ...(a[baseCase].litigationId || {}),
          [c.litigationId]: true,
        }
      } else if (k === 'plaintiffId'){
        a[baseCase].plaintiff = {
          ...(a[baseCase].plaintiff || {}),
          [c.plaintiffId]: {
            plaintiffId: c.plaintiffId,
            plaintiffName: c.plaintiffName,
            entryId: (((a[baseCase].plaintiff || {})[c.plaintiffId] || {}).entryId || []).concat(c.entryId),
            litigationId: (((a[baseCase].plaintiff || {})[c.plaintiffId] || {}).litigationId || []).concat(c.litigationId),
          }
        }
        a[baseCase].litigationId = {
          ...(a[baseCase].litigationId || {}),
          [c.litigationId]: true,
        }
      } else if (k === 'courtEntityName') {
        a[baseCase].court = {
          courtEntityName: c.courtEntityName,
          courtEntityId: c.courtEntityId,
        }
      }
      return j;
    }, {});
    return a;
  }, {});
}


const tempLitigation = new TempLitigation();

export function LitigationCases(props) {
  const {
    setActiveCaseNum,
    activeCaseNum,
    mappedResults,
    setMappedResults,
    setResults,
    editMode,
    saveInfo,
    loading,
    setLoading,
    handleFieldChange,
    setEditMode,
    selectCaseToUpdate,
    readOnly,
    validateComplete,
  } = props;

  const findCase = async () => {
    if (!activeCaseNum) return;
    setLoading({...loading, search: true});
    const result = await validateLitigation({ caseNumStr: activeCaseNum });
    if (result && result.dtrm) {
      const transformedResults = getDtrmResult(result.dtrm);
      setMappedResults(transformedResults);
      setResults(result);
    }
    setLoading({...loading, search: false});

  }

  const caseContent = (litigationCase, isUpdating) => {
    return (
      <>
        {litigationCase.court && (
          <InfoGrid>
            <div>
              Court
            </div>
            <div>
              {litigationCase.court.courtEntityName} <Label size='small' basic>{litigationCase.court.courtEntityId}</Label>
            </div>
          </InfoGrid>
        )}
        {!isUpdating && (
          <>
            {litigationCase.plaintiff && (
              <InfoGrid>
                <div>
                  Plaintiff
                </div>
                <div>
                {Object.keys(litigationCase.plaintiff).map(plaintiff => (
                  <div key={plaintiff}>
                    {litigationCase.plaintiff[plaintiff].plaintiffName} <Label size='small' basic>{plaintiff}</Label>
                  </div>
                ))}
                </div>

              </InfoGrid>
            )}

            {litigationCase.defendant && (
              <InfoGrid>
                <div>
                  Defendant
                </div>
                <div>
                {Object.keys(litigationCase.defendant).map(defendant => (
                  <div key={defendant}>
                    {litigationCase.defendant[defendant].defendantName} <Label size='small' basic>{defendant}</Label>
                  </div>
                ))}
                </div>

              </InfoGrid>
            )}
            </>
          )}
        {Object.keys(tempLitigation.displayFieldDetails).map(field => litigationCase[field] ? (
          <InfoGrid key={field}>
            <div>{tempLitigation.displayFieldDetails[field].Header}</div>
            <div>
              {editMode === field ? (
                <Segment>
                  <Form onSubmit={() => saveInfo(field)}>
                  <Input
        						field={tempLitigation.displayFieldDetails[field]}
        						defaultValue={litigationCase[field] || ''}
        						handleFieldChange={handleFieldChange} />
                  <Button positive basic type="submit" loading={loading.update === field} disabled={loading.update === field}><Icon name='save' />Save</Button>
                  {loading.update !== field && (
                    <Button
                      basic
                      size='small'
                      compact
                      onClick={() => setEditMode(null)}
                    ><Icon name='delete'/>Cancel</Button>
                  )}
                  </Form>
                </Segment>
              ) : litigationCase[field]}
              {' '}
              {isUpdating && tempLitigation.displayFieldDetails[field].update && editMode !== field && (
                <Button
                  icon='edit'
                  basic
                  size='small'
                  compact
                  onClick={() => setEditMode(field)}
                />
              )}
            </div>
          </InfoGrid>
        ): null)}
      </>
    )
  };

  return (
    <>
    <Form onSubmit={findCase}>
      <Text
        field={{
          Header: 'Case Number',
          id: 'caseNumStr',
          required: true,
          minLength: 4,
        }}
        handleFieldChange={(e, { value }) => setActiveCaseNum(value)}
        defaultValue={activeCaseNum}
      />
      <Button type="submit" positive loading={loading.search} disabled={loading.search}>Find Case</Button>
      {validateComplete && (
        <Button content='Skipped (Please make sure before progressing)'
        onClick={validateComplete} value={true} />
      )}
    </Form>
    {mappedResults && (
      <Message>
        {Object.keys(mappedResults).length} cases match found.
      </Message>
    )}
    {mappedResults && Object.keys(mappedResults).map((caseNum, matchIndex) => (
      <div key={caseNum}>
        {mappedResults[caseNum] && (
          <Segment>
            <Header><Label circular>{matchIndex + 1}</Label>{caseNum}</Header>
            {caseContent(mappedResults[caseNum], editMode === UPDATE_CASE)}
            <br/>
            {!readOnly && (
              <Button onClick={() => selectCaseToUpdate(caseNum)} basic positive>Select case {caseNum} to update</Button>
            )}
          </Segment>
        )}
      </div>
    ))}
    </>
  )
}
