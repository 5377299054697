import React from 'react';
import { Menu } from 'semantic-ui-react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import MenuItem from './MenuItem';
import EntityRecords from './ingestion/EntityRecords';
import AccessDenied from './AccessDenied';
import { RelationshipRecords } from './ingestion/RelationshipRecords';

const Scrollable = styled.div`
  overflow: auto;
  padding: 20px 5px 5px 5px;
`;
class IngestionRecords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.toggleEntryPopup = this.toggleEntryPopup.bind(this);
  }

  componentDidMount() {
    const { url, params } = this.props.match;
    const { projectId } = params;
    if (url === `/project/${projectId}/records`) {
      this.props.history.push(`${url}/entity`)
    }
  }

  toggleEntryPopup(e, value) {
    this.setState(prevState => ({
      entryPopup: prevState.entryPopup ? null : value,
    }))
  }

  render() {
    const { entryPopup } = this.state;
    const { entityRecords, loaded, permitted, lastFetched, relRecords, litRecords } = this.props;

    const { match } = this.props;
    const { projectId } = match.params;

    if (!permitted) return <AccessDenied action='read' resourceType='project' />
    return (
      <div>
        <div>
          <Menu size='small' pointing>
            <MenuItem
              name='Entity Basic info'
              value='entity'
              active={match.url === `/project/${projectId}/records/entity`}
            />
            <MenuItem
              name='Relationship Basic info'
              value='relationship'
              active={match.url === `/project/${projectId}/records/relationship`}
            />
            <MenuItem
              name='Litigations'
              value='litigation'
              active={match.url === `/project/${projectId}/records/litigation`}
            />
          </Menu>
        </div>
        <Scrollable>
          <Route path={`${match.path}/entity`} render={(routeProps) => (
            <EntityRecords
              entityRecords={entityRecords}
              entryPopup={entryPopup}
              permitted={permitted}
              toggleEntryPopup={this.toggleEntryPopup}
              updateEntityRecords={this.props.updateEntityRecords}
              loaded={loaded.entityRecords}
              lastFetched={lastFetched.entityRecords}
              project={this.props.project}
              {...routeProps}
            />
          )} />
          <Route path={`${match.path}/relationship`} render={(routeProps) => (
            <RelationshipRecords
              relRecords={relRecords}
              entryPopup={entryPopup}
              permitted={permitted}
              toggleEntryPopup={this.toggleEntryPopup}
              updateRelRecords={this.props.updateRelRecords}
              loaded={loaded.relRecords}
              lastFetched={lastFetched.relRecords}
              project={this.props.project}
              {...routeProps}
            />
          )} />
          <Route path={`${match.path}/litigation`} render={(routeProps) => (
            <RelationshipRecords
              relRecords={litRecords}
              type="litigation"
              entryPopup={entryPopup}
              permitted={permitted}
              toggleEntryPopup={this.toggleEntryPopup}
              updateRelRecords={this.props.updateLitRecords}
              loaded={loaded.litRecords}
              lastFetched={lastFetched.litRecords}
              project={this.props.project}
              {...routeProps}
            />
          )} />

        </Scrollable>
      </div>
    )
  }
}


export default withRouter(IngestionRecords);
