import React, { useEffect } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import { TempRelationship } from '../../model/relationship/tempRelationship';
import { stringCleaning } from '../../utils/conversion/specialCharacter';
import * as OPTIONS from '../../utils/ref/formConstants';
import { addNewRelationships, uploadDocument, retrieveEntity } from '../../utils/api';
const SHAREHOLDING_REL_TYPES = ['Shareholding', 'Subsidiary'];

export function ExpressRelationshipForm({ params, state, setState }) {
  const { projectId, type } = params;
  const tempRelationship = new TempRelationship();

  useEffect(() => {
    if (SHAREHOLDING_REL_TYPES.indexOf(state.form.relTypeStr) === -1) {
      setState(prevState => (
        { ...prevState, isShareholdingDisabled: true }
      ))
    } else {
      setState(prevState => (
        { ...prevState, isShareholdingDisabled: false }
      ))
    }
  }, [state.form.relTypeStr]);

  const handleOnSubmit = evt => {
    try {
      if (!state.form.sourceStr) {
        throw Error('Source not specified.');
      }
      if (!state.form.relTypeStr) {
        throw Error('Relationship type not specified.');
      }
      setState(prevState => (
        { ...prevState,
          payload: prevState.payload.concat(swapPayloadEntityIds(prevState.form)),
          isProcessing: true,
          errorMessage: null,
          isSuccess: false }
      )) 
    } catch (error) {
      setState(prevState => (
        { ...prevState, errorMessage: error.message }
      ))  
    } finally {
      setState(prevState => (
        { ...prevState, isProcessing: false }
      ))
    }
  }

  const swapPayloadEntityIds = payload => {
    const cloned = JSON.parse(JSON.stringify(payload));
    if (state.areEntitiesSwitched) {
      const entityId = cloned.entity2Id;
      const entity2Id = cloned.entityId;
      cloned.entityId = entityId;
      cloned.entity2Id = entity2Id;
    }
    return cloned;
  }

  const handleLiteeddIngestion = async evt => {
    try {
      if (state.payload.length === 0) {
        return;
      }
      setState(prevState => (
        { ...prevState,
          isProcessing: true,
          errorMessage: null,
          isSuccess: false }
      ))
      const body = state.payload.map(rel => parsePayload(rel));

      const result = await addNewRelationships(projectId, { body });
      if (result.error) {
        throw result.message;
      }
      setState(prevState => (
        { ...prevState, active: 'pep' }
      ))
    } catch (error) {
      setState(prevState => (
        { ...prevState, errorMessage: error.message }
      ))  
    } finally {
      setState(prevState => (
        { ...prevState, isProcessing: false }
      ))
    }
  }

  const handleIngestion = async evt => {
    try {
      setState(prevState => (
        { ...prevState,
          isProcessing: true,
          errorMessage: null,
          isSuccess: false }
      ))
      const body = state.payload.map(rel => parsePayload(rel));

      const result = await addNewRelationships(projectId, { body });
      if (result.error) {
        throw result.message;
      }
      setState(prevState => (
        { ...prevState, active: 'success' }
      )) 
    } catch (error) {
      setState(prevState => (
        { ...prevState, errorMessage: error.message }
      ))  
    } finally {
      setState(prevState => (
        { ...prevState, isProcessing: false }
      ))
    }
  }

  const handleOnInputChange = (evt, data) => {
    setState(prevState => (
      { ...prevState, form: {
          ...prevState.form, [data.id]: data.value
        }
      }  
    ))
  }

  const parsePayload = (payload) => {
		let copy = JSON.parse(JSON.stringify(payload));
		copy.sourceId = Object.keys(OPTIONS.sourceMapping).find(key => OPTIONS.sourceMapping[key] === copy.sourceStr);
		delete copy.sourceStr;
		copy.relTypeId = OPTIONS.relTypeMapping[copy.relTypeStr];
		delete copy.relTypeStr;
		Object.keys(copy).forEach((key) => {
      if (copy[key] == null) {
        delete copy[key]
      } else if (tempRelationship.fieldDetails[key]) {
        copy[key] = stringCleaning(tempRelationship.fieldDetails[key].type, copy[key])
      }
    });
		return copy;
  }

  const handleOnRetrieveEntity = async evt => {
    evt.preventDefault();
    try {
      setState(prevState => ({ ...prevState, isProcessing: true, isSuccess: false, errorMessage: null }));
      const results = await retrieveEntity(state.form.entity2Id);
      if (results.length > 0) {
        setState(prevState => ({ ...prevState, isSuccess: true }))
      } else {
        throw Error('Entity not present in database.');
      }  
    } catch (error) {
      setState(prevState => ({ ...prevState, errorMessage: error.message }))
    } finally {
      setState(prevState => ({ ...prevState, isProcessing: false }))
    }
  }
  
  const handleOnClickS3 = async evt => {
    evt.preventDefault();
    try {
      setState(prevState => (
        { ...prevState, isProcessing: true, errorMessage: null, isSuccess: false }
      ))
      const s3Url = await uploadDocument(JSON.stringify({
        url: state.form.sourceUrlStr,
        entityId: state.form.entityId
      }));
      if (s3Url === null) {
        throw Error('S3 credits have been used up. Kindly contact your administrator.')
      }
      setState(prevState => (
        { ...prevState, form: {
            ...prevState.form, sourceUrlStr: s3Url.location
          },
          errorMessage: null
        }  
      ))
    } catch (error) {
      setState(prevState => (
        { ...prevState, errorMessage: error.message }
      ))  
    } finally {
      setState(prevState => (
        { ...prevState, isProcessing: false }
      ))
    }
  }

  return <>
  { state.isSuccess &&
    <Message positive>Entity present in database.</Message> }
  { state.errorMessage !== null &&
    <Message negative>{state.errorMessage}</Message> }
  <Form onSubmit={ handleOnSubmit } size='mini'>
    { ['liteedd', 'keystone'].indexOf(type) !== -1 &&
      <div className='justify-space-between'>
        <Form.Button
          size='mini'
          disabled={state.isProcessing}
          loading={state.isProcessing}
          type='submit' content='Add relationship' />
        <Form.Button
          size='mini'
          onClick={handleLiteeddIngestion}
          disabled={state.isProcessing}
          loading={state.isProcessing}
          type='button' content='Save' />
      </div> }

    { tempRelationship.newRelationshipGroupedFields.map((group, groupId) => {
			return <Form.Group widths='equal' key={`relationship-${groupId}`}>
			{ group.map(field => {
        if (field.input.type === 'dropdown') {
          return <Form.Select
          key={field.id}
          id={field.id}
          required={field.required}
          placeholder={field.Header}
          label={field.Header}
          search
          onChange={ handleOnInputChange }
          disabled={state.isProcessing}
          upward={false}
          options={OPTIONS[field.input.source].map(option => (
            {
              key: option,
              text: option,
              value: option
            }
          ))}>
          </Form.Select>
        }
        if (field.id === 'entity2Id') {
          return <Form.Input
            action={<Button onClick={handleOnRetrieveEntity} content='Check' loading={state.isProcessing} disabled={state.isProcessing} size='mini' />}
            key={field.id}
            required={field.required}
            id={field.id}
            value={state.form[field.id] || ''}
            disabled={
              state.isProcessing ||
              field.id === 'entityId' ||
              (field.id === 'shareholdingPercentage' && state.isShareholdingDisabled)
            }
            label={state.areEntitiesSwitched ? 'Entity id 1' : field.Header}
            onChange={handleOnInputChange}
            type={((field || {}).input || {}).type}>              
          </Form.Input>
        }
        if (field.id === 'sourceUrlStr') {
          return <Form.Input
            action={<Button onClick={handleOnClickS3} content='Generate S3 link' loading={state.isProcessing} disabled={state.isProcessing} size='mini' />}
            key={field.id}
            id={field.id}
            required={field.required}
            value={state.form[field.id] || ''}
            disabled={
              state.isProcessing ||
              field.id === 'entityId' ||
              (field.id === 'shareholdingPercentage' && state.isShareholdingDisabled)
            }
            label={field.Header}
            onChange={handleOnInputChange}
            type={((field || {}).input || {}).type}>              
          </Form.Input>
        }
        if (field.id === 'entityId') {
          return <Form.Input
            key={field.id}
            id={field.id}
            required={field.required}
            value={state.form[field.id] || ''}
            disabled={
              state.isProcessing ||
              field.id === 'entityId' ||
              (field.id === 'shareholdingPercentage' && state.isShareholdingDisabled)
            }
            label={state.areEntitiesSwitched ? 'Entity id 2' : field.Header}
            onChange={handleOnInputChange}
            type={((field || {}).input || {}).type} />
        }
        return <Form.Input
          key={field.id}
          id={field.id}
          required={field.required}
          value={state.form[field.id] || ''}
          disabled={
            state.isProcessing ||
            field.id === 'entityId' ||
            (field.id === 'shareholdingPercentage' && state.isShareholdingDisabled)
          }
          label={field.Header}
          onChange={handleOnInputChange}
          type={((field || {}).input || {}).type} /> })
				}
			</Form.Group>
		})}
    <Form.Checkbox
      onChange={ () => {
        setState(prevState => (
          { ...prevState, areEntitiesSwitched: !prevState.areEntitiesSwitched }
        ))
      } }
      label='Swap Entity Ids: Entity 1 will become Entity 2 and vice versa' />
    { ['liteedd', 'keystone'].indexOf(type) === -1 && <>
      <div className='buttons-wrapper'>
        <Form.Button
          size='mini'
          disabled={state.isProcessing}
          loading={state.isProcessing}
          type='submit' content='Add relationship' />
        <Form.Button
          size='mini'
          onClick={handleIngestion}
          disabled={state.isProcessing}
          loading={state.isProcessing}
          type='button' content='Ingest relationships' />
      </div></> }
  </Form>
  </>
  
}