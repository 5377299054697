import React, { useEffect } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { getEntityBasicInfo, updateKeystoneEntity } from '../../../../utils/api';
import { COUNTRIES, COMPANY_STATUS_OPTIONS, COMPANY_TYPE_OPTIONS, ENTITY_TYPE, INDUSTRY_MAPPING } from '../constants';
import { CustomTextArea } from '../CustomTextArea';

export function BusinessDescription({ setState, state }) {

  useEffect(() => {
    async function fetching() {
      if (!state.businessdescription) {
        await fetchDefaultBusinessDescription();
      }
      return () => {
        new AbortController().abort();
      };
    }
    fetching();
  }, []);

  const fetchDefaultBusinessDescription = async () => {
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))
    const { basicInfo } = await getEntityBasicInfo(state.dataramaEntityId);
    if (basicInfo) {
      setState(prevState => (
        { ...prevState, businessdescription: basicInfo }
      ))  
    }
    setState(prevState => (
      { ...prevState, isProcessing: false }
    ))
  }


  const handleOnSubmit = async evt => {
    evt.preventDefault();
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))

    const payload = {
      dataramaId: state.dataramaEntityId,
      businessdescription: state.businessdescription,
      entityType: ENTITY_TYPE,
      showCapitalIQData: false
    }

    for (let attr of [
      'companystatus',
      'detailedcompanytype',
      'officefax',
      'officephone',
      'streetaddress',
      'city',
      'zipcode',
      'country',
      'namenonenglish',
      'webpage',
      'yearfounded',
      'companyRegistrationNumber',
      // 'sector',
      // 'subsector'
    ]) {
      if (state[attr]) {
        payload[attr] = state[attr];
      }
    }

    if (state['subsector']) {
      payload['subsector'] = state['subsector'];
      payload['sector'] = INDUSTRY_MAPPING[state['subsector']];
    }

  
    await updateKeystoneEntity(JSON.stringify(payload));
    setState(prevState => (
      { ...prevState,
        completed: { ...prevState.completed, businessdescription: true },
        isProcessing: false }
    ))
  }

  const handleOnChange = (evt, data) => {
    setState(prevState => (
      { ...prevState, [data.id]: data.value }  
    ))
  }

  const FORM_FIELDS = [
    [
      {
        value: 'companystatus',
        label: 'Company Status',
        type: 'select',
        options: COMPANY_STATUS_OPTIONS },
      {
        value: 'detailedcompanytype',
        label: 'Company Type',
        type: 'select',
        options: COMPANY_TYPE_OPTIONS },
      { value: 'officefax', label: 'Office Fax #', type: 'text' }
    ],
    [
      { value: 'officephone', label: 'Office Phone #', type: 'text' },
      { value: 'streetaddress', label: 'Street Address', type: 'text' },
      { value: 'city', label: 'City', type: 'text' }  
    ],
    [
      { value: 'zipcode', label: 'Zipcode', type: 'text' },
      { value: 'country', label: 'Country', type: 'select', options: COUNTRIES.map(item => ({ key: item, text: item, value: item })) },
      { value: 'webpage', label: 'Webpage URL', type: 'text' }
    ],
    [
      { value: 'yearfounded', label: 'Year Founded', type: 'number' },
      { value: 'companyRegistrationNumber', label: 'Company Registration Number', type: 'text' },
      { value: 'subsector', label: 'Simple Industry', type: 'select', options: Object.keys(INDUSTRY_MAPPING).map(item => ({ key: item, text: item, value: item })) }
    ]
  ]

  return <><Form size='small' onSubmit={ handleOnSubmit }>
    <div className='segment businessdescription'>
      <div className='justify-space-between'>
        <Button size='mini' content='Save' type='button' onClick={ handleOnSubmit } loading={ state.isProcessing } disabled={ state.isProcessing } />
        <Button size='mini' content='Refresh' type='button' onClick={ fetchDefaultBusinessDescription } loading={ state.isProcessing } disabled={ state.isProcessing } />
      </div>
      { FORM_FIELDS.map((formGroup, idx) => (
        <Form.Group widths='equal' key={idx}>
          { formGroup.map(field => {
            if (field.type === 'select') {
              return <Form.Select
              fluid
              id={ field.value }
              key={ field.value }
              value={ state[field.value] }
              onChange={ handleOnChange }
              type={ field.type }
              label={ field.label }
              options={ field.options }
              placeholder={ field.label } />
            }
            return <Form.Input
              id={ field.value }
              key={ field.value }
              type={ field.type }
              label={ field.label }
              value={state[field.value]}
              onChange={ handleOnChange } />
          }) }
        </Form.Group>
      ))}
    </div>
    </Form>
    <CustomTextArea setState={ setState } state={ state } />
    </>

}