import TmpPerson from '../../model/entity/tmpPerson';
import TmpCompany from '../../model/entity/tmpCompany';
import TmpOtherEntity from '../../model/entity/tmpOtherEntity';

export const COMPANY = 'company';
export const PERSON = 'person';
export const FAMILY = 'family';
export const CONGLOMERATE = 'conglomerate';
export const GOVERNMENT_AGENCY = 'governmentAgency';
export const OTHER_ORGANISATION = 'otherOrganisation';

const tmpPerson = new TmpPerson();
const tmpCompany = new TmpCompany();
const tmpOtherEntity = new TmpOtherEntity();

export const platformConversion = {
  [COMPANY]: 'organisation',
  [PERSON]: 'person',
}

export const entityTypeId = {
  [COMPANY]: 103,
  [PERSON]: 101,
  [FAMILY]: 102,
  [CONGLOMERATE]: 104,
  [GOVERNMENT_AGENCY]: 105,
  [OTHER_ORGANISATION]: 106,
}

export const ENTITY_BY_TYPE = {
  [COMPANY]: {
    index: 0,
    code: entityTypeId[COMPANY],
    header: 'Company',
    key: COMPANY,
    columns: tmpCompany.getColumns(),
  },
  [PERSON]: {
    index: 1,
    code: entityTypeId[PERSON],
    header: 'Person',
    key: PERSON,
    columns: tmpPerson.getColumns(),
  },
  [FAMILY]: {
    index: 2,
    code: entityTypeId[FAMILY],
    header: 'Family',
    key: FAMILY,
    columns: tmpOtherEntity.getColumns(),
    searchScope: {
      dtrmOnly: true,
    }
  },
  [GOVERNMENT_AGENCY]: {
    index: 3,
    code: entityTypeId[GOVERNMENT_AGENCY],
    header: 'Government Agency',
    key: GOVERNMENT_AGENCY,
    columns: tmpOtherEntity.getColumns(),
    searchScope: {
      dtrmOnly: true,
    },
  },
  [OTHER_ORGANISATION]: {
    index: 4,
    code: entityTypeId[OTHER_ORGANISATION],
    header: 'Other Organisation',
    key: OTHER_ORGANISATION,
    columns: tmpOtherEntity.getColumns(),
    searchScope: {
      dtrmOnly: true,
    }
  }
}

export const ENTITY_TYPE = {
  [COMPANY]: COMPANY,
  [PERSON]: PERSON,
  [FAMILY]: FAMILY,
  [GOVERNMENT_AGENCY]: GOVERNMENT_AGENCY,
  [OTHER_ORGANISATION]: OTHER_ORGANISATION
}


export const ENTITY_TYPE_MSSQL_REF = {
  [GOVERNMENT_AGENCY]: 'Government Agency',
  [OTHER_ORGANISATION]: 'other organisation',
  [FAMILY]: 'family',
  [PERSON]: 'person',
  [COMPANY]: 'company',
}
