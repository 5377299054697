/* eslint-disable array-callback-return */
import matchSorter from 'match-sorter';

class Project {
  constructor(props) {
    this.fields = {
      projectId: {
        Header: 'Project ID',
        id: 'projectId',
        accessor: 'projectId',
        type: 'integer',
        displayWhen: {
          read: true,
        },
      },
      name: {
        Header: 'Project Name',
        id: 'name',
        accessor: 'name',
        type: 'string',
        required: true,
        filterAll: true,
        filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["name"] }),
        displayWhen: {
          read: true,
          create: true,
        },
        input: {
          type: 'text',
        }
      },
      importTable: {
        Header: 'Import Table name',
        id: 'importTable',
        accessor: 'importTable',
        type: 'string',
        displayWhen: {},
        input: {
          type: 'text',
        }
      },
      requester: {
        Header: 'Requested by',
        id: 'requester',
        accessor: 'requester',
        type: 'string',
        fieldTransform: (val) => val && JSON.parse(val).id,
        displayWhen: {
          read: true,
          create: true,
        },
        input: {
          type: 'dropdown',
          sourceFromState: 'clients',
          displayField: 'name',
        }
      },
      projectType: {
        Header: 'Project Type',
        id: 'projectType',
        accessor: 'projectType',
        type: 'string',
        displayWhen: {
          read: true,
          create: true,
        },
        input: {
          type: 'dropdown',
          source: 'projectType',
          filter: 'projectType'
        }
      },
      country: {
        Header: 'Country',
        id: 'country',
        accessor: 'country',
        type: 'string',
        displayWhen: {
          read: true,
          create: true,
        },
        input: {
          type: 'dropdown',
          source: 'country',
        }
      },
      analysts: {
        Header: 'Analysts',
        id: 'analysts',
        accessor: 'analysts',
        type: 'array',
        fieldTransform: (data) => data && data.map(d => JSON.parse(d).id),
        rawDataTransform: (data) => data && data.split(',').filter(p => !!p),
        displayTransform: (data) => data && data.map(d => {
          return JSON.parse(d).display
        }).join(', '),
        displayWhen: {
          read: true,
          create: true,
        },
        input: {
          type: 'dropdown',
          multiple: true,
          displayField: 'email',
          sourceFromState: 'users',
        }
      },
      editor: {
        Header: 'Editorial by',
        id: 'editor',
        accessor: 'editor',
        type: 'string',
        displayWhen: {
          read: true,
          create: true,
        },
        input: {
          type: 'text',
        }
      },
      dateReceived: {
        Header: 'Date received',
        id: 'dateReceived',
        accessor: 'dateReceived',
        type: 'date',
        displayWhen: {
          read: true,
          create: true,
        },
        input: {
          type: 'date'
        }
      },
      dateFinalReview: {
        Header: 'Final Review',
        id: 'dateFinalReview',
        accessor: 'dateFinalReview',
        type: 'date',
        displayWhen: {
          read: true,
          create: true,
        },
        input: {
          type: 'date',
          noMax: true,
        }
      },
      deadline: {
        Header: 'Deadline',
        id: 'deadline',
        accessor: 'deadline',
        type: 'date',
        displayWhen: {
          read: true,
          create: true,
        },
        input: {
          type: 'date',
          noMax: true,
        }
      },
      comment: {
        Header: 'Comment',
        id: 'comment',
        accessor: 'comment',
        type: 'string',
        displayWhen: {
          read: true,
          create: true,
        },
        input: {
          type: 'text'
        }
      },
      reviewer: {
        Header: 'Reviewer',
        id: 'reviewer',
        accessor: 'reviewer',
        type: 'string',
        displayWhen: {
          read: true,
          create: true,
        },
        fieldTransform: (val) => JSON.parse(val).id,
        input: {
          type: 'dropdown',
          fieldTransform: (val) => JSON.parse(val).id,
          sourceFromState: 'users',
          displayField: 'email'
        }
      },
      overallStatus: {
        Header: 'Overall Status',
        id: 'overallStatus',
        accessor: 'overallStatus',
        type: 'string',
        displayWhen: {
          read: true,
          create: true,
          adminOnly: true,
        },
        input: {
          type: 'dropdown',
          source: 'overallStatus',
          filter: 'overallStatus',
          colorSpectrum: {
            active: '#1a9e3f',
            inactive: '#f97e7e',
            archived: '#adadad',
          }
        }
      },
      projectStatus: {
        Header: 'Project Status',
        id: 'projectStatus',
        accessor: 'projectStatus',
        type: 'string',
        displayWhen: {
          read: true,
          create: true,
        },
        input: {
          type: 'dropdown',
          source: 'projectStatus',
          filter: 'projectStatus',
          cellConditionValue: (val) => {
            const index = val && parseInt(val[0], 10);
            return isNaN(index) ? val : index;
          },
          colorSpectrum: {
            '1': '#c43821',
            '2': '#d0dd60',
            '3': '#3b4347',
            '4': '#ffd35b',
            '5': '#b0bb4a',
            '6': '#316b1b',
          }
        },
      },
      creator: {
        Header: 'Created By',
        id: 'creator',
        accessor: 'creator',
        type: 'string',
        displayWhen: {
          read: true,
        },
        input: {
          type: 'dropdown',
          fieldTransform: (val) => val && JSON.parse(val).id,
          sourceFromState: 'users',
          displayField: 'email'
        }
      },
      ins_date: {
        Header: 'Created at',
        id: 'ins_date',
        accessor: 'ins_date',
        displayWhen: {
          read: true,
        },
        type: 'string'
      },
      upd_date: {
        Header: 'Last Update',
        id: 'upd_date',
        accessor: 'upd_date',
        type: 'string',
        displayWhen: {
          read: true,
        },
      }
    }
    // importTable
    if (props) {
      this.values = {};
      Object.keys(this.fields).forEach(p => {
        if (props[p]) {
          this.values[p] = props[p]
        }
      })
    }

    this.newGroupFields = [
      ['name'],
      ['requester', 'country'],
      ['projectType'],
      ['analysts'],
      ['editor', 'reviewer'],
      ['overallStatus', 'projectStatus'],
      ['dateReceived', 'dateFinalReview', 'deadline'],
      ['comment'],
    ]


    this.readableGroup = [
      ['name'],
      ['requester', 'projectType', 'country'],
      ['overallStatus', 'projectStatus'],
      ['analysts'],
      ['editor', 'reviewer'],
      ['dateReceived', 'dateFinalReview', 'deadline'],
      ['comment'],
      ['creator', 'ins_date', 'upd_date']
    ]

    this.digestFields = [
      'requester',
      'projectType',
      'dateReceived',
      'dateFinalReview',
      'deadline',
      'analysts'
    ]

    this.displayableFields = [
      'name',
      'requester',
      'projectType',
      'country',
      'editor',
      'dateReceived',
      'dateFinalReview',
      'deadline',
      'comment',
      'reviewer',
      'creator',
      'projectStatus',
      'overallStatus',
      'ins_date',
      'upd_date'
    ]

    this.getNewGroupedFields = () => {
      return this.newGroupFields.map(p => p.map(v => this.fields[v]))
    }

    this.getReadColumns = () => {
      const columns = [
        "name",
        "requester",
        "projectType",
        "country",
        "projectStatus",
        "overallStatus",
        "analysts",
        "editor",
        "reviewer",
        "dateReceived",
        "dateFinalReview",
        "deadline",
        "comment",
        "creator",
        "ins_date",
        "upd_date"
      ];
      return columns.reduce((a, field) => {
        const data = this.fields[field];
        if (data.displayWhen && data.displayWhen.read) {
          return a.concat(data)
        }
        return a;
      }, []);

    }
    this.getDigestFields = () => {
      return this.digestFields.map(p => this.fields[p])
    }
    this.getFieldDetails = (fieldId) => {
      return this.fields[fieldId]
    }

    // this.getDigest = () => {
    //   return [
    //       'requester',
    //       'projectType',
    //       'dateReceived',
    //       'dateFinalReview',
    //       'deadline',
    //       'analysts'
    //     ]
    //   // return {
    //   //   name: data.name,
    //   //   projectId: data.projectId,
    //   //   meta: {
    //   //     requester: data.requester,
    //   //     projectType: data.projectType,
    //   //     dateReceived: data.dateReceived,
    //   //     dateFinalReview: data.dateFinalReview,
    //   //     deadline: data.deadline,
    //   //     analysts: data.analysts,
    //   //   }
    //   // }
    // }

    this.getReadable = () => {
      return Object.keys(this.fields).reduce((a, field) => {
        const data = this.fields[field];
        if (data.displayWhen && data.displayWhen.read) {
          return a.concat({
            ...data,
            value: this.values[field]
          })
        }
        return a;
      }, []);
    }
    this.getReadableGroup = () => {
      return this.readableGroup.map(group => {
        return group.map(field => {
          const data = this.fields[field];
          if (data.displayWhen && data.displayWhen.read) {
            return ({
              ...data,
              value: this.values[field]
            })
          }
        })
      })
    }
  }

}

export default Project;