import styled from 'styled-components';
export const Button = styled.button`
  border: 0;
  background: transparent;
  font-family: inherit;
  font-size: 10px;
  color: #666;
  outline: none;
  cursor: pointer;
  &:hover {
    color: #000;
    border: 0;
  }
`;
