import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from 'semantic-ui-react';
import Text from './ingestion/fields/Text';
import DateField from './ingestion/fields/DateField';
import { getSettings } from '../reducers/selectors';
import Input from './Input';

const GroupedFields = (props) => {
  const { groupedFields, isValidating, errors = {}, handleFieldChange } = props;

  return (
    <React.Fragment>
    {groupedFields && groupedFields.map((group, groupId) => (
      <Form.Group widths='equal' key={groupId}>
        {group.map(field => {
          if (!field.input) return null;
          switch (field.input.type) {
            case 'dropdown':
            return (
              <Input
                key={field.id}
                field={field}
                isValidating={isValidating}
                error={errors[field.id]}
                defaultValue={field.defaultValue}
                handleFieldChange={handleFieldChange}
              />
            )
            case 'date':
              return (
                <DateField
                  key={field.id}
                  field={field}
                  isValidating={isValidating}
                  error={errors[field.id]}
                  defaultValue=""
                  noMax={field.input.noMax}
                  handleFieldChange={handleFieldChange}
                />
              )
            default:
              return (
                <Text
                  key={field.id}
                  field={field}
                  meta={field.input.meta}
                  error={errors[field.id]}
                  defaultValue=""
                  isValidating={isValidating}
                  handleFieldChange={handleFieldChange}
                />
              )
          }
        })}
      </Form.Group>
    ))}

    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const settings = getSettings(state);
  return {
    users: settings.users,
    clients: settings.clients,
    riskTypes: settings.riskTypes,
    languages: settings.languages,
  }
};

export default withRouter(connect(mapStateToProps)(GroupedFields));
