import React, { useState } from 'react';
import { ExpressRelationshipForm } from '../../ingestion/ExpressRelationshipForm';
import { ExpressRelationshipTable } from '../../ingestion/ExpressRelationshipTable';

export function Relationships({ params, state, setState }) {
  const [relState, setRelState] = useState({
    form: {
      entityId: state.dataramaEntityId
    },
    isProcessing: false,
    isShareholdingDisabled: false,
    areEntitiesSwitched: false,
    payload: [],
    errorMessage: null,
    isSuccess: false
  });

  const translate = rels => {
    return rels.reduce((acc, rel) => {
      if (rel.relTypeStr) {
        if (!acc[rel.relTypeStr]) {
          acc[rel.relTypeStr] = [];
        }
        acc[rel.relTypeStr] = acc[rel.relTypeStr].concat(rel);
      }
      if (rel.relTypeDescStr) {
        if (!acc[rel.relTypeDescStr]) {
          acc[rel.relTypeDescStr] = [];
        }
        acc[rel.relTypeDescStr] = acc[rel.relTypeDescStr].concat(rel);
      }
      return acc;
    }, {});
  }

  return <div className='segment'>
    <div>1. <b><u><a href={`/research/pepblacklist/byId/${state.isCompany ? 'company': 'person'}/${state.dataramaEntityId}`} target="_blank" rel='noopener noreferrer'>Click here</a></u></b> to perform PEP check.</div>
    <div>2. Ingest relationships that reaffirm entity's PEP status.</div>
    <br /><br />
    <ExpressRelationshipForm
      className='form'
      params={ params }
      state={ relState }
      setState={ setRelState } />
    <ExpressRelationshipTable
      className='table'
      rels={ translate(relState.payload) }
      state={ relState }
      setState={ setRelState } />
  </div>
}