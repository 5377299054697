/* eslint-disable no-useless-escape */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Header, Loader, Form, Segment, Message, Label } from 'semantic-ui-react';
import { getLogs, runImporter } from '../../utils/api';
import { getAuth } from '../../reducers/selectors';
import AccessDenied from '../AccessDenied';

const matchLogsReg = new RegExp('Started|Finished|finished updating', 'g');

const HighlightLog = styled.div`
  background: #b0e3a9;
  font-weight: 600;
  margin: 1px 0;
`;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      importer: {},
    };
    this.setLoadingStatus = this.setLoadingStatus.bind(this);
    this.fetchLog  = this.fetchLog.bind(this);
    this.switchTab = this.switchTab.bind(this);

    this.handleImporterFormChange = (e, { value, name }) => {
      this.setState(prevState => {
        let newValue = value;
        if (name === 'confirmed') {
          newValue = !prevState.importer[name];
        }
        return {
          importer: Object.assign({}, prevState.importer, {
            [name]: newValue,
          }),
        }
      })
    }

    this.runImporter = async () => {
      const { importer } = this.state;
      if (!importer.confirmed || !importer.env) return;
      const runResult = await runImporter(importer.env);
      if (runResult) {
        this.setState(() => ({
          running: importer.env,
        }))
      } else {
        this.setState(() => ({
          onError: importer.env,
        }))
      }
    }

    this.clear = () => {
      this.setState(() => ({
        running: false,
        importer: {},
        onError: null,
      }))
    }
  }

  switchTab(tab, env) {
    this.setState(() => ({
      activeTab: tab,
    }), () => {
      if (tab === 'log') {
        this.fetchLog(env);
      }
    })
  }

  async fetchLog(env = 'production') {
    this.setLoadingStatus(true);
    const data = await getLogs(env);
    this.setLoadingStatus(false);
    if (data) {
      const logs = data.data.replace(/\\r/g,"").split(/\"|\\n/g).reverse();
      this.setState(() => ({
        logs,
        logType: env,
      }))
    }
  }

  setLoadingStatus(status) {
    this.setState(() => ({
      loading: status
    }))
  }

  render() {
    const { logs, loading, activeTab, importer, onError, running, logType } = this.state;
    const { isAdmin } = this.props;

    if (!isAdmin) {
      return (
        <AccessDenied action='admin' resourceType='importer'/>
      )
    }

    return (
      <div>
      {loading && (
        <Loader active>Loading</Loader>
      )}
      <Button active={activeTab === 'run'}onClick={() => this.switchTab('run')}>Run Importer</Button>
      <Button onClick={() => this.switchTab('log', 'production')}>Read Production Logs</Button>
      <Button onClick={() => this.switchTab('log', 'staging')}>Read Staging Logs</Button>
      {activeTab === 'run' && (
        <Segment>
          {!running && (
            <>
              <Form onSubmit={this.runImporter}>
                <Form.Group inline>
                  <label>Environment</label>
                  <Form.Radio
                    label='Staging'
                    value='staging'
                    checked={importer.env === 'staging'}
                    onChange={this.handleImporterFormChange}
                    name='env'
                  />
                  <Form.Radio
                    label='Production'
                    name='env'
                    value='production'
                    checked={importer.env === 'production'}
                    onChange={this.handleImporterFormChange}
                  />
                </Form.Group>
                <Form.Checkbox
                  label='Confirm before running the importer'
                  required
                  onChange={this.handleImporterFormChange}
                  name='confirmed'
                  checked={importer.confirmed}
                />
                <Form.Button disabled={!importer.confirmed || running} positive>Run {importer.env} Importer</Form.Button>
              </Form>
              {onError && (
                <Message warning>
                  Something wrong with {onError} env. please try again.
                  <Button onClick={this.clear} basic>OK</Button>
                </Message>
              )}
            </>
          )}

          {running && (
            <Message positive>
              <Label color='green'>{running}</Label> I2 importer is running. Please check logs to view progress.
              <br/>
              <Button onClick={this.clear} basic>OK</Button>
            </Message>
          )}
        </Segment>
      )}
      {activeTab === 'log' && logs && (
        <Segment>
          <Header>Displaying last 1000 rows in <Label color='green'>{logType}</Label></Header>
          {logs.map((p, pindex) => {
            const shouldHighlight = p.match(matchLogsReg);
            return (
              <div key={pindex}>
                {shouldHighlight ? (
                  <HighlightLog>{p}</HighlightLog>
                ) : p}
              </div>
            )
          })}
        </Segment>
      )}
      </div>

    )
  }
}


const mapStateToProps = state => {
  const authState = getAuth(state);
  return {
    loggedInUser: authState.loggedInUser,
    isAdmin: authState.permissions.general['importer.admin'],
  }
};

export default withRouter(connect(mapStateToProps)(Dashboard));
