import React, { useState, useEffect } from 'react';
import { Button, Grid, Form, Icon } from 'semantic-ui-react';
import { updatePreviewReport } from '../../../utils/api';

export function ArticleRow({id, riskCategories, title, url, s3Url, state, setState}) {
  const [isFormProcessing, setIsFormProcessing] = useState(false);

  useEffect(() => {
    setIsFormProcessing(false);
  }, [state.articles.length])

  const handleRemoveArticle = async evt => {
    evt.preventDefault();
    setIsFormProcessing(true);
    const articles = JSON.parse(JSON.stringify(state.articles));
    delete articles[id];
    const adverseArticlesCount = Object.keys(articles).length;

    const payload = JSON.stringify({
      reportId: state.reportId,
      adverseArticlesCount,
      articles
    });
    await updatePreviewReport(payload);
    setState(prevState => (
      {...prevState,
      adverseArticlesCount,
      articles }
    ))
  }

  const handleInputChange = (evt, data) => {
    evt.preventDefault();
    setState(prevState => (
      {...prevState,
      articles: { ...prevState.articles, [id]: { ...prevState.articles[id], summary: data.value }}}
    ))
  }

  const addSummaryToArticle = async evt => {
    evt.preventDefault();
    setIsFormProcessing(true);
    const articles = JSON.parse(JSON.stringify(state.articles));

    const payload = JSON.stringify({
      reportId: state.reportId,
      articles
    });
    await updatePreviewReport(payload);
    setState(prevState => (
      {...prevState,
      articles }
    ))
    setIsFormProcessing(false);
  }

  return <>
    <Grid.Column width={2} style={{ textTransform: 'capitalize' }}>
      { riskCategories && riskCategories.join(', ') }
    </Grid.Column>
    <Grid.Column width={2}>
      { url &&
      <a href={ url } target="_blank" rel='noopener noreferrer'>Ref</a> }

      { s3Url &&
      <><br /><a href={ s3Url } target="_blank" rel='noopener noreferrer'>S3 Ref</a></> }
    </Grid.Column>
    <Grid.Column width={3}>
      { title }
    </Grid.Column>
    <Grid.Column width={8}>
      <div>{(state.articles[id] || {}).summary}</div>
      <br />
      <Form size='mini' onSubmit={ addSummaryToArticle }>
        <Form.Input size='mini'
          value={(state.articles[id] || {}).summary}
          onChange={handleInputChange}
          action={<Button
            disabled={isFormProcessing}
            loading={isFormProcessing}
            type='submit' size='mini' content='Update' />} />
      </Form>
    </Grid.Column>
    <Grid.Column width={1}>
      <Icon
        name='remove'
        color='grey'
        onClick={ handleRemoveArticle }>
      </Icon>
    </Grid.Column>
  </>
}
