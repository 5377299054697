import React, {useState} from 'react';
import styled from 'styled-components';
import { Button, Segment, Icon, Label, Header } from 'semantic-ui-react';
import DtrmID from './DtrmID';
import getEntityModel from '../model/entity/getEntityModel';
import { getDisplayType } from '../utils/conversion/entityType';
import RiskDetails from './ingestion/RiskDetails';

const Wrapper = styled.div`
  position: relative;
  > * {
    display: inline-block;
  }
  margin-bottom: 10px;
`;

const Row = styled.div`
  > div:first-of-type {
    width: 30%;
    background: #ececec;
    font-weight: 600;
  }
  > div {
    display: inline-block;
    padding: 5px 10px;
  }
  margin-bottom: 5px;
`;


export default ({ results, type, collapse, omitFields = {} }) => {
  let baseEntity;
  if (type) {
    baseEntity = getEntityModel(type);
  }
  if (!baseEntity) return null;
  let [showAll, setShowAll] = useState(!collapse);

  const columns = showAll ? baseEntity.getColumns() : baseEntity.getDigestedColumns();
  return (
    <React.Fragment>
      <Wrapper>
        {collapse && (
          <React.Fragment>
            <Label color='green' horizontal>{getDisplayType(type)}</Label>
            <Button
              onClick={() => setShowAll(!showAll)}
              size='mini'
              basic
              compact
              icon
              labelPosition='right'
            >
              <Icon name={showAll ? 'chevron up' : 'chevron down'} />
              {showAll ? 'Show Less' : 'Show All Fields'}
            </Button>
          </React.Fragment>

        )}
      </Wrapper>
      <Segment>
        {columns.map((col) => omitFields[col.id] ? null : (
          <Row key={col.id}>
            <div>{col.Header}</div>
            {col.id === 'entityId' ? (
              <DtrmID id={results.entityId} type={type} link />
            ) : (
              <div>
                {typeof col.accessor === 'function' ? col.accessor(results) : (
                  results[col.id]
                )}
              </div>
            )}
          </Row>
        ))}
      </Segment>
      {showAll && !omitFields.risks && (
        <React.Fragment>
          <Header as='h3'>Risks</Header>
          <Segment>
            <RiskDetails risks={results.risks} />
          </Segment>
        </React.Fragment>
      )}
    </React.Fragment>

  )
}
