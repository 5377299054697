import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Message } from 'semantic-ui-react';
import { RelationshipForm } from './RelationshipForm';
import { RelationshipMessage } from './RelationshipMessage';
import { findDataramaId } from '../../utils/api';
import { RelationshipTable } from './RelationshipTable';
import { RelationshipSearchPanel } from './RelationshipSearchPanel';
import { RelationshipStepper } from './RelationshipStepper';

const Wrapper = styled.div`
  overflow: auto;
  width: 100%;
  padding-top: 10px;
`;

const NO_DATA = {
  dataramaId: 'Cannot find Datarama ID %s. Try different relationship ID'
}

export function UpdateRelationship({ match: { params }, location }) {

    // Declare active step variables
	const INPUT_FORM = 'inputForm';
	const VALIDATE = 'validate';

  // Declare component states
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState({});
  const [results, setResults] = useState(null);
  const [noData, setNoData] = useState(null);
  const [activeStep, setActiveStep] = useState(VALIDATE);
  const [completed, setCompleted] = useState({});
  const [errors, setErrors] = useState({});
  const [httpResponse, setHttpResponse] = useState(null);
  
  useEffect(() => {
    const { relId, type } = location.state || {};
    if (relId && type) {
      findRelById(relId, false);
    }
  }, []);

  const handleFindRelByRelId = () => {
    return findRelById(inputs.dataramaId, false);
  }

  const findRelById = async (relId, keepSearchResults) => {
    setLoading({ findById: true });
    const output = await findDataramaId('rel', relId);
    if (output) {
      window.scrollTo(0, 0);
      setInputs({ ...output, sourceStr: `${output.sourceStr} + ${output.sourceQualityStr}` });
      if (!keepSearchResults) {
        setResults(null);
      }
      setActiveStep(INPUT_FORM);
      setCompleted(prevState => (
        { ...prevState, validate: true }
      ))
      setLoading({});
    } else {
      setNoData(NO_DATA.dataramaId.replace(/%s/g, relId));
    }
  }

  return(
    <Wrapper>
      <RelationshipStepper completed={completed} activeStep={activeStep} isNewRelationship={false} />
      { noData && <Message content={ noData } error onDismiss={() => setNoData(null)} /> }
      { activeStep === VALIDATE &&
        <RelationshipSearchPanel
          loading={ loading }
          isNewRelationship={ false }
          setInputs = { setInputs }
          setLoading = { setLoading }
          params = { params }
          inputs = { inputs }
          setResults = { setResults }
          handleFindRelByRelId={ handleFindRelByRelId } />}
      { activeStep === VALIDATE && results && results.dtrm &&
        <RelationshipTable
          isNewRelationship={false}
          findRelById={ findRelById }
          loading={ loading }
          params = { params }
					inputs = { inputs }
          setResults = { setResults }
          results={ results } /> }
      { activeStep === INPUT_FORM && !httpResponse &&
        <RelationshipForm
          params = { params }
          isNewRelationship = { false }
          inputs = { inputs }
          errors = { errors }
          setErrors = { setErrors }
          setHttpResponse = { setHttpResponse }
          setInputs = { setInputs } /> }
      { httpResponse && httpResponse.relid &&
        <RelationshipMessage
          setInputs={ setInputs }
          setLoading={ setLoading }
          setErrors={ setErrors }
          setHttpResponse={ setHttpResponse }
          setResults={ setResults }
          setActiveStep={ setActiveStep }
          VALIDATE={ VALIDATE }
          isNewRelationship={ false }
          httpResponse={ httpResponse } /> }
    </Wrapper>
  )
}