import React from 'react';
import { Button, Form, Checkbox } from 'semantic-ui-react';
import {
  FINANCIAL_YEARS_OPTIONS,
  FINANCIAL_MAGNITUDE_OPTIONS,
  FINANCIAL_CURRENCIES_OPTIONS } from '../constants';

export function Config({ componentState, setComponentState, state, setState }) {

  const handleInputChange = (evt, data) => {
    setComponentState(prevState => (
      { ...prevState, [data.id]: data.value }
    ))
  }

  return <>
    <div className='justify-end'>
      <Button
        onClick={() => setComponentState(prevState => ( { ...prevState, active: 'fs' } ))}
        size='mini'
        content='Next'
        type='button'
        loading={ state.isProcessing }
        disabled={ state.isProcessing } />
    </div>
    <Form size='small' style={{paddingLeft: '20px'}}>
      <Form.Group>
        <Form.Dropdown
          placeholder='Financial years'
          label='Financial Years'
          id='years'
          multiple
          selection
          value={componentState.years}
          onChange={ handleInputChange }
          options={ FINANCIAL_YEARS_OPTIONS } />
        <Form.Dropdown
          placeholder='Financial magnitude'
          label='Financial Magnitude'
          id='magnitude'
          selection
          value={componentState.magnitude}
          onChange={ handleInputChange }
          options={ FINANCIAL_MAGNITUDE_OPTIONS } />
        <Form.Dropdown
          placeholder='Currency'
          label='Currency'
          id='currency'
          search
          selection
          value={componentState.currency}
          onChange={ handleInputChange }
          options={ FINANCIAL_CURRENCIES_OPTIONS } />
        <Form.Field style={{display: 'flex', alignItems: 'flex-end' }}>
          <Checkbox
            id='estimated'
            size='mini'
            label='Are the financials estimated?'
            checked={ state.estimated }
            onChange={ () => setState(prevState => (
              { ...prevState, estimated: !prevState.estimated }
            )) } />
        </Form.Field>

      </Form.Group>
    </Form>
  <br /><br /></>
}