import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getSettings } from '../reducers/selectors';

const mapStateToProps = state => {
  const settings = getSettings(state);
  return {
    users: settings.users,
    clients: settings.clients,
    languages: settings.languages,
    sources: settings.sources,
  }
};

const ValueFromState = (props) => {
  const { field, value, displayField } = props;
  if (!props[field]) return null;
  const obj = props[field][value];
  const display = displayField(obj)
  return `${display}`
}

export default withRouter(connect(mapStateToProps)(ValueFromState));
