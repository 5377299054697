import React from 'react';
import { Form, Message } from 'semantic-ui-react';
import { retrieveEntity } from '../../utils/api';

export function ExpressRelationshipEntitySearch({state, setState}) {
  const handleOnSubmit = async evt => {
    try {
      setState(prevState => (
        { ...prevState, isProcessing: true, errorMessage: null }
      ))
      const entities = await retrieveEntity(state.form.entityId);
      if (entities.length === 0) {
        throw Error('No such entity in database found.');
      }
      setState(prevState => (
        { ...prevState, isProcessing: false, active: 'form' }
      ))
    } catch (error) {
      setState(prevState => (
        { ...prevState, isProcessing: false, errorMessage: error.message }
      ))  
    }
  }

  const handleOnInputChange = (evt, data) => {
    setState(prevState => (
      { ...prevState, form: {
          ...prevState.form, [data.id]: data.value
        }
      }  
    ))
  }

  return <>
  { state.errorMessage !== null &&
    <Message negative>{state.errorMessage}</Message> }
  <Form onSubmit={handleOnSubmit}>
    <Form.Input
      id='entityId'
      disabled={ state.isProcessing }
      label='Entity Id'
      onChange={ handleOnInputChange }
      type='number' />
    <Form.Button
      type='submit'
      content='Submit'
      disabled={state.isProcessing}
      loading={state.isProcessing} />
  </Form>
  </>

}