import React, { useState } from 'react';
import { Step } from 'semantic-ui-react';
import { EntityId } from './entityId/EntityId';
import { PEP } from './pep/PEP';
import { Relationships } from './relationships/Relationships';
import { Sanctions } from './sanctions/Sanctions';
import { BasicInfo } from './basicInfo/BasicInfo';
import { Search } from './search/Search';
import { Articles } from './articles/Articles';
import { AdditionalSummaryDetails } from './addSummary/AdditionalSummaryDetails';
import {
  BASIC_INFORMATION,
  BLACKLIST,
  POLITICAL_EXPOSURE,
  TOTAL_ARTICLES_COUNT
} from '../../constants';
import './report.scss';

export function NewLiteEDDReport({params}) {
  const [state, setState] = useState({
    isCompany: true,
    isError: false,
    dataramaEntityId: "",
    dataramaProjectId: "",
    reportId: "",
    entityName: "",
    entityType: "",
    adverseArticlesCount: 0,
    articles: {},
    [TOTAL_ARTICLES_COUNT]: 0,
    [BLACKLIST]: "",
    [BASIC_INFORMATION]: "",
    [POLITICAL_EXPOSURE]: "",
    sanctions: {
      results: []
    },
    isProcessing: false,
    completed: {
      entityId: false,
      basicInfo: false,
      pep: false,
      sanctions: false,
      search: false,
      searchResults: false,
      articles: false
    },
    active: 'entityId'
  });

  const STEPS = [
    { key: 'entityId', title: 'Entity Id', description: 'Enter Entity Id' },
    { key: 'basicInfo', title: 'Basic Info', description: 'Verify basic info' },
    { key: 'relationships', title: 'Relationships', description: 'Ingest relevant relationships (N/A for companies)' },
    { key: 'pep', title: 'PEP', description: 'Verify PEP' },
    { key: 'sanctions', title: 'Blacklists/ Sanctions', description: 'Verify blacklists / sanctions' },
    { key: 'search', title: 'Search', description: 'Search crawled articles (optional)' },
    { key: 'articles', title: 'Add more articles', description: 'Add articles via manual search' },
    { key: 'addSummary', title: 'Others', description: 'Indicate total articles count' }
  ];

  return <div className='liteedd-report'>
    <Step.Group widths={8} size='mini' ordered>
      { STEPS.map(step => (
        <Step key={step.key}
          completed={state.completed[step.key]}
          onClick={() => {
            if (state.reportId !== '') {
            setState(prevState => (
              { ...prevState, active: step.key }
            ))
            }
          }}
          active={state.active === step.key}>
          <Step.Content>
            <Step.Title>
              { step.title }
            </Step.Title>
            <Step.Description>
              { step.description }
            </Step.Description>
          </Step.Content>
        </Step>
      )) }
    </Step.Group>

    { state.active === 'entityId' &&
      <EntityId state={ state } setState={ setState } /> }
    { state.active === 'basicInfo' &&
      <BasicInfo state={ state } setState={ setState } /> }
    { state.active === 'relationships' && !state.isCompany &&
      <Relationships params={ params } state={ state } setState={ setState } /> }
    { state.active === 'relationships' && state.isCompany &&
      <div>Ingestion of relationships not applicable in the context of company PEP check.</div> }
    { state.active === 'pep' &&
      <PEP state={ state } setState={ setState } /> }
    { state.active === 'sanctions' &&
      <Sanctions state={ state } setState={ setState } /> }
    { (state.active === 'search' || state.active === 'searchResults') &&
      <Search state={ state } setState={ setState } /> }
    { state.active === 'articles' &&
      <Articles state={ state } setState={ setState } /> }
    { state.active === 'addSummary' &&
      <AdditionalSummaryDetails state={state} setState={setState} /> }
  </div>;
}