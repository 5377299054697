import React from 'react';
import { Table, Pagination,Dimmer, Loader, Icon } from 'semantic-ui-react';
import { searchES } from '../../utils/api';
import DtrmID from '../DtrmID';
import styled from 'styled-components';
import { esColumns } from  '../../utils/tableUtils';

const Wrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const RelativeWrapper = styled.div`
  position: relative;
`;


class ES extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      loading: false,
    }
    this.setLoading = (status) => {
      this.setState(() => ({
        loading: status
      }))
    }
    this.navigateToPage = this.navigateToPage.bind(this);
  }

  async navigateToPage(e, { activePage }) {
    const ifSearched = this.props.results.hits && this.props.results.hits[activePage];
    this.setState(() => ({
      activePage,
    }));
    if (ifSearched) return;
    const { currentSearch } = this.props;

    if (currentSearch) {
      this.setLoading(true);
      const result = await searchES(this.props.type, {...currentSearch, page: activePage - 1});
      this.setLoading(false);
      if (result.hits) {
        this.props.updateSearchResults('es', result.hits);
      }
    }

  }

  render() {
    const { results, type, action } = this.props;
    const { activePage, loading } = this.state;

    const cellContent = (col, value, dataramaId, disabled) => {
      if (col.isExist) {
        return value ? (<Icon color='green' name='check circle' />) : (<Icon color='grey' name='delete' />)
      }
      if (col.isFlag) {
        return value ? (<Icon color='red' name='flag' />) : null;
      }
      if (col.isLink && !disabled) {
        return (
          <DtrmID id={value} type={type} link action={action} />
        )
      }
      return value;
    }
    if (!results) return;
    const hits = loading && activePage > 0 ? ((results.hits && results.hits[activePage - 1]) || []) : ((results.hits && results.hits[activePage]) || []);

    return (
      <RelativeWrapper>
        <Table compact size='small' celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='16'>
                <Pagination
                  size='mini'
                  activePage={activePage}
                  totalPages={Math.ceil(results.total / 10)}
                  onPageChange={this.navigateToPage}
                />
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              {esColumns[type].map(col => (
                <Table.HeaderCell
                  key={col.label}
                  rowSpan={col.items ? 1 : 2}
                  colSpan={col.items ? col.items.length : 1}
                  width='two'
                >{col.label}</Table.HeaderCell>
              ))}
            </Table.Row>
            <Table.Row>
              {esColumns[type].map(col => col.items ? col.items.map(item => (
                <Table.HeaderCell key={item.value} width={5}>{item.label}</Table.HeaderCell>
              )) : null)}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {hits.map(match => (
              <Table.Row key={match.dataramaId}>
                {esColumns[type].map(col => col.items ? col.items.map(item => (
                  <Table.Cell key={item.value}>
                    {match[item.value]}
                  </Table.Cell>
                )) : (
                  <Table.Cell
                    key={col.value}
                  >
                    {cellContent(col, match[col.value], match.dataramaId)}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {loading && (
          <Wrapper>
            <Dimmer inverted active><Loader inline active/></Dimmer>
          </Wrapper>
        )}
      </RelativeWrapper>
    )
  }
}

export default ES;
