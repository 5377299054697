import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const Content = styled.div`
  text-align: center;
`;
const Header = styled.h1`
  font-size: 50px;
  margin: 10px;
`;
const Details = styled.div`
  max-width: 300px;
`;

class NotFound extends React.Component {

  render() {
    return (
      <Wrapper>
        <Content>
          <Header>404! :O</Header>
          <Details>{this.props.details || 'Page not found'}</Details>
        </Content>
      </Wrapper>
    )
  }
}

export default NotFound;
