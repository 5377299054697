import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import './index.scss';
import App from './App';
import store from './store';
import './fonts/NeuzeitGro.ttf';
import './fonts/NeuzeitGroBla.ttf';
import './fonts/NeuzeitGroBold.ttf';
import './fonts/NeuzeitGroConBla.ttf';
import './fonts/NeuzeitGroExtBla.ttf';
import './fonts/NeuzeitGroLig.ttf';

const render = () => {
  ReactDOM.render(<Provider store={store}><Router><App /></Router></Provider>, document.getElementById('root'));
}
if (module.hot) {
  module.hot.accept('./App', () => {
    render();
  });
}
render();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
