import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import { Dimmer, Loader, Label, Button, Icon, Menu } from 'semantic-ui-react';
import { Route } from 'react-router-dom';
import { getProject } from '../utils/api';
import IngestionRecords from './IngestionRecords';
import IngestionActions from './IngestionActions';
import ProjectDetails from './ProjectDetails';
import { getAuth, getProjects } from '../reducers/selectors';
import { checkPermissionForProject }  from '../utils/api';
import { updateSelfPermission, updateProject, setProjects } from '../actions';
import ProjectTag from './ProjectTag';

const ROUTE_AUTH_CHECKS = {
  details: ['admin'],
  records: ['read'],
  actions: ['modify']
}

const ProjectHeader = styled.div`
  display: inline-block;
  padding: 0 20px 0 10px;
  border-left: 1px solid #ececec;
  min-width: 200px;
`;
const InlineDiv  =styled.div`
  display: inline-block;
  border-left: 1px solid #ececec;
  vertical-align: top;
  padding: 0 20px;
`;
const HeaderWrapper = styled.div`
  > * {
    display: inline-block;
    vertical-align: middle;
  }
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
  margin-bottom: 10px;
  h2 {
    margin: 0 !important;
  }
  > div:last-of-type {
    flex: 1;
  }
  > div:first-of-type {
    padding-right: 10px;
  }
  display: flex;
`;

const Subheader = styled.div`
  color: #999;
  font-size: 15px;
  font-weight: 300;
  margin-left: 2px;
`;
class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entityRecords: {},
      relRecords: [],
      litRecords: [],
      loading: false,
      loaded: {
        entityRecords: false,
        relRecords: false
      },
      lastFetched: {
        entityRecords: null,
        relRecords: null
      }
    };
    this.fetchProject = this.fetchProject.bind(this);
    this.switchToPath = this.switchToPath.bind(this);
    this.updateEntityRecords = this.updateEntityRecords.bind(this);
    this.updateRelRecords = this.updateRelRecords.bind(this);
    this.updateLitRecords = this.updateLitRecords.bind(this);
    this.checkPermission = this.checkPermission.bind(this);
    this.updateProject = this.updateProject.bind(this);
    this.updateProjectPermission = this.updateProjectPermission.bind(this);
  }

  componentDidMount() {
    if (this.props.location.pathname === this.props.match.url) {
      // CASE when no sub-type of project detalis is selected;
      this.props.history.push(`${this.props.match.url}/details`);
    }
    this.fetchProject();
  }

  updateProjectPermission(permissions) {
    const updatedRoles = permissions.reduce((a, c) => {
      const { userId, updated } = c;
      if (!userId || !updated) return a;
      const updatedRoles = updated.reduce((userRoles, role) => {
        userRoles[`${role.resourceType}.${role.action}`] = true;
        return userRoles;
      }, {})
      a[userId] = updatedRoles;
      return a;
    }, {})
    if (updatedRoles[this.props.loggedInUser]) {
      this.props.updateSelfPermission('project', this.state.project.projectId, updatedRoles[this.props.loggedInUser])
    }
    const { project } = this.props;
    this.props.updateProject(project.projectId, 'roles', Object.assign({}, project.roles, updatedRoles));

    this.setState((prevState) => ({
      project: Object.assign({}, prevState.project, {
        roles: Object.assign({}, prevState.project.roles, updatedRoles),
      })
    }))
  }

  switchToPath(e, { value, absolute }) {
    let url = this.props.match.url + '/' + value;
    if (absolute) {
      url = absolute;
    }
    this.props.history.push(`${url}`);
  }

  updateProject(field, update) {
    this.setState(prevState => {
      let additionalMeta = {};
      if (field === 'overallStatus') {
        additionalMeta.allowUpdates = update === 'active';
      }
      return {
        project: {
          ...prevState.project,
          ...additionalMeta,
          [field]: update
        }
      }
    });
    const { project } = this.state;
    this.props.updateProject(project.projectId, field, update);
  }

  async fetchProject() {
    const { match: { params }, loggedInUser } = this.props;
    const { projectId } = params;
    if (projectId) {
      this.setState(() => ({
        loading: true,
      }))
      const project = await getProject(projectId);
      if (!project) {
        this.props.history.push({
          pathname: '/projects',
          state: {
            error: 401
          }
        })
        return;
      }

      project.allowUpdates = project.overallStatus === 'active';

      if (project.roles && project.roles[loggedInUser]) {
        this.props.updateSelfPermission('project', projectId, project.roles[loggedInUser])
      }
      this.setState(() => ({
        project,
        loading: false,
      }))
      this.props.setProjects([project], true);
    }

  }

  updateEntityRecords(records) {
    this.setState(prevState => ({
      loaded: Object.assign({}, prevState.loaded, {
        entityRecords: true,
      }),
      lastFetched: Object.assign({}, prevState.lastFetched, {
        entityRecords: moment(),
      }),
      entityRecords: records,
    }))
  }

  updateRelRecords(records) {
    this.setState(prevState => ({
      loaded: Object.assign({}, prevState.loaded, {
        relRecords: true,
      }),
      lastFetched: Object.assign({}, prevState.lastFetched, {
        relRecords: moment(),
      }),
      relRecords: records,
    }))
  }

  updateLitRecords(records) {
    this.setState(prevState => ({
      loaded: Object.assign({}, prevState.loaded, {
        litRecords: true,
      }),
      lastFetched: Object.assign({}, prevState.lastFetched, {
        litRecords: moment(),
      }),
      litRecords: records,
    }))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.checkPermission();
    }
  }

  async checkPermission() {
    const { pathname } = this.props.location;
    const { url } = this.props.match;
    const projectTab = pathname.replace(`${url}/`, '');
    const permissionsToCheck = ROUTE_AUTH_CHECKS[projectTab];
    if (!permissionsToCheck) return;

    const { projectId } = this.state.project || {};
    if (projectId) {
      const permitted = await checkPermissionForProject(projectId, permissionsToCheck)
      if (permitted) {
        this.props.updateSelfPermission('project', projectId, permitted)
      }
    }
  }

  render() {
    const { match, permission, project } = this.props;

    const baseUrl = match.url;

    const activeItem = this.props.location.pathname;

    const { loaded, entityRecords, relRecords, lastFetched, loading, litRecords } = this.state;

    if (!project || loading) return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    )

    return (
      <React.Fragment>
        <ProjectTag status={project.overallStatus} />
        <HeaderWrapper>
          <div>
            <ProjectHeader>
              <Label size='large' color='black'>{project.name}</Label>

              <Subheader>Project ID: {match.params.projectId} <Button size='mini' onClick={this.fetchProject} basic compact icon>
                <Icon name='refresh'/>Refresh
              </Button></Subheader>

            </ProjectHeader>
            <InlineDiv>
            <Menu pointing secondary>
              <Menu.Item name='Project details' value="details" active={activeItem === baseUrl + '/details'} onClick={this.switchToPath} />
              <Menu.Item
                name='Ingestion Records'
                value='records'
                active={activeItem.indexOf(baseUrl + '/records') !== -1}
                onClick={this.switchToPath}
              />
              <Menu.Item
                name='Actions'
                value='actions'
                active={activeItem.indexOf(baseUrl + '/actions') !== -1}
                onClick={this.switchToPath}
              />
            </Menu>
            </InlineDiv>
          </div>
        </HeaderWrapper>
        <div>
          <Route path={`${match.path}/details`} render={() => (
            <ProjectDetails
              project={project}
              permitted={permission.admin}
              updateProjectPermission={this.updateProjectPermission}
              updateProject={this.updateProject}
            />
          )} />
          <Route path={`${match.path}/records`} render={() => (
            <IngestionRecords
              project={project}
              loaded={loaded}
              lastFetched={lastFetched}
              updateEntityRecords={this.updateEntityRecords}
              entityRecords={entityRecords}
              updateRelRecords={this.updateRelRecords}
              updateLitRecords={this.updateLitRecords}
              litRecords={litRecords}
              relRecords={relRecords}
              permitted={permission.read}
            />)} />
          <Route
            path={`${match.path}/actions`}
            render={() => <IngestionActions project={project} permitted={permission.modify}/>}
          />
        </div>
      </React.Fragment>
    )
  }
}


const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps.match.params || {};
  const authState = getAuth(state);
  const projects = getProjects(state);
  const permissions = ((authState.permissions || {}).project || {})[projectId];

  return {
    loggedInUser: authState.loggedInUser,
    project: projects.projects[projectId],
    permission: {
      admin: permissions && permissions['project.admin'],
      read: permissions && permissions['project.read'],
      modify: permissions && permissions['project.modify'],
    }
  }
};

export default withRouter(connect(mapStateToProps, { updateSelfPermission, setProjects, updateProject })(Project));
