import React from 'react';
import moment from 'moment';
import { Button, Form, Checkbox, Label, Icon } from 'semantic-ui-react';
import {
  fetchPendingEntityDetails,
  updateKeystoneEntity,
  getEntityFromKeystone } from '../../../../utils/api';
import { CustomTextArea } from '../CustomTextArea';
import { ENTITY_TYPE } from '../constants';

export function Blacklist({ state, setState }) {

  const fetchBlacklists = async () => {
    try {
      setState(prevState => (
        { ...prevState, isProcessing: true }
      ))
      const { response } = await getEntityFromKeystone({
        entityId: state.dataramaEntityId,
        entityType: ENTITY_TYPE });
      if (response && response.doneBlacklistResearch && response.blacklisted) {
        setState(prevState => (
          { ...prevState, blacklistText: response.blacklistText, isProcessing: false }
        ))
      } else {
        const results = await fetchPendingEntityDetails(ENTITY_TYPE, state.dataramaEntityId);
        if (results !== null && results.data) {
          const { riskyEntityList } = results.data;
          setState(prevState => (
            { ...prevState, blacklistText: buildText(riskyEntityList), isProcessing: false }
          ))
        } else {
          setState(prevState => (
            { ...prevState, blacklistText: buildText([]), isProcessing: false }
          ))
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  const buildText = (blacklisted=[]) => {
    if (blacklisted.length > 0) {
      return buildBlacklistedText(blacklisted);
    }
    return buildNonBlacklistedText();
  }

  const buildNonBlacklistedText = () => (
    `<p>This company was checked against lists from sanctioning bodies, law enforcement agencies and financial regulators worldwide. These lists include, but are not restricted to: the Specially Designated Nationals List maintained by the Office of Foreign Asset Control, a division of the US Treasury; the Denied Persons List maintained by the Bureau of Industry & Security, a division of the US Department of Commerce; the UK's HM Treasury sanctions lists; the World Bank Listing of Ineligible Firms; and the Excluded Parties Listing System maintained by the US government.<br />The company was not found mentioned in the records of any lists. Datarama notes that the aforementioned lists may not be comprehensive.</p>`
  );

  const buildBlacklistedText = (blacklisted) => {
    let text = `<p>This company was checked against lists from sanctioning bodies, law enforcement agencies and financial regulators worldwide. These lists include, but are not restricted to: the Specially Designated Nationals List maintained by the Office of Foreign Asset Control, a division of the US Treasury; the Denied Persons List maintained by the Bureau of Industry & Security, a division of the US Department of Commerce; the UK's HM Treasury sanctions lists; the World Bank Listing of Ineligible Firms; and the Excluded Parties Listing System maintained by the US government.<br />A check on privately run risk-and-compliance databases as well as extensive public domain research found that ${state.entityName} had been blacklisted by `;
    let additionalText = '';
    for (let i = 0; i < blacklisted.length; i++) {
      additionalText += `${blacklisted[i].riskTypeDesc}${i === (blacklisted.length - 1) ? ` as at ${moment(Date.now()).format('DD-MMM-YYYY')}.`: ', '}`
    }
    return text + additionalText + '</p>';
  };

  const handleOnSubmit = async evt => {
    evt.preventDefault();
    setState(prevState => (
      { ...prevState, isProcessing: true }
    ))
    const payload = JSON.stringify({
      entityType: ENTITY_TYPE,
      dataramaId: state.dataramaEntityId,
      blacklisted: !!state.blacklisted,
      blacklistText: state.blacklistText,
      doneBlacklistResearch: !!state.doneBlacklistResearch,
      politicalExposure: !!state.politicalExposure,
      politicalExposureText: state.politicalExposureText,
      doneBankruptcyCheck: !!state.doneBankruptcyCheck,
      bankruptcyCheckText: state.bankruptcyCheckText,
      doneOffshoreInterestsResearch: !!state.doneOffshoreInterestsResearch,
      offshoreInterests: !!state.offshoreInterests,
      offshoreInterestsText: state.offshoreInterestsText,
      doneLitigationResearch: !!state.doneLitigationResearch
    });
    await updateKeystoneEntity(payload);

    setState(prevState => (
      { ...prevState,
        isProcessing: false,
        completed: { ...prevState.completed, companyCheck: true }
      }
    ))
  }

  const handleCheckbox = (evt, data) => {
    setState(prevState => (
      {...prevState, [data.id]: !prevState[data.id] }
    ))
  }

  const buildCompanyPEPText = () => {
    return '<p>Datarama carried out searches on privately run risk-and-compliance databases listing politically exposed persons, and online public records. The company was not named on any of these records. These databases are not deemed comprehensive.</p>';
  }

  
  return <Form size='small' onSubmit={ handleOnSubmit }>
    <div className='segment'>
      <div className='justify-space-between'>
        <Button size='mini' content='Save' type='submit' loading={ state.isProcessing } disabled={ state.isProcessing } />
      </div>
      <Label color='blue' content='Blacklist and PEP Research' />
      <br /><br />
      <Checkbox
        id='doneBlacklistResearch'
        label='Blacklist and PEP check has been performed'
        disabled={state.isProcessing}
        onClick={handleCheckbox}
        checked={!!state.doneBlacklistResearch} />
      <br /><br />
      { state.doneBlacklistResearch &&
        <>
        <Checkbox
          id='blacklisted'
          label='Entity is blacklisted'
          disabled={state.isProcessing}
          onClick={handleCheckbox}
          checked={!!state.blacklisted} />&nbsp;&nbsp;&nbsp;&nbsp;
          <Icon name='refresh' onClick={ fetchBlacklists } />
          <br /><br /></> }
      { state.blacklisted &&
        <>
        <div>1. By default, blacklist details from C6 are populated in the textbox.</div>
        <div>2. <b><u><a href='/research/sanctions' target="_blank" rel='noopener noreferrer'>Click here</a></u></b> to perform additional sanctions list check.</div>
        <br /><br />
        <CustomTextArea setState={ setState } state={ state } type='blacklist' />
        <br /><br />
        </> }
        { state.doneBlacklistResearch &&
        <>
        <Checkbox
          id='politicalExposure'
          label='Entity is politically exposed'
          disabled={state.isProcessing}
          onClick={handleCheckbox}
          checked={!!state.politicalExposure} />&nbsp;&nbsp;&nbsp;&nbsp;
        <Icon name='refresh' onClick={ buildCompanyPEPText } />
        <br /><br /></> }
      { state.politicalExposure &&
        <>
        <CustomTextArea setState={ setState } state={ state } type='pep' />
        <br /><br />
        </> }

      <Label color='blue' content='Bankruptcy Check' />
      <br /><br />
      <Checkbox
        id='doneBankruptcyCheck'
        label='Bankruptcy check has been performed'
        disabled={state.isProcessing}
        onClick={handleCheckbox}
        checked={!!state.doneBankruptcyCheck} />
      <br /><br />
      { state.doneBankruptcyCheck &&
        <>
        <CustomTextArea setState={ setState } state={ state } type='bankrupt' />
        <br />
        <b><u>Blurbs</u></b>
        <br />
        A check against records maintained by [name of local agency] as well as extensive public domain research indicates that [company XXX] has never been declared insolvent or bankrupt. 
        <br />
        For jurisdictions where bankruptcy checks are unavailable: Comprehensive insolvency and bankruptcy searches are not available in [country]. Extensive public domain research indicates that [company XXX] has never been declared insolvent or bankrupt. 
        <br />
        <b>OR</b> 
        <br />
        A check against records maintained by [name of local agency] indicates that [company XXX] was declared [insolvent/bankrupt] in [month and year].
        <br />
        [Other details].
        <br /><br />
        </> }
      <Label color='blue' content='Litigation Research' />
      <br /><br />
      <Checkbox
        id='doneLitigationResearch'
        label='Litigation research has been performed'
        disabled={state.isProcessing}
        onClick={handleCheckbox}
        checked={ !!state.doneLitigationResearch } />
      <br /><br />
      <Label color='blue' content='Offshore Interests Research' />
      <br /><br />
      <Checkbox
        id='doneOffshoreInterestsResearch'
        label='Offshore research has been performed'
        disabled={state.isProcessing}
        onClick={handleCheckbox}
        checked={ !!state.doneOffshoreInterestsResearch } />
      <br /><br />
      { state.doneOffshoreInterestsResearch &&
        <Checkbox
          id='offshoreInterests'
          label='Entity has offshore interests'
          disabled={state.isProcessing}
          onClick={handleCheckbox}
          checked={ !!state.offshoreInterests } /> }
      { state.offshoreInterests &&
        <>
        <br /><br />
        <CustomTextArea setState={ setState } state={ state } type='offshoreInterests' />
        </> }
    </div>
  </Form>
  
} 