import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import 'semantic-ui-css/semantic.min.css';
import Login from './components/Login';
import auth from './utils/auth';
import 'react-table/react-table.css';
import './utils/styles.css';
import { setAuthState } from './actions';
import { getAuth } from './reducers/selectors';
import Loading from './components/Loading';
import MainContent from './components/MainContent';
import SiteTitle from './components/SiteTitle';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSession: false,
    };
    this.onAuthStateChange = this.onAuthStateChange.bind(this);
    this.setAuthState = this.setAuthState.bind(this);
  }

  componentDidMount() {
    this.onAuthStateChange();
  }

  async onAuthStateChange() {
    this.setState(() => ({
      loadingSession: true,
    }));
    const { loggedInUser } = this.props;
    auth.isLoggedIn(loggedInUser, (user) => {
      this.setState(() => ({
        loadingSession: false,
      }));
      const { userId, permissions } = user || {};
      if (userId) {
        this.props.setAuthState(userId, permissions);
        return userId;
      } else {
        auth.logout();
      }
    });
  }

  setAuthState(loggedInUser) {
    if (loggedInUser) {
      const { userId, permissions } = loggedInUser;
      if (userId) {
        this.props.setAuthState(userId, permissions);
      }
    } else {
      this.props.setAuthState(null);
    }
  }

  render() {
    const { loadingSession } = this.state;
    const { loggedInUser } = this.props;
    return (
      <div className="App">
        <Loading active={loadingSession} dim />
        <SiteTitle />
        {loggedInUser ? (
          <MainContent onAuthStateChange={this.setAuthState} />
        ) : (
          <Login onAuthStateChange={this.setAuthState} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getAuth(state).loggedInUser,
})
export default withRouter(connect(mapStateToProps, {
  setAuthState
})(App));
