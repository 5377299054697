import React, { useEffect, useState } from 'react';
import { Button } from './Button';
import { CONCEPTS } from './constants';
import { PaginatedArticles } from './PaginatedArticles';
import { getSentences } from '../../utils/api';

import {
  Btn,
  BucketRow,
  BucketTag,
  CountTag,
  FieldHint,
  FormatBtn,
  Icon,
  LoadingTag,
  PageFetchHint,
  StatGrid,
  Statistics,
  Summary,
  SummaryRow,
  Tabs,
  TabBtn
} from './styles.js';

export function ResultsWidget({
  activeCategory,
  articlesNotAdverse,
  articlesToIgnore,
  articleType,
  categories,
  currentSearch,
  handleDeselectDuplicates,
  handleFetchByPage,
  handleFormatForReport,
  handleMarkNotAdverse,
  handleSaveCategoryAsCsv,
  handleSetValidation,
  loggedInUser,
  queue,
  requestQueue,
  results,
  setActiveCategory,
  stats,
  terms
}) {

  const [mlSentences, setMlSentences] = useState(null);

  useEffect(() => {
    async function fetching() {
      const response = await getSentences();
      if (response) {
        setMlSentences(response);
      }
      return () => {
        new AbortController().abort();
      };
    }
    fetching();
  }, []);


  return (
    <>
      { (
        <div>
          <Tabs>
          {stats ? (
            <>
            <Statistics>
              <Summary>
                <SummaryRow header>
                  <div></div>
                  <div>Raw</div>
                  <div>Valid</div>
                </SummaryRow>
                <SummaryRow>
                  <div>Total number of media Articles Generated <FieldHint>For case as same article mentioned in multiple buckets, eg. X article mentioned 3 times, the total count of general search will be + 3 instead of + 1.</FieldHint></div>
                  <div>{stats.raw.all}</div>
                  <div>{stats.valid.all}</div>
                </SummaryRow>
                <SummaryRow>
                  <div>Total number of adverse media articles generated</div>
                  <div>{stats.raw.adv}</div>
                  <div>{stats.valid.adv}</div>
                </SummaryRow>
                <SummaryRow>
                  <div>Ratio</div>
                  <div>{(stats.raw.adv / stats.raw.all).toFixed(4)} ({(stats.raw.adv * 100 / stats.raw.all).toFixed(2)}%)</div>
                  <div>{(stats.valid.adv / stats.valid.all).toFixed(4)} ({(stats.valid.adv * 100 / stats.valid.all).toFixed(2)}%)</div>
                </SummaryRow>
              </Summary>
              <div>
                <Button
                  onClick={handleFormatForReport}
                  label="Format for Report"
                  component={FormatBtn} />
              </div>
            </Statistics>
            </>
                ) : (
            <LoadingTag>Processing...</LoadingTag>
          )}
          {requestQueue && categories.map(category => requestQueue[category] && (
            <Button
              onClick={cat => setActiveCategory(cat)}
              label={(<span>{CONCEPTS.filter(c => c.code === category)[0].label} {results && results[category] && `(${results[category].total || 0})`} {!requestQueue[category].status && <LoadingTag small>Processing...</LoadingTag>}</span>)}
              value={category}
              disabled={!requestQueue[category].status}
              key={category}
              component={TabBtn}
              meta={{
                selected: activeCategory === category,
              }}
            />
          ))}
          </Tabs>
          {activeCategory && (
            <>
              {!requestQueue[activeCategory].status && (
                <LoadingTag>Processing...</LoadingTag>
              )}
              <BucketRow>
                <div>
                  <BucketTag>
                    { CONCEPTS.filter(c => c.code === activeCategory)[0].label }
                  </BucketTag>
                  <StatGrid><div>Total Count</div><div><CountTag>{results[activeCategory].total}</CountTag></div></StatGrid>
                  <StatGrid><div>Currently Showing:</div><div><CountTag>{(results[activeCategory].result || []).length}</CountTag></div></StatGrid>
                  <StatGrid><div>Invalid Count:</div><div><CountTag>{results[activeCategory].filteredCount}</CountTag></div></StatGrid>
                  <br/>
                  {/* <StatGrid><div>Valid Count</div><div><CountTag>{(results[activeCategory].result || []).filter(p => !articlesToIgnore[p.id]).length}</CountTag></div></StatGrid> */}
                  {results[activeCategory].total > ((results[activeCategory].result || []).length + results[activeCategory].filteredCount) && !queue[activeCategory] && (
                    <div>
                      <PageFetchHint>{!queue[activeCategory] && (results[activeCategory].total - results[activeCategory].filteredCount - (results[activeCategory].result || []).length)} article more to fetch</PageFetchHint>
                      <br />
                      <Button
                        disabled={ !requestQueue[activeCategory].status }
                        onClick={handleFetchByPage}
                        value={{
                          category: activeCategory,
                          page: (results[activeCategory].page || 0) + 1
                        }}
                        label={(<span>
                          <Icon>add</Icon> Fetch More
                        </span>)}
                        component={Btn} />
                      <br /><br />
                      <Button
                        disabled={ !requestQueue[activeCategory].status }
                        onClick={handleFetchByPage}
                        value={{
                          category: activeCategory,
                          fetchAll: true,
                          page: (results[activeCategory].page || 0) + 1
                        }}
                        label={(<span>
                          <Icon>add</Icon> Fetch All
                        </span>)}
                        component={Btn} />
                    </div>
                  )}
                  {queue[activeCategory] && (
                    <LoadingTag>loading...</LoadingTag>
                  )}
                </div>
                <div>
                  <Button
                    onClick={handleSaveCategoryAsCsv}
                    disabled={!requestQueue[activeCategory].status}
                    value={activeCategory}
                    label={`Download ${CONCEPTS.filter(c => c.code === activeCategory)[0].label} results (${(results[activeCategory].result || []).length} articles) as CSV`}
                    component={Btn} />
                  <PaginatedArticles
                    mlSentences={ mlSentences }
                    category={activeCategory}
                    articles={results[activeCategory].result}
                    articlesToIgnore={articlesToIgnore}
                    duplicates={articleType.dups}
                    loggedInUser={loggedInUser}
                    titleDuplicates={articleType.byTitleDups}
                    setValidation={handleSetValidation}
                    markNotAdverse={handleMarkNotAdverse}
                    deselectDuplicates={handleDeselectDuplicates}
                    articlesNotAdverse={articlesNotAdverse}
                    terms={currentSearch && Object.keys(currentSearch.name).reduce((a, c) => Object.assign({}, a, {[c]: terms[currentSearch.client][c]}), {})}
                    searchScope={currentSearch} />
                </div>
              </BucketRow>
            </>
          )}
        </div>
      )}

    </>
  )
}