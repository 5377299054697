import React, { useState } from 'react';
import moment from 'moment';
import { Grid, Button, Form } from 'semantic-ui-react';
import { LANGUAGES } from '../../../constants';
import { Summary } from './Summary';
import { EntitySearchFields } from './EntitySearchFields';
import { DefaultFields } from './DefaultFields';

export function SearchConditions({state, setState}) {
  const DEFAULT_STATE = {
    activeLang: '',
    entityId: '',
    entityType: 'company',
    requestPayload: {
      'eng': { entityName: state.entityName }
    },
    isCacheDisabled: true,
    isEventRegistry: false,
    isSecondEntityVisible: false
  };
  
  const [search, setSearch] = useState(DEFAULT_STATE);

  const handleOnSubmit = event => {
    try {
      event.preventDefault();      
      const dateRange = {
        start: moment(moment().subtract(3, 'years').format('YYYY')).format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD')
      }

      setState(prevState => (
        { ...prevState,
          search: {
            ...prevState.search,
            dateRange,
            requestPayload: cleanPayload(search.requestPayload),
            entityType: search.entityType,
            isCacheDisabled: search.isCacheDisabled
          },
          active: 'searchResults'
        }
      ))
    } catch (err) {
      console.log(err);
    }
  }

  const cleanPayload = (payload) => {
    return Object.keys(payload).reduce((acc, lang) => {
      if (payload[lang].entityName === '' || payload[lang].entityName === undefined) {
        return acc;
      }
      acc[lang] = payload[lang];
      return acc;
    }, {});
  }

  return (
    <Form size='mini' onSubmit={ handleOnSubmit } className='search-conditions'>
      <Summary state={ search } />
      <br /><br />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Grid columns={2}>
              <DefaultFields state={ search } setState={ setSearch } />
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <Form.Dropdown
                      id='lang'
                      upward={false}
                      fluid
                      selection
                      label='Language'
                      placeholder='Select none'
                      onChange={(evt, data) => setSearch(prevState => {
                        if (prevState.requestPayload &&
                          prevState.requestPayload[prevState.activeLang] &&
                          prevState.requestPayload[prevState.activeLang].entityName === '') {
                            delete prevState.requestPayload[prevState.activeLang];
                        }
                        return {...prevState, activeLang: data.value }
                      })}
                      options={ Object.keys(LANGUAGES).map(lang => ({ key: lang, value: lang, text: LANGUAGES[lang] })).filter(lang => lang.key !== 'eng') } />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <EntitySearchFields
                search={ search }
                setSearch={ setSearch } />
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      <br /><br />
      {/* <div>
        <Checkbox
          label='Disable Cache (Must check if searching for different time span / different client)'
          checked={ search.isCacheDisabled }
          onChange={ () => setSearch(prevState => (
            { ...prevState, isCacheDisabled: !prevState.isCacheDisabled }
          )) } />
      </div>
      <br /><br /> */}
      {/* <Button basic onClick={ () => setSearch(DEFAULT_STATE) }>Reset</Button> */}
      <Button basic type='submit'>Submit</Button>
    </Form>)

}

