import React from 'react';
import { withRouter } from 'react-router';
import { Route } from "react-router-dom";
import styled from 'styled-components';
import { Menu, Label } from 'semantic-ui-react';
import NewEntity from './ingestion/NewEntity';
import UpdateEntity from './ingestion/UpdateEntity';
import UpdateRisk from './ingestion/UpdateRisk';
import { NewRelationship } from './ingestion/NewRelationship';
import { UpdateRelationship } from './ingestion/UpdateRelationship';
import { ExpressRelationship } from './ingestion/ExpressRelationship';
import { UpdateLitigationCase } from './ingestion/UpdateLitigationCase';
import { NewLitigation } from './ingestion/NewLitigation';
import { NewLiteEDDReport } from './liteEDD/NewLiteEDDReport';
import { KeystonePeople } from './keystone/people/KeystonePeople';
import { KeystoneOrganisations } from './keystone/organisations/KeystoneOrganisations';
import MenuItem from './MenuItem';
import AccessDenied from './AccessDenied';
import UpdateDenied from './Message/UpdateDenied';
import { ENTITY_TYPE, ENTITY_BY_TYPE } from '../utils/ref/entityType';

const Content = styled.div`
  flex: 1;
  overflow: hidden;
`;
const HeaderItem = styled(Menu.Item)`
  font-weight: 600;
  font-size: 14px;
`
const MenuWrapper = styled.div`
  > * {
    display: inline-block
  }
`;

const RELATIONSHIP = 'relationship';
const LITIGATION = 'litigation';
const LITEEDD = 'liteedd';

const ELEM_MAP = {
  add: {
    [RELATIONSHIP]: NewRelationship,
    [LITIGATION]: NewLitigation,
    [LITEEDD]: NewLiteEDDReport,
    default: NewEntity,
  },
  update: {
    [RELATIONSHIP]: UpdateRelationship,
    [LITIGATION]: UpdateLitigationCase,
    default: UpdateEntity,
  },
  express: {
    [RELATIONSHIP]: ExpressRelationship
  },
  keystone: {
    person:  KeystonePeople,
    company: KeystoneOrganisations
  }
}

const MENU_ITEMS = {
  [ENTITY_TYPE.person]: {
    header: ENTITY_BY_TYPE.person.header,
    key: ENTITY_TYPE.person,
    options: ['add', 'update', 'updateRisk', 'keystone']
  },
  [ENTITY_TYPE.company]: {
    header: ENTITY_BY_TYPE.company.header,
    key: ENTITY_TYPE.company,
    options: ['add', 'update', 'updateRisk', 'keystone']
  },
  [ENTITY_TYPE.family]: {
    header: ENTITY_BY_TYPE.family.header,
    key: ENTITY_TYPE.family,
    options: ['add', 'update', 'updateRisk']
  },
  [ENTITY_TYPE.governmentAgency]: {
    header: ENTITY_BY_TYPE.governmentAgency.header,
    key: ENTITY_TYPE.governmentAgency,
    options: ['add', 'update', 'updateRisk']
  },
  [ENTITY_TYPE.otherOrganisation]: {
    header: ENTITY_BY_TYPE.otherOrganisation.header,
    key: ENTITY_TYPE.otherOrganisation,
    options: ['add', 'update', 'updateRisk']
  },
  [RELATIONSHIP]: {
    header: 'Relationship',
    key: RELATIONSHIP,
    options: ['add', 'update', 'express']
  },
  [LITIGATION]: {
    header: 'Litigation Cases',
    key: LITIGATION,
    options: ['add', 'update']
  },
  [LITEEDD]: {
    header: 'LiteEDD',
    key: LITEEDD
  }
};

const MENU_ORDER = [
  ENTITY_TYPE.person,
  ENTITY_TYPE.company,
  ENTITY_TYPE.family,
  ENTITY_TYPE.governmentAgency,
  ENTITY_TYPE.otherOrganisation,
  RELATIONSHIP,
  LITIGATION,
  LITEEDD
];

const OPTIONS = {
  add: {
    action: 'add',
    display: 'Add New'
  },
  update: {
    action: 'update',
    display: 'Update'
  },
  updateRisk: {
    action: 'update-risk',
    display: 'Update Risk Details'
  },
  express: {
    action: 'express',
    display: 'Express'
  },
  keystone: {
    action: 'keystone',
    display: 'Keystone Entry'
  }
}

const IngestionActions = (props) => {
  const { match, permitted } = props;
  const { project } = props;
  if (!permitted) return <AccessDenied action='modify' resourceType='project' />

  if (project && !project.allowUpdates) return (
     <UpdateDenied status={project.overallStatus} />
  )

  return (
    <div>
      <MenuWrapper>
        <Menu pointing>
          <HeaderItem header>Entity</HeaderItem>
          {MENU_ORDER.map(item => (
            <MenuItem
              key={item}
              name={MENU_ITEMS[item].header}
              value={MENU_ITEMS[item].key}
            />
          ))}
        </Menu>
        <Route
          path={`${match.path}/:type`}
          render={(routeProps) => {
            const { match: { params }} = routeProps;
            const { type } = params;
            if (!MENU_ITEMS[type]) return null;
            if (MENU_ITEMS[type].key === LITEEDD) {
              return <NewLiteEDDReport params={params} />;
            }
            return (
              <React.Fragment>
                <Label horizontal basic>{MENU_ITEMS[type].header}</Label>
                <MenuWrapper>
                  <Menu size='small' secondary>
                    {MENU_ITEMS[type].options.map(op => (
                      <MenuItem
                        key={op}
                        name={OPTIONS[op].display}
                        value={`${OPTIONS[op].action}`}
                        exact
                      />
                    ))}
                  </Menu>
                </MenuWrapper>
              </React.Fragment>
            )
          }}
        />
      </MenuWrapper>
      <Content>
        <Route
          path={`${match.path}/:type/add`}
          exact
          render={(routeProps) => {
            const { match: { params }} = routeProps;
            const { type } = params;
            const Elem = ELEM_MAP.add[type] || ELEM_MAP.add.default;
            return (
              <Elem projectId={project.projectId} {...routeProps}/>
            )
          }}
        />
        <Route
          path={`${match.path}/:type/update`}
          exact
          render={(routeProps) => {
            const { match: { params }} = routeProps;
            const { type } = params;
            const Elem = ELEM_MAP.update[type] || ELEM_MAP.update.default;
            if (!Elem) return null;
            return (
              <Elem projectId={project.projectId} {...routeProps}/>
            )
          }}
        />
        <Route
          path={`${match.path}/:type/express`}
          exact
          render={(routeProps) => {
            const { match: { params }} = routeProps;
            const { type } = params;
            const Elem = ELEM_MAP.express[type];
            return (
              <Elem projectId={project.projectId} {...routeProps}/>
            )
          }} />
        <Route
          path={`${match.path}/:type/keystone`}
          exact
          render={(routeProps) => {
            const { match: { params }} = routeProps;
            const { type } = params;
            const Elem = ELEM_MAP.keystone[type];
            return (
              <Elem projectId={project.projectId} {...routeProps}/>
            )
          }} />
        <Route
          path={`${match.path}/:type/update-risk`}
          exact
          render={(routeProps) => <UpdateRisk projectId={project.projectId} {...routeProps}/>}
        />
      </Content>
    </div>
  )
}

export default withRouter(IngestionActions);
