import React from 'react';
import { ArticleRow } from './ArticleRow';
import { AddArticle } from './AddArticle';
import { Grid } from 'semantic-ui-react';

export function Articles({state, setState}) {
  return <>
  <AddArticle state={ state } setState={ setState } />
  <Grid style={{padding: '2rem'}}>
    <Grid.Column width={2}>
      <b><u>Risk Categories</u></b>
    </Grid.Column>
    <Grid.Column width={2}>
      <b><u>Links</u></b>
    </Grid.Column>
    <Grid.Column width={3}>
      <b><u>Title</u></b>
    </Grid.Column>
    <Grid.Column width={8}>
      <b><u>Summary</u></b>
    </Grid.Column>
    { state.articles && Object.keys(state.articles).map(id => (
      <ArticleRow
        state={ state }
        setState={setState}
        key={ id }
        id={ id }
        title={ state.articles[id].title }
        riskCategories={ state.articles[id].riskCategories }
        s3Url={ state.articles[id].s3Url }
        url={ state.articles[id].url } />
    ))}
  </Grid></>
}
