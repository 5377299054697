import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react';
import { platformConversion } from '../utils/ref/entityType';
import { Hyperlink } from '../utils/styles';

const ClickWrapper = styled.span`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  margin-left: 5px;
`;
const CopiedMessage = styled.div`
  position: absolute;
  font-size: 11px;
  font-weight: 600;
`;

export default ({ id, type, link, action }) => {
  const [copied, setCopied] = useState(false);
  let timer;
  const setTimeoutMessage = () => {
    setCopied(true);
    timer = setTimeout(() => {
      setCopied(false);
    }, 1000);
  }
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const ac = new AbortController();
    return () => ac.abort();
  })
  const convertedType = platformConversion[type];

  const url = convertedType && `${convertedType}/${id}`;

  return (
    <React.Fragment>
    {action && (
      <Button basic size='mini' onClick={action} value={id}>Select ID and upload</Button>
    )}
    {link && convertedType ? (
      <Hyperlink href={`https://platform.datarama.com/${url}`} target="_blank" rel='noreferrer noopener'>
      {id}
      </Hyperlink>
    ) : id}
       <CopyToClipboard text={`${id}`}
         onCopy={() => setTimeoutMessage()}>
         <ClickWrapper>
           <Icon name='copy' color='grey' />
         </ClickWrapper>
       </CopyToClipboard>
       {copied && (
         <CopiedMessage>Copied to Clipboard</CopiedMessage>
       )}
    </React.Fragment>
  )
}
