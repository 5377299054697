import axios from 'axios';

const API_URL = {
  ops: process.env.REACT_APP_OPS_URL || 'https://bq573scfub.execute-api.ap-northeast-2.amazonaws.com/prod',
  adverse: process.env.REACT_APP_ADVERSE_MEDIA_URL || 'https://bq573scfub.execute-api.ap-northeast-2.amazonaws.com/prod',
  entities: process.env.REACT_APP_ENTITIES_URL || 'https://bq573scfub.execute-api.ap-northeast-2.amazonaws.com/prod',
  importer: process.env.REACT_APP_IMPORTER_V2_URL || 'https://bq573scfub.execute-api.ap-northeast-2.amazonaws.com/prod',
  reports: process.env.REACT_APP_REPORTS_URL || 'https://bq573scfub.execute-api.ap-northeast-2.amazonaws.com/prod',
  pep: `${process.env.REACT_APP_GATEWAY_URL || 'https://resource.datarama.com/api'}/pepmodule`,
  logserver: `${process.env.REACT_APP_GATEWAY_URL || 'https://resource.datarama.com/api'}/logserver`
};

const OPTIONS = {
  ops: {
    withCredentials: true,
    crossDomain: false,
  },
}

const authHeaders = {
  pep: 'token'
};

class Request {
  constructor(type) {
    this.type = type;
    if (type === 'adverse' || type === 'entities' || type === 'importer' || type === 'reports') {
      this.type = 'ops'; //ensures auth header contains cookie
    }
    if (!API_URL[type]) return;
    this.API_URL = API_URL[type] || API_URL.auth;
    this.get = this._get.bind(this);
    this.post = this._post.bind(this);
    this.postForm = this._postForm.bind(this);
    this.delete = this._delete.bind(this);
  }

  async _getToken(type) {
    if (this.token) return this.token;
    const newToken = await axios.get(`${this.API_URL}/${authHeaders[this.type]}`);
    this.token = newToken.data;
    return this.token;
  }

  async _get(path, skipReload) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = OPTIONS[this.type] || {};
        if (authHeaders[this.type]) {
          const authToken = await this._getToken();
          if (!authToken) return resolve({});
          options.headers = {
            Authorization: `Bearer ${authToken}`
          }
        }
        const result = await axios.get(`${this.API_URL}/${path}`, options);
        if (result) {
          resolve(result.data);
        }
      } catch (err) {
        if (err.response) {
          const { data } = err.response;
          // Should set only if statuscode = invalid token -> refresh

          if (data.code && data.code === 1001 && !skipReload) {
            console.log('get error: ', path, data.code);
            window.location.reload();
          }
        }

        resolve(null)
      }
    })
  }

  async _delete(path) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.delete(`${this.API_URL}/${path}`, {
          withCredentials: true,
          crossDomain: false,
        });
        if (result) {
          resolve(result.data);
        }
      } catch (err) {
        resolve(null)
      }
    })
  }

  _post(path, body, cb) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = OPTIONS[this.type] || {};
        if (authHeaders[this.type]) {
          const authToken = await this._getToken();
          options.headers = {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        }
        const result = await axios.post(`${this.API_URL}/${path}`, body, options);
        if (result) {
          const statusCode = result.data && (result.data.status || result.data.statusCode);
          if (statusCode && statusCode > 200) {
            return resolve({ error: result.data.body && JSON.parse(result.data.body) });
          }
          resolve(result.data);
        }

      } catch (err) {
        if (err.response) {
          const { data } = err.response;
          if ((data.code && data.code === 1001) && window.location) {
            window.location.reload();
          }
          if ((data.code && data.code === 11001) && window.location) {
            resolve({ ...data, error: true });
          }
        }
        resolve(null)
      }
    })
  }

  _postForm(path, body, cb) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.post(`${this.API_URL}/${path}`, body, {
          withCredentials: true,
          crossDomain: false,
          'Content-Type': 'multipart/form-data'
        });
        if (result) {
          const statusCode = result.data && (result.data.status || result.data.statusCode);
          if (statusCode && statusCode > 200) {
            return resolve({ error: result.data.body && JSON.parse(result.data.body) });
          }
          resolve(result.data);
        }

      } catch (err) {
        if (err.response) {
          const { data } = err.response;
          console.log('get error: ', path, data.code);
          if ((!data.code || data.code === 1001) && window.location) {
            window.location.reload();
          }
        }

        resolve(null)
      }
    })
  }
}


export default Request;
