import TmpEntity from './tmpEntity';

class TmpCompany extends TmpEntity {
  constructor(props) {
    super(props);
    this.type = 'company';

    this._fieldDetails = Object.assign({}, this.basicFieldDetails, {
      entityName: Object.assign({}, this.basicFieldDetails.entityName, {
        Header: 'Company Name'
      }),
      entityStartDate: Object.assign({}, this.basicFieldDetails.entityStartDate, {
        Header: 'Incorporation date'
      }),
      entityEndDate: Object.assign({}, this.basicFieldDetails.entityEndDate, {
        Header: 'Termination date'
      }),
      entityStatus: {
        Header: 'Company Status',
        id: 'entityStatus',
        accessor: 'entityStatus',
        type: 'string',
        required: true,
        input: {
          type: 'dropdown',
          source: 'entityStatus',
        }
      },
      entityLegalForm: {
        Header: 'Legal Form',
        id: 'entityLegalForm',
        accessor: 'entityLegalForm',
        type: 'string',
        required: true,
        input: {
          type: 'dropdown',
          source: 'legalForm',
        }
      },
      'SOE?': {
        Header: 'State-Owned',
        id: 'SOE?',
        accessor: 'SOE?',
        type: 'string',
        required: true,
        input: {
          type: 'dropdown',
          source: 'yesNo',
        }
      },
      'tickerSymbol': {
        Header: 'Ticker',
        id: 'tickerSymbol',
        accessor: 'tickerSymbol',
        type: 'string',
        input: {
          type: 'text',
        }
      },
      'businessRegistrationNo1': {
        Header: 'Business Registration (Only applicable to Chinese company, 注册号)',
        id: 'businessRegistrationNo1',
        accessor: 'businessRegistrationNo1',
        type: 'string',
        input: {
          type: 'text',
        }
      },
      'uniformSocialCreditCode1': {
        Header: 'Social Credit Code (Only applicable to Chinese company, 统一社会信用代码)',
        id: 'uniformSocialCreditCode1',
        accessor: 'uniformSocialCreditCode1',
        type: 'string',
        input: {
          type: 'text',
        }
      },
      'organisationCode1': {
        Header: 'Organisation Code (Only applicable to Chinese company, 组织机构代码)',
        id: 'organisationCode1',
        accessor: 'organisationCode1',
        type: 'string',
        input: {
          type: 'text',
        }
      },
      'NPWP94': {
        Header: 'NPWP94 (Only applicable to Indonesian company)',
        id: 'NPWP94',
        accessor: 'NPWP94',
        type: 'string',
        input: {
          type: 'text',
        }
      },
      'simpleIndustry': {
        Header: 'Simple Industry',
        id: 'simpleIndustry',
        accessor: 'simpleIndustry',
        input: {
          type: 'dropdown',
          source: 'simpleIndustry',
        }
      },
      phoneNumStr: {
        Header: 'Phone #',
        id: 'phoneNumStr',
        accessor: 'phoneNumStr',
        type: 'string',
        input: {
          type: 'text'
        }
      },
      faxNumStr: {
        Header: 'Fax #',
        id: 'faxNumStr',
        accessor: 'faxNumStr',
        type: 'string',
        input: {
          type: 'text'
        }
      },
    });
    this.columns = [
      // 'entryType',
    'entityId',
    'entityName',
    'entityAlias1',
    'langId1',
    'entityAlias2',
    'langId2',
    'entityAlias3',
    'langId3',
    'entityStartDate',
    'entityEndDate',
    'entityStatus',
    'entityLegalForm',
    'SOE?',
    'Blacklist',
    'Blacklist detail',
    'tickerSymbol',
    'businessRegistrationNo1',
    'uniformSocialCreditCode1',
    'organisationCode1',
    'otherRegistrationNo1',
    'RegistrationCountry',
    'NPWP94',
    'addressStr',
    'addressStrInOtherLang',
    'langId4',
    'country',
    'simpleIndustry',
    'phoneNumStr',
    'faxNumStr',
    'source',
    'QOS',
    'ins_date',
    'upd_date',
    'creator'
  ];

  this.digestFields = [
    'entityId',
    'entityName',
    'country',
    'simpleIndustry',
    'source'
  ]

  // tickerSymbol
    this.newEntityGroupFields = [
      ['entityName', 'country'],
      ['simpleIndustry'],
      ['entityAlias1', 'langId1'],
      ['entityAlias2', 'langId2'],
      ['entityAlias3', 'langId3'],
      ['entityStartDate', 'entityEndDate', 'entityStatus', 'entityLegalForm'],
      ['SOE?', 'Blacklist', 'Blacklist detail'],
      ['addressStr', 'addressStrInOtherLang', 'langId4'],
      ['phoneNumStr', 'faxNumStr'],
      ['businessRegistrationNo1', 'uniformSocialCreditCode1', 'organisationCode1'],
      ['RegistrationCountry', 'otherRegistrationNo1', 'NPWP94'],
      ['source', 'QOS']
    ]

    this.restrictedFieldsByCountry = {
      businessRegistrationNo1: {
        China: true,
      },
      NPWP94: {
        Indonesia: true,
      },
      uniformSocialCreditCode1: {
        China: true,
      },
      organisationCode1: {
        China: true,
      }
    }
  }

}

export default TmpCompany;
