import { ENTITY_TYPE } from '../../utils/ref/entityType';

export default {
  [ENTITY_TYPE.person]: {
    firstName: {
      label: 'First name',
      placeholder: 'First name',
      required: true,
    },
    middleName: {
      label: 'Middle name',
      placeholder: 'Middle name (optional)'
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Last name',
      required: true,
    }
  },
  [ENTITY_TYPE.company]: {
    companyName: {
      label: 'Company name',
      placeholder: 'Company name',
      required: true,
    }
  },
  [ENTITY_TYPE.family]: {
    name: {
      label: 'Family name',
      placeholder: 'family name',
      required: true,
    }
  },
  [ENTITY_TYPE.otherOrganisation]: {
    name: {
      label: 'Other organisation Name',
      placeholder: 'Other organisation name',
      required: true,
    }
  },
  [ENTITY_TYPE.governmentAgency]: {
    name: {
      label: 'Government Agency Name',
      placeholder: 'Government Agency name',
      required: true,
    }
  }
}
