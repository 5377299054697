import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const category = {
  businessdescription: 'businessdescription',
  executiveSummary: 'executiveSummary',
  publicProfile: 'publicProfile'
}

export function CustomTextArea({
  state,
  setState,
  type
}) {
  const [editorState, setEditorState] = useState('');

  useEffect(() => {
    if (type === 'bankrupt') {
      setEditorState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(state.bankruptcyCheckText || '')
        )
      ))  
    } else if (type === 'blacklist') {
      setEditorState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(state.blacklistText || '')
        )
      ))  
    } else if (type === 'offshoreInterests') {
      setEditorState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(state.offshoreInterestsText || '')
        )
      ))  
    } else if (type === 'pep') {
      setEditorState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(state.politicalExposureText || '')
        )
      ))
    } else {
      setEditorState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(state[category[state.active]] || '')
        )
      ))  
    }
  }, [state.active, state.isProcessing])

  const onEditorStateChange = blockState => {
    setEditorState(blockState);
    if (type === 'bankrupt') {
      setState(prevState => (
        { ...prevState,
          bankruptcyCheckText: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }
      ));
    } else if (type === 'blacklist') {
      setState(prevState => (
        { ...prevState,
          blacklistText: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }
      ));
    } else if (type === 'offshoreInterests') {
      setState(prevState => (
        { ...prevState,
          offshoreInterestsText: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }
      ));
    } else if (type === 'pep') {
      setState(prevState => (
        { ...prevState,
          politicalExposureText: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }
      ));
    } else {
      setState(prevState => (
        { ...prevState,
          [category[state.active]]: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }
      ));
    }
  }
  return <Editor
    editorState={editorState}
    wrapperClassName="wysiwyg-wrapper"
    editorClassName="wysiwyg-editor"
    onChange={() => {}}
    toolbar={{
      options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history']
    }}
    onEditorStateChange={onEditorStateChange} />
}