import React, { useEffect, useState } from 'react';
import { BASIC_INFORMATION, BLACKLIST, POLITICAL_EXPOSURE, TINYMCE_API_KEY } from '../../constants';
import {Editor} from '@tinymce/tinymce-react';

const category = {
  basicInfo: BASIC_INFORMATION,
  sanctions: BLACKLIST,
  pep: POLITICAL_EXPOSURE
}

export function CustomTextArea({
  state,
  setState
}) {
  const [editorState, setEditorState] = useState('');

  useEffect(() => {
    if (state.active !== 'addSummary') {
      setEditorState(state[category[state.active]] || '');
    }
  }, [state.active, state.isProcessing])

  const handleEditorChange = (blockState, editor) => {
    setEditorState(blockState);
    setState(prevState => (
      { ...prevState,
        [category[state.active]]: blockState
      }
    ));
  }

  return <Editor
    initialValue = {editorState}
    apiKey={TINYMCE_API_KEY}
    init={{
      height: 500,
      plugins: [
        'advlist autolink lists image charmap print preview anchor', 
        'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount'
      ],
      toolbar: ['undo redo | bold italic | alignleft aligncenter alignright']
    }}
    value={editorState}
    onEditorChange={handleEditorChange} />
}