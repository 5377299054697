import React, {useState} from 'react';
import { Header, Menu } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import FindEntity from '../FindEntity';
import { ENTITY_TYPE, ENTITY_BY_TYPE } from '../../utils/ref/entityType';

const entityTypeOrder = [ENTITY_TYPE.person, ENTITY_TYPE.company, ENTITY_TYPE.family, ENTITY_TYPE.otherOrganisation, ENTITY_TYPE.governmentAgency];

const FindById = (props) => {
  let [type, setType] = useState('');
  return (
    <div>
      <Header
        content='Find Entity by Datarama ID'
        subheader='Note: result only showing data from master database'
      />
      <Menu>
        {entityTypeOrder.map(entityType => (
          <Menu.Item
            key={entityType}
            name={ENTITY_BY_TYPE[entityType].header}
            active={entityType === type}
            onClick={() => setType(entityType)}
            value={entityType}
          />
        ))}
      </Menu>
      {type && (
        <FindEntity
          type={type}
          searchById
          {...props}
        />
      )}
    </div>
  )
}

export default withRouter(FindById);
