import React, { useEffect, useState } from 'react';
import { Icon, Menu, Label, Table } from 'semantic-ui-react';
// import { flagRelationship } from '../../utils/api';

export function ExpressRelationshipTable({state, setState, rels}) {
  const [isActive, setIsActive] = useState('');
  const handleOnClick = (evt, data) => {
    evt.preventDefault();
    setIsActive(data.name);
  }
  useEffect(() => {
    setIsActive(Object.keys(rels)[0])
  }, [Object.keys(rels).length])

  const handleFlagErrorRel = async (event, { value }) => {
    event.stopPropagation();
    // if (value.relId) {
    //   const { relId } = await flagRelationship(JSON.stringify(value));
    //   setState(prevState => (
    //     { ...prevState, relationships: prevState.relationships.filter(rel => rel.relId !== relId)}
    //   ))
    // } else {
    setState(prevState => (
      { ...prevState, payload: prevState.payload.filter(rel => {
        return (rel.relTypeStr !== value.relTypeStr && rel.entityId !== value.entityId && rel.entity2Id !== value.entity2Id && rel.relDescStr !== value.relDescStr);
        })
      }
    ))
    // }
  }
  if (Object.keys(rels).length === 0) {
    return null;
  }
  return <>
    <Menu items={Object.keys(rels).map(rel => ({key: rel, active: isActive===rel, name: rel, onClick:handleOnClick }))} />
    <Table size='small'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Rel Id</Table.HeaderCell>
          <Table.HeaderCell>Entity</Table.HeaderCell>
          <Table.HeaderCell>Entity 2</Table.HeaderCell>
          <Table.HeaderCell>Desc.</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Source</Table.HeaderCell>
          <Table.HeaderCell>Source Url</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>

      <Table.Body>
        { (rels[isActive] || []).map((rel, index) => (
          <Table.Row key={index}>
            <Table.Cell>
              {rel.relId ? rel.relId : <Label color='green'>New!</Label>}
            </Table.Cell>
            <Table.Cell>
              {rel.entityId}<br />
              {rel.entityOneName}
            </Table.Cell>
            <Table.Cell>
              {rel.entity2Id}<br />
              {rel.entityTwoName}
            </Table.Cell>
            <Table.Cell>{rel.relDescStr}</Table.Cell>
            <Table.Cell>{rel.relTypeStr ? rel.relTypeStr : rel.relTypeDescStr}</Table.Cell>
            <Table.Cell>{rel.sourceStr ? rel.sourceStr :`${rel.sourceNameStr}+${rel.sourceQualityStr}`}</Table.Cell>
            <Table.Cell>
              <a href={rel.sourceUrlStr} target="_blank" rel='noopener noreferrer'>Ref</a>
            </Table.Cell>
            <Table.Cell>
              <Icon name='remove' className='errorFlag' onClick={handleFlagErrorRel} value={{
                errorLinkFlag: rel.errorLinkFlag ? 0 : 1,
                entityId: rel.relId ? null : rel.entityId,
                entity2Id: rel.relId ? null : rel.entity2Id,
                relDescStr: rel.relId ? null : rel.relDescStr,
                relTypeStr: rel.relId ? null : rel.relTypeStr,
                relId: rel.relId ? rel.relId : null
              }}/>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </>
}