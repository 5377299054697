import Request from './request';

export const getSettings = async () => {
  const request = new Request('ops');
  const result = await request.get('settings');
  return result;
}

export const checkPermissionForProject = async (projectId, action) => {
  const request = new Request('ops');
  const result = await request.post(`project/${projectId}/checkAccess`, { action });
  return result;
}

export const checkPermissions = async (resourceType, userId) => {
  const request = new Request('ops');
  const result = await request.get(`access/${resourceType}/${userId}`);
  return result;
}

export const updateProjectStatus = async (projectId, status) => {
  const request = new Request('ops');
  const result = await request.post(`project/${projectId}/status/${status}`);
  return result;
}

export const updateProject = async (projectId, meta) => {
  const request = new Request('ops');
  const result = await request.post(`project/${projectId}`, meta);
  return result;
}

export const getSanctionsList = async ({
  limit = 5,
  skip,
  name = '',
  type = '' }) => {
  const request = new Request('ops');
  return await request.get(`sanctions/?name=${name}&type=${type}&skip=${skip}&limit=${limit}`);
}

// callback to set state
export const validateEntity = async (type, body, cb) => {
  const request = new Request('ops');
  const result = await request.post('validate/' + type, body, cb);
  return result;
}

export const validateRelationship = async (type, body, cb) => {
  const request = new Request('ops');
  const result = await request.post('validate/' + type, body, cb);
  return result;
}

export const validateLitigation = async (body, cb) => {
  const request = new Request('ops');
  const result = await request.post('validate/litigation', body, cb);
  return result;
}

export const searchES = async (type, body, cb) => {
  const request = new Request('ops');
  const result = await request.post('search/' + type, body, cb);
  return result;
}

export const findDataramaId = async (type, dataramaId) => {
  const request = new Request('ops');
  const result = await request.get(`find/${type}/${dataramaId}`);
  return result;
}

export const getAllProjects = async (page = 0) => {
  const request = new Request('ops');
  const result = await request.get('projects/' + page );
  return result;
}

export const updateProjectAccessRight = async (projectId, userList, accessRights = [], isRemoving = false) => {
  const request = new Request('ops');
  const result = await request.post('project/' + projectId + '/access', {
    users: userList,
    accessRights,
    removeAccess: isRemoving,
  });
  return result;

}


export const updateAccessRights = async (userId, permissionUpdates) => {
  const request = new Request('ops');
  const result = await request.post('access', {
    userId,
    permissionUpdates,
  });
  return result;

}

export const createClient = async (properties) => {
  const request = new Request('ops');
  const result = await request.post('client', properties);
  return result;
}



export const getProject = async (projectId) => {
  const request = new Request('ops');
  const result = await request.get('project/' + projectId);
  return result;
}

export const createProject = async (meta) => {
  const request = new Request('ops');
  const result = await request.post('project', meta);
  return result;
}

export const addNewEntity = async (projectId, body) => {
  const request = new Request('ops');
  const result = await request.post('project/' + projectId + '/entity/new', body);
  return result;
}

export const addNewRel = async (projectId, body) => {
  const request = new Request('ops');
  const resourceType = body.entityType === 'litigation' ? 'litigation' : 'rel';
  const result = await request.post('project/' + projectId + '/' + resourceType + '/new', body);
  return result;
}

export const updateRel = async (projectId, body) => {
  const request = new Request('ops');
  const resourceType = body.entityType === 'litigation' ? 'litigation' : 'rel';
  const result = await request.post('project/' + projectId + '/' + resourceType + '/update', body);
  return result;
}

export const addNewUpdateEntry = async (projectId, body) => {
  const request = new Request('ops');
  const result = await request.post('project/' + projectId + '/entity/update', body);
  return result;
}

export const listIngestionRecordsByProject = async (projectId, type) => {
  const request = new Request('ops');
  const result = await request.get('records/' + projectId + '/' + type);
  return result;
}

export const addRiskDetails = async (projectId, entityId, body) => {
  const request = new Request('ops');
  const result = await request.post('risk/' + projectId + '/' + entityId + '/new', body);
  return result;
}

export const removeRiskDetails = async (projectId, entityId, riskTypeId) => {
  const request = new Request('ops');
  const result = await request.delete('risk/' + projectId + '/' + entityId + '/remove/' + riskTypeId);
  return result;
}

export const getHistories = async (limit) => {
  const request = new Request('ops');
  const result = await request.get('histories' + (limit ? ('/' + limit) : ''));
  return result;
}


export const pushNewCapiqEntity = async (type, entityData, cb) => {
  const request = new Request('ops');
  const result = await request.post('capiq/' + type + '/' + entityData.capiqId, JSON.stringify(entityData), cb);
  return result;
}

export const getArticlesWithQuery = async (nameList, meta) => {
  const request = new Request('adverse');
  const result = await request.post(`articles/${meta.category}/0`, Object.assign({}, {lang: nameList}, meta));
  return result;
}

export const getSentences = async () => {
  const request = new Request('adverse');
  const result = await request.get('adverseData');
  return result;
}

export const getRiskIndicators = async concept => {
  const request = new Request('adverse');
  const result = await request.get(`risk-indicator-terms/${concept}`);
  return result;
}

export const getAllRiskIndicators = async () => {
  const request = new Request('adverse');
  const result = await request.get('all-risk-indicator-terms');
  return result;
}

export const fetchArticleFeedback = async body => {
  const request = new Request('adverse');
  const result = await request.get('fetch-article-feedback');
  return result;
}

export const amendArticleFeedback = async body => {
  const request = new Request('adverse');
  const result = await request.post('amend-article-feedback', body);
  return result;
}

export const addRiskIndicator = async body => {
  const request = new Request('adverse');
  const result = await request.post('risk-indicator-term', body);
  return result;
}

export const removeRiskIndicator = async id => {
  const request = new Request('adverse');
  const result = await request.delete(`risk-indicator-term/${id}`);
  return result;
}


export const addSentence = async body => {
  const request = new Request('adverse');
  const result = await request.post('adverseData', body);
  return result;
}


export const listFilesById = async (dataramaId) => {
  const request = new Request('ops');
  const result = await request.get('files/' + dataramaId);
  return result;
}


export const uploadFiles = async (dataramaId, file) => {
  const request = new Request('ops');
  const result = await request.postForm('files/' + dataramaId, file);
  return result;
}

export const markEntityDoNotUse = async (dataramaId, type) => {
  const request = new Request('ops');
  if (!dataramaId || !type) return;
  const result = await request.post(`entity/${type}/${dataramaId}`);
  return result;
}

export const deleteProject = async (projectId) => {
  const request = new Request('ops');
  const result = await request.delete(`project/${projectId}`);
  return result;
}

const pepRequest = new Request('pep');

export const fetchPendingEntityDetails = async (type, entityId) => {
  if (!pepRequest) return;
  const entityType = (type === 'company' && 'business') || (type === 'person' && 'person');
  const result = await pepRequest.get(`blacklistNew/${entityType}/${entityId}?getAll=true`);
  return result;
}

export const confirmEntity = async (type, entityId, token, additional) => {
  if (!pepRequest) return;
  const entityType = (type === 'company' && 'business') || (type === 'person' && 'person');
  const result = await pepRequest.get(`confirmation/${entityType}/${entityId}?token=${token}${additional ? `&additional=${additional}` : ''}`);
  return result;
}


export const restoreEntity = async (type, entityId, token) => {
  if (!pepRequest) return;
  const result = await pepRequest.get(`restoreConfirmation/${type}/${entityId}?token=${token}`);
  return result;
};


export const postCombineProfile = async (entityId, data) => {
  if (!pepRequest) return;
  const result = await pepRequest.post(`analyst/${entityId}`, data);
  return result;
}


export const searchPepByName = async (name, isMultiple) => {
  if (!pepRequest) return;
  const result = await pepRequest.post(`search`, { multiple: isMultiple, name });
  return result;
}

const logserverRequest = new Request('logserver');

export const getLogs = async (env = 'production') => {
  if (!logserverRequest) return;
  const result = await logserverRequest.get(`logs/data/${env}`);
  return result;
}

export const runImporter = async (env = 'production') => {
  if (!logserverRequest) return;
  const result = await logserverRequest.get(`i2/import?site=${env}&key=REALLYN*OTWORKING@9311!`);
  return result;
}

export const checkEntityIdsForCapiqId = async body => {
  const request = new Request('entities');
  const result = await request.post('deduplication/entities', body);
  return result;
}

export const flagRelationship = async body => {
  const request = new Request('entities');
  const result = await request.post('flag-relationship', body);
  return result;
}
 
export const runImporterV2 = async body => {
  const request = new Request('importer');
  const result = await request.post('importer-trigger', body);
  return result;
}

/**
 * Create liteEDD report
 *
 * @param {Object} body Input object
 * @param {Object} [body.articlesNotAdverse] non-adverse articles
 * @param {Object} [body.articlesToIgnore] articles to be ignored
 * @param {Object} [body.currentSearch] search terms like names, languages etc
 * @param {Object} [body.dispatchableArticles] articles excluding adverse articles and articles to be ignored
 * @param {Object} [body.displayableArticles] all articles
 * @param {Object} [body.results] results sorted by category
 * @param {Object} [body.stats] search results statistics
 * @returns {Object} Response object of created report
 */
export const createPreviewReport = async body => {
  const request = new Request('reports');
  const result = await request.post('create-preview-report/v1', body);
  return result;
}

/**
 * Get liteEDD report by identification number
 *
 * @param {String} reportId input string specifying reportId
 * @returns {Object} Response object of fetched report
 */
export const getPreviewReport = async reportId => {
  const request = new Request('reports');
  const result = await request.get(`liteedd-report/${reportId}`);
  return result;
}

/**
 * Get liteEDD report by entity
 *
 * @param {String} entityId input string specifying reportId
 * @returns {Object} Response object of fetched report
 */
export const getPreviewReportByEntityId = async entityId => {
  const request = new Request('reports');
  const result = await request.get(`liteedd-reports/entities/${entityId}`);
  return result;
}

/**
 * Get liteEDD reports created by logged-in user
 *
 * @returns {Array} Response object of fetched reports
 */
export const getPreviewReports = async () => {
  const request = new Request('reports');
  const result = await request.get(`liteedd-reports`);
  return result;
}

/**
 * Create liteEDD report
 *
 * @param {Object} body Input object
 * @param {String} [body.basicInformation] basic information about entity
 * @param {String} [body.blacklist] blacklist information about entity
 * @param {String} [body.politicalExposure] PEP information about entity
 * @param {Boolean} [body.isCompany] indicates if entity is company or individual
 * @param {String} [body.reportId] identification number of report
 * @param {Object} [body.contentPages] Page numbers for content
 * @returns {Object} Response object of created report
 */
export const updatePreviewReport = async body => {
  const request = new Request('reports');
  const result = await request.post('update-preview-report/v1', body);
  return result;
}

export const getEntityBasicInfo = async entityId => {
  const request = new Request('entities');
  const result = await request.get(`entity-basic-info/${entityId}`);
  return result;
}


export const getImporterLogs = async env => {
  const request = new Request('importer');
  const result = await request.get(`logs/${env}`);
  return result;
}

export const getRiskFactors = async category => {
  const request = new Request('reports');
  const result = await request.get(`riskfactors/${category}`);
  return result;
}

export const addRiskFactor = async body => {
  const request = new Request('reports');
  const result = await request.post('riskfactor', body);
  return result;
}

export const removeRiskFactor = async id => {
  const request = new Request('reports');
  const result = await request.delete(`riskfactor/${id}`);
  return result;
}

export const retrieveEntity = async entityId => {
  const request = new Request('entities');
  const result = await request.get(`retrieve-entity/${entityId}`);
  return result;
}

export const retrieveEntityRels = async entityId => {
  const request = new Request('entities');
  const result = await request.get(`retrieve-entity-rels/${entityId}`);
  return result;
}

export const uploadDocument = async body => {
  const request = new Request('entities');
  const result = await request.post('dtrm-files', body);
  return result;
}

export const addNewRelationships = async (projectId, body) => {
  const request = new Request('entities');
  const result = await request.post('projs/' + projectId + '/relationships', body);
  return result;
}

export const updateKeystoneEntity = async body => {
  const request = new Request('entities');
  const result = await request.post('update-keystone-entity', body);
  return result;
}

export const getEntityFromKeystone = async ({ entityType, entityId }) => {
  const request = new Request('entities');
  const result = await request.get(`keystone/${entityType}/${entityId}`);
  return result;
}