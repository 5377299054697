import React from 'react';
import styled from 'styled-components';
import { Header, Grid, Segment, Table, Form, Button, Divider, Label, Message, Loader, Dimmer, Icon } from 'semantic-ui-react';
import getEntityModel from '../model/entity/getEntityModel';

import Text from './ingestion/fields/Text';
import { findDataramaId, validateEntity } from '../utils/api';
import FIELDS from '../model/entity/entityName';
import * as tableUtils from '../utils/tableUtils';
import EntityDetails from './EntityDetails';
import { getDisplayType } from '../utils/conversion/entityType';

const TableRow = styled(Table.Row)`
  cursor: pointer;
`;

const Wrapper = styled.div`
  margin-top: 20px;
`;
const Scrollable = styled.div`
  overflow: auto;
  margin-bottom: 10px;
`;

const NO_DATA = {
  dataramaId: 'Cannot find Datarama ID %s. Try different entity type or different ID',
  name: 'Cannot find Entity. Try another name'
}

class FindEntity extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      findByIdResults: null,
      errors: {},
      noData: null,
      searchFields: {},
      loading: {},
      fieldsRestriction: {},
      findByNameResults: null,
      updatedEntity: null,
    };
    this.input = {};
    this.updateInputValue = this.updateInputValue.bind(this);
    this.viewNameResultOnly = () => {
      this.setState(() => ({
        findByIdResults: null,
      }))
    };
    this.findEntityByDataramaId = this.findEntityByDataramaId.bind(this);
    this.handleDismissMessage = () => {
      this.setState(() => ({
        noData: null,
      }))
    }
    this.selectEntityId = this.selectEntityId.bind(this);

    this.clearValidate = (e, { value }) => {
      let clearNameSearchResults = true;
      if (value && value === 'result') {
        clearNameSearchResults = false;
      }
      this.setState(prevState => ({
        validating: null,
        isValidating: false,
        updatedEntity: null,
        completed: {},
        activeStep: 'validate',
        findByIdResults: null,
        findByNameResults: clearNameSearchResults ? null : prevState.findByNameResults,
      }))
    }
    this.findEntity = this.findEntity.bind(this);
    this.setFields = this.setFields.bind(this);
  }

  componentDidMount() {
    this.setFields();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.type !== this.props.match.params.type) {
      this.setFields();
    }
    if (prevProps.displayResult && !this.props.displayResult) {
      this.setState(() => ({
        findByIdResults: null,
        findByNameResults: null,
      }))
    }
  }

  selectEntityId(entityMatch) {
    const type = this.props.type || this.props.match.params.type;

    this.setState(() => ({
      loading: {
        find: true,
      }
    }));

    this.findEntityByDataramaId(type, entityMatch.entityId, true);
  }

  setFields() {
    const type = this.props.type || this.props.match.params.type;
    this.baseEntity = getEntityModel(type);

    this.setState(() => ({
      loading: {},
      findByNameResults: null,
      findByIdResults: null
    }))
  }

  async findEntity (e, { name }) {
    const type = this.props.type || this.props.match.params.type;
    if (!type) return;
    this.setState(() => ({
      noData: null,
      loading: {
        find: true,
      }
    }))

    if (name === 'name') {
      let error = false;
      const body = Object.keys(FIELDS[type]).reduce((a, field) => {
        if (this.input[field]) {
          if (this.input[field].length < 3) {
            error = true;
          }
          a[field] = this.input[field];
        }
        return a;
      }, {});
      if (error) {
        return this.setState(() => ({
          loading: {}
        }))
      }
      const result = await validateEntity(type, Object.assign({}, body, {
        scope: ['dtrm']
      }));
      this.setState(() => ({
        findByNameResults: {
          dtrm: result.dtrm.map(r => this.baseEntity.transformData(r)),
        },
        findByIdResults: null,
        loading: {},
      }))
    } else if (name === 'dataramaId') {
      const id = this.input[name];
      if (!id) return;
      this.findEntityByDataramaId(type, id);
    }
  }

  async findEntityByDataramaId(type, id, keepNameResult) {
    const result = await findDataramaId(type, id);
    this.setState(prevState => {
      let newState = {};

      if (result && this.baseEntity) {
        window.scrollTo(0, 0);
        const output = this.baseEntity.transformData(result);
        this.input = Object.assign({}, output);

        if (this.props.selectEntityAction) {
          this.props.selectEntityAction(output);
          newState = {
            loading: {},
            findByNameResults: null,
            findByIdResults: null
          }
        } else {
          newState = {
            findByIdResults: Object.assign({}, output),
            updatedEntity: null,
            findByNameResults: keepNameResult ? prevState.findByNameResults : null,
            activeStep: 'inputForm',
            completed: Object.assign({}, prevState.completed, {
              validate: true,
            }),
            loading: {},
          }
        }
      } else {
        newState = {
          noData: NO_DATA.dataramaId.replace(/%s/g, id),
          loading: {},
          findByNameResults: null,
          findByIdResults: null
        };
      }

      return newState;
    })

  }

  updateInputValue(e, { name, value }) {
    this.input[name] = value;
    this.setState(prevState => ({
      searchFields: Object.assign({}, prevState, {
        [name]: value,
      })
    }))
  }

  render() {
    const { displayResult, upload, searchById, searchByName } = this.props;
    // const { match: { params }, displayResult, upload } = this.props;
    // const { type } = params;
    const type = this.props.type || this.props.match.params.type;

    const {
        loading,
        findByNameResults,
        findByIdResults,
        noData,
      } = this.state;

    const columns = this.baseEntity && this.baseEntity.getColumns();

    if (!type || displayResult) return null;
    const isGridDisplay = searchByName && searchById;
    const findByIdDisplay = (
      <React.Fragment>
        <Header icon>
          Find<Label color='black'>{getDisplayType(type)}</Label> by Datarama ID
        </Header>
        <Form onSubmit={this.findEntity} name='dataramaId'>
          <Text
            field={{
              Header: 'Datarama ID',
              id: 'dataramaId',
              required: true,
            }}
            entityType={type}
            handleFieldChange={this.updateInputValue}
            defaultValue={''}
            type="number"
          />
          <Button type="submit" loading={loading.find} disabled={loading.find}>Find</Button>
        </Form>

      </React.Fragment>
    )

    const displayType = getDisplayType(type);

    const findByNameDisplay = (
      <React.Fragment>
        <Header icon>
          Find by Name
        </Header>
        <Form onSubmit={this.findEntity} name='name'>
          {FIELDS[type] && Object.keys(FIELDS[type]).map(field => (
            <Text
              key={field}
              field={{
                Header: FIELDS[type][field].label,
                id: field,
                required: FIELDS[type][field].required,
                minLength: 2,
              }}
              entityType={type}
              handleFieldChange={this.updateInputValue}
              defaultValue={''}
            />
          ))}
          <Button type="submit" loading={loading.find} disabled={loading.find}>Find</Button>
        </Form>
      </React.Fragment>
    )
    const showSearchOption = (searchById && findByIdDisplay) || (searchByName && findByNameDisplay);

    return (
      <Wrapper>
      <Scrollable>
        {loading.find && (
          <Dimmer active>
            <Loader content='Loading' />
          </Dimmer>
        )}
        <div>
          <Segment placeholder={isGridDisplay}>
            {isGridDisplay ? (
              <Grid columns={2} stackable textAlign='center'>
                <Grid.Row  stretched verticalAlign='middle'>
                  <Grid.Column>
                    {findByIdDisplay}
                  </Grid.Column>
                  <Grid.Column>
                    {findByNameDisplay}
                  </Grid.Column>
                  <Divider vertical>Or</Divider>
                </Grid.Row>
              </Grid>
            ) : showSearchOption}
          </Segment>
          {findByNameResults && !findByIdResults && findByNameResults.dtrm && (
            <React.Fragment>
            <Divider horizontal>
              <Header as='h3'>
                Find by name results ({findByNameResults.dtrm ? findByNameResults.dtrm.length : 0})
                <Header.Subheader>Select Datarama ID by clicking respective row</Header.Subheader>
              </Header>
            </Divider>
            <Table celled striped selectable className='table-selectable'>
              <Table.Header>
                <Table.Row>
                  {tableUtils.dtrmColumns[type].map(col => (
                    <Table.HeaderCell
                      key={col.label}
                      rowSpan={col.items ? 1 : 2}
                      colSpan={col.items ? col.items.length : 1}
                    >{col.label}</Table.HeaderCell>
                  ))}
                </Table.Row>
                <Table.Row>
                  {tableUtils.dtrmColumns[type].map(col => col.items ? col.items.map(item => (
                    <Table.HeaderCell key={item.value}>{item.label}</Table.HeaderCell>
                  )) : null)}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {findByNameResults.dtrm.map(match => (
                  <TableRow
                    key={match.entityId}
                    onClick={() => {
                      this.selectEntityId(match)
                    }}>
                    {tableUtils.dtrmColumns[type].map(col => col.items ? col.items.map(item => (
                      <Table.Cell key={item.value}>
                        {match[item.value]}
                      </Table.Cell>
                    )) : (
                      <Table.Cell key={col.value}>
                        {match[col.value]}
                      </Table.Cell>
                    ))}
                  </TableRow>
                ))}
              </Table.Body>
              {findByNameResults.dtrm.length === 0 && (
                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell colSpan='10'>
                        No results found
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
            </React.Fragment>
          )}
          {findByIdResults && (
            <React.Fragment>
            {findByNameResults ? (
              <React.Fragment>
                <Button onClick={this.viewNameResultOnly} basic compact icon labelPosition='left'>
                  <Icon name='left arrow' />
                  Back to find by name results
                </Button>
                <br/><br/>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Divider horizontal>
                  <Header as='h3'>
                    Find by ID
                  </Header>
                </Divider>
                {this.props.selectEntityAction && (
                  <Button
                    fluid
                    color='green'
                    onClick={() => { this.props.selectEntityAction(findByIdResults) }}>
                    Select {displayType}
                  </Button>
                )}
                <Message
                  header={`${displayType} Record found with ${findByIdResults.entityId}`}
                  positive
                />
              </React.Fragment>
            )}

            {upload && (
              <Button
                onClick={() => { this.props.selectEntityId(type, findByIdResults.entityId, {...findByIdResults, type }) }}
                fluid
                color='green'
                size='large'
              >
                <Icon name='cloud upload' /> Upload for this {displayType}
              </Button>
            )}
              {this.props.actionComponent && this.props.actionComponent({...findByIdResults, type })}
              <EntityDetails columns={columns} results={findByIdResults} type={type} />
            </React.Fragment>
          )}
      </div>
        {noData && (
          <Message
            error
            onDismiss={this.handleDismissMessage}
          >{noData}</Message>
        )}

      </Scrollable>
      </Wrapper>
    )
  }
}

export default FindEntity;
