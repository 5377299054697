import React, { useState, useEffect } from 'react';
import { LANGUAGES, RISK_FACTORS, RISK_FACTORS_MAPPING } from '../../constants';
import Loading from '../Loading';
import { Icon, Label, Menu, Segment, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { getRiskFactors, addRiskFactor, removeRiskFactor } from '../../utils/api';

const MenuWrapper = styled(Menu)`
display: flex;
flex-direction: row;
flex-wrap: wrap;
`;

const DEFAULT_CATEGORY = 'sanctions_and_blacklists';

export function RiskFactors() {
  const [buckets, setBuckets] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({name: '', language: ''});
  const [isFormProcessing, setIsFormProcessing] = useState(false);

  useEffect(() => {
    async function fetching() {
      await loadRiskFactors(DEFAULT_CATEGORY);
      return () => {
        new AbortController().abort();
      };
    }
    fetching();
  }, []);

  const loadRiskFactors = async category => {
    const results = await getRiskFactors(category);
    setBuckets(results);
    setActiveItem(category);
  }

  const handleConceptChange = async (event, { value }) => {
    event.preventDefault();
    setActiveItem(null);
    const results = await getRiskFactors(value);
    setBuckets(results);
    setActiveItem(value);
  }

  const handleSubmit = async event => {
    event.preventDefault();
    setIsFormProcessing(true);
    setFormError(null);
    if (!form.language || !form.name) {
      setFormError(prevState => {
        if (!form.language && !form.name) {
          return { ...prevState, language: true, name: true }
        }
        if (!form.name) {
          return { ...prevState, name: true }
        }
        if (!form.language) {
          return { ...prevState, language: true }
        }
      });  
      return;
    }

    const requestBody = JSON.stringify({
      name: form.name,
      language: form.language,
      company: RISK_FACTORS_MAPPING[activeItem].isCompany ? activeItem : null,
      individual: RISK_FACTORS_MAPPING[activeItem].isIndividual ? activeItem : null
    })

    await addRiskFactor(requestBody);
    await loadRiskFactors(activeItem);
    setForm({name: '', language: ''});
    setIsFormProcessing(false);
  }

  const handleOnChange = async (event, { id, value }) => {
    event.preventDefault();
    setForm(prevState => {
      if (id === 'name') {
        return { ...prevState, name: value }
      }
      if (id === 'language') {
        return { ...prevState, language: value }
      }
    })    
  }

  const handleOnRemove = async (event, id, lang) => {
    event.preventDefault();
    const result = buckets.map(bucket => {
      if (Object.keys(bucket)[0] === lang) {
        return {
          [lang]: Object.values(bucket)[0].filter(obj => obj._id !== id)
        }
      }
      return bucket;
    })
    setBuckets(result);
    await removeRiskFactor(id);
  }

  return <>
    <div>
      <MenuWrapper>
        {RISK_FACTORS.map(concept => (
          <Menu.Item
            key={concept.code}
            value={concept.code}
            name={concept.label}
            active={activeItem === concept.code}
            onClick={handleConceptChange} />
        ))}
      </MenuWrapper>
      { activeItem &&
        <Segment attached='bottom'>
          <Form size='mini' onSubmit={handleSubmit}>
            <Form.Group widths='equal'>
              <Form.Input
                error={!!formError && !!formError.name}
                id='name'
                disabled={isFormProcessing}
                value={form.name || ''}
                onChange={handleOnChange}
                fluid label='Risk Indicator'
                placeholder='Risk Indicator' />
              <Form.Select
                error={!!formError && !!formError.language}
                fluid
                id='language'
                disabled={isFormProcessing}
                value={form.language || ''}
                onChange={handleOnChange}
                label='Language'
                options={Object.keys(LANGUAGES).map(lang => ({ key: lang, value: lang, text: LANGUAGES[lang] }))}
                placeholder='Language'
              />
            </Form.Group>
            <Form.Button size='mini' loading={isFormProcessing} disabled={isFormProcessing}>Add Risk Indicator</Form.Button>
          </Form>
          <br /><br />

          {buckets.map(buck => {
            let key = Object.keys(buck)[0];
            let value = buck[key];
            return (
              <div key={key}>
                <h3>
                  {console.log('aaaaa', (Object.keys(LANGUAGES).map(lang => ({ key: lang, value: lang, text: LANGUAGES[lang] })).filter(c => c.key === key)))}
                  { ((Object.keys(LANGUAGES).map(lang => ({ key: lang, value: lang, text: LANGUAGES[lang] })).filter(c => c.key === key))[0] || {}).text } ({value.length} terms)
                </h3>
                <br />
                <Label.Group>
                { value.map((k) => (
                  <Label key={k._id}>
                    {k.name.replace(/_/g, ' ')}
                    <Icon
                      name='delete'
                      onClick={ evt => handleOnRemove(evt, k._id, Object.keys(LANGUAGES).map(lang => ({ key: lang, value: lang, text: LANGUAGES[lang] })).filter(c => c.key === key)[0].key) } />
                  </Label>)) }
                </Label.Group>
                <br /><br />
              </div>
            )})}
        </Segment>            
      }
      { !activeItem && <Loading active={true} /> }
    </div>
  </>;
}
