import React from 'react';
import { Button, Dimmer, Accordion, Icon } from 'semantic-ui-react';
import ReactTable from 'react-table';
import styled from 'styled-components';
import ViewEntityRow from './ViewEntityRow';
import LastUpdated from '../LastUpdated';
import { ENTITY_BY_TYPE } from '../../utils/ref/entityType';

const { listIngestionRecordsByProject } = require('../../utils/api');

const TopWrapper = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
`;

class EntityRecords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      loading: false,
    };
    this.handleClick = (e, titleProps) => {
      const { index } = titleProps
      const { activeIndex } = this.state
      const newIndex = activeIndex === index ? -1 : index

      this.setState({ activeIndex: newIndex })
    }

    this.fetchRecords = this.fetchRecords.bind(this);
    this.cellOnClick = (state, rowInfo, column, instance) => {
      return {
        onClick: (e, handleOriginal) => {
          this.props.toggleEntryPopup(null, rowInfo && rowInfo.original);
        }
      }
    }

    this.updateEntry = (e, { value }) => {
      if (!value) return;
      const { type, entityId } = value || {};
      if (!type || !entityId) return;
      const { projectId } = this.props.match.params;
      this.props.history.push({
        pathname: `/project/${projectId}/actions/${type}/update`,
        state: {
          entityId,
          type,
        }
      })

    }
  }

  componentDidMount() {
    if (!this.props.loaded) {
      this.fetchRecords();
    }
  }

  async fetchRecords() {
    this.setState(() => ({
      loading: true,
    }))
    const { projectId } = this.props.project || {};
    if (!projectId || !this.props.permitted) {
      this.props.updateEntityRecords(null);
      return;
    };
    const records = await listIngestionRecordsByProject(projectId, 'entity');

    if (records) {
      const updatedRecords = records.reduce((a, record) => {
        if (record.typeid)  {
          a[record.typeid] = (a[record.typeid] || []).concat(record);
        }
        return a;
      }, {});
      this.props.updateEntityRecords(updatedRecords);
    }

    this.setState(() => ({
      loading: false,
    }))

  }

  render() {
    const {
      loaded,
      entityRecords,
      entryPopup,
      toggleEntryPopup,
      lastFetched
    } = this.props;
    const { activeIndex, loading } = this.state;
    if (!entityRecords) return null;


    const accordionList = [
      ENTITY_BY_TYPE.company,
      ENTITY_BY_TYPE.person,
      ENTITY_BY_TYPE.family,
      ENTITY_BY_TYPE.governmentAgency,
      ENTITY_BY_TYPE.otherOrganisation,
    ]


    return (
      <div>
        <TopWrapper>
          <Button size='small' compact onClick={this.fetchRecords} icon basic>
            <Icon name='refresh'/>
          </Button>
          <LastUpdated
            loading={!loaded}
            date={lastFetched}
          />
        </TopWrapper>
        {entityRecords && (
          <Dimmer.Dimmable as={Accordion} blurring dimmed={loading} fluid styled>
          {accordionList.map(type => (
            <React.Fragment key={type.key}>
              <Accordion.Title active={activeIndex === type.index} index={type.index} onClick={this.handleClick}>
                <Icon name='dropdown' />
                {type.header} ({(entityRecords[type.code] && entityRecords[type.code].length) || 0})
              </Accordion.Title>
              <Accordion.Content active={activeIndex === type.index}>
                {entityRecords[type.code] && (
                  <ReactTable
                    data={entityRecords[type.code]}
                    columns={type.columns}
                    defaultPageSize={10}
                    filterable
                    className='-striped -highlight'
                    style={{ cursor: 'pointer' }}
                    getTdProps={this.cellOnClick}
                    defaultFilterMethod={(filter, row) =>
                      String(row[filter.id]) === filter.value}
                  />
                )}
              </Accordion.Content>
            </React.Fragment>
          ))}
          </Dimmer.Dimmable>
        )}
        {entryPopup && (
          <ViewEntityRow
            close={toggleEntryPopup}
            entry={entryPopup}
            updateEntry={this.updateEntry}
            allowUpdates={this.props.project && this.props.project.allowUpdates}
          />
        )}

      </div>
    )
  }
}


export default EntityRecords;
