/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-mixed-operators */
import React from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

import Radio from './Radio';
import Dropdown from './Dropdown';
import { recordCount, nameDisplay } from './labelling';

const TabBtn = styled(Button)`
  margin-bottom: 5px !important;
  ${({ selected }) => selected && `
    background: #cccccc !important;
  `}
  ${({ highlight }) => highlight && `
    font-size: 18px;
    background: #ac72eb !important;
    position: relative;
    &::before {
      content: 'Confirmed';
      position: absolute;
      right: 0;
      font-size: 12px;
      top: 0;
      color: #fff;
    }
  `}
`;

const label = {
  'complyAdvantage': "Comply Advantage",
  'c6': "C6",
}

const DetailWrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
`;
const Action = styled.div`
`;

const DetailHeader = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  > div:last-of-type {
    padding: 10px;
  }
`;

const SubHeader = styled.div`
  h3 {
    margin: 0;
  }
  padding: 10px 0;
`;

const ButtonWrapper = styled.div`
  position: relative;
`;

const Address = styled.div`
  background: #ececec;
  margin: 5px 0;
  padding: 5px;
  line-height: 25px;
`;

const ActionBar = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const HyperLink = styled.a`
  overflow-wrap: break-word;
`;

const NumberLabel = styled.div`
  text-align: center;
  span {
    font-size: 18px;
    padding: 5px;
    font-weight: 700;
    background: #fff;
  }
`;
const PaddedDiv = styled.div`
  padding: 5px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ grid }) => grid || '30% 70%'};
  margin-bottom: 10px;
  ${({ header }) => header && `font-weight: 700;`}
  ${({ inner }) => !inner && `
    padding: 10px;
    border-bottom: 1px solid #ececec;
  `}
  padding: 5px;
  ${({ highlight }) => highlight && `
    background: #ececec;
  `}
`;
const Category = styled.div`
  display: inline-block;
  background: #ff5247;
  font-size: 13px;
  padding: 2px 5px;
  margin-right: 10px;
  color: #fff;
`;

const Details = styled.div`
  padding: 10px;
`;

const BLACKLIST_FIELDS = {
  'c6': [
    { key: 'isSanctionsCurrent', label: 'Saction Current' },
    { key: 'isSanctionsPrevious', label: 'Saction Previous' },
    { key: 'isLawEnforcement', label: 'Law Enforcement' },
    { key: 'isFinancialregulator', label: 'Financial Regulator' },
    { key: 'isDisqualifiedDirector', label: 'Disqualified Director' },
    { key: 'isInsolvent', label: 'Insolvent' },
  ]
};

class ListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidateView: null
    };
    this.switchCandidate = this.switchCandidate.bind(this);
  }

  switchCandidate(e, { value }) {
    this.setState({
      candidateView: value,
    })
  }

  render() {
    const { candidateView } = this.state;
    const {
      dataType,
      data,
      autoConfirmed,
      previouslyConfirmed,
      additional,
      onEditCombine,
      previousData,
      combinedProfile,
      currentResult,
      addToCombineProfile,
      updateCombineProfilePepTier,
      confirmPrompt,
      entityType,
      nonSelective,
    } = this.props;

    const selectedCandidate = data && data.filter(e => e.id === candidateView)[0];
    const displayTypeLabel = label[dataType];

    return (
      <div>
      {data &&
        <div>
          <DetailHeader>
            <SubHeader>
              {!nonSelective && (
                <h3>{previouslyConfirmed && ('Confirmed ')}{displayTypeLabel} Candidates</h3>
              )}
              <div>Total: {recordCount(data.length)} found</div>
            </SubHeader>
          </DetailHeader>
          <DetailWrapper>
            <Action>
              {data.map((candidate, idx) => (
                <ButtonWrapper key={candidate.id}>
                  <TabBtn
                    key={candidate.id}
                    onClick={this.switchCandidate}
                    value={candidate.id}
                    fluid
                    selected={
                      selectedCandidate && candidate.id === selectedCandidate.id
                    }
                    highlight={(currentResult && currentResult.id === candidate.id) ? "true" : undefined}
                  >
                  #{idx + 1} {nameDisplay(entityType, candidate)} {candidate.isPEP && '🚩'}
                  </TabBtn>
                </ButtonWrapper>
              ))}
              {previousData && (
                <div>
                  <br/><br/>
                  Click restore as default on top right to restore the previous records of {recordCount(previousData.length)} found
                </div>
              )}
            </Action>
            {selectedCandidate ?
              <Details>
                {nonSelective || ((!previouslyConfirmed)
                  && !onEditCombine && !autoConfirmed && (currentResult && currentResult.id !== selectedCandidate.id)) &&
                  (<ActionBar>
                    <Button positive onClick={() => confirmPrompt('match', { selectedCandidate })}>Confirm as a Match</Button>
                  </ActionBar>)
                }
                <Grid>
                  <div>Name</div>
                  <div>{nameDisplay(entityType, selectedCandidate)}</div>
                </Grid>
                <Grid>
                  <div>Aliases</div>
                  <div>{selectedCandidate.aliases && selectedCandidate.aliases.map((alias, idx) => (
                    <div key={idx}>{alias.businessName || alias.name}</div>
                  ))}</div>
                </Grid>
                <Grid>
                  <div>{onEditCombine && (<Radio
                    customOnChange={(e) => addToCombineProfile(
                      'isPEP',
                      selectedCandidate.pepLevel,
                      e.target.checked,
                      null,
                      'isPEP'
                    )}
                    item={selectedCandidate}
                    value={selectedCandidate.isPEP}
                    field='isPEP'
                    checked={combinedProfile.isPEP === selectedCandidate.isPEP}
                  />)}PEP</div>
                  <div>{selectedCandidate.isPEP ? '🚩' : 'No'} (PEP Tier: {selectedCandidate.pepLevel !== undefined && `${selectedCandidate.pepLevel}`} {onEditCombine && (combinedProfile.isPEP === selectedCandidate.isPEP) &&
                    (<input type="number" min={1} max={4} ref={(c) => { this.tiering = c; }} onChange={updateCombineProfilePepTier} defaultValue={selectedCandidate.pepLevel || 0} />)})
                  </div>
                </Grid>
                {BLACKLIST_FIELDS[dataType] && BLACKLIST_FIELDS[dataType].map(blacklistField => (
                  <Grid key={blacklistField.key}>
                    <div>{onEditCombine && (<Radio
                      onChange={addToCombineProfile}
                      item={selectedCandidate}
                      value={selectedCandidate[blacklistField.key]}
                      field='isLawEnforcement'
                      checked={combinedProfile[blacklistField.key] === selectedCandidate[blacklistField.key]}
                    />)}{blacklistField.label}</div>
                    <div>{selectedCandidate[blacklistField.key] ? '🚩' : 'No'}</div>
                  </Grid>
                ))}
                {entityType === 'person' && (
                  <>
                    <Grid>
                      <div>Nationality</div>
                      <div>{selectedCandidate.nationality && (JSON.stringify(selectedCandidate.nationality))}</div>
                    </Grid>
                    <Grid>
                      <div>Image</div>
                      <div>{selectedCandidate.imgURL &&
                        <img src={selectedCandidate.imgURL} height="100px" />
                      }</div>
                    </Grid>
                    <Grid>
                      <div>Gender</div>
                      <div>{selectedCandidate.gender}</div>
                    </Grid>
                    <Grid>
                      <div>Email</div>
                      <div>{selectedCandidate.email}</div>
                    </Grid>
                    <Grid>
                      <div>Is Deceased</div>
                      <div>{selectedCandidate.isDeceased ? 'Yes' : 'No'}</div>
                    </Grid>
                    <Grid>
                      <div>Year of Birth</div>
                      <div>{selectedCandidate.yearOfBirth}</div>
                    </Grid>
                    <Grid>
                      <div>Year of Death</div>
                      <div>{selectedCandidate.yearOfDeath}</div>
                    </Grid>
                  </>
                )}
                {entityType === 'company' && (
                  <>
                    <Grid>
                      <div>Website</div>
                      <div>{selectedCandidate.website && (<a href={selectedCandidate.website} target='_blank' rel='noreferrer noopener'>{selectedCandidate.website}</a>)}</div>
                    </Grid>

                  </>
                )}
                <Grid>
                  <div>Telephone Number</div>
                  <div>{selectedCandidate.telephoneNumber}</div>
                </Grid>
                <Grid>
                  <div>Fax Number</div>
                  <div>{selectedCandidate.faxNumber}</div>
                </Grid>
                <Grid>
                  <div>Addresses</div>
                  <div>
                  {selectedCandidate.addresses && selectedCandidate.addresses.map((add, idx) => (
                    <Grid inner key={idx} grid="10% 90%" highlight>
                      <NumberLabel><span>#{idx + 1}</span></NumberLabel>
                      <Address>
                        {add.address1 && (<span>{add.address1}<br/></span>)}
                        {add.address2 && (<span>{add.address2}<br/></span>)}
                        {add.address3 && (<span>{add.address3}<br/></span>)}
                        {add.address4 && (<span>{add.address4}<br/></span>)}
                        {add.city && (<span>City: {add.city}<br/></span>)}
                        {add.county && (<span>County: {add.county}<br/></span>)}
                        {add.postcode && (<span>Postcode: {add.postcode}<br/></span>)}
                        {add.country && (<b>{add.country.name}</b>)}
                      </Address>
                    </Grid>
                  ))}
                  </div>
                </Grid>
                <Grid>
                  <div>Political Positions</div>
                  <div>
                  {selectedCandidate.politicalPositions && selectedCandidate.politicalPositions.map((pp, idx) => (
                    <Grid inner key={idx} grid="10% 90%" highlight>
                      <NumberLabel>{onEditCombine && (<Radio
                        onChange={addToCombineProfile}
                        item={selectedCandidate}
                        value={pp}
                        field="politicalPositions"
                        type="array"
                        uniqueField="description"
                        checked={combinedProfile.politicalPositions.filter(e => e.description === pp.description).length > 0}
                      />)}<span>#{idx + 1}</span></NumberLabel>
                      <PaddedDiv>
                        <Grid inner grid="20% 80%">
                          <div>Description</div>
                          <div>{pp.description}</div>
                        </Grid>
                        <Grid inner grid="20% 80%">
                          <div>Country</div>
                          <div>{pp.country && pp.country.name}</div>
                        </Grid>
                        <Grid inner grid="20% 80%">
                          <div>From</div>
                          <div>{pp.from}</div>
                        </Grid>
                        <Grid inner grid="20% 80%">
                          <div>To</div>
                          <div>{pp.to}</div>
                        </Grid>
                      </PaddedDiv>
                    </Grid>
                  ))}
                  </div>
                </Grid>
                <Grid>
                  <div>Related Articles</div>
                  <div>
                  {selectedCandidate.articles && selectedCandidate.articles.map((art, idx) => (
                    <Grid inner key={idx} grid="10% 90%" highlight>
                      <NumberLabel>{onEditCombine && (<Radio
                        onChange={addToCombineProfile}
                        item={selectedCandidate}
                        value={art}
                        field="articles"
                        type="array"
                        uniqueField="c6URL"
                        checked={combinedProfile.articles.filter(e => e.c6URL === art.c6URL).length > 0}
                      />)}<span>#{idx + 1}</span></NumberLabel>
                      <PaddedDiv>
                        {art.title && (
                          <Grid inner grid="20% 80%">
                            <div>Title</div>
                            <div>{art.title}</div>
                          </Grid>
                        )}
                        <Grid inner grid="20% 80%">
                          <div>URL</div>
                          <HyperLink href={art.originalURL || art.url} target='_blank' rel='noreferrer noopener'>{art.originalURL || art.url}</HyperLink>
                        </Grid>
                        <Grid inner grid="20% 80%">
                          <div>Date Collected</div>
                          <div>{art.dateCollected}</div>
                        </Grid>
                        {art.snippet && (
                          <Grid inner grid="20% 80%">
                            <div>Snippets</div>
                            <div>{art.snippet}</div>
                          </Grid>
                        )}
                        {art.c6URL && (
                          <Grid inner grid="20% 80%">
                            <div>Backup URL</div>
                            <HyperLink href={art.c6URL} target='_blank' rel='noreferrer noopener'>URL</HyperLink>
                          </Grid>
                        )}
                        {art.categories && (
                          <Grid inner grid="20% 80%">
                            <div>Category</div>
                            <div>{art.categories.map((cat => (<Category key={cat.name}>{cat.name}</Category>)))}</div>
                          </Grid>
                        )}
                      </PaddedDiv>
                    </Grid>
                  ))}
                  </div>
                </Grid>
                <Grid>
                  <div>Linked Businesses</div>
                  <div>
                    {selectedCandidate.linkedBusinesses && selectedCandidate.linkedBusinesses.map((business, idx) => (
                      <Grid inner key={idx} grid="10% 90%" highlight>
                        <NumberLabel>{onEditCombine && (<Radio
                          onChange={addToCombineProfile}
                          item={selectedCandidate}
                          value={business}
                          field="linkedBusinesses"
                          type="array"
                          uniqueField="businessId"
                          checked={combinedProfile.linkedBusinesses.filter(e => e.businessId === business.businessId).length > 0}
                        />)}<span>#{idx + 1}</span></NumberLabel>
                        <PaddedDiv>
                          <Grid inner>
                            <div>Name</div>
                            <div>{business.businessName}</div>
                          </Grid>
                          <Grid inner>
                            <div>Details</div>
                            <div>{business.linkDescription}</div>
                          </Grid>
                        </PaddedDiv>
                      </Grid>
                    ))}
                  </div>
                </Grid>
                <Grid>
                  <div>Linked Persons</div>
                  <div>
                    {selectedCandidate.linkedPersons && selectedCandidate.linkedPersons.map((person, idx) => (
                      <Grid inner key={idx} grid="10% 90%" highlight>
                        <NumberLabel>{onEditCombine && (<Radio
                          onChange={addToCombineProfile}
                          item={selectedCandidate}
                          value={person}
                          field="linkedPersons"
                          type="array"
                          uniqueField="personId"
                          checked={combinedProfile.linkedPersons.filter(e => e.personId === person.personId).length > 0}
                        />)}<span>#{idx + 1}</span></NumberLabel>
                        <PaddedDiv>
                          <Grid inner>
                            <div>Name</div>
                            <div>{person.name}</div>
                          </Grid>
                          <Grid inner>
                            <div>Association</div>
                            <div>{person.position}</div>
                          </Grid>
                          {onEditCombine && combinedProfile.linkedPersons.filter(e => e.personId === person.personId).length > 0 &&
                            <Grid inner>
                              <div>Datarama Match</div>
                              <div><Dropdown list={this.state.connections} onChange={this.matchDataramaID} value={person.personId}/></div>
                            </Grid>
                          }
                        </PaddedDiv>
                      </Grid>
                    ))}
                  </div>
                </Grid>
                <Grid>
                  <div>Notes from {displayTypeLabel}</div>
                  <div>{selectedCandidate.notes && selectedCandidate.notes.map((note, idx) => (
                    <div key={idx}>{note.text} [source: ${note.datasource && note.datasource.name}]</div>
                  ))}</div>
                </Grid>
                {selectedCandidate.raw && selectedCandidate.raw.fields && (
                  <div>
                    <h3>Other Fields</h3>
                    {selectedCandidate.raw.fields.map((field, fieldIndex) => (
                      <Grid key={fieldIndex}>
                        <div>{field.name}</div>
                        <div>
                          <div>{field.value}</div>
                          {field.source && (
                            <div>(source: {field.source})</div>
                          )}
                        </div>
                      </Grid>
                    ))}
                  </div>
                )}
              </Details>
              :
              <Details>
                {additional &&
                  <div>
                    <Grid>
                      <div>PEP</div>
                      <div>{additional.data.isPEP ? '🚩' : 'No'} (PEP Tier: {additional.data.pepLevel})</div>
                    </Grid>
                    {BLACKLIST_FIELDS[dataType] && BLACKLIST_FIELDS[dataType].map(blacklistField => (
                      <Grid key={blacklistField.key}>
                        <div>{blacklistField.label}</div>
                        <div>{additional.data[blacklistField.label] ? '🚩' : 'No'}</div>
                      </Grid>
                    ))}
                    <Grid>
                      <div>Political Positions</div>
                      <div>
                      {additional.data.politicalPositions.map((pp, idx) => (
                        <Grid inner key={idx} grid="10% 90%" highlight>
                          <NumberLabel><span>#{idx + 1}</span></NumberLabel>
                          <PaddedDiv>
                            <Grid inner grid="20% 80%">
                              <div>Description</div>
                              <div>{pp.description}</div>
                            </Grid>
                            <Grid inner grid="20% 80%">
                              <div>Country</div>
                              <div>{pp.country && pp.country.name}</div>
                            </Grid>
                            <Grid inner grid="20% 80%">
                              <div>From</div>
                              <div>{pp.from}</div>
                            </Grid>
                            <Grid inner grid="20% 80%">
                              <div>To</div>
                              <div>{pp.to}</div>
                            </Grid>
                          </PaddedDiv>
                        </Grid>
                      ))}
                      </div>
                    </Grid>
                    <Grid>
                      <div>Related Articles</div>
                      <div>
                      {additional.data.articles.map((art, idx) => (
                        <Grid inner key={idx} grid="10% 90%" highlight>
                          <NumberLabel><span>#{idx + 1}</span></NumberLabel>
                          <PaddedDiv>
                            <Grid inner grid="20% 80%">
                              <div>URL</div>
                              <HyperLink href={art.originalURL} target='_blank' rel='noreferrer noopener'>{art.originalURL}</HyperLink>
                            </Grid>
                            <Grid inner grid="20% 80%">
                              <div>Date Collected</div>
                              <div>{art.dateCollected}</div>
                            </Grid>
                            <Grid inner grid="20% 80%">
                              <div>Backup URL</div>
                              <HyperLink href={art.c6URL} target='_blank' rel='noreferrer noopener'>URL</HyperLink>
                            </Grid>
                            <Grid inner grid="20% 80%">
                              <div>Category</div>
                              <div>{art.categories.map((cat => (<Category key={cat.name}>{cat.name}</Category>)))}</div>
                            </Grid>
                          </PaddedDiv>
                        </Grid>
                      ))}
                      </div>
                    </Grid>
                    <Grid>
                      <div>Linked Persons</div>
                      <div>
                        {additional.data.linkedPersons.map((person, idx) => (
                          <Grid inner key={idx} grid="10% 90%" highlight>
                            <NumberLabel><span>#{idx + 1}</span></NumberLabel>
                            <PaddedDiv>
                              <Grid inner>
                                <div>Name</div>
                                <div>{person.name}</div>
                              </Grid>
                              <Grid inner>
                                <div>Association</div>
                                <div>{person.position}</div>
                              </Grid>
                              <Grid inner>
                                <div>Datarama Match</div>
                                <div>{person.DataramaID || 'N/A'}</div>
                              </Grid>
                            </PaddedDiv>
                          </Grid>
                        ))}
                      </div>
                    </Grid>
                    <Grid>
                      <div>Linked Businesses</div>
                      <div>
                        {additional.data.linkedBusinesses.map((business, idx) => (
                          <Grid inner key={idx} grid="10% 90%" highlight>
                            <NumberLabel><span>#{idx + 1}</span></NumberLabel>
                            <PaddedDiv>
                              <Grid inner>
                                <div>Name</div>
                                <div>{business.businessName}</div>
                              </Grid>
                              <Grid inner>
                                <div>Details</div>
                                <div>{business.linkDescription}</div>
                              </Grid>
                            </PaddedDiv>
                          </Grid>
                        ))}
                      </div>
                    </Grid>

                  </div>
                }
              </Details>
            }
          </DetailWrapper>
        </div>
      }
      </div>

    )
  }
}

export default ListView;
