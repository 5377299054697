import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { TOTAL_ARTICLES_COUNT } from '../../../constants';
import { updatePreviewReport } from '../../../utils/api';

export function AdditionalSummaryDetails({ setState, state }) {

  const handleOnSubmit = async () => {
    try {
      setState(prevState => (
        { ...prevState, isProcessing: true }
      ))
  
      await updatePreviewReport(JSON.stringify({
        reportId: state.reportId,
        [TOTAL_ARTICLES_COUNT]: state[TOTAL_ARTICLES_COUNT]
      }));
      setState(prevState => (
        { ...prevState, isProcessing: false }
      ));
    } catch (err) {
      console.error(err);
    }
  }

  const handleOnClick = () => {
    window.open(`/reports/liteedd/preview/${state.reportId}`, '_blank');
  }

  return <Form onSubmit={handleOnSubmit} className='add-summary-details'>
    <div className='justify-space-between'>
      <Button size='mini' content='Save' type='submit' loading={ state.isProcessing } disabled={ state.isProcessing } />
      <Button size='mini' content='Go to preview' onClick={handleOnClick} type='button' loading={ state.isProcessing } disabled={ state.isProcessing } />
    </div>
    <br /><br />
    <Form.Group>
      <Form.Input
        disabled={ state.isProcessing }
        type='number'
        required
        value={ state[TOTAL_ARTICLES_COUNT] || '' }
        id={ TOTAL_ARTICLES_COUNT }
        label='Indicate total articles count on entity from Google'
        onChange={ (evt, data) => (
          setState(prevState => (
            { ...prevState, [data.id]: data.value }
          ))
        ) } />
    </Form.Group>
  </Form>
}