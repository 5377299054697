import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react'
import { SanctionsTable } from './SanctionsTable';
import { getSanctionsList } from '../../utils/api';

export function Sanctions(props) {

  const PAGE_LIMIT = 5;
  const OPTIONS = [
    { key: 'person', text: 'Person', value: 'person' },
    { key: 'organisation', text: 'Organisation', value: 'organisation' },
    { key: 'other organisation', text: 'Other organisation', value: 'other organisation' },
    { key: 'government agency', text: 'Government agency', value: 'government agency' },
    { key: 'null', text: 'Null', value: 'null' },
  ]

  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({});
  const [results, setResults] = useState(null);
  const [count, setCount] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const handleOnInputChange = (event, { name, value }) => {
    setActivePage(1);
		setInputs(prevState => (
			{ ...prevState, [name]: value }
    ));
  }
  
  const handleFetchRecords = async(page) => {
    try {
      setLoading(true);
      const skip = (page - 1)*PAGE_LIMIT;
      const response = await getSanctionsList({ ...inputs, limit: PAGE_LIMIT, skip });
      setResults(response.results);
      setCount(response.count);
    } catch (err) {

    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <h3>Sanction List</h3>
      <Form onSubmit={() => handleFetchRecords(activePage)}>
        { <Form.Group widths='equal'>
          <Form.Input
            name='name'
            placeholder='Name of entity'
            onChange={ handleOnInputChange }
            label='Entity Name' />
          <Form.Select
            name='type'
            placeholder='Choose entity type'
            search
            options={OPTIONS}
            onChange={ handleOnInputChange }
            label='Entity Type' />
        </Form.Group> }
        <Button
          content='Search'
          disabled={loading}
          floated='right'
          loading={loading}
          type="submit" /> 
      </Form>
      <br /><br />
      { results && results.length >= 0 && <SanctionsTable 
        results={ results }
        count={ count }
        handleFetchRecords={ handleFetchRecords }
        PAGE_LIMIT={ PAGE_LIMIT }
        activePage={ activePage }
        setActivePage={ setActivePage } /> }
    </>
  )
}