import React, { useState, useContext } from 'react';
import moment from 'moment';
import { Button } from 'semantic-ui-react';
import { FullArticleRow } from './FullArticleRow';
import { RISK_FACTORS_MAPPING } from '../../../constants';
import { SearchContext } from './SearchContext';

export function ArticleRow({
  article,
  index
}) {

  const [isArticleDisplayed, setIsArticleDisplayed] = useState(false);
  const contextObj = useContext(SearchContext);

  const handleMarkArticle = (articleId, relevance) => {
    let markedArticles = contextObj.results.articles;
    markedArticles[articleId]['relevance'] = relevance;
    contextObj.setResults(markedArticles);

  }

  return <>
  <div className='article-row' id={ article.id }>
    <div>{ index + 1 }</div>
    <div><small>{ moment(article.date).format() }</small></div>
    <div>
      { article.title }
      <br /><br />

      { article.riskFactors.map(rf => (
        <button key={rf} className='risk-factor-label'>{ (RISK_FACTORS_MAPPING[rf] || {}).label }</button>
      )) }
    </div>
    <div>
      { article.meta && article.meta.summary &&
        <div className='summary-sentence' dangerouslySetInnerHTML={ {__html: article.meta.summary } } /> }
      <Button
        size='mini'
        onClick={ () => setIsArticleDisplayed(!isArticleDisplayed) }
        content={ isArticleDisplayed ? '- Hide' : '+ Read full article' } />
    </div>
    <div>
      <a href={ article.url } target="_blank" rel='noopener noreferrer'>
        { article.source }
      </a>
    </div>
    <div>
      <Button
        onClick={() => handleMarkArticle(article.id, 1)}
        active={article.relevance === 1}
        basic={!(article.relevance === 1)}
        size="mini">Mark as relevant</Button>
      <br /><br />
      <Button
        onClick={() => handleMarkArticle(article.id, 2)}
        active={article.relevance === 2}
        basic={!(article.relevance === 2)}
        size="mini">Mark as irrelevant</Button>
      <br /><br />
      <Button
        onClick={() => handleMarkArticle(article.id, 3)}
        active={article.relevance === 3}
        basic={!(article.relevance === 3)}
        size="mini">Mark as related but not adverse</Button>
    </div>
  </div>
  { isArticleDisplayed && <FullArticleRow article={ article } /> }
  </>
}