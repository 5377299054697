import React, {Fragment, useEffect, useState} from 'react';
import { RISK_FACTORS_MAJOR_CATEGORIES } from '../../../constants';

export function AdverseMediaAnalysis({ report }) {
  const [articles, setArticles] = useState({});
  useEffect(() => {
    const rfs = Object.keys(report.articles || {}).reduce((acc, articleId) => {
      for (let category of report.articles[articleId].riskCategories) {
        if (report.articles[articleId]) {
          if (category && acc[category]) {
            acc[category] = acc[category].concat(report.articles[articleId]);
          } else {
            acc[category] = [report.articles[articleId]];
          }
        }
      }
      return acc;
    },{});
    setArticles(rfs);
  }, []);

  const translateArticleSummary = (articlesPerCategory = []) => {
    return articlesPerCategory.reduce((acc, cur) => {
      if (cur && cur.summary) {
        acc += `<div class='summarySentence'>${cur.summary}</div>`;
      }
      return acc;
    }, '');
  }

  return <div className='adverseMediaAnalysis'>
    <div className='prelude'>A media analysis of { report.entityName } is as follows.</div>
    <div className='title'>Adverse Media Analysis</div>
    <div className='table summary'>
      <div className='tableBody'>
        <div className='tableRow'>
          <div className='tableCell left'>Total number of media articles generated on { report.entityName }</div>
          <div className='tableCell right'>{ (report.totalArticlesCount || 0).toLocaleString() }</div>
        </div>
        <div className='tableRow'>
          <div className='even tableCell left'>Total number of adverse media articles generated on { report.entityName }</div>
          <div className='even tableCell right'>{`${ report.adverseArticlesCount || 0 } (${(((report.adverseArticlesCount || 0)/(report.totalArticlesCount || 0)).toFixed(2)) * 100}% of total coverage)`}</div>
        </div>
      </div>
    </div>
    <div className='table details'>
      <div className='tableRow header'>
        <div className='tableCell cellOne'>Risk Indicator</div>
        <div className='tableCell cellTwo'>Summary</div>
        <div className='tableCell cellThree'>Number of articles</div>
        <div className='tableCell cellFour'>Links</div>
      </div>
      <div className='tableBody'>
        { RISK_FACTORS_MAJOR_CATEGORIES[report.isCompany ? 'company' : 'individual'].map((category, index) => (
          <div className={`tableRow ${index % 2 === 0 ? 'even': ''}`} key={ category }>
            <div className='tableCell cellOne'>
              <div>
                { category }
              </div>
            </div>
            <div className='tableCell cellTwo' dangerouslySetInnerHTML={{ __html: 
              `We found ${(!articles[category] || (articles[category] || {}).length === 0) ? 'no article': `${(articles[category] || {}).length} articles` } in local or international media mentioning ${report.entityName} in relation to ${category}. ${translateArticleSummary(articles[category])}`}}
            ></div>
            <div className='tableCell cellThree'>{ (articles[category] || {}).length || 0 }</div>
            <div className='tableCell cellFour'>
              { (articles[category] || []).map(article => (
                <Fragment key={ `${category}-${article.id}` }>
                <a href={ article.s3Url || article.url } target="_blank" rel='noopener noreferrer'>Ref</a>
                <br />
                </Fragment>
              )) }
            </div>
          </div>
        )) }
      </div>
    </div>
    <div className='fineprint'>Full list of adverse terms checked is available upon request</div>
  </div>
}