export const processArticles = (articles, searchCriterion = {}) => {
  const { allTerms, category, names } = searchCriterion;

  const processedArticles = articles.map(article => {
    const { body, lang } = article;
    const terms = allTerms[lang][category];

    let name = names[lang].toLowerCase();
    let output = article;
    const originalText = body;
    const composedHtmlTexts = [];
    let composedHighlights = [];
    const summary = {};

    if (name.indexOf('%and%') !== -1) {
      name = name.split('%and%');
    }
    if (name.indexOf('%or%') !== -1) {
      name = name.split('%or%');
    }
    if (name.indexOf('%near%') !== -1) {
      name = name.split('%near%');
    }
    if (name.indexOf('%next%') !== -1) {
      name = name.split('%next%');
    }
    const termsReg = new RegExp(terms.concat(name).join('|'), 'gi');
    let match, fullTextMatch = {};
    let lastMatch = 0;

    if (article.meta && article.meta.response) {
      output.composedNLPHighlights = _processNLPArticles(article);
    }

    while ((match = termsReg.exec(originalText)) != null) {
      const isEntity = name.indexOf(match[0].toLowerCase()) !== -1;
      composedHtmlTexts.push({ content: originalText.slice(lastMatch, match.index) });
      const matchTag = isEntity ? match[0] : originalText.slice(match.index, match[0].length + match.index);
      composedHtmlTexts.push({ content: matchTag, index: match.index, type: isEntity ? 'entity' : 'term' })
      lastMatch = match.index + match[0].length;
      const extract = originalText.slice(match.index - 100, match.index) + '<b>' + matchTag + '</b>' + originalText.slice(match.index + match[0].length, match.index + match[0].length + 100);

      if (isEntity && !output.keyHighlight) {
        output.keyHighlight = extract;
      }

      composedHighlights.push({
        text: extract,
        matches: [{
          term: matchTag,
          isEntity,
          fullTextIndex: match.index,
        }]
      });  
      summary[matchTag] = (summary[matchTag] || []).concat(match.index);
      fullTextMatch[match[0]] = (fullTextMatch[match[0]] || []).concat(match.index);
    }
    composedHtmlTexts.push({ content: originalText.slice(lastMatch) });

    output.fullTextMatch = JSON.parse(JSON.stringify(fullTextMatch));
    output.composedBody = composedHtmlTexts;
    output.composedHighlights = composedHighlights;
    output.summary = summary;
    if (name && Array.isArray(name)) {
      output.searchTerms = name.join(', ');
    }
    if (name && !Array.isArray(name)) {
      output.searchTerms = name;
    }
    return output;
  });

  return processedArticles;
}

// const _processNLPArticles = (article) => {
//   const composedNLPHighlights = [];
//   for (let details of ((article || {}).meta || {}).response || []) {
//     for (let ter of details.verbs) {
//       const match = new RegExp(ter, 'gi').exec(details.sentence);

//       const extract = match != null
//         ? details.sentence.slice(0, match.index) + '<b>' + ter + '</b>' + details.sentence.slice(match.index + ter.length)
//         : details.sentence;

//       composedNLPHighlights.push({'term': ter, 'extract': extract, 'originalExtract': details.sentence});  
//     }
//   }

//   return composedNLPHighlights;
// }

const _processNLPArticles = (article) => {
  const composedNLPHighlights = [];
  for (let details of ((article || {}).meta || {}).response || []) {
    let matches;
    let words;
    if (details.entities && details.entities.length > 0) {
      words = (details.verbs).concat(details.entities);
    } else {
      words = details.verbs;
    }
    const regexp = RegExp(words.join('|'), 'gi');
    const subStrs = [];
    let ind = 0;
    while ((matches = regexp.exec(details.sentence)) !== null) {
      subStrs.push(details.sentence.slice(ind, matches.index) + '<b>' + matches[0] + '</b>')
      ind = regexp.lastIndex;
    }
    subStrs.push(details.sentence.slice(ind));
    composedNLPHighlights.push({'term': details.verbs, 'extract': subStrs.join(''), 'originalExtract': details.sentence, 'entities': details.entities });  
  }
  return composedNLPHighlights;
}