import React from 'react';

export function Blacklist({ report }) {
  return <div className='blacklist'>
    <div className='title'>Blacklist and Political Exposure Checks</div>
    <div className="content">
      <div className='subtitle'>International blacklists/sanctions lists</div>
      <div className='body' dangerouslySetInnerHTML={ { __html: report.blacklist } } />
    </div>
    <div className="content">
      <div className='subtitle'>Political Exposure</div>
      <div className='body' dangerouslySetInnerHTML={ { __html: report.politicalExposure } } />
    </div>
  </div>;
}