import React, { useState } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import uuid from 'uuid';
import { RISK_FACTORS_MAJOR_CATEGORIES } from '../../../constants';
import { updatePreviewReport, uploadDocument, uploadFiles } from '../../../utils/api';
import axios from 'axios';

export function AddArticle({state, setState}) {
  const [content, setContent] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  const handleOnChange = (evt, data) => {
    setContent(prevState => (
      { ...prevState, [data.id]: data.value }  
    ))
  }

  const handleOnDropdownChange = (evt, data) => {
    setContent(prevState => (
      { ...prevState, [data.id]: data.value }  
    ))
  }

  const handleOnSubmit = async (evt, data) => {
    evt.preventDefault();
    try {
      setErrorMessage(null);
      if (!content.riskCategories || content.riskCategories.length === 0) {
        throw Error('Please indicate risk categories.');
      }
      setState(prevState => (
        {...prevState, isProcessing: true }
      ))
      const identifier = uuid.v4();
      let article = {
        riskCategories: content.riskCategories,
        id: identifier,
        title: content.title,
        url: content.url,
        s3Url: content.s3Url || '',
      }
      const articles = JSON.parse(JSON.stringify(state.articles));
      articles[article.id] = article;
      const adverseArticlesCount = Object.keys(articles).length;
      const payload = JSON.stringify({
        reportId: state.reportId,
        adverseArticlesCount,
        articles
      });
      await updatePreviewReport(payload);
      setState(prevState => (
        {...prevState,
        adverseArticlesCount,
        articles }
      ))
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setState(prevState => (
        {...prevState,
        isProcessing: false }
      ))
    }
  }

  const handleCreateS3Link = async (evt) => {
    evt.preventDefault();
    try {
      setErrorMessage(null);
      setState(prevState => (
        {...prevState, isProcessing: true }
      ))
      const s3Url = await uploadDocument(JSON.stringify({
        url: content.url,
        entityId: state.dataramaEntityId
      }));
      if (s3Url === null) {
        throw Error('There is insufficient credits for upload to S3. Please contact the administrator.');
      }
      setContent(prevState => (
        { ...prevState, s3Url: s3Url.location }
      ))  
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setState(prevState => (
        {...prevState,
        isProcessing: false }
      ))
    }
  }

  const handleOnLoadLocalStore = async () => {
    try {
      setErrorMessage(null);
      setState(prevState => (
        {...prevState, isProcessing: true }
      ))
      const { results } = JSON.parse(localStorage.getItem('dtrm-articles'));
      const articles = JSON.parse(JSON.stringify(state.articles));
      for (let result of results) {
        const s3Url = await uploadDocument(JSON.stringify({
          url: result.url,
          entityId: state.dataramaEntityId
        }));
        const identifier = uuid.v4();
        let article = {
          riskCategories: result.riskFactors,
          id: identifier,
          title: result.title,
          url: result.url
        }
        if (s3Url === null) {
          throw Error('There is insufficient credits for upload to S3. Please contact the administrator.');
        }
        article.s3Url = s3Url.location;
        articles[article.id] = article;
      }
      const adverseArticlesCount = Object.keys(articles).length;
      const payload = JSON.stringify({
        reportId: state.reportId,
        adverseArticlesCount,
        articles
      });
      await updatePreviewReport(payload);
      setState(prevState => (
        {...prevState,
        adverseArticlesCount,
        articles }
      ))
  
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setState(prevState => (
        {...prevState,
        isProcessing: false }
      ))
    }
  }

  const handleSubmitFile = async e => {
    e.preventDefault();
    setState(prevState => (
      {...prevState, isProcessing: true }
    ))
    const file = e.target.files;
    const fileId = uuid.v4();
    const fileMapping = {
      [fileId]: {
        fileId,
        name: file.name,
        index: 0
      }
    }

    const presigned = await uploadFiles(state.dataramaEntityId, Object.keys(fileMapping));
    if (!presigned) return;

    await Promise.all(presigned.map(p => {
      const index = fileMapping[p.fileId].index;
      if (file[index]) {
        return actualUpload(p, file[index], index)
      }
      return false;
    }))
    setState(prevState => (
      {...prevState, isProcessing: false }
    ))
  }

  const actualUpload = (presignedPostData, file, fileIndex) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
      });

      formData.append("file", file);

      axios.post(presignedPostData.url, formData)
      .then((result) => {
        const { key } = presignedPostData.fields;
        const s3Url = `${presignedPostData.url}/${key}`;
        setContent(prevState => (
          { ...prevState, s3Url }  
        ))
        resolve({ Key: key });
      })
      .catch(err => {
        console.log('error!');
      })
    });
  }


  if (state.reportId == null) {
    return null;
  }

  return <Form size='mini' onSubmit={handleOnSubmit}>
    <div className='segment'>
      <Button size='mini' content='Add articles from Articles Picker Widget' className='local-store-button' onClick={handleOnLoadLocalStore} type='button' loading={ state.isProcessing } disabled={ state.isProcessing } />
      {/* <div className='justify-space-between'>
        <Button size='mini' content='Next' onClick={handleOnClick} type='button' loading={ state.isProcessing } disabled={ state.isProcessing } />
      </div> */}
      { errorMessage !== null &&
        <Message negative>
          <Message.Header>{errorMessage}</Message.Header>
        </Message> }
      <br /><br />

      <Form.Group>
        <Form.Dropdown
          required
          id='riskCategories'
          placeholder='Select risk category'
          upward={false}
          multiple
          onChange={ handleOnDropdownChange }
          options={ RISK_FACTORS_MAJOR_CATEGORIES[state.isCompany ? 'company' : 'individual'].map(category => ({
            key: category,
            text: category,
            value: category
          })) } />
      </Form.Group>
      <Form.TextArea
        id='title'
        label='Title'
        value={content.title || ''}
        required
        onChange={ handleOnChange }></Form.TextArea>
      <Form.Group widths='equal'>
        <Form.Input
          id='url'
          label='Original Url'
          value={content.url || ''}
          action={<Button onClick={handleCreateS3Link} content='Generate S3 link' loading={state.isProcessing} disabled={state.isProcessing} size='mini' />}
          onChange={ handleOnChange }></Form.Input>
        <Form.Input label='Upload PDF to S3' type='file' name='file' onChange={handleSubmitFile} size='mini' />
      </Form.Group>
      <Form.Group widths='equal'>
        <Form.Input
          id='s3Url'
          label='S3 Url'
          value={content.s3Url || ''}
          required
          onChange={ handleOnChange }></Form.Input>
      </Form.Group>
      <Button size='mini' content='Add article' type='submit' loading={ state.isProcessing } disabled={ state.isProcessing } />
      </div>
  </Form>
}