import React from 'react';
import styled from 'styled-components';
import { Label } from 'semantic-ui-react';

const Errors = styled.div`
  margin: 10px 0;
  background: #ececec;
  padding: 10px;
`;

export default ({ errors }) => {
  return (
    <React.Fragment>
      {Object.keys(errors).length > 0 && (
        <Errors>
        <Label>Missing fields</Label> {Object.keys(errors).map(error => (
          <Label color='red' key={error}>{error}</Label>
        ))}
        </Errors>
      )}
    </React.Fragment>

  )
}
