import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import auth from '../utils/auth';

class Logout extends Component {
  constructor(props) {
    super(props);
    this.logout = () => {
      auth.logout(() => {
        this.props.onAuthStateChange(false);
      })
    }
  }
  render() {
    return (
      <Button onClick={this.logout}>Logout</Button>
    );
  }
}

export default Logout;
