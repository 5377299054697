import TmpEntity from './tmpEntity';

class TmpPerson extends TmpEntity {
  constructor(props) {
    super(props);
    this.type = 'person';
    this._fieldDetails = Object.assign({}, this.basicFieldDetails, {
      entityName: Object.assign({}, this.basicFieldDetails.entityName, {
        Header: 'Individual Name'
      }),
      Gender: {
        Header: 'Gender',
        id: 'Gender',
        accessor: 'Gender',
        type: 'string',
        input: {
          type: 'dropdown',
          source: 'gender',
        }
      },
      entityStatus: Object.assign({}, this.basicFieldDetails.entityStatus, {
        Header: 'Alive?',
        options: ['Active', 'Inactive'],
      }),
      entityStartDate: Object.assign({}, this.basicFieldDetails.entityStartDate, {
        Header: 'DOB'
      }),
      entityEndDate: Object.assign({}, this.basicFieldDetails.entityEndDate, {
        Header: 'Date of Death'
      }),
      otherRegistrationNo1: Object.assign({}, this.basicFieldDetails.otherRegistrationNo1, {
        Header: 'Other Registration Code (e.g. passport number, ID etc)'
      }),
      RegistrationCountry: Object.assign({}, this.basicFieldDetails.RegistrationCountry, {
        Header: 'Other Registration Country'
      }),
      PEP: {
        Header: 'PEP',
        id: 'PEP',
        accessor: 'PEP',
        type: 'string',
        required: true,
        input: {
          type: 'dropdown',
          source: 'yesNo',
        }
      },
    });

    this.columns = [
      // 'entryType',
      'entityId',
    'entityName',
    'entityAlias1',
    'langId1',
    'entityAlias2',
    'langId2',
    'entityAlias3',
    'langId3',
    'entityStartDate',
    'entityEndDate',
    'Gender',
    'entityStatus',
    'PEP',
    'Blacklist',
    'Blacklist detail',
    'addressStr',
    'addressStrInOtherLang',
    'langId4',
    'otherRegistrationNo1',
    'RegistrationCountry',
    'country',
    'source',
    'QOS']
    this.digestFields = [
      'entityId','entityName', 'Gender', 'country','source'
    ];
    this.newEntityGroupFields = [
      ['entityName', 'Gender', 'country'],
      ['entityAlias1', 'langId1'],
      ['entityAlias2', 'langId2'],
      ['entityAlias3', 'langId3'],
      ['entityStartDate', 'entityEndDate', 'entityStatus'],
      ['PEP', 'Blacklist', 'Blacklist detail'],
      ['addressStr', 'addressStrInOtherLang', 'langId4'],
      ['otherRegistrationNo1', 'RegistrationCountry'],
      ['source', 'QOS']
    ]
  }

}

export default TmpPerson;
