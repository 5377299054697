import React, { useState } from 'react';
import { Step } from 'semantic-ui-react';
import { EntityId } from './entityId/EntityId';
import { BusinessDescription } from './businessdescription/BusinessDescription';
import { Financials } from './financials/Financials';
import { MediaRiskRating } from './mediaRiskRating/MediaRiskRating';
import { Blacklist } from './blacklist/Blacklist';
import { ExecutiveSummary } from './executiveSummary/ExecutiveSummary';
import { PublicProfile } from './publicProfile/PublicProfile';
import './index.scss';

export function KeystoneOrganisations({ projectId }) {
  const [state, setState] = useState({
    isError: false,
    dataramaEntityId: "",
    keystoneId: "",
    entityName: "",
    entityType: "",
    rawFinancials: {},
    financialLineItems: {},
    isProcessing: false,
    completed: {
      entityId: false,
      businessdescription: false,
      executiveSummary: false,
      publicProfile: false,
      entityCheck: false
    },
    active: 'entityId'
  });

  const STEPS = [
    { key: 'entityId', title: 'Entity Id', description: 'Enter Entity Id' },
    { key: 'businessdescription', title: 'Basic Information', description: 'Basic information' },
    { key: 'executiveSummary', title: 'Executive Summary', description: 'Executive Summary' },
    { key: 'publicProfile', title: 'Public Profile', description: 'Public Profile' },
    { key: 'financials', title: 'Financials', description: 'Financials' },
    { key: 'riskRating', title: 'Risk Rating', description: 'Risk Rating' },
    { key: 'entityCheck', title: 'Entity Check', description: 'Entity Check' }
  ];

  return <div className='keystone organisations'>
    <Step.Group widths={8} size='mini' ordered>
      { STEPS.map(step => (
        <Step key={step.key}
          onClick={() => {
            if (state.keystoneId !== '') {
            setState(prevState => (
              { ...prevState, active: step.key }
            ))
            }
          }}
          active={state.active === step.key}>
          <Step.Content>
            <Step.Title>
              { step.title }
            </Step.Title>
            <Step.Description>
              { step.description }
            </Step.Description>
          </Step.Content>
        </Step>
      )) }
    </Step.Group>

    { state.active === 'entityId' &&
      <EntityId state={ state } setState={ setState } /> }
    { state.active === 'businessdescription' &&
      <BusinessDescription state={ state } setState={ setState } /> }
    { state.active === 'financials' &&
      <Financials state={ state } setState={ setState } /> }
    { state.active === 'riskRating' &&
      <MediaRiskRating state={state} setState={setState} /> }
    { state.active === 'executiveSummary' &&
      <ExecutiveSummary state={ state } setState={ setState } /> }
    { state.active === 'publicProfile' &&
      <PublicProfile state={ state } setState={ setState } /> }
    { state.active === 'entityCheck' &&
      <Blacklist state={ state } setState={ setState } /> }
  </div>;
}