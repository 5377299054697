import { ENTITY_TYPE } from './ref/entityType';

import { entityTypeCamelCase } from './conversion/entityType';

export const checkIfValidEntityType = (type) => {
  if (ENTITY_TYPE[type] || ENTITY_TYPE[entityTypeCamelCase(type)]) {
    return true;
  }
  return false;
}
