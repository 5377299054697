import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Label } from 'semantic-ui-react';
import { TempRelationship } from '../../model/relationship/tempRelationship';
import { TempLitigation } from '../../model/litigation/tempLitigation';
import Input from '../Input';
import DateField from './fields/DateField';
import Text from './fields/Text';
import { addNewRel, updateRel } from '../../utils/api';
import { relTypeMapping, sourceMapping } from '../../utils/ref/formConstants';
import { stringCleaning } from '../../utils/conversion/specialCharacter';

const Errors = styled.div`
  margin: 10px 0;
  background: #ececec;
  padding: 10px;
`;

export function RelationshipForm({
	params,
	setHttpResponse,
	setErrors,
	errors,
	inputs,
  formType,
	isNewRelationship,
	setInputs }) {

  const entityType = formType === 'litigation' ? formType : 'relationship';

  const tempRelationship = entityType === 'litigation' ? new TempLitigation() : new TempRelationship();

	// Declare component states
	const [isValidating, setIsValidating] = useState(false);
	const [validated, setValidated] = useState(false);
	const [loading, setLoading] = useState({});
	const [isShareholdingDisabled, setIsShareholdingDisabled] = useState(false);

	// Declare side effects
	useEffect(() => {
		const SHAREHOLDING_RELS = ['Subsidiary', 'Shareholding'];
		if (SHAREHOLDING_RELS.indexOf(inputs.relTypeStr) === -1) {
			setIsShareholdingDisabled(true);
		} else {
			setIsShareholdingDisabled(false);
		}
	},[inputs.relTypeStr]);

	let { type, projectId } = params;

	const handleFieldChange = (event, { name, value }) => {
		setInputs(prevState => (
			{ ...prevState, [name]: value }
		));
	}

	const handleOnSubmit = () => {
		setErrors({});
		const fields = tempRelationship.fieldDetails;
		const outputFields = Object.keys(fields).reduce((a, fieldName) => {
			const field = fields[fieldName];
			if (field.required && !inputs[fieldName]) {
				a.errors[fieldName] = true;
			}
			if (inputs[fieldName]) {
				a.fields[fieldName] = inputs[fieldName];
			}
			return a;
		}, { errors: {}, fields: {} });
		if (!validated && isNewRelationship) {
			outputFields.errors.validated = true;
		} else {
			delete outputFields.errors.validated;
		}
		if (Object.keys(outputFields.errors).length > 0) {
			setErrors(outputFields.errors);
		} else {
			setIsValidating(true);
		}
	}

	const handleAddRelationship = async event => {
		try {
			setLoading(prevState => (
				{ ...prevState, adding: true }
			));
			const result = await addNewRel(projectId, {...parsePayload(inputs), entityType });
			if (result.error) throw result.message;
			setHttpResponse(result);
      setLoading(prevState => (
				{ ...prevState, adding: false }
			));
		} catch (err) {
			setErrors({[err]: true});
			setLoading({});
		}
	}

	const handleUpdateRelationship = async event => {
		try {
			setLoading(prevState => (
				{ ...prevState, update: true }
			));
			const result = await updateRel(projectId, {...parsePayload(inputs), entityType })
			setHttpResponse(result);
		} catch (err) {
			setErrors({[err]: true});
			setLoading({});
		}
	}

	const parsePayload = (payload) => {
		let copy = JSON.parse(JSON.stringify(payload));
		copy.sourceId = Object.keys(sourceMapping).find(key => sourceMapping[key] === copy.sourceStr);
		delete copy.sourceStr;
		copy.relTypeId = relTypeMapping[copy.relTypeStr];
		delete copy.relTypeStr;
		Object.keys(copy).forEach((key) => {
      if (copy[key] == null) {
        delete copy[key]
      } else if (tempRelationship.fieldDetails[key]) {
        copy[key] = stringCleaning(tempRelationship.fieldDetails[key].type, copy[key])
      }
    });
		return copy;
	}
	return (
		<Form onSubmit={ handleOnSubmit }>
		{ tempRelationship.newRelationshipGroupedFields.map((group, groupId) => {
			return <Form.Group widths='equal' key={`${type}-${groupId}`}>
			{ group.map(field => {
				if (field.input.type === 'dropdown') {
					return <Input
						key={field.id}
						field={field}
						isValidating={isValidating}
						defaultValue={inputs[field.id] || ''}
						handleFieldChange={handleFieldChange} />;
				}
				if (field.input.type === 'date') {
					return <DateField
						key={field.id}
						field={field}
						isValidating={isValidating}
						error={errors[field.id]}
						defaultValue={inputs[field.id] || ''}
						handleFieldChange={handleFieldChange} />
				}
				return <Text
					key={field.id}
					field={field}
					disabled={field.id === 'shareholdingPercentage' && isShareholdingDisabled}
					meta={field.input.meta}
					error={errors[field.id]}
					defaultValue={inputs[field.id] || ''}
					isValidating={isValidating}
					handleFieldChange={handleFieldChange} />
				})
				}
			}
			</Form.Group>
		})}
		{ isNewRelationship &&
		<Form.Group widths='equal' key={`${type}-checkbox`}>
			<Form.Field required>
				<Form.Checkbox required
					name='validated'
					onChange={ () => setValidated(!validated) }
					disabled={ isValidating }
					checked={ validated }
					error={ errors.validated }
					label='I have checked this is a new relationship between the two entities.' />
			</Form.Field>
		</Form.Group> }
		{ Object.keys(errors).length > 0 && (
			<Errors>
				<Label>Missing fields</Label>
				{ Object.keys(errors).map(error =>
					<Label color='red' key={error}>{error}</Label>) }
			</Errors>
		) }
		{ !isValidating &&
			<Button type='submit' fluid size='large' content= {`${ isNewRelationship ? 'To Confirm adding new relationship' : 'To Confirm updating relationship' }`} /> }
		{ isNewRelationship && isValidating && (
			<>
				<br/>
				<Button onClick={ handleAddRelationship }
					fluid
					color='green'
					content='Confirm and add new relationship'
					loading={ loading.adding }
					disabled={ loading.adding || false }
					size='large' />
				<br/>
				<Button onClick={ () => setIsValidating(false) }
					fluid
					size='large'
					color='grey'
					basic
					value="cancel"
					disabled={ loading.adding || false }>Edit Again</Button>
			</>
		)}
		{!isNewRelationship && isValidating && (
			<>
				<br />
				<Button onClick={ handleUpdateRelationship } size='large' color='green' loading={loading.update} disabled={loading.update} fluid>Confirm and Update</Button>
				<br/>
				<Button onClick={() => setIsValidating(false)} size='large' color='grey' basic value="cancel" disabled={loading.update} fluid>Edit Again</Button>
			</>
		)}
	</Form>
	)
}
