import React from 'react';
import styled from 'styled-components';
import { FeedbackWidget } from './FeedbackWidget';

const HighlightedWord = styled.span`
  font-weight: 700;
  font-size: 15px;
  padding: 1px 5px;
  border-radius: 2px;
  margin-bottom: 5px;
  background: ${({ main }) => main ? '#696969' : '#f75353'};
  color: #fff;
`;
const Paragraph = styled.div`
  display: inline;
`;

const Extract = styled(Paragraph)`
  display: inline;
  b {
    border-bottom: 2px solid red;
  }
`;

const TermSummary = styled.div`
  h3 {
    margin: 0;
  }
  > div {
    display: inline-block;
    margin: 0 5px;
    > span {
      margin-right: 5px;
    }
  }
  padding: 10px 0;
`;


export function EnglishArticle({article, category, isNLP, searchScope, mlSentences, loggedInUser}) {
  if (!article) {
    return null;
  }
  return (
    <>
      { isNLP && article.composedNLPHighlights.map((sentence, index) => {
        return <FeedbackWidget key={index} article={article} category={category} sentence={sentence} mlSentences={mlSentences} loggedInUser={loggedInUser} />
      })}
      { !isNLP && <><TermSummary>{ Object.keys(article.summary).map(term => {
        const refName = searchScope.name[article.lang];
        return <div key={term}>
          <HighlightedWord main={term.toLowerCase() === refName}>{term}</HighlightedWord>
          { article.summary[term].length }
        </div>}) }
      </TermSummary>
      <Extract dangerouslySetInnerHTML={{ __html: '...' + article.keyHighlight + '...' }} /></> }

    </>
  )
}