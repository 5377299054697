import React, { useState } from 'react';
import { Message } from 'semantic-ui-react';
import { ExpressRelationshipForm } from './ExpressRelationshipForm';
import { ExpressRelationshipTable } from './ExpressRelationshipTable';
import { ExpressRelationshipEntitySearch } from './ExpressRelationshipEntitySearch';
import './relationships.scss';

export function ExpressRelationship({ match: { params } }) {
  const [state, setState] = useState({
    form: {
      // relTypeStr: '',
      // entityId: '100000000009640801'
    },
    payload: [],
    isProcessing: false,
    isShareholdingDisabled: true,
    // relationships: [],
    active: 'search',
    errorMessage: null,
    isSuccess: false,
    areEntitiesSwapped: false
  });

  const translate = rels => {
    return rels.reduce((acc, rel) => {
      if (rel.relTypeStr) {
        if (!acc[rel.relTypeStr]) {
          acc[rel.relTypeStr] = [];
        }
        acc[rel.relTypeStr] = acc[rel.relTypeStr].concat(rel);
      }
      if (rel.relTypeDescStr) {
        if (!acc[rel.relTypeDescStr]) {
          acc[rel.relTypeDescStr] = [];
        }
        acc[rel.relTypeDescStr] = acc[rel.relTypeDescStr].concat(rel);
      }
      return acc;
    }, {});
  }

  return <div className='express-relationship'>
    { state.active === 'search' &&
      <ExpressRelationshipEntitySearch
        className='entity-search'
        state={ state }
        setState={ setState } /> }
    { state.active === 'form' && 
      <>
        <ExpressRelationshipForm
          className='form'
          params={ params }
          state={ state }
          setState={ setState } />
        <ExpressRelationshipTable
          className='table'
          rels={ translate(state.payload) }
          // rels={translate((state.relationships || []).concat(state.payload))}
          state={ state }
          setState={ setState } />
      </>}
    { state.active === 'success' &&
      <Message positive>Ingestion of relationships completed for entity {state.form.entityId}.</Message> }
  </div>
}