const REQUEST_PATH = 'testrequests';

const LANGUAGES = [
  { code: 'eng', label: 'English' },
  { code: 'ind', label: 'Bahasa Indonesian' },
  { code: 'zho', label: 'Chinese' },
  { code: 'vie', label: 'Vietnamese' },
  { code: 'tha', label: 'Thai' },
  { code: 'hin', label: 'Hindi' },
  { code: 'tam', label: 'Tamil' },
  { code: 'jap', label: 'Japanese' },
  { code: 'kor', label: 'Korean' }
];

const CONCEPTS = [
  { code: 'corporateGovernance', label: 'Corporate Governance' },
  { code: 'corruption', label: 'Corruption' },
  { code: 'environmental', label: 'Environmental' },
  { code: 'financialCrime', label: 'Financial Crime' },
  { code: 'general', label: 'General' },
  { code: 'legal', label: 'Legal' },
  { code: 'operational', label: 'Operational' },
  { code: 'political', label: 'Political' },
  { code: 'social', label: 'Social' }
]

const RISK_FACTORS = [
  { code: 'v2_sanctionsAndBlacklists', label: 'Sanctions/ Blacklists' },
  { code: 'v2_pep', label: 'Politically Exposed Persons' },
  { code: 'v2_terroristInvolvement', label: 'Terrorist Involvement' },
  { code: 'v2_corruptionBriberyAndFraud', label: 'Corruption, Bribery and Fraud' },
  { code: 'v2_malfeasance', label: 'Malfeasance' },
  { code: 'v2_seriousAndOrganisedCrime', label: 'Serious and Organised Crime' },
  { code: 'v2_moneyLaundering', label: 'Money Laundering' },
  { code: 'v2_counterfeitingActivity', label: 'Counterfeiting Activity' },
  { code: 'v2_taxEvasion', label: 'Tax Evasion' },
  { code: 'v2_regulatoryBreaches', label: 'Regulatory Breaches' },
  { code: 'v2_warCrimesAndWeaponsTrafficking', label: 'War Crimes and Weapons Trafficking'},
  { code: 'v2_labourAbuses', label: 'Labour Abuses' },
  { code: 'v2_humanRightsAbuses', label: 'Human Rights Abuses' },
  { code: 'v2_intellectualPropertyViolations', label: 'Intellectual Property Violations' },
  { code: 'v2_cyberBreaches', label: 'Cyber Breaches' },
  { code: 'v2_relationshipRisks', label: 'Relationship Risks' },
  { code: 'v2_socialAccountabilityRisks', label: 'Social Accountability Risks' },
  { code: 'v2_adversarialSocialMediaActivity', label: 'Adversarial Social Media Activity' },
  { code: 'v2_otherRiskFactors', label: 'Other Risks Factors' }
]

export {
  CONCEPTS,
  LANGUAGES,
  RISK_FACTORS,
  REQUEST_PATH
}