import React from 'react';
import { Header, Card, Segment, Divider, Message, Button, Icon, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import values from 'lodash.values';
import { getAllProjects, createProject, checkPermissions } from '../utils/api';
import { setProjects } from '../actions';
import moment from 'moment';
import Loading from './Loading';
import { getAuth, getSettings, getProjects } from '../reducers/selectors';
import LastUpdated from './LastUpdated';
import Project from '../model/project';
import ProjectStatus from './table/projectStatus';
import { isTimeAfter } from '../utils/time';

const CardRow = styled.div`
  > div {
    display: inline-block;
    margin: 3px 0;
  }
  > div:first-of-type {
    width: 100px;
  }
`;
const Float = styled.div`
  position: absolute;
  right: 10px;
  top: 20px;
  text-align: right;
`;

class HomeTracker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      errors: null,
      isLoading: false,
      pageError: (props.location || {}).state && ((props.location || {}).state || {}).error,
    };
    this.project = new Project();
    this.addProject = this.addProject.bind(this);
    this.fetchProjects = this.fetchProjects.bind(this);
    this.setErrors = (errors) => {
      this.setState(() => ({
        errors,
      }))
    };
    this.clearPageError = () => {
      this.setState(() => ({
        pageError: null,
      }))
    }
    this.handleTabChange = async (e, { activeIndex }) => {
      let actionToCheck;
      switch (activeIndex) {
        case 1:
          actionToCheck = 'create'
          break;
        default:
          actionToCheck = null
      }
      let permitted = true;
      if (actionToCheck && this.props.loggedInUser){
        const checkResult = await checkPermissions('project', this.props.loggedInUser);
        // const checkResult = await checkPermissions('project', [{ resourceType: 'project', action: actionToCheck }]);
        if (checkResult && checkResult.general) {
          if (!checkResult.general[`project.${actionToCheck}`]) {
            permitted = false;
          }
          this.props.updateSelfPermission('general', null, checkResult.general)
        }
      }
      this.setState(prevState => ({
        activeTab: permitted ? activeIndex : prevState.activeTab,
      }))
    }
  }

  componentDidMount() {
    const { lastFetched } = this.props;
    // Fetch only if not previously fetched or last fetched > 5 minutes
    const shouldFetch = !lastFetched || isTimeAfter(lastFetched);
    if (shouldFetch) {
      this.fetchProjects();
    }
  }

  async fetchProjects(e, props) {
    let forceUpdate = false;
    if (props) {
      if (props.value === 'hard') {
        forceUpdate = true;
      }
    }
    this.setState(() => ({
      isLoading: true,
    }))
    const data = await getAllProjects();
    if (data) {
      this.props.setProjects(data.projects, false, forceUpdate);
      this.setState(() => ({
        isLoading: false,
      }))
    }
  }

  async addProject(data) {
    let input;
    // let input = {
    //   name: 'testing',
    //   importTable: 'TmpProjAbc'
    // }
    if (data) {
      input = data;
    }
    if (!input) return;
    const newProject = await createProject(input);
    if (newProject) {
      this.setState(prevState => ({
        projects: prevState.projects.concat(newProject),
        activeTab: 0,
      }))
    } else {
      this.setState(() => ({
        errors: 'Unable to create project',
      }))
    }

  }
  render() {
    const { pageError, isLoading } = this.state;
    const { loggedInUser, lastFetched, projects } = this.props;

    const columns = this.project.getReadColumns();

    columns.forEach((col) => {
      if (col.input && col.input.sourceFromState) {
        col.Cell = ({ value }) => {
          if (!this.props[col.input.sourceFromState]) return null;
          if (col.rawDataTransform && value) {
            return col.rawDataTransform(value).map(p => this.props[col.input.sourceFromState][p][col.input.displayField]).join(', ');
          }
          if (!this.props[col.input.sourceFromState][value]) return null
          const val = this.props[col.input.sourceFromState][value][col.input.displayField];
          return val;
        }
      }
    })

    const sortedProject = projects && values(projects).reduce((a, proj) => {
      const isSelfProject = proj.overallStatus ==='active' && ((proj.analysts && proj.analysts.indexOf(loggedInUser) !== -1) || (proj.reviewer && proj.reviewer === loggedInUser));
      if (isSelfProject)  {
        a.self = (a.self || []).concat(proj)
      }
      return a;
    }, {});

    const digestFields = this.project.getDigestFields();

    const valueField = (project, field) => {
      let value = project[field.id];
      if (!field.input || !value) return (<Label size='medium' basic horizontal>{value || 'N/A'} </Label>);
      if (field.input.type === 'dropdown') {
        if (field.input.sourceFromState && this.props[field.input.sourceFromState]) {
          if (field.rawDataTransform && value) {
            return field.rawDataTransform(value).map(p => (
              <Label key={p} size='medium' basic horizontal>
                {this.props[field.input.sourceFromState][p][field.input.displayField]}
              </Label>
            ))
          }
          if (!this.props[field.input.sourceFromState][value]) return null;
          return (
            <Label horizontal size='medium' basic>{this.props[field.input.sourceFromState][value][field.input.displayField]}</Label>
          )
        }
      } else if (field.input.type === 'date') {
        value = value && moment(value).format('DD MMM YYYY (ddd)');
      }
      return value ? (<Label size='medium' basic horizontal>{value}</Label>) : (<Label basic>N/A</Label>)
    }
    const projectStatusField = this.project.getFieldDetails('projectStatus');
    // const projectStatus = (val) => {
    //   const condVal = projectStatusField.input.cellConditionValue ? projectStatusField.input.cellConditionValue(val) : val;
    //   return (
    //     <ProjectStatus col={projectStatusField} value={val} fluid />
    //   )
    //   return (
    //     <React.Fragment>
    //       Project Status: <ColorLabel tag bgcolor={projectStatusField.input.colorSpectrum[condVal]}>{val}</ColorLabel>
    //     </React.Fragment>
    //   )
    //
    //
    const getColor = (overallStatus) => {
      if (overallStatus === 'active') return { color: 'green'};
      return {}
    }
    const cardContent = (proj) => (
      <Card
        key={proj.projectId}
        onClick={() => {
          this.props.history.push('/project/' + proj.projectId)
        }}
      >
        <Card.Content>
          <Card.Header>{proj.name}</Card.Header>
          <Label horizontal {...getColor(proj.overallStatus)} ribbon='right'>{proj.overallStatus.toUpperCase()}</Label>
          <Card.Description>
            {digestFields.map(field => (
              <CardRow key={field.id}>
                <div>{field.Header}</div> <div>{valueField(proj, field)}</div>
              </CardRow>
            ))}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Card.Description>
            <CardRow>
              <div>Project Status</div>
              <div style={{height: '30px', width: '60%', 'verticalAlign': 'top'}}>
                <ProjectStatus col={projectStatusField} value={proj.projectStatus} />
              </div>
            </CardRow>
          </Card.Description>
        </Card.Content>
      </Card>
    )
    return (
      <div>
        <Header
          as='h2'
          content='My Tracker'
          subheader='Show projects that are active, on-going and assigned to you'
        />
        <Float>
          <Button compact onClick={this.fetchProjects} value="hard" icon basic>
            <Icon name='refresh'/> Refresh
          </Button>
          <div>
            <LastUpdated
              loading={isLoading}
              date={lastFetched}
            />
          </div>
        </Float>
        <Divider />

        {pageError && pageError === 401 && (
          <Message
            negative
            onDismiss={this.clearPageError}
            header="Unable to access project. Please check your permission."
            content="You cannot access the project because you do not have the correct rights to do so. Please check with tech team or analyst team for assistance"
          />
        )}
        {sortedProject && !sortedProject.self && (
          <Segment placeholder>
            <Header icon>
              <Icon name='folder open outline' />
              No projects are currently on your plate.
            </Header>
          </Segment>
        )}
        <Card.Group>
          {sortedProject && (sortedProject.self || []).map(proj => cardContent(proj))}
        </Card.Group>
        <Loading active={isLoading} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const authState = getAuth(state);
  const settings = getSettings(state);
  const projects = getProjects(state);
  return {
    users: settings.users,
    clients: settings.clients,
    projects: projects.projects,
    lastFetched: projects.lastFetched,
    loggedInUser: authState.loggedInUser,
    rolesRef: (getSettings(state).roles),
  }
};

export default withRouter(connect(mapStateToProps, { setProjects })(HomeTracker));
