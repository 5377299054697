import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Header, Form, Button, Segment, Label, Message } from 'semantic-ui-react';
import { getSettings, getAuth } from '../../reducers/selectors';
import Project from '../../model/project';
import GroupedForm from '../GroupedForm';
import { createProject } from '../../utils/api';
import GotoPath from '../GotoPath';
import { ColorLabel } from '../../utils/styles';
import { isTimeAfter } from '../../utils/time';
import AccessDenied from '../AccessDenied';

let lastChecked;
const NewProject =  (props) => {
  const { canCreate } = props;
  if (!canCreate) return <AccessDenied action='create' resourceType='project' />
  if ((!lastChecked || isTimeAfter(lastChecked)) && props.checkPermission) {
    props.checkPermission();
    lastChecked = moment();
  }
  const [input, setInput ] = useState({});
  const [loading, setLoading ] = useState(false);
  const [errors, setErrors ] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const [result, setResult] = useState(null);
  const project = new Project();
  const allFields = project.fields;
  const groupedFields = project.getNewGroupedFields();

  const fieldChange = (e, { name, value }) => {
    const newInput = input;
    let newValue = value;
    newInput[name] = newValue;
    setInput(newInput);
  }
  const beforeSubmit = () => {
    let errors;
    const output = Object.keys(allFields).reduce((a, c) => {
      const currField = allFields[c];
      if (currField.required && !input[c]) {
        errors = (errors || []).push(c);
      } else {
        a[c] = input[c]
      }
      return a;
    }, {});
    setConfirming(true);
    setErrors(null);
    setInput(output);
  }
  const confirmSubmit = async () => {
    setLoading(true);
    const newProject = await createProject(input);
    if (newProject) {
      setResult(newProject);
      setConfirming(false);
    } else {
      setErrors({ error: true })
    }
    setLoading(false);
  }

  const valueField = (field, value) => {
    if (!field.input || !value) return (<Label size='large' horizontal>{value || 'N/A'} </Label>);
    if (field.input.type === 'dropdown') {
      if (field.input.sourceFromState && props[field.input.sourceFromState]) {
        if (field.rawDataTransform && value) {
          return value.map(p => (
            <Label key={p} size='large' horizontal color='olive'>
              {props[field.input.sourceFromState][p][field.input.displayField]}
            </Label>
          ))
        }
        if (!props[field.input.sourceFromState][value]) return null;
        return (
          <Label horizontal color='olive' size='large'>{props[field.input.sourceFromState][value][field.input.displayField]}</Label>
        )
      }
    } else if (field.input.type === 'date') {
      value = value && moment(value).format('DD MMM YYYY (ddd)');
    }
    let meta = {};
    if (field.input.colorSpectrum) {
      const condVal = field.input.cellConditionValue ? field.input.cellConditionValue(value) : value;
      meta = {
        bgcolor: field.input.colorSpectrum[condVal],
      };
    }
    return value ? (
      <ColorLabel {...meta} size='large' color='olive' horizontal>{value}</ColorLabel>
    ) : (<Label basic>N/A</Label>)
  }

  if (result) {
    return (
      <Message positive>
        Successfully added new project.
        <GotoPath
          display={result.projectId}
          path={'/project/' + result.projectId}
        />
        {groupedFields.map((group, groupId) => (
          <Segment.Group key={groupId}>
            {group.map(field => (
              <Segment key={field.id}>
              <b>{field.Header}</b> {valueField(field, result[field.id])}
              </Segment>
            ))}
          </Segment.Group>
        )) }

      </Message>
    )
  }

  if (confirming && !errors) {
    return (
      <React.Fragment>
        <Message>Please confirm the details before creating</Message>
        <Segment.Group>
          {groupedFields.map((group, groupId) => (
            <Segment.Group key={groupId}>
              {group.map(field => (
                <Segment key={field.id}>
                  <b>{field.Header}</b> {valueField(field, input[field.id])}
                </Segment>
              ))}
            </Segment.Group>
          )) }
          <Segment>
            <Button onClick={confirmSubmit} loading={loading} disabled={loading}>Add new project</Button>
          </Segment>
        </Segment.Group>
      </React.Fragment>
    )
  }
  const handleDismiss = () => {
    setErrors(null);
  }
  return (
    <React.Fragment>
      <Header>New Project</Header>
      {errors && (
        <Message
          warning
          onDismiss={handleDismiss}
        >
          <Message.Header>Unable to create project. Please change the IMPORT TABLE NAME to make sure it is unique</Message.Header>
          <p>If problem persists, please contact tech team for support.</p>
        </Message>
      )}
      <Form onSubmit={beforeSubmit}>
        <GroupedForm groupedFields={groupedFields} isValidating={confirming} handleFieldChange={fieldChange} />
        <Button type='submit'>{'Preview before Creating project'}</Button>
      </Form>
    </React.Fragment>

  )
}

const mapStateToProps = state => {
  const settings = getSettings(state);
  const authState = getAuth(state);
  return {
    users: settings.users,
    clients: settings.clients,
    roles: settings.roles,
    canCreate: ((authState.permissions || {}).general || {})['project.create'],
  }
};
export default withRouter(connect(mapStateToProps)(NewProject));
