import { ENTITY_TYPE } from './ref/entityType';

const entityId = {
  label: 'Datarama ID',
  value: 'entityId',
  isLink: true,
  id: 'entityId'
};
const nameStr = {
  label: 'Name',
  value: 'nameStr',
  id: 'nameStr',
};
const nameStartDateStr = {
  label: 'Start',
  value: 'nameStartDateStr',
  id: 'nameStartDateStr',
};
const nameEndDateStr = {
  label: 'Termination',
  value: 'nameEndDateStr',
  id: 'nameEndDateStr',

};
const sourceId = {
  label: 'Source',
  value: 'sourceId',
  isStateValue: true,
  id: 'sourceId',
  stateKey: 'sources',
  stateValue: (data) => {
    return data && `${data.sourceNameStr} + ${data.sourceQualityStr}`
  }
}
const cleanNameStr = {
  label: 'Clean Name',
  value: 'cleanNameStr',
  id: 'cleanNameStr',
};
const flags = {
  label: 'Flag',
  id: 'flags',
  items: [{
    label: 'doNotUse',
    value: 'doNotUseFlag',
    id: 'doNotUseFlag',
    isBoolean: true,
  }, {
    label: 'PermDel',
    value: 'PermDelFlag',
    id: 'PermDelFlag',
    isBoolean: true,
  }]
}

const errorLinkFlag = {
  label: 'Error Link Flag',
  id: 'errorLinkFlag',
  value: 'errorLinkFlag',
  isBoolean: true
}

const relId = {
  label: 'Datarama ID',
  value: 'relId',
  isLink: true,
  id: 'relId'
}
const relEntityId = {
  label: 'Entity 1 Id',
  value: 'entityId',
  id: 'entityId'
};
const relEntity2Id = {
  label: 'Entity 2 Id',
  value: 'entity2Id',
  id: 'entity2Id'
};
const relTypeDescStr = {
  label: 'Relationship Type',
  value: 'relTypeDescStr',
  id: 'relTypeDescStr'
}
const relDescStr = {
  label: 'Relationship Description',
  value: 'relDescStr',
  id: 'relDescStr'
}
const sourceStr = {
  label: 'Source',
  value: 'sourceStr',
  id: 'sourceStr'
}
const litigationId = {
  label: 'Litigation Id',
  value: 'litigationId',
  id: 'litigationId'
};
const caseDetailsStr = {
  label: 'Case Details',
  value: 'caseDetailsStr',
  id: 'caseDetailsStr'
};
const caseNumStr = {
  label: 'Case Number',
  value: 'caseNumStr',
  id: 'caseNumStr'
};
const decisionDateStr = {
  label: 'Decision Date',
  value: 'decisionDateStr',
  id: 'decisionDateStr'
};
const decisionStr = {
  label: 'Decision',
  value: 'decisionStr',
  id: 'decisionStr'
};

const plaintiff = {
  label: 'Plaintiff',
  value: 'plaintiff',
  items: [{
    label: 'Id',
    id: 'plaintiffId',
    value: 'plaintiffId',
  }, {
    label: 'Name',
    id: 'plaintiffName',
    value: 'plaintiffName',
  }]
}
const defendant = {
  label: 'Defendant',
  value: 'defendant',
  items: [{
    label: 'Id',
    id: 'defendantId',
    value: 'defendantId',
  }, {
    label: 'Name',
    id: 'defendantName',
    value: 'defendantName',
  }]
}
const court = {
  label: 'Court',
  value: 'court',
  items: [{
    label: 'Id',
    value: 'courtEntityId',
    id: 'courtEntityId'
  }, {
    label: 'Name',
    id: 'courtEntityName',
    value: 'courtEntityName',
  }]
}

export const dtrmColumns = {
  [ENTITY_TYPE.person]: [
    Object.assign({}, entityId, { isLink: true }),
    nameStr,
    Object.assign({}, nameStartDateStr, { label: 'Date of birth' }),
    Object.assign({}, nameEndDateStr, { label: 'Date of death' }),
    sourceId,
    cleanNameStr,
    flags
  ],
  [ENTITY_TYPE.company]: [
    Object.assign({}, entityId, { isLink: true }),
    nameStr,
    nameStartDateStr,
    nameEndDateStr,
    sourceId,
    cleanNameStr,
    flags,
  ],
  [ENTITY_TYPE.family]: [
    entityId,
    nameStr,
    nameStartDateStr,
    nameEndDateStr,
    sourceId,
    flags,
  ],
  [ENTITY_TYPE.otherOrganisation]: [
    entityId,
    nameStr,
    nameStartDateStr,
    nameEndDateStr,
    sourceId,
    flags,
  ],
  [ENTITY_TYPE.governmentAgency]: [
    entityId,
    nameStr,
    nameStartDateStr,
    nameEndDateStr,
    sourceId,
    flags,
  ],
  relationship: [
    relId,
    relEntityId,
    relEntity2Id,
    sourceStr,
    relTypeDescStr,
    relDescStr,
    errorLinkFlag
  ],
  litigation: [
    litigationId,
    caseDetailsStr,
    caseNumStr,
    court,
    plaintiff,
    defendant,
    decisionDateStr,
    decisionStr,
  ]
}

export const esColumns = {
  [ENTITY_TYPE.person]: [{
    label: 'Datarama ID',
    value: 'dataramaId',
    isLink: true,
  }, {
    label: 'Name ',
    value: 'all.name',
    items: [{
      label: 'Name',
      value: 'name',
    }, {
      label: 'other name',
      value: 'namenonenglish'
    }]
  }, {
    label: 'Country',
    value: 'nationality'
  }, {
    label: 'Year Born',
    value: 'yearborn'
  }, {
    label: 'Biography',
    value: 'biography',
    isExist: true,
  },{
    label: 'PEP',
    value: 'pep',
    isFlag: true,
  }, {
    label: 'Blacklisted',
    value: 'blacklist',
    isFlag: true,
  }, {
    label: 'Alive?',
    value: 'alive',
    isExist: true,
  }, {
    label: 'Public Profile',
    value: 'publicProfile',
    isExist: true,
  }, {
    label: 'Exec.Summary',
    value: 'executiveSummary',
    isExist: true,
  }],
  [ENTITY_TYPE.company]: [{
    label: 'Datarama ID',
    value: 'dataramaId',
    isLink: true,
  }, {
    label: 'Name ',
    width: 4,
    items: [{
      label: 'Name',
      value: 'name',
    }, {
      label: 'other name',
      value: 'namenonenglish',
    }]
  }, {
    label: 'Country',
    value: 'country'
  }, {
    label: 'Type',
    value: 'companytype'
  }, {
    label: 'Sector',
    value: 'sector'
  }, {
    label: 'Status',
    value: 'companystatus'
  }, {
    label: 'Exec.Summary',
    value: 'executiveSummary',
    isExist: true,
  }, {
    label: 'Public Profile',
    value: 'publicProfile',
    isExist: true,
  }, {
    label: 'Financials',
    value: 'financials',
    isExist: true,
  }, {
    label: 'Rating',
    value: 'riskRating'
  }, {
    label: 'Year Founded',
    value: 'yearfounded'
  }]
}
